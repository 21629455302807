import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Flex, Row } from "antd";
import kwhIcon from "../../../Images/kwh.svg";
import pfIcon from "../../../Images/pf.svg";
import runtimeIcon from "../../../Images/runtime.svg";
import productionIcon from "../../../Images/production.svg";
import kvahIcon from "../../../Images/kvah.svg";
import kvaIcon from "../../../Images/kva.svg";
import energyIntensityIcon from "../../../Images/energy_intensity.svg";
import utilizationIcon from "../../../Images/utilization.svg";
import { Box, Typography } from "@mui/material";

const icons = [
  kwhIcon,
  pfIcon,
  runtimeIcon,
  productionIcon,
  kvahIcon,
  kvaIcon,
  energyIntensityIcon,
  utilizationIcon,
];

const MonthlyCalendertab = ({ selectedMonth, selectedStationPoint }) => {
  const [monthlyEvent, setMonthlyEvents] = useState(null); // Initially set to null

  const fetchMonthlyEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/monthlycalendar-events`,
        {
          params: {
            month: selectedMonth || new Date().toISOString().slice(0, 7),
            stationPoint: selectedStationPoint || "HV",
          },
        }
      );
      setMonthlyEvents(response.data); // Set data from API response
    } catch (error) {
      console.error("Error fetching monthly calendar events:", error);
      setMonthlyEvents(null); // Handle errors by setting to null
    }
  };

  useEffect(() => {
    fetchMonthlyEvents();
  }, [selectedMonth, selectedStationPoint]);

  const renderValue = (value) => (value === null ? "N/A" : value);

  // Function to check if the object is empty
  const isEmptyObject = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  console.log(monthlyEvent?.cardsData);

  return (
    <Box mt={1}>
      <Row gutter={[10, 10]}>
        {monthlyEvent?.cardsData &&
          monthlyEvent?.cardsData.map((data, index) => (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card style={{ height: "130px" }}>
                <Typography fontWeight={'600'}>{data.label}</Typography>

                <Flex
                  style={{ marginTop: "30px" }}
                  justify="space-between"
                  align="center"
                >
                  <Typography>{renderValue(data.value)}</Typography>

                  <img
                    src={icons[index]}
                    alt={data.label}
                    style={{ width: 40, height: 40 }}
                  />
                </Flex>
              </Card>
            </Col>
          ))}
      </Row>
    </Box>
  );
};

export default MonthlyCalendertab;
