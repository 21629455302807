
import React, { useState } from 'react';
import Calendartab from '../Calendertab/Calendertab';
import MonthlyCalendertab from '../MonthlyCalender/MonthlyCalender';
import Calendercombinecontainer from './Calendercombinecontainer';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import { Calendereventsheader } from '../../Operator/Data';
import './Calendercombine.css';
import TopBar from '../../Commonfns/Topbar/Topbar';
const Calenderscombine = () => {
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    });
    const [selectedStationPoint, setSelectedStationPoint] = useState('HV');

    const handleMonthChange = (month) => {
        setSelectedMonth(month);
    };

    const handleStationPointChange = (stationPoint) => {
        setSelectedStationPoint(stationPoint);
    };

    return (
        <div id='calendercombine-total'>
            <div id='calendercombine-right'>
                <div id='calendercombine-totalright'>
                    <Calendercombinecontainer
                        selectedMonth={selectedMonth}
                        onMonthChange={handleMonthChange}
                        onStationPointChange={handleStationPointChange}
                        defaultStationPoint={selectedStationPoint}
                    />
                    <div id='calendercombine-bothtabs'>
                    <MonthlyCalendertab selectedMonth={selectedMonth} selectedStationPoint={selectedStationPoint} />
                    <Calendartab selectedMonth={selectedMonth} selectedStationPoint={selectedStationPoint} />
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default Calenderscombine;
