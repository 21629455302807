

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronRight, FaChevronUp } from "react-icons/fa";
import "../../Operator/DailyConsumption/DailyConsumption.css";
import { FaFileCsv } from "react-icons/fa6";
import "./Dailycomsptionreport.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import MultiStationpoints from "../../Commonfns/MultiStationpoints";

const Dailycomsptionreport = () => {
  const [sections, setSections] = useState([]); // API response data
  const [expandedSections, setExpandedSections] = useState({}); // For hierarchy
  const [sectionFilter, setSectionFilter] = useState([]); // Multi-section filter
  const [dateFilter, setDateFilter] = useState(getThisMonth()); // Month filter
  const [loading, setLoading] = useState(false); // Loading state

  // Get the current month
  function getThisMonth() {
    return dayjs().format("YYYY-MM"); // Current month in "YYYY-MM" format
  }

  // Fetch data whenever `sectionFilter` or `dateFilter` changes
  useEffect(() => {
    fetchData();
  }, [sectionFilter, dateFilter]);

  const fetchData = () => {
    const queryParams = new URLSearchParams();
    // if (sectionFilter.length > 0) queryParams.append("sectionNames", sectionFilter.join(","));\
    if (sectionFilter.length > 0) {
      let encodedSections = sectionFilter.map((sec) => encodeURIComponent(sec));
      queryParams.append("sectionNames", encodedSections.join(",")); // Multiple sections
    }
    if (dateFilter) queryParams.append("month", dateFilter);

    axios
      .get(
        `${
          process.env.REACT_APP_API_EMS_URL
        }/api/accumlativeconsumption-data?${queryParams.toString()}`
      )
      .then((response) => {
        setSections(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sections data:", error);
      });
  };

  const handleStationPointChange = (selectedStationPoints) => {
    setSectionFilter(selectedStationPoints || []); // Update filter state
  };

  const handleDateChange = (date) => {
    if (date) {
      setDateFilter(date.format("YYYY-MM")); // Ensure date is formatted as "YYYY-MM"
    } else {
      setDateFilter(getThisMonth()); // Default to the current month
    }
  };

  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const flattenSections = (sections) => {
    const flatData = [];
    const flatten = (section, parentName = "") => {
      flatData.push({
        section_name: section.section_name,
        parent_name: parentName,
        date: dateFilter,
        consumption: section.value ?? "--",
      });
      section.childs.forEach((child) => flatten(child, section.section_name));
    };
    sections.forEach((section) => flatten(section));
    return flatData;
  };

  const downloadCSV = () => {
    try {
      setLoading(true);
      const flatData = flattenSections(sections);
      const month = dateFilter || getThisMonth();
      const sectionName = sectionFilter.length > 0 ? sectionFilter.join("_") : "AllSections";

      const csvContent =
        "data:text/csv;charset=utf-8," +
        ["Section Name,Parent Name,Month,Consumption"]
          .concat(
            flatData.map(
              (row) =>
                `${row.section_name},${row.parent_name},${month},${row.consumption}`
            )
          )
          .join("\n");

      const encodedUri = encodeURI(csvContent);
      const fileName = `Consumption-month-${month}.csv`;
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderSections = () => {
    if (sectionFilter.length > 0) {
      // Flat rendering for filtered sections
      return sections.map((section) => (
        <div key={section.section_name} className="daily-consumpton-tr">
          <div className="chevron-container"></div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="consumption-column">{section.value ?? "--"}</div>
        </div>
      ));
    }

    // Hierarchical rendering for unfiltered sections
    return sections.map((section) => renderRow(section));
  };

  const renderRow = (section, level = 0) => {
    const isExpanded = expandedSections[section.section_name];

    return (
      <React.Fragment key={section.section_name}>
        <div
          className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""} level-${level + 1}`}
        >
          <div className="chevron-container">
            {section.childs?.length > 0 ? (
              <div onClick={() => toggleSection(section.section_name)}>
                {isExpanded ? <FaChevronUp /> : <FaChevronRight />}
              </div>
            ) : (
              <div style={{ width: "16px" }}></div>
            )}
          </div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="consumption-column">{section.value ?? "--"}</div>
        </div>
        {isExpanded && section.childs?.map((child) => renderRow(child, level + 1))}
      </React.Fragment>
    );
  };

  return (
    <div id="dailyconsumptionreport-right-2">
      <div id="dailyconsumptionreport-right-21">
        <label>
          <span>Select Month:</span>
          <DatePicker
            allowClear={false}
            size="medium"
            picker="month"
            value={dateFilter ? dayjs(dateFilter, "YYYY-MM") : null}
            onChange={handleDateChange}
            disabledDate={(current) => current && current > dayjs()}
          />
        </label>
        <label>
          <MultiStationpoints onStationPointChange={handleStationPointChange} />
        </label>
        <label>
        <Button
          type="primary"
          icon={
            loading ? (
              <LoadingOutlined style={{ color: "#252B3F" }} />
            ) : (
              <FaFileCsv style={{ color: "#252B3F" }} />
            )
          }
          onClick={downloadCSV}
        />
        </label>
     
      </div>
      <div id="dailyconsumption-right-22">
        <div id="dailyconsumption-right-221">
          <div className="chevron-container"></div>
          <div className="daily-consyumptoon-t1">Section Name</div>
          <div className="consumption-column">kWh Consumption</div>
        </div>
        <div className="section-body">{renderSections()}</div>
      </div>
    </div>
  );
};

export default Dailycomsptionreport;
