// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#opalert-total {
    background-color: #F3F6FE;
    height: auto !important;
}

#alert-right-2 {
    #alert-right-21 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 75px;   
        width: 100%;
        background-color: white;
        border: 1px solid #DFE6FA;
        border-radius: 10px;
        align-content: center;

        label {
            display: flex;
            align-content: center;
            flex-direction: column;
            /* justify-content: flex-end; */
            margin-right: 30px;
        }

        button {
            padding: 4.5px 2px;
            /* width: 5vw; */
            /* margin-left: 35%; */
            background: transparent;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            font-size: 25px;
            margin-right: 20px;
        }
    }

    #alert-right-3 {
        table {
            margin-top: 1vh;
            background-color: white;
            width: 100%;
            /* height: 55vh; */
            height: 500px;
            overflow-y: scroll;
            display: block;
        }
        th,
        td {
            width: calc(91vw / 7);
        }
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/Operator/AlertsTable/Alertstable.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,yBAAyB;QACzB,YAAY;QACZ,WAAW;QACX,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB,qBAAqB;;QAErB;YACI,aAAa;YACb,qBAAqB;YACrB,sBAAsB;YACtB,+BAA+B;YAC/B,kBAAkB;QACtB;;QAEA;YACI,kBAAkB;YAClB,gBAAgB;YAChB,sBAAsB;YACtB,uBAAuB;YACvB,kBAAkB;YAClB,YAAY;YACZ,eAAe;YACf,eAAe;YACf,kBAAkB;QACtB;IACJ;;IAEA;QACI;YACI,eAAe;YACf,uBAAuB;YACvB,WAAW;YACX,kBAAkB;YAClB,aAAa;YACb,kBAAkB;YAClB,cAAc;QAClB;QACA;;YAEI,qBAAqB;QACzB;IACJ;AACJ","sourcesContent":["#opalert-total {\n    background-color: #F3F6FE;\n    height: auto !important;\n}\n\n#alert-right-2 {\n    #alert-right-21 {\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        height: 75px;   \n        width: 100%;\n        background-color: white;\n        border: 1px solid #DFE6FA;\n        border-radius: 10px;\n        align-content: center;\n\n        label {\n            display: flex;\n            align-content: center;\n            flex-direction: column;\n            /* justify-content: flex-end; */\n            margin-right: 30px;\n        }\n\n        button {\n            padding: 4.5px 2px;\n            /* width: 5vw; */\n            /* margin-left: 35%; */\n            background: transparent;\n            border-radius: 5px;\n            border: none;\n            cursor: pointer;\n            font-size: 25px;\n            margin-right: 20px;\n        }\n    }\n\n    #alert-right-3 {\n        table {\n            margin-top: 1vh;\n            background-color: white;\n            width: 100%;\n            /* height: 55vh; */\n            height: 500px;\n            overflow-y: scroll;\n            display: block;\n        }\n        th,\n        td {\n            width: calc(91vw / 7);\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
