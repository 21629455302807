import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LighteningArrestors.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";

const LightingArrestor = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,

        groundpointnumber: Number(values.groundpointnumber),
        voltagerating: Number(values.voltagerating),
        currentrating: Number(values.currentrating),
        responsetime: Number(values.responsetime),
        groundresistance: Number(values.groundresistance),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors`,
        dataToSend
      );

      toast.success("Lighting Arrestor added successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Lighting Arrestor");
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.LightingArrestorHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Location" name="location">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Location Number" name="locationnumber">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type of Arrestor" name="typeofarrestor">
                  <Select placeholder="Select Type of Arrestor">
                    <Select.Option value="ROD">ROD</Select.Option>
                    <Select.Option value="Cable">Cable</Select.Option>
                    <Select.Option value="Station Class">
                      Station Class
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Ground Point Number" name="groundpointnumber">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Voltage Rating" name="voltagerating">
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Current Rating" name="currentrating">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Response Time" name="responsetime">
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Ground Resistance" name="groundresistance">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Proposed Periodicity of Maintenance"
                  name="proposedperiodicityofmaintenance"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Previous Maintenance Done Date"
                  name="previousmaintenancedonedate"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LightingArrestor;
