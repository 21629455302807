import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Mastertransformer.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";

const Mastertransformerform = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [form] = Form.useForm();
  const [parentNames, setParentNames] = useState([]);

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-hvincomerNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching incomer names", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        primaryvoltage: Number(values.primaryvoltage),
        secondaryvoltage: Number(values.secondaryvoltage),
        kvarating: Number(values.kvarating),
        fullloadcurrent: Number(values.fullloadcurrent),
        impedence: Number(values.impedence),
        idleloadcurrent: Number(values.idleloadcurrent),
        slno: Number(values.slno),
        dimentions: Number(values.dimentions),
        weight: Number(values.weight),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers`,
        dataToSend
      );

      toast.success("Transformer added successfully");
      form.resetFields();
      fetchParentNames();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Transformer");
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.TransformerHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            {/* Form Fields */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Parent Name" name="parentname">
                  <Select id="ant-input-style" placeholder="Select Parent Name">
                    {parentNames.map((name, index) => (
                      <Select.Option key={index} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Name of the Transformer"
                  name="transformername"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Phase" name="phase">
                  <Select id="ant-input-style" placeholder="Select Phase">
                    <Select.Option value="3-Phase">3-Phase</Select.Option>
                    <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                    <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                    <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Primary Voltage" name="primaryvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Secondary Voltage" name="secondaryvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="kVA Rating" name="kvarating">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Full Load Current" name="fullloadcurrent">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Impedence" name="impedence">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Idle Load Current" name="idleloadcurrent">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Vector Type" name="vectortype">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Coolant Type" name="coolanttype">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Make" name="make">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Serial No." name="slno">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="IEC Standard" name="iecstandard">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="ISO Standard" name="isostandard">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Dimensions" name="dimentions">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Weight" name="weight">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Proposed Periodicity of Maintenance"
                  name="proposedperiodicityofmaintenance"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Previous Maintenance Done Date"
                  name="previousmaintenancedonedate"
                >
                  <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Mastertransformerform;
