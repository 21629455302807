import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Button as AntDButton, DatePicker, Flex, Popover } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import { getDistributionLosses } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import lightIcon from "../../../Images/light.svg";
import manIcon from "../../../Images/man.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const DistributionLosses = () => {
  const { t } = useTranslation();

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      year: dayjs(),
    },
  });

  const { year } = watch();

  const { data } = useQuery({
    queryKey: ["distributionLosses", year],
    queryFn: () => getDistributionLosses(year.format("YYYY")),
    retry: 0,
  });

  const chartData = {
    labels: data?.month_labels,
    datasets: [
      {
        type: "line",
        label: "Base Line",
        data: data?.month_labels?.map(() => data?.base_value),
        backgroundColor: "#DB4545",
        borderColor: "#DB4545",
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 0,
        tension: 0,
        datalabels: {
          display: false, // Hides labels on the chart
        },
      },
      {
        label: "Distribution Loss",
        data: data?.values,
        backgroundColor: data?.month_labels?.map((_date) => "#6174D7"),
        borderWidth: 1,
        maxBarThickness: 100,
      },
    ],
  };

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("year");
  };

  const onChange = (value) => {
    if (value) {
      setValue("year", value);
    }
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Loss Percentage",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      // duration: 50,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false, // Hide tooltip when hovering over multiple bars
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label; // Get the label for the current bar
            return `Month:  ${dayjs(label).format("MMM YYYY")}`;
          },
        }
  
      },
      datalabels: {
        color: "white",
        display: (context) => context.dataset.data[context.dataIndex] != null,
        font: {
          // weight: "bold",
          size: 12,
        },
        anchor: "center", // Positions the label in the center of the bar
        align: "center", // Aligns the label with the bar's center
        rotation: -90, // Rotates the label to be vertical
      },
    },
  };

  const chartRef = React.useRef();

  const handleDownloadPdf = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) return;

    try {
      const canvas = await html2canvas(chartElement, {
        useCORS: true, // Ensures cross-origin images are loaded
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the chart into the page
      const ratio = Math.min(
        pageWidth / canvas.width,
        pageHeight / canvas.height
      );
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;

      // Calculate offsets to center the image
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("distribution_losses_trend.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
          display: "flex",
          justifyContent: "end",
          p: 1,
          mb: 1,
        }}
        // className="rcpe-filter-container"
      >
        <Flex align="end" gap={20}>
          <Box>
            <div>
              <label>{"Select Year"}</label>
            </div>

            <div>
              <DatePicker
                {...register("year")}
                style={{ width: "200px" }}
                defaultValue={getValues("year")}
                size={"medium"}
                picker={"year"}
                disabledDate={disableFutureMonths} // Restricts future months
                onChange={onChange}
              />
            </div>
          </Box>

          <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none", marginTop: 13 }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>

      <Paper
        ref={chartRef}
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("distributionLosses.heading")}
          </Typography>

          <Flex gap={10}>
            <Box>
              <Flex>
                <Typography>
                  Base Line:{" "}
                  <span style={{ color: "#DB4545" }}>
                    {data?.base_value || 0}
                  </span>
                </Typography>
              </Flex>
            </Box>
            <Popover
              placement="leftBottom"
              content={
                <Box maxWidth={600}>
                  <Box className="static-content-container">
                    <Box className="imp-container">
                      <img src={chatIcon} width={"40px"} height={"40px"} />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.whyIsThisImportanQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("distributionLosses.whyIsThisImportantAnswer")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="static-content-container">
                    <Box
                      className="imp-container"
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <img
                          style={{ alignSelf: "start" }}
                          src={manIcon}
                          width={"80px"}
                          height={"40px"}
                        />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.WhatShouldYouDoQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t("distributionLosses.whatShouldYouDoAnswer")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="static-content-container">
                    <Box className="imp-container">
                      <img src={lightIcon} width={"40px"} height={"40px"} />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.howWillYouImproveQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("distributionLosses.howWillYouImproveAnswer")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
              // title="Title"
            >
              <Box
                type="button"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.1,
                }}
                // onClick={() => setOpen()}
              >
                <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
                <Typography
                  fontSize={15}
                  style={{ display: "inline", color: "gray" }}
                >
                  How to use?
                </Typography>
              </Box>
            </Popover>
          </Flex>
        </Box>
        <Bar className="alerts-chart" data={chartData} options={options} />
        <div style={{ textAlign: "center" }}>{`year -- ${year}`}</div>
      </Paper>
    </>
  );
};

export default DistributionLosses;
