// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 #livesummary-total {
    background-color: #F3F6FE;
    height: 100%;
    background: cover;
}
#livesummary-right-2 {
    padding: 0px 10px 15px 10px;
}

#livesummary-lastupdated{
    padding: 0px 0px 5px 0px;
}

#livesummary-right-21 {
    height: 260px;
    background-color: white;
    border-radius: 10px;
    width: 100%;
}

#livesummary-right-22 {
    padding: 8px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    p {
        font-weight: bold;
        font-size: 400;
    }
    
    label {
        font-weight: bold;
        font-size: 400;
        font-size: 20px;
        align-items: center;
    }
}



`, "",{"version":3,"sources":["webpack://./src/Components/Livesummary/Livesummary.css"],"names":[],"mappings":";CACC;IACG,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,aAAa;IACb,8BAA8B;IAC9B;QACI,iBAAiB;QACjB,cAAc;IAClB;;IAEA;QACI,iBAAiB;QACjB,cAAc;QACd,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":["\n #livesummary-total {\n    background-color: #F3F6FE;\n    height: 100%;\n    background: cover;\n}\n#livesummary-right-2 {\n    padding: 0px 10px 15px 10px;\n}\n\n#livesummary-lastupdated{\n    padding: 0px 0px 5px 0px;\n}\n\n#livesummary-right-21 {\n    height: 260px;\n    background-color: white;\n    border-radius: 10px;\n    width: 100%;\n}\n\n#livesummary-right-22 {\n    padding: 8px 20px 10px 20px;\n    display: flex;\n    justify-content: space-between;\n    p {\n        font-weight: bold;\n        font-size: 400;\n    }\n    \n    label {\n        font-weight: bold;\n        font-size: 400;\n        font-size: 20px;\n        align-items: center;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
