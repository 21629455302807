

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PowerBills.css';
import { useLocation } from 'react-router-dom';
import { formatDateToMMYYYY } from '../../Operator/OperatorForms/Dateformat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const convertTMonthInputFormat = (dateString) => {
    const [mm, yyyy] = dateString.split('-');
    return `${yyyy}-${mm}`;
};

const PowerBills = ({ onViewData }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const unfilledMonth = queryParams.get('month') || ''; // Get date from query parameter


    const getCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month, so we add 1
        return `${year}-${month}`;
    };

    const [formData, setFormData] = useState({
        month: unfilledMonth ? convertTMonthInputFormat(unfilledMonth) : getCurrentMonth(),
        // month: unfilledMonth || getCurrentMonth(),
        kWhOnFromDate: '',
        kWhOnToDate: '',
        Billed_kWh_units: '',
        kVAhOnFromDate: '',
        kVAhOnToDate: '',
        Billed_kVAh_units: '',
        CumulativeMaxDemand: '',
        BilledMD: '',
        NumberOfResets: '',
        PenaltyTODkVAhConsumption: '',
        IncentiveTODkVAhConsumption: '',
        TotalBillInRupees: '',
        ['Multiplying_actor_(MF)']: '',
        ['Contracted_max_demand_(CMD)']: '',
        createdby: '',
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [userDesignation, setUserDesignation] = useState('');

    const [tariffData, setTariffData] = useState({
        GeneralTariff: '',
        PenaltyTariff: '',
        IncentivesTariff: ''
    });

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);

    useEffect(() => {
        const fetchFixedVariables = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`);

                if (response.data && response.data.length > 0) {
                    const fixedVariableData = response.data[0]; // Get the first document

                    // Map the fetched data into the formData state
                    setFormData((prevData) => ({
                        ...prevData,
                        ['Multiplying_actor_(MF)']: fixedVariableData['Multiplying_actor_(MF)'] || '',
                        ['Contracted_max_demand_(CMD)']: fixedVariableData['Contracted_max_demand_(CMD)'] || ''
                    }));
                } else {
                    console.error('No fixed variables data found');
                }
            } catch (error) {
                console.error('Error fetching fixed variables:', error);
                toast.error('Failed to fetch fixed variables data');
            }
        };

        fetchFixedVariables();
    }, []);

    useEffect(() => {
        const fetchTariffData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/tariff`);
                const result = await response.json();
                if (result && Array.isArray(result) && result.length > 0) {
                    const data = result[0];
                    setTariffData({
                        GeneralTariff: data['General Tariff'],
                        PenaltyTariff: data['Penalty Tariff'],
                        IncentivesTariff: data['Incentives Tariff']
                    });
                } else {
                    console.error('No tariff data received from the server');
                }
            } catch (error) {
                console.error('Error fetching tariff data:', error);
            }
        };

        fetchTariffData();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        const regexDouble = /^\d{1,15}(\.\d{0,2})?$/; // 15 digits with up to 2 decimal places
        const regexDouble8Digits = /^\d{1,8}(\.\d{0,2})?$/; // 8 digits with up to 2 decimal places
        const regexDouble2Digits = /^\d{1,2}(\.\d{0,2})?$/; // 2 digits with up to 2 decimal places
    
        let isValid = true;
    
        // Perform validations only if the value is not empty
        if (value !== '') {
            switch (name) {
                case 'kVAhOnFromDate':
                case 'kVAhOnToDate':
                case 'kWhOnFromDate':
                case 'kWhOnToDate':
                case 'CumulativeMaxDemand':
                case 'IncentiveTODkVAhConsumption':
                case 'PenaltyTODkVAhConsumption':
                case 'TotalBillInRupees':
                    if (!regexDouble.test(value)) {
                        isValid = false;
                        toast.error(`Please enter a valid double value with up to 15 digits and 2 decimal places ${name}.`);
                    }
                    break;
    
                case 'BilledMD':
                    if (!regexDouble8Digits.test(value)) {
                        isValid = false;
                        toast.error('Please enter a valid double value with up to 8 digits and 2 decimal places for Billed MD.');
                    }
                    break;
    
                case 'NumberOfResets':
                    if (!regexDouble2Digits.test(value)) {
                        isValid = false;
                        toast.error('Please enter a valid double value with up to 2 digits and 2 decimal places for No.of.Resets.');
                    }
                    break;
    
                default:
                    break;
            }
        }
    
        // Update state only if validation passes or the input is empty
        if (isValid || value === '') {
            setFormData((prevData) => {
                const updatedFormData = {
                    ...prevData,
                    [name]: value
                };
    
                // Perform additional calculations if necessary
                if (name === 'kWhOnFromDate' || name === 'kWhOnToDate') {
                    const initial = parseFloat(updatedFormData.kWhOnFromDate);
                    const final = parseFloat(updatedFormData.kWhOnToDate);
    
                    if (!isNaN(initial) && !isNaN(final) && initial > final) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            kWhOnFromDate: 'kWh On FromDate value cannot be greater than kWh On ToDate value.'
                        }));
                    } else {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            kWhOnFromDate: ''
                        }));
                        updatedFormData.Billed_kWh_units = !isNaN(initial) && !isNaN(final) ? final - initial : '';
                    }
                }
    
                if (name === 'kVAhOnFromDate' || name === 'kVAhOnToDate') {
                    const initial = parseFloat(updatedFormData.kVAhOnFromDate);
                    const final = parseFloat(updatedFormData.kVAhOnToDate);
    
                    if (!isNaN(initial) && !isNaN(final) && initial > final) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            kVAhOnFromDate: 'kVAh On FromDate value cannot be greater than kVAh On ToDate value.'
                        }));
                    } else {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            kVAhOnFromDate: ''
                        }));
                        updatedFormData.Billed_kVAh_units = !isNaN(initial) && !isNaN(final) ? final - initial : '';
                    }
                }
    
                return updatedFormData;
            });
        }
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any error exists in the errors object
        const hasErrors = Object.values(errors).some(errorMessage => errorMessage !== '');

        if (hasErrors) {
            toast.error('Please fix the errors before submitting');
            return; // Stop form submission if there are any errors
        }


        const formattedMonth = formatDateToMMYYYY(formData.month);

        const formattedData = {
            ...formData,
            month: formattedMonth,
            // kWhOnFromDate: parseFloat(formData.kWhOnFromDate),
            // kWhOnToDate: parseFloat(formData.kWhOnToDate),
            // Billed_kWh_units: parseFloat(formData.Billed_kWh_units),
            // kVAhOnFromDate: parseFloat(formData.kVAhOnFromDate),
            // kVAhOnToDate: parseFloat(formData.kVAhOnToDate),
            // Billed_kVAh_units: parseFloat(formData.Billed_kVAh_units),
            // CumulativeMaxDemand: parseFloat(formData.CumulativeMaxDemand),
            // BilledMD: parseFloat(formData.BilledMD),
            // NumberOfResets: parseFloat(formData.NumberOfResets),
            // PenaltyTODkVAhConsumption: parseFloat(formData.PenaltyTODkVAhConsumption),
            // IncentiveTODkVAhConsumption: parseFloat(formData.IncentiveTODkVAhConsumption),
            // TotalBillInRupees: parseFloat(formData.TotalBillInRupees),
            // ['Multiplying_actor_(MF)']: parseFloat(formData['Multiplying_actor_(MF)']),
            // ['Contracted_max_demand_(CMD)']: parseFloat(formData['Contracted_max_demand_(CMD)']),
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`, formattedData);
            if (response.data.success) {
                setFormData({
                    month: unfilledMonth ? convertTMonthInputFormat(unfilledMonth) : getCurrentMonth(),
                    kWhOnFromDate: '',
                    kWhOnToDate: '',
                    Billed_kWh_units: '',
                    kVAhOnFromDate: '',
                    kVAhOnToDate: '',
                    Billed_kVAh_units: '',
                    CumulativeMaxDemand: '',
                    BilledMD: '',
                    NumberOfResets: '',
                    PenaltyTODkVAhConsumption: '',
                    IncentiveTODkVAhConsumption: '',
                    TotalBillInRupees: '',
                    ['Multiplying_actor_(MF)']: '',
                    ['Contracted_max_demand_(CMD)']: '',
                    createdby: loggedInUser.username,
                });
                toast.success('Power bill data saved successfully');
            }
        } catch (error) {
            console.error('Error Adding Power bill', error);
            if (error.response && error.response.data.message === 'A log already exists for the month') {
                toast.error('Data already exists for the Selected Month, Please select any other Month');
            } else {
                toast.error('Failed to add Power bill. Please try again later.');
            }
        }
    };

    const handleKeyPress = (e) => {
        const invalidChars = ["e", "E", "+", "-"];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <div id='powerbills-total'>
            <div id='powerbills-right'>

                <div id='powerbills-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='powerbills-divtop'>

                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='powerbills-div1'>
                            <label>
                                <span>Month<span id='all-mandetorymark'>*</span></span>
                                <input type='month' name='month' value={formData.month} onChange={handleChange} max={getCurrentMonth()} required />
                            </label>
                            <label>
                                <span>kWh (initial reading)<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='kWhOnFromDate' value={formData.kWhOnFromDate} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress} />
                                {errors.kWhOnFromDate && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kWhOnFromDate}</span>}
                            </label>
                        </div>

                        <div id='powerbills-div1'>
                            <label>
                                <span>kWh (final reading)<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='kWhOnToDate' value={formData.kWhOnToDate} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress}/>
                                {errors.kWhOnToDate && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kWhOnToDate}</span>}
                            </label>
                            <label>
                                <span>Billed kWh units<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='Billed_kWh_units' value={formData.Billed_kWh_units} onChange={handleChange} disabled />
                            </label>
                        </div>
                        <div id='powerbills-div1'>
                            <label>
                                <span>kVAh (initial reading)<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='kVAhOnFromDate' value={formData.kVAhOnFromDate} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress}/>
                                {errors.kVAhOnFromDate && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kVAhOnFromDate}</span>}
                            </label>
                            <label>
                                <span>kVAh (final reading)<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='kVAhOnToDate' value={formData.kVAhOnToDate} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress} />
                                {errors.kVAhOnToDate && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kVAhOnToDate}</span>}
                            </label>
                        </div>
                        <div id='powerbills-div1'>
                            <label>
                                <span>Billed kVAh units<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='Billed_kVAh_units' value={formData.Billed_kVAh_units} onChange={handleChange} disabled />
                            </label>
                            <label>
                                <span>Cumulative max demand <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='CumulativeMaxDemand' value={formData.CumulativeMaxDemand} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress} />
                            </label>
                        </div>

                        <div id='powerbills-div1'>
                            <label>
                                <span>Billed MD<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='BilledMD' value={formData.BilledMD} onChange={handleChange} required maxLength="11"  onKeyPress={handleKeyPress} />
                            </label>
                            <label>
                                <span>No.of.Resets</span>
                                <input type='number' name='NumberOfResets' value={formData.NumberOfResets} onChange={handleChange} maxLength="5"  onKeyPress={handleKeyPress} />
                            </label>
                        </div>

                        <div id='powerbills-div1'>
                            <label>
                                <span>TOD kVAh consumption (Penalty  Hours) <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='PenaltyTODkVAhConsumption' value={formData.PenaltyTODkVAhConsumption} onChange={handleChange} required  onKeyPress={handleKeyPress} />
                            </label>
                            <label>
                                <span>TOD kVAh consumption (Incentive Hours) <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='IncentiveTODkVAhConsumption' value={formData.IncentiveTODkVAhConsumption} onChange={handleChange} required  onKeyPress={handleKeyPress} />
                            </label>
                        </div>

                        <div id='powerbills-div1'>
                            <label>
                                <span>Total Bill (In rupees) <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='TotalBillInRupees' value={formData.TotalBillInRupees} onChange={handleChange} required maxLength="18"  onKeyPress={handleKeyPress} />
                            </label>
                            <label>
                                <span>Multiplying Factor (MF)</span>
                                <input type='text' name="Multiplying_actor_(MF)" value={formData['Multiplying_actor_(MF)']} onChange={handleChange} disabled />
                            </label>
                        </div>

                        <div id='powerbills-div1'>
                            <label>
                                <span>Contracted max demand (CMD)</span>
                                <input type='text' name="Contracted_max_demand_(CMD)" value={formData['Contracted_max_demand_(CMD)']} onChange={handleChange} disabled />
                            </label>
                        </div>
                        <div id='powerbills-div1'>

                            <label>
                                <span>Note : Per unit price of energy consumption</span>
                                <ul>
                                    <li>for incentives ₹ <span>{tariffData.IncentivesTariff}</span></li>
                                    <li>for penalty ₹ <span>{tariffData.PenaltyTariff}</span></li>
                                    <li>for general ₹ <span>{tariffData.GeneralTariff}</span></li>
                                </ul>

                            </label>

                        </div>

                        <div  id='powerbills-save'>
                                <button  type="submit">Submit</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default PowerBills;
