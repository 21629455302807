import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./LiveAlerts.css";
import Stationpoints from "../../Commonfns/Stationpoints";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { Box, TableCell, TableRow } from "@mui/material";
import { Empty } from "antd";

const MAX_ALERTS = 500;

const parameterTableColumns = [
  { id: 1, label: "S. No.", align: "center" },
  { id: 2, label: "Section", align: "center" },
  { id: 3, label: "Parameter", align: "center" },
  { id: 4, label: "Description", align: "center" },
  { id: 5, label: "Type", align: "center" },
  { id: 6, label: "Value", align: "center" },
  { id: 7, label: "Date & Start Time", align: "center" },
];

const LiveAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [sectionFilter, setSectionFilter] = useState("");

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_LIVEALERTS_URL);

  //   socket.on('mqtt_alert', (data) => {
  //     console.log('Alert received:', data);
  //     const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

  //     setAlerts((prevAlerts) => {
  //       const existingAlertIndex = prevAlerts.findIndex(alert => alert.key === alertKey);

  //       if (existingAlertIndex !== -1) {
  //         // Update the existing alert
  //         const updatedAlerts = [...prevAlerts];
  //         updatedAlerts[existingAlertIndex] = {
  //           ...updatedAlerts[existingAlertIndex],
  //           eventValue: data.eventValue,
  //           dateTime: data.dateTime,
  //           start_time: data.start_time
  //         };
  //         localStorage.setItem('alerts', JSON.stringify(updatedAlerts));
  //         return updatedAlerts;
  //       } else {
  //         // Add a new alert
  //         const newAlerts = [{ ...data, key: alertKey }, ...prevAlerts].slice(0, MAX_ALERTS);
  //         localStorage.setItem('alerts', JSON.stringify(newAlerts));
  //         return newAlerts;
  //       }
  //     });
  //   });

  //   socket.on('connect_error', (err) => {
  //     console.error('Connection Error:', err);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_LIVEALERTS_URL);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log(data);
      

      const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

      setAlerts((prevAlerts) => {
        const existingAlertIndex = prevAlerts.findIndex(
          (alert) => alert.key === alertKey
        );

        if (existingAlertIndex !== -1) {
          // Update the existing alert
          const updatedAlerts = [...prevAlerts];
          updatedAlerts[existingAlertIndex] = {
            ...updatedAlerts[existingAlertIndex],
            eventValue: data.eventValue,
            dateTime: data.dateTime,
            start_time: data.start_time,
          };
          localStorage.setItem("alerts", JSON.stringify(updatedAlerts));
          return updatedAlerts;
        } else {
          // Add a new alert
          const newAlerts = [{ ...data, key: alertKey }, ...prevAlerts].slice(
            0,
            MAX_ALERTS
          );
          localStorage.setItem("alerts", JSON.stringify(newAlerts));
          return newAlerts;
        }
      });
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      ws.close();
      setAlerts([])
    }
  }, []);
  // useEffect(() => {
  //   const storedAlerts = JSON.parse(localStorage.getItem("alerts") || "[]");
  //   setAlerts(storedAlerts);
  // }, []);

  const handleStationPointChange = (selectedStationPoint) => {
    setSectionFilter(selectedStationPoint);
  };

  const filteredAlerts = alerts.filter((alert) =>
    alert.stationPoint.toLowerCase().includes(sectionFilter.toLowerCase())
  );

  return (
    <div>
      <div className="livealerts-right-2">
        <div>
          <label>
            <Stationpoints
              showAll={true}
              onStationPointChange={handleStationPointChange}
            />
          </label>
        </div>
      </div>

      <Box mt={2}>
        <CommonTable columns={parameterTableColumns}>
          {filteredAlerts.length === 0 && (
            <TableRow sx={{ height: 300 }}>
              <TableCell colSpan={7} align="center">
                <Empty description={"No data available"}></Empty>
              </TableCell>
            </TableRow>
          )}

          {filteredAlerts.map((alert, index) => (
            <TableRow key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{alert.stationPoint}</TableCell>
              <TableCell align="center">{alert.parameterName}</TableCell>
              <TableCell align="center">{alert.eventDescription}</TableCell>
              <TableCell align="center">{alert.eventType}</TableCell>
              <TableCell align="center">{alert.eventValue}</TableCell>
              <TableCell align="center">{alert.start_time}</TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </Box>
    </div>
  );
};

export default LiveAlerts;
