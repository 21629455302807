
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashTypeofload.css';

const DashTypeofloadConsumption = ({ selectedMonth }) => {
    const [data, setData] = useState([]);
    const [isDataAvailable, setIsDataAvailable] = useState(true);
    const chartRef = useRef(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption/month`,
                {
                    params: {
                        month: selectedMonth || new Date().toISOString().slice(0, 7),
                    },
                }
            );
            const fetchedData = response.data;
    
            if (Array.isArray(fetchedData) && fetchedData.length > 0) {
                setData(fetchedData);
                setIsDataAvailable(true);
            } else {
                console.warn("No valid data received from API");
                setIsDataAvailable(false);
            }
        } catch (error) {
            console.error("Error Fetching Type of Load Data:", error.message);
            setIsDataAvailable(false);
        }
    };
    

      useEffect(() => {
        fetchData();
      }, [selectedMonth]);

      useEffect(() => {
        if (chartRef.current && isDataAvailable && data.length > 0) {
            const myChart = echarts.init(chartRef.current);
    
            // Safely access and map data for the chart
            const seriesData = data.length > 0 
                ? data[0].type_of_load.map((type, index) => ({
                    value: parseFloat(data[0].percentage[index] || 0), // Default to 0 if undefined
                    name: type || "Unknown", // Default name if undefined
                }))
                : [];
    
            const chartOption = {
                title: {
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    data: data.length > 0 ? data[0].type_of_load : [],
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { show: true, type: ['pie', 'funnel'] },
                    },
                },
                color: [
                    '#34A853',
                    '#46BDC6',
                    '#7BAAF7',
                    '#FF6D01',
                    '#FCD04F',
                    '#F07B72',
                    '#EA4335',
                    '#CCBC81 ',
                ],
                series: [
                    {
                        name: 'Type of Load',
                        type: 'pie',
                        radius: '50%',
                        data: seriesData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                              return value + ' %';
                            }
                          },
                        label: {
                            show: true,
                            formatter: '{b} ({d}%)',
                        },
                    },
                ],
            };
    
            myChart.setOption(chartOption);
    
            // Resize chart on window resize
            window.addEventListener('resize', myChart.resize);
    
            // Cleanup on component unmount
            return () => {
                window.removeEventListener('resize', myChart.resize);
                myChart.dispose();
            };
        }
    }, [data, isDataAvailable]);
    

    return (
        <div id='dashtypeofload-total'>
            <span>Load Type Consumption Breakdown</span>
            <div>
                {!isDataAvailable && <p style={{textAlign: 'center'}}>No Data Available</p>}
                {isDataAvailable && <div ref={chartRef} id='dashtypeofload-piechart'></div>}
            </div>
        </div>
    );
};

export default DashTypeofloadConsumption;
