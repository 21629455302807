
import { useState, React, useEffect } from 'react';
import './Alertspage.css';
import OperatorAlertstable from '../AlertsTable/Alertstable';
import LiveAlerts from '../LiveAlerts/LiveAlerts';
import CommonTabs from "../../Common/CommonTabs/CommonTabs";

const Alertspage = () => {
    const tabs = [
        {
          name: "Live Alerts",
          label: "live-alerts",
          component: <LiveAlerts />,
        },
        {
          name: "Alerts History",
          label: "alerts-history",
          component: <OperatorAlertstable />,
        }
      ];



    return (
        <div>
          <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
        </div>
    );
};

export default Alertspage;




