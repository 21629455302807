import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Maxdemand.css'
// import { Link } from 'react-router-dom';

const Maxdemand = () => {
  const [maxdemandData, setMaxdemandData] = useState([]);

  useEffect(() => {
    const fetchDailyMaxdemandData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/monthpowerfactor/thismonth`);
        setMaxdemandData(response.data);
      } catch (err) {
        console.error('Error fetching daily PF data:', err);
      }
    };

    fetchDailyMaxdemandData();
  }, []);

  const firstData = maxdemandData[0] || {};


  return (

    <div id='maxdemand-total' >
      <div id='maxdemand-div1'>
        <span>Maximum Demand (kVA)</span>
      </div>
      <div id='maxdemand-div2'>
          {firstData.Maximum_Demand_HV_kVA || 'N/A'}
      </div>
      <div id='maxdemand-inside'>
        <label>
          <span>Last Updated :</span>
          {firstData.date || 'N/A'}
          <span> &nbsp; (24 Hours)</span>
        </label>

      </div>
    </div>
  )
}

export default Maxdemand