
import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import './App.css';
import io from 'socket.io-client';
import axios from 'axios';

const CustomMarquee = () => {
  const [imaxValues, setImaxValues] = useState({});
  const [sectionHierarchy, setSectionHierarchy] = useState(null);
  const [offlineSections, setOfflineSections] = useState([]);

  // Recursive function to extract sections from hierarchy
  const extractSections = (hierarchy, prefix = '') => {
    let sections = [];
    for (const [key, value] of Object.entries(hierarchy)) {
      const sectionName = prefix ? `${prefix} -> ${key}` : key;
      sections.push(sectionName);
      if (value.children) {
        sections = sections.concat(extractSections(value.children, sectionName));
      }
    }
    return sections;
  };

  // Fetch the section hierarchy from the API
  useEffect(() => {
    const fetchHierarchy = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/getectionshierarchy`);
        setSectionHierarchy(response.data);
      } catch (error) {
        console.error('Error fetching section hierarchy:', error);
      }
    };

    fetchHierarchy();
  }, []);

  // Establish WebSocket connection and track live sections
  useEffect(() => {
    const socket = io(process.env.REACT_APP_LIVESUMMARY_URL);

    socket.on('imax_percentage_values', (data) => {
      setImaxValues((prevValues) => ({ ...prevValues, ...data }));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Calculate offline sections whenever hierarchy or live data updates
  useEffect(() => {
    if (sectionHierarchy) {
      const allSections = extractSections(sectionHierarchy); // Get all sections from hierarchy
      const liveSections = Object.keys(imaxValues); // Live sections from WebSocket data

      const offline = allSections.filter((section) => !liveSections.includes(section));
      setOfflineSections(offline);
    }
  }, [sectionHierarchy, imaxValues]);

  return (
    <div className="custom-marquee">
      <Marquee
        gradient={false}
        speed={50}
        direction="right"
        pauseOnHover={true}
        pauseOnClick={true}
      >
        {offlineSections.length > 0 ? (
          offlineSections.map((section, index) => (
            <span key={index} style={{ margin: '0 20px' }}>
              {section} is Offline
            </span>
          ))
        ) : (
          <span style={{ color: 'green' }}>All sections are online</span>
        )}
      </Marquee>
    </div>
  );
};

export default CustomMarquee;

