import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VFD.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
const VFD = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [form] = Form.useForm();
    const [parentNames, setParentNames] = useState([]);
    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        vfdid: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        capacityinvw: '',
        voltagelevel: '',
        inputvoltage: '',
        outputvoltage: '',
        outputfrequency: '',
        frequencycontrolaccuracy: '',
        accelerationanddeaccelarationrates: '',
        energyefficiency: '',
        controltype: '',
        overcurrentprotectionavailable: '',
        overvoltageprotectionavailable: '',
        regenartivecapability: '',
        harmonicmigartion: '',
        compatability: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };


    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleSubmit = async (values) => {
        try {
            const dataToSend = {
                ...values,
                capacityinvw: Number(values.capacityinvw),
                voltagelevel: Number(values.voltagelevel),
                inputvoltage: Number(values.inputvoltage),
                outputvoltage: Number(values.outputvoltage),
                outputfrequency: Number(values.outputfrequency),
                frequencycontrolaccuracy: Number(values.frequencycontrolaccuracy),
                accelerationanddeaccelarationrates: Number(values.accelerationanddeaccelarationrates),
                energyefficiency: Number(values.energyefficiency),
                harmonicmigartion: Number(values.harmonicmigartion),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/vfds`, dataToSend);

            toast.success('VFD added successfully');
            form.resetFields();
            fetchParentNames();
        } catch (error) {
            console.error("Error submitting data", error);
            toast.error("Error adding VFD");
        }
    };

    return (
        <div id='masterforms-total'>
            <div id='masterforms-right'>
                <div id='masterforms-right-2'>
                <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ date: getCurrentDate() }}
                        onFinish={handleSubmit}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h3>{Masterformsheadingsss.VFDHeading}</h3>
                            <Button
                                type="default"
                                onClick={onViewData}
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                View Data
                            </Button>
                        </div>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Date" name="date">
                                    <Input id="ant-input-style" type="date" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Parent Name" name="parentname">
                                    <Select id="ant-input-style" placeholder="Select Parent Name">
                                        {parentNames.map((name, index) => (
                                            <Select.Option key={index} value={name}>
                                                {name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Meter Make" name="metermake">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Meter Serial No." name="meterslno">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Phase" name="phase">
                                    <Select id="ant-input-style" placeholder="Select Phase">
                                        <Select.Option value="">Select Phase</Select.Option>
                                        <Select.Option value="3-Phase">3-Phase</Select.Option>
                                        <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                                        <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                                        <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Capacity (in kW)" name="capacityinvw">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Voltage Level" name="voltagelevel">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Input Voltage" name="inputvoltage">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Output Voltage" name="outputvoltage">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Output Frequency" name="outputfrequency">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Frequency Control Accuracy" name="frequencycontrolaccuracy">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Acceleration and Deacceleration Rates" name="accelerationanddeaccelarationrates">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Energy Efficiency" name="energyefficiency">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Control Type" name="controltype">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Overcurrent Protection Available" name="overcurrentprotectionavailable">
                                    <Select id="ant-input-style" placeholder="Select Option">
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Overvoltage Protection Available" name="overvoltageprotectionavailable">
                                    <Select id="ant-input-style" placeholder="Select Option">
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Regenerative Capability" name="regenartivecapability">
                                    <Select id="ant-input-style" placeholder="Select Regenerative Capability">
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Harmonic Migration" name="harmonicmigartion">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Compatibility" name="compatability">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Proposed Periodicity of Maintenance" name="proposedperiodicityofmaintenance">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Previous Maintenance Done Date" name="previousmaintenancedonedate">
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default VFD;
