import { Box, CircularProgress, Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DatePicker, Segmented, Select } from "antd";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/services/axios";

const { Option } = Select;
const CriticalAlertsPattern = () => {
  const { setValue: setHookValue, watch } = useForm({
    defaultValues: {
      range: "TD",
      dateRange: [],
      stationPoint: "HV",
    },
  });

  const { range, dateRange, stationPoint } = watch();

  const navigate = useNavigate();

  const [startDate, endDate] = dateRange;

  const { data: sectionData } = useQuery({
    queryKey: ["critical-alerts-section"],
    queryFn: async () => {
      return (await axiosInstance.get(`/api/renewable-sections`)).data;
    },
  });

  const {
    data: apiData,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["live-critical-count", stationPoint, range, startDate, endDate],
    queryFn: async ({ queryKey }) => {
      const [_, stationPoint, range, startDate, endDate] = queryKey;
      const params = {
        fromDate: startDate?.format("YYYY-MM-DD"),
        toDate: endDate?.format("YYYY-MM-DD"),
        range,
        stationPoint,
      };
      return (await axiosInstance.get(`/api/critical-alerts-count`, { params }))
        .data;
    },
    retry: 3,
  });

  const options = {
    chart: {
      height: 600, // Adjust chart height here
    },
    rangeSelector: {
      enabled: false, // Disable the range selector
    },
    series: [
      {
        name: "Current Count",

        data: apiData?.data?.currentCount,
        type: "area", // Set type to 'area' for filled region
        tooltip: {
          valueDecimals: 2, // Display numbers with 2 decimal points in the tooltip
        },
        color: "#007bff", // Line color
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(0, 123, 255, 0.5)"], // Gradient start color
            [1, "rgba(0, 123, 255, 0)"], // Gradient end color
          ],
        },
      },
    ],

    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        // text: 'Price'
      },
    },

    tooltip: {
      useHTML: true,
      formatter: function () {
        const date = this.x;
        const currentCount = this.y;

        // find total count from apiData of above points
        const totalCountPoint = apiData?.data?.totalCount.find(
          (point) => new Date(point[0]).getTime() === date
        );

        // Retrieve the total count value (or default to 0 if not found)
        const totalCount = totalCountPoint ? totalCountPoint[1] : 0;

        return `
      <div style="text-align: left; width: 200px; padding: 10px; border-radius: 5px; background: white; box-shadow: 0px 2px 6px rgba(0,0,0,0.1);">
        <p id="tooltip-date"><strong>Date:</strong> 
        
        ${moment.utc(date).format("DD-MM-YYYY HH:mm:ss")}
          
          </p>
        <p id="tooltip-current-count"><strong>Current Count:</strong> ${
          currentCount !== "N/A" ? currentCount?.toFixed(2) : "N/A"
        }</p>
        <p id="tooltip-total-count"><strong>Total Count:</strong> ${
          totalCount !== "N/A" ? totalCount?.toFixed(2) : "N/A"
        }</p>


        <div  style="text-align: right;">

          <button
              id="details-button"
              style="background-color: #007bff; color: white; border: none; padding: 5px 10px; border-radius: 4px; cursor: pointer;"
            >
             View details
            </button>
        </div>

       

      </div>
    `;
      },
    },
    chart: {
      height: 500,
      events: {
        load: function () {
          const chart = this;
          chart.container.addEventListener("click", function (event) {
            const button = event.target.closest("#details-button");
            if (button) {
              const dateElement = document.getElementById("tooltip-date");

              const date = dateElement
                ? dateElement.textContent.replace("Date: ", "").trim()
                : null;

              if (date) {
                navigate(
                  `/critical-alerts-tab?section=critical-alerts&station-point=${stationPoint}&from=${moment
                    .utc(date)
                    .format("YYYY-MM-DD HH:mm")}&to=${moment
                    .utc(date)
                    .add(60, "minutes")
                    .format("YYYY-MM-DD HH:mm")}`
                );
              } else {
                console.error(
                  "Unable to fetch date or value from the tooltip."
                );
              }
            }
          });
        },
      },
    },
  };
  const { RangePicker } = DatePicker;

  const onChangeStationPoint = (value) => {
    setHookValue("stationPoint", value);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #DFE6FA",
        //    display: "flex",
        justifyContent: "end",
        //    p: 1,
        mb: 1,
        p: 1,
      }}
    >
      <Box display={"flex"} justifyContent={"end"} gap={2}>
        <Select
          showSearch={true}
          allowClear={true}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size={"medium"}
          placeholder={"Select Section"}
          style={{ width: "200px" }}
          defaultValue={stationPoint}
          onChange={onChangeStationPoint}
        >
          {sectionData?.length > 0
            ? sectionData?.map((option) => (
                <Option
                  key={option?.["Station point"]}
                  value={option?.["Station point"]}
                >
                  {option?.["Station point"]}
                </Option>
              ))
            : null}
        </Select>

        <Segmented
          options={["TD", "24H", "1W", "1M", "3M", "1Y", "All"]}
          onChange={(value) => {
            setHookValue("range", value);
          }}
        />

        <RangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          onChange={(value) => setHookValue("dateRange", value ? value : [])}
          type="date"
        />
      </Box>

      {isLoading && (
        <Box
          height={500}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {isSuccess && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={options}
        />
      )}
    </Paper>
  );
};

export default CriticalAlertsPattern;
