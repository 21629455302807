
import {useNavigate , useLocation } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { Link } from "react-router-dom";
import { DrawerState } from "../../../api/client/drawer.state";
import AppBarComponent from "../AppBar";
import { getNavbarlist, OperatorNavBarList } from "../../../utils/navbar";
import { routes } from "../../../routes";
import logo from "../../../Images/logo.svg";
const drawerWidth = 280;


const openedMixin = (theme) => ({
  flexGrow: 1,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  height: "100vh",
});

const closedMixin = (theme) => ({
  flexGrow: 1,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  // height: "100vh",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: window.innerWidth - (window.innerWidth - 64), // Cover the left 10%
      height: "100%", // Full height
      backgroundColor: "#252B3F", // Example color
      // zIndex: -1, // Send behind the AppBar
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100vh",
  flexShrink: 1,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    margin: 0,
    height: "100vh",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    margin: 0,
    height: "100vh",
  }),
}));

const MenuItemButton = styled(ListItemButton)(({ theme }) => ({
  "&:hover": {
    boxShadow: `0 4px 8px ${theme.palette.grey[700]}`,
  },
}));

export default function SideBar() {
  // const { mutate: logout } = useLogout();

  const [openSubmenus, setOpenSubmenus] = React.useState({});


  const navigate = useNavigate();
  const location = useLocation();

    // Find the current route in the routes array
    const currentRoute = routes.find((route) => route.path === location.pathname);

    // If the route exists, use its heading; otherwise, fallback to a default heading
    const pageName = currentRoute?.heading || "";

const handleLogout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessedLevel');
  navigate('/');
};


  const handleClick = (index) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const open = DrawerState((state) => state.isOpen);

  const setDrawerOpen = DrawerState((state) => state.setDrawerOpen);
  const setDrawerClose = DrawerState((state) => state.setDrawerClose);

  const handleDrawerOpen = () => {
    setDrawerOpen();
  };

  const handleChildClick = React.useCallback((event) => {
    // Stop event from bubbling up to parent
    event.stopPropagation();
  }, []);

  const navList = React.useMemo(
    () => getNavbarlist(),
    [localStorage.getItem("accessedLevel")]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        elevation={2}
        sx={{ backgroundColor: "#ffffff" }}
        position="fixed"
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <AppBarComponent pageName={pageName} />
        </Toolbar>
      </AppBar>

      <Drawer
        onMouseEnter={() => setDrawerOpen()}
        onMouseLeave={() => setDrawerClose()}
        variant="permanent"
        open={open}
      >
        <DrawerHeader sx={{ backgroundColor: "#252B3F", color: "white" }}>
          <img src={logo} height={50} />
        </DrawerHeader>
        <Divider />

        <List
          sx={{
            backgroundColor: "#252B3F",
            height: "100vh",
            color: "white",
          }}
        >
          {navList.map((navItem, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding>
                {navItem.children ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: drawerWidth,
                    }}
                  >
                    <MenuItemButton
                      onClick={() => handleClick(index)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {navItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="heading"
                            sx={{ color: "#fff", fontSize: 16 }}
                          >
                            {navItem.label}
                          </Typography>
                        }
                      />
                      {openSubmenus[index] ? <ExpandLess /> : <ExpandMore />}
                    </MenuItemButton>

                    <Collapse in={openSubmenus[index]}>
                      {navItem.children.map((child, childIndex) => (
                        <Link
                          key={childIndex}
                          to={child.url}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <MenuItemButton>
                            <ListItemIcon sx={{ color: "white" }}>
                              {child.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="heading"
                                  sx={{ color: "#fff", fontSize: 16 }}
                                >
                                  {child.label}
                                </Typography>
                              }
                            />
                          </MenuItemButton>
                        </Link>
                      ))}
                    </Collapse>
                  </Box>
                ) : (
                  <Link
                    to={navItem.url}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      width: "100%",
                    }}
                    onClick={(event) => event.stopPropagation()} // Ensure no bubbling
                  >
                    <MenuItemButton>
                      <ListItemIcon sx={{ color: "white" }}>
                        {navItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="heading"
                            sx={{ color: "#fff", fontSize: 16 }}
                          >
                            {navItem.label}
                          </Typography>
                        }
                      />
                    </MenuItemButton>
                  </Link>
                )}
              </ListItem>
            </React.Fragment>
          ))}
        </List>

        <ListItem
          disablePadding
          sx={{
            backgroundColor: "#252B3F",
            width: "100%",
            color: "white",
          }}
        >
          <MenuItemButton onClick={handleLogout}>
            <ListItemIcon sx={{ color: "white" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </MenuItemButton>
        </ListItem>
      </Drawer>
    </Box>
  );
}
