import { useState, React, useEffect } from "react";
import "./Allreports.css";
import TopBar from "../../Commonfns/Topbar/Topbar";
import Supervisorsidebar from "../../Supervisor/Supersidebar/Supersidebar";
import ReportsAlertscount from "../AlertsCount/ReportAlertcount";
import ReportDowntime_Uptime from "../Downtimereport/ReportDowntime";
import ReportRCPE_Report from "../RCPEreports/ReportsRCPE";
import Dailycomsptionreport from "../Dailycomsptionreport/Dailycomsptionreport";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CommonTabs from "../../Common/CommonTabs/CommonTabs";
import ScoreCard from "../ScoreCard/ScoreCard";
import WeeklyReport from "../WeeklyReport/WeeklyReport";
import LiveConsumption from "../LiveConsumption/LiveConsumption";

const tabs = [
  // {
  //   name: "Score Card",
  //   label: "score-card",
  //   component: <ScoreCard />,
  // },
  // {
  //   name: "Weekly Report",
  //   label: "weekly-report",
  //   component: <WeeklyReport />,
  // },
  {
    name: "Monthly Consumption",
    label: "monthly-consumption",
    component: <Dailycomsptionreport />,
  },
  {
    name: "Downtime and Uptime",
    label: "downtime-uptime",
    component: <ReportDowntime_Uptime />,
  },
  {
    name: "RCPE - Energy Efficiency and Production",
    label: "rcpe-report",
    component: <ReportRCPE_Report />,
  },
  {
    name: "Alert Counter",
    label: "alert-count",
    component: <ReportsAlertscount />,
  },
  {
    name: "Live Consumption",
    label: "live-consumption",
    component: <LiveConsumption />,
  }
  
  
];

const AllReports = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const section = searchParams.get("section");

    if (section && tabs.some((tab) => tab.label === section)) {
      setActiveSection(section);
    
    } else {
      setActiveSection(tabs[0].label);

      navigate(`?section=${tabs[0].label}`, { replace: true });
    }
  }, []);

  return (
    <div id="super-report-total">
      <div id="super-report-right">
        <CommonTabs tabs={tabs} defaultTab={activeSection} />
      </div>
    </div>
  );
};

export default AllReports;
