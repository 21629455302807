import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./PFcorrectorsfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const PFcorrectorsfetch = ({ onFormButtonClick }) => {
  const [PFcorrectors, setPFcorrectors] = useState([]);
  const [PFcorrectorEditData, setPFcorrectorEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  const fetchPFcorrectors = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors`
      );
      setPFcorrectors(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
    fetchPFcorrectors();
  }, []);

  const handleEditClick = (PFcorrector) => {
    setPFcorrectorEditData(PFcorrector);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setPFcorrectorEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setPFcorrectorEditData({ ...PFcorrectorEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setPFcorrectorEditData({
        ...PFcorrectorEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...PFcorrectorEditData,
        ratedpowerinva: Number(PFcorrectorEditData.ratedpowerinva),
        operatingvoltage: Number(PFcorrectorEditData.operatingvoltage),
        numberofstages: Number(PFcorrectorEditData.numberofstages),
        numberofcellsineachstage: Number(
          PFcorrectorEditData.numberofcellsineachstage
        ),
        eachcellcapacity: Number(PFcorrectorEditData.eachcellcapacity),
        presenttestedvalueofeachcell: Number(
          PFcorrectorEditData.presenttestedvalueofeachcell
        ),
        pfcefficiency: Number(PFcorrectorEditData.pfcefficiency),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors/${PFcorrectorEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("PF Corrector updated successfully");
      fetchPFcorrectors();
      setPFcorrectorEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="pfcorrectorsfetch-total">
      <div id="pfcorrectorsfetch-right">
        <div id="pfcorrectorsfetch-right-2">
          <div id="pfcorrectorsfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.PFCorrectorHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Parent Name</th>
                <th>Meter Make</th>
                <th>Meter SL No</th>
                <th>Phase</th>
                <th>Rated Power (kVA)</th>
                <th>Operating Voltage</th>
                <th>Mode Select</th>
                <th>Capacitor Type</th>
                <th>Number of Stages</th>
                <th>Number of Cells in Each Stage</th>
                <th>Each Cell Capacity</th>
                <th>Present Tested Value of Each Cell</th>
                <th>PFC Efficiency</th>
                <th>Overcurrent Protection Available</th>
                <th>Overvoltage Protection Available</th>
                <th>Proposed Periodicity of Maintenance</th>
                <th>Previous Maintenance Done Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {PFcorrectors.map((PFcorrector) => (
                <tr key={PFcorrector._id}>
                  <td>{PFcorrector.date}</td>
                  <td>{PFcorrector.name}</td>
                  <td>{PFcorrector.parentname}</td>
                  <td>{PFcorrector.metermake}</td>
                  <td>{PFcorrector.meterslno}</td>
                  <td>{PFcorrector.phase}</td>
                  <td>{PFcorrector.ratedpowerinva}</td>
                  <td>{PFcorrector.operatingvoltage}</td>
                  <td>{PFcorrector.autoselect}</td>
                  <td>{PFcorrector.capacitortype}</td>
                  <td>{PFcorrector.numberofstages}</td>
                  <td>{PFcorrector.numberofcellsineachstage}</td>
                  <td>{PFcorrector.eachcellcapacity}</td>
                  <td>{PFcorrector.presenttestedvalueofeachcell}</td>
                  <td>{PFcorrector.pfcefficiency}</td>
                  <td>{PFcorrector.overcurrentprotectionavailable}</td>
                  <td>{PFcorrector.overvoltageprotectionavailable}</td>
                  <td>{PFcorrector.proposedperiodicityofmaintenance}</td>
                  <td>{PFcorrector.previousmaintenancedonedate}</td>
                  <td>
                    <button onClick={() => handleEditClick(PFcorrector)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
    title="Edit PF Corrector"
    visible={isModalVisible}
    onOk={handleEditSubmit}
    onCancel={handleCancel}
    okText="Update"
    cancelText="Cancel"
    okButtonProps={{ style: { backgroundColor: "#252B3F", color: "#fff" } }}
>
    {PFcorrectorEditData && (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Date">
                        <Input
                            name="date"
                            value={PFcorrectorEditData.date}
                            onChange={handleEditChange}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Parent Name">
                    <Select
                            value={PFcorrectorEditData.parentname}
                            onChange={(value) =>
                                setPFcorrectorEditData({
                                    ...PFcorrectorEditData,
                                    parentname: value,
                                })
                            }
                        >
                            {parentNames.map((name, index) => (
                                <Select.Option key={index} value={name}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Name">
                        <Input
                            name="name"
                            value={PFcorrectorEditData.name}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Meter Make">
                        <Input
                            name="metermake"
                            value={PFcorrectorEditData.metermake}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Meter Serial No.">
                        <Input
                            name="meterslno"
                            value={PFcorrectorEditData.meterslno}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Phase">
                        <Select
                            name="phase"
                            value={PFcorrectorEditData.phase}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'phase', value } })
                            }
                        >
                            <Select.Option value="3-Phase">3-Phase</Select.Option>
                            <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                            <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                            <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {/* Repeat the same structure for all other fields */}
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Rated Power (kVA)">
                        <Input
                            name="ratedpowerinva"
                            type="number"
                            value={PFcorrectorEditData.ratedpowerinva}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Operating Voltage">
                        <Input
                            name="operatingvoltage"
                            type="number"
                            value={PFcorrectorEditData.operatingvoltage}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Mode Select">
                        <Select
                            name="autoselect"
                            value={PFcorrectorEditData.autoselect}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'autoselect', value } })
                            }
                        >
                            <Select.Option value="Auto">Auto</Select.Option>
                            <Select.Option value="Manual">Manual</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Capacitor Type">
                        <Input
                            name="capacitortype"
                            value={PFcorrectorEditData.capacitortype}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Number of Stages">
                        <Input
                            name="numberofstages"
                            type="number"
                            value={PFcorrectorEditData.numberofstages}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Number of Cells in Each Stage">
                        <Input
                            name="numberofcellsineachstage"
                            type="number"
                            value={PFcorrectorEditData.numberofcellsineachstage}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Each Cell Capacity">
                        <Input
                            type="number"
                            name="eachcellcapacity"
                            value={PFcorrectorEditData.eachcellcapacity}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Present Tested Value of Each Cell">
                        <Input
                            type="number"
                            name="presenttestedvalueofeachcell"
                            value={PFcorrectorEditData.presenttestedvalueofeachcell}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="PFC Efficiency">
                        <Input
                            type="number"
                            name="pfcefficiency"
                            value={PFcorrectorEditData.pfcefficiency}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Overcurrent Protection Available">
                        <Select
                            value={PFcorrectorEditData.overcurrentprotectionavailable}
                            onChange={(value) =>
                                setPFcorrectorEditData({
                                    ...PFcorrectorEditData,
                                    overcurrentprotectionavailable: value,
                                })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Overvoltage Protection Available">
                        <Select
                            value={PFcorrectorEditData.overvoltageprotectionavailable}
                            onChange={(value) =>
                                setPFcorrectorEditData({
                                    ...PFcorrectorEditData,
                                    overvoltageprotectionavailable: value,
                                })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Proposed Periodicity of Maintenance">
                        <Input
                            name="proposedperiodicityofmaintenance"
                            value={PFcorrectorEditData.proposedperiodicityofmaintenance}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Previous Maintenance Done Date">
                        <DatePicker
                            disabledDate={disableDates}
                            value={
                                PFcorrectorEditData.previousmaintenancedonedate
                                    ? dayjs(PFcorrectorEditData.previousmaintenancedonedate)
                                    : null
                            }
                            onChange={(date) =>
                                setPFcorrectorEditData({
                                    ...PFcorrectorEditData,
                                    previousmaintenancedonedate: date,
                                })
                            }
                            format={dateFormat}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )}
</Modal>
        </div>
      </div>
    </div>
  );
};

export default PFcorrectorsfetch;
