import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import "./TODview.css";
import Stationpoints from "../../Commonfns/Stationpoints";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { Box, TableCell, TableRow } from "@mui/material";
import { Empty } from "antd";

const tableColumns = [
  { id: 1, label: "TOD Timings", align: "center" },
  { id: 2, label: "Types", align: "center" },
  { id: 3, label: "Section", align: "center" },
  { id: 4, label: "KVAh", align: "center" },
  { id: 5, label: "KWh", align: "center" },
  { id: 6, label: "KVArh", align: "center" },
  { id: 7, label: "MD", align: "center" },
  { id: 8, label: "PF", align: "center" },
];

const TodDataComponent = () => {
    const [todData, setTodData] = useState({});
    const [section, setSection] = useState('HV');

    // useEffect(() => {
    //     const socket = socketIOClient(process.env.REACT_APP_TODVIEW_URL);

    //     socket.on('mqtt_data', (data) => {
    //         setTodData(data);
    //     });

    //     return () => socket.disconnect();
    // }, []);


    useEffect(() => {
        // Open a WebSocket connection to the server
        const ws = new WebSocket(process.env.REACT_APP_TODVIEW_URL);
    
        
        ws.onmessage = (event) => {      
         
         const data = JSON.parse(event.data);

         setTodData(data);

        }
    
        
    
        // Clean up the WebSocket connection when the component unmounts
        return () => ws.close();
      }, []);




  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  // Function to filter TOD data based on selected section
  const filteredTodData = Object.entries(todData)
    .filter(([dataSection]) => section === "" || dataSection === section) // Show all if selectedSection is empty
    .map(([dataSection, tods]) => ({
      dataSection,
      tods: Object.entries(tods).map(([tod, data]) => ({ tod, ...data })),
    }));

  return (
    <div id="todview-total">
      <div id="todview-right">
        <div id="todview-right-2">
          <div id="todview-right-21">
            <label>
              {/* <span>Select Section:</span> */}
              <Stationpoints
                showAll={true}
                onStationPointChange={handleStationPointChange}
                defaultStationPoint="HV"
              />
            </label>
          </div>

          <Box mt={2}>
            <CommonTable columns={tableColumns}>
              {filteredTodData?.length === 0 && (
                <TableRow sx={{ height: 300 }}>
                  <TableCell colSpan={8} align="center">
                    <Empty description={"No data available"} />
                  </TableCell>
                </TableRow>
              )}
              {filteredTodData.map(({ dataSection, tods }) =>
                tods.map(({ tod, Type, KVAh, KWh, KVArh, MD, PF }) => (
                  <TableRow key={`${dataSection}-${tod}`}>
                    <TableCell align="center">{tod}</TableCell>
                    <TableCell align="center">{Type}</TableCell>
                    <TableCell align="center">{dataSection}</TableCell>
                    <TableCell align="center">{KVAh?.toFixed(2)}</TableCell>
                    <TableCell align="center">{KWh?.toFixed(2)}</TableCell>
                    <TableCell align="center">{KVArh?.toFixed(2)}</TableCell>
                    <TableCell align="center">{MD?.toFixed(2)}</TableCell>
                    <TableCell align="center">{PF?.toFixed(2)}</TableCell>
                  </TableRow>
                ))
              )}
            </CommonTable>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default TodDataComponent;
