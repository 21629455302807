import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./Analog.css";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { Box, TableCell, TableRow } from "@mui/material";
import { Empty } from "antd";

const dataLogColumns = [
  { id: 1, label: "Division", align: "center" },
  { id: 2, label: "Section", align: "center" },
  { id: 3, label: "Timestamp", align: "center" },
  { id: 4, label: "Vr", align: "center" },
  { id: 5, label: "Vy", align: "center" },
  { id: 6, label: "Vb", align: "center" },
  { id: 7, label: "Vavg", align: "center" },
  { id: 8, label: "Ir", align: "center" },
  { id: 9, label: "Iy", align: "center" },
  { id: 10, label: "Ib", align: "center" },
  { id: 11, label: "Iavg", align: "center" },
  { id: 12, label: "Imax", align: "center" },
  { id: 13, label: "Fr", align: "center" },
  { id: 14, label: "THDvr", align: "center" },
  { id: 15, label: "THDvy", align: "center" },
  { id: 16, label: "THDvb", align: "center" },
  { id: 17, label: "THDir", align: "center" },
  { id: 18, label: "THDiy", align: "center" },
  { id: 19, label: "THDib", align: "center" },
  { id: 20, label: "Vry", align: "center" },
  { id: 21, label: "Vyb", align: "center" },
  { id: 22, label: "Vbr", align: "center" },
  { id: 23, label: "PF", align: "center" },
  { id: 24, label: "kW", align: "center" },
  { id: 25, label: "kVA", align: "center" },
];

const Analog = ({ selectedSection }) => {
  const [mqttData, setMqttData] = useState([]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SECTIONS_URL);

  //   socket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from WebSocket server');
  //   });

  //   socket.on('mqtt_data', (data) => {
  //     console.log('Received data:', data);

  //     setMqttData((prevData) => {
  //       const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
  //       if (existingDataIndex !== -1) {
  //         // Update existing data if deviceEUi already exists in state
  //         const updatedData = [...prevData];
  //         updatedData[existingDataIndex] = data;
  //         return updatedData;
  //       } else {
  //         // Add new data if deviceEUi is not in state
  //         return [data, ...prevData];
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);


  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_SECTIONS_URL);

    // When a message is received from the server, update the state
    ws.onmessage = (event) => {

      const data = JSON.parse(event.data);

      setMqttData(data);

    };

    // Clean up the WebSocket connection when the component unmounts
    return () => ws.close();
  }, []);

  const filteredData = selectedSection
    ? mqttData.filter((data) => data.section === selectedSection)
    : mqttData;

  const renderTableBody = () => {
    if (!filteredData || filteredData.length === 0) {
      return (
        <TableRow sx={{ height: 300 }}>
          <TableCell colSpan={25} align="center">
            <Empty description={"No data available"} />
          </TableCell>
        </TableRow>
      );
    }

    return filteredData.map((data) => (
      <TableRow key={data.deviceEUi}>
        <TableCell align="center">{data.division}</TableCell>
        <TableCell align="center">{data.section}</TableCell>
        <TableCell align="center">{data.utcTimestamp}</TableCell>
        <TableCell align="center">{data.Vr}</TableCell>
        <TableCell align="center">{data.Vy}</TableCell>
        <TableCell align="center">{data.Vb}</TableCell>
        <TableCell align="center">{data.Vavg}</TableCell>
        <TableCell align="center">{data.Ir}</TableCell>
        <TableCell align="center">{data.Iy}</TableCell>
        <TableCell align="center">{data.Ib}</TableCell>
        <TableCell align="center">{data.Iavg}</TableCell>
        <TableCell align="center">{data.Imax}</TableCell>
        <TableCell align="center">{data.Fr}</TableCell>
        <TableCell align="center">{data.THDvr}</TableCell>
        <TableCell align="center">{data.THDvy}</TableCell>
        <TableCell align="center">{data.THDvb}</TableCell>
        <TableCell align="center">{data.THDir}</TableCell>
        <TableCell align="center">{data.THDiy}</TableCell>
        <TableCell align="center">{data.THDib}</TableCell>
        <TableCell align="center">{data.Vry}</TableCell>
        <TableCell align="center">{data.Vyb}</TableCell>
        <TableCell align="center">{data.Vbr}</TableCell>
        <TableCell align="center">{data.Pf}</TableCell>
        <TableCell align="center">{data.kW}</TableCell>
        <TableCell align="center">{data.kVA}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Box
      sx={{
        width: "93vw",
      }}
    >
      <CommonTable
        height={500}
        columns={dataLogColumns}
        isWithPagination={false}
      >
        {renderTableBody()}
      </CommonTable>
    </Box>
  );
};

export default Analog;
