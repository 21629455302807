// dataDefinitions.js
import { MdNotificationsActive } from 'react-icons/md';
// import { IoMdSettings } from 'react-icons/io';
import Logo from '../../Images/logo1.svg'; // Adjust the path as necessary
import { Link } from 'react-router-dom';


export const Topbarheadings = {
  Operatordashheading: "Home",
  OperatorTaskheading: "Task History",
  Liveplantsummaryheading: "Live Plant Summary",
  TODTrackingheading: "TOD Tracking",
  Alertsheading: "Alerts",
  ConsumptionHeader: "Consumption Monitoring",
  SectionsHeader: "Sections",
  HistorydataHeader: "History Data",
  FormsHeader: "Forms",
  PendingTasksHeader: "Pending Tasks",

  // -------------Supervisor--------------
  Supervisordashheading: "Home",
  Criticalalertsheading: "Critical Alerts",
  Calenderheading: "Monthly Calender",
  ReportsHeader: "Reports",
  SupervisorTaskheading: "Task History",
  Generatorsheading: "Energy Generators",
  Masterformsheading: "Master Forms",
  Trendsheading: "Trends",
  LoadManagementheading: "Load Management",

  // -------------revenueloss- diagnose-------------
  LowpfHeading: "Low PF Revenue Loss Diagnose",
  LeadpfHeading: "Lead PF Revenue Loss Diagnose",
  HarmonicdistortionHeading: "Harmonic Distortion Revenue Loss Diagnose",
  PowersurgeHeading: "Power Surge Revenue Loss Diagnose",
  UnbalancedloadHeading: "Unbalanced Load Revenue Loss Diagnose",
  VoltageHeading: " Voltage Revenue Loss Diagnose",
  UtilizationrevenuelossHeading: "Utilization Revenue Loss Diagnose",
  IdlerevenuelossHeading: "Idle Revenue loss Diagnose",
  TodrevenuelossHeading: "TOD Revenue Loss Diagnose",
  TransformerrevenuelossHeading: "Transformer Revenue Loss Diagnose",

  // -------------------Administartor---------
  DesignationHeading:"Designations",
  AddsuperadminHeading:"Super Admin",
  AdduserHeading:"User Details",

  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
  ],
  logoSrc: Logo,

};

export const Operatorheadingss = {
  Alertsheading: "Alerts",
  HistoricalalertHeading: "Alerts History",
  LivealetsalertHeading: "Live Alerts",
  TODHeading: "TOD Tracking",
  ConsumptionHeader: "Consumption Monitoring",
  SectionsHeader: "Sections",
  FormsHeader: "Forms",
  alertActions: [
    {
      icon: (
        // <Link to="/Alertspage" style={{ textDecoration: 'none', color: 'inherit' }}>
        //   <MdNotificationsActive />
        // </Link>
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
  ],
  logoSrc: Logo,

};

export const Masterformsheadingsss = {

  AllformsHeading: "Master Forms",
  incomersHeading: "Incomers",
  HVHeading: "HV Form",
  TransformerHeading: "Transformer",
  LVHeading: "LV Form",
  GeneratorHeading: "Generator",
  SectionsHeading: "Sections",
  MachineHeading: "Machine",
  UPSHeading: "UPS",
  StebilizerHeading: "Stebilizer",
  PFCorrectorHeading: "PF Correctors",
  HarmonicFilterHeading: "Harmonic Filter",
  VFDHeading: "VFD",
  LightingArrestorHeading: "Lighting Arrestor",
  GroundingpointHeading: "Grounding Point",
  AlertsdurationHeading: "Alerts Duration",
  TariffHeading: "Tariff",
  FixedvariablesHeading: "Basic Plant Details",
  Formheading: "Add New",
  Updateheading: "Update",
  Submitheading: "Submit",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
  ],
  logoSrc: Logo,
};


export const supervisordashbuttons = {
  button1Heading: "Overview",
  button2Heading: "Data Summary ",
  button3Heading: "Metric Breakdown",
  button3Headingfordash: "Metric Breakdown of :",
  button4Heading: "Energy Billing",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const alertData = {
  alertHeading: "Alerts History",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const alertData2 = {

  alertHeading: "History Data",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const alertData3 = {

  alertHeading: "Live Data",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const alertData4 = {

  alertHeading: "Live Alerts",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const StatusSummaryView = {

  alertHeading: "Status summary view",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const Sectionpointsheader = {

  alertHeading: "Section Wise View",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const TODview = {

  alertHeading: "TOD Live",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
  timings: [
    "TOD 1 (00:00:00 - 06:00:00)",
    "TOD 2 (06:00:00 - 10:00:00)",
    "TOD 3 (10:00:00 - 18:00:00)",
    "TOD 4 (18:00:00 - 22:00:00)",
    "TOD 5 (22:00:00 - 24:00:00)",
    "Total"
  ],
  types: [
    "Incentives",
    "Penalty",
    "Normal",
    "Penalty",
    "Incentives",
  ]
};

export const TODhistory = {

  alertHeading: "TOD History",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Breakdownheader = {

  alertHeading: "Break down logs-form",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};
export const Breakdowndetailsheader = {

  alertHeading: "Break down logs Details",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Transformermonitoringheader = {

  alertHeading: "Transformer Monitoring Logs",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Transformermonitoringdetails = {

  alertHeading: "Transformer Monitoring details",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const DGlogsheader = {

  alertHeading: "DG Logs",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};
export const DGlogsfetchheader = {

  alertHeading: "DG Log details",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const DailyConsumptionheader = {

  alertHeading: "Daily consumption monitoring logs",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const Livesummaryheader = {

  alertHeading: "Live Summary",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};



export const Calendereventsheader = {

  alertHeading: "Monthly Calender",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const powerbillsheader = {

  alertHeading: "Power Bills",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const powerbillsfetchheader = {

  alertHeading: "Power Bills Data",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const productionentryheader = {

  alertHeading: "Production Entry",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const pf_mdheader = {

  alertHeading: "PF & MD",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const monthpf_mdheader = {

  alertHeading: "Monthly PF & MD",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const criticalalertsheader = {

  alertHeading: "Critical Alerts",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Operatordahboard = {

  alertHeading: "Home Dashboard",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Revenuelossheader = {

  alertHeading: "Revenue Loss",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const Groundingpointsheader = {

  alertHeading: "Grounding Points",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

// --------master data

export const Incomersheader = {

  alertHeading: "Incomers",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const HVheader = {

  alertHeading: "HV form",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};



export const Parentdetailsheader = {

  alertHeading: "Parent Details",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Mastertransformerheader = {

  alertHeading: "Transformer Form",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const LVheader = {

  alertHeading: "LV Form",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Generatorsheader = {

  alertHeading: "Generators",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Sectionsheader = {

  alertHeading: "Sections",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Machineheader = {

  alertHeading: "Machine",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const UPSheader = {

  alertHeading: "UPS",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Stebilizersheader = {

  alertHeading: "Stebilizer",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const PFCorrectorsheader = {

  alertHeading: "PF Correctors",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Harmonicfilterheader = {

  alertHeading: "Harmonic filters",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const VFDheader = {

  alertHeading: "VFD",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Lightingarrestorheader = {

  alertHeading: "Lightening Arrestors",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const Tarieffheader = {

  alertHeading: "Tariff",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Alertsdurationheader = {

  alertHeading: "Alerts Duration",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

// -----------------------------
export const Voltagedignoseheader = {

  alertHeading: "Voltage Fluctuations Resolution",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const CriticalAlertsData = {

  alertHeading: "Critical Alerts",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Powersurgedignoseheader = {

  alertHeading: "Power Surges",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Harmonicdistortionheader = {

  alertHeading: "Harmonic Distortion",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Unbalancedloadsheader = {

  alertHeading: "Unbalanced Loads",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Diagnosesheader = {

  alertHeading: "Diagnosis",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};



export const Voltagevoltagefluctuationsheader = {

  alertHeading: "Voltage Diagnose",
  subalertHeading: "Voltage Fluctuations",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Power_Surgesheader = {

  alertHeading: "Power Surges Diagnose",
  subalertHeading: "Power Surges",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Harmonicformheader = {

  alertHeading: "Harmonics Diagnose",
  subalertHeading: "Harmonics",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const Phaseimbalanceheader = {

  alertHeading: "Unbalanced loads Diagnose",
  subalertHeading: "Phase Imbalance",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const LeadPFheader = {

  alertHeading: "Lead PF Diagnose",
  subalertHeading: "Lead PF",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const LowPFheader = {

  alertHeading: "Low PF Diagnose",
  subalertHeading: "Low PF",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Pendingtsaksheader = {

  alertHeading: "Pending Tasks",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};



export const Designationheader = {

  alertHeading: "Designations",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Higherlevelheader = {

  alertHeading: "Super Admin",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const Userheader = {

  alertHeading: "User Details",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const TransformerFormHeader = {

  alertHeading: "Transformer",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const RevenuelossHeader = {
  RevenuelossHeading1: "Pending Critical Alerts Diagnose",
  RevenuelossHeading2: "Revenue Loss Sugessions",
  Revenuelossheading3: "No Pending Tokens Available",
  Revenuelossheading4: "Close Pending Issue",
  Revenuelossbuttonname: "Diagnose",
  LowpfHeading: "Low PF Revenue Loss Diagnose",
  LeadpfHeading: "Lead PF Revenue Loss Diagnose",
  HarmonicdistortionHeading: "Harmonic Distortion Revenue Loss Diagnose",
  PowersurgeHeading: "Power Surge Revenue Loss Diagnose",
  UnbalancedloadHeading: "Unbalanced Load Revenue Loss Diagnose",
  VoltageHeading: " Voltage Revenue Loss Diagnose",
  UtilizationrevenuelossHeading: "Utilization Revenue Loss Diagnose",
  IdlerevenuelossHeading: "Idle Revenue loss Diagnose",
  TodrevenuelossHeading: "TOD Revenue Loss Diagnose",
  TransformerrevenuelossHeading: "Transformer Revenue Loss Diagnose",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};


export const GeneratorKPIHeader = {

  alertHeading: "Energy Generators",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};

export const SupervisorDashHeader = {

  alertHeading: "Home",
  reportheading: "Reports",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};



export const Task_Historyheadingsss = {

  TaskHeading: "Task History",
  alertActions: [
    {
      icon: (
        <Link to="/Alertspage?section=OperatorAlertstable-1" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MdNotificationsActive />
        </Link>
      ),
      label: "Notifications"
    },
    // { icon: <IoMdSettings />, label: "Settings" }
  ],
  logoSrc: Logo,
};
