
import React from "react";
import Homealerts from "./Homealerts";
import HomeLoad from "./HomeLoad";
import "./Home.css";
import { Operatordahboard } from "../Data";
import Operatorsidebar from "../Sidebar/Sidebar";
import DashPending_Task from "../../SuperDashboards/Tab1/DashPending_Task/DashPending_Task";
import TopBar from "../../Commonfns/Topbar/Topbar";
import Newunfilledforms from "../../SuperDashboards/Tab1/NewUnfilledForms/Newunfilledforms";
import { Grid, Box } from "@mui/material";
const Home = () => {
  return (
       <div id="opehome-total">
      <div id="opehome-right-2">
        <Grid container >
          <Grid  item xs={6} md={6} >
            <Newunfilledforms />
          </Grid>
          <Grid>
            <Homealerts item xs={6} md={6} />
          </Grid>
        </Grid>
      </div>
      <div>
        <HomeLoad />
      </div>
    </div>
  );
};

export default Home;
