import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import Stationpoints from "../../Commonfns/Stationpoints";
import { FaFileCsv } from "react-icons/fa6";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import "./Alertstable.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { DatePicker, Space, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CommonTable from "../../Common/CommonTable/CommonTable";

const tableColumns = [
  { id: 1, label: "S. No.", align: "center" },
  { id: 2, label: "Section", align: "center" },
  { id: 3, label: "Parameter", align: "center" },
  { id: 4, label: "Description", align: "center" },
  { id: 5, label: "Type", align: "center" },
  { id: 6, label: "Value", align: "center" },
  { id: 7, label: "Date & Time", align: "center" },
];

const itemsPerPage = 20;

const { Option } = Select;

const fetchAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDate, toDate, selectedAlertType] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/filter?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (section) query += `&stationPoint=${encodeURIComponent(section)}`;
  if (selectedAlertType)
    query += `&eventDescription=${encodeURIComponent(selectedAlertType)}`;

  const response = await fetch(query);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const fetchAlertTypes = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_EMS_URL}/api/uniquealerttype`
  );
  if (!response.ok) throw new Error("Failed to fetch alert types");
  return response.json();
};

const OperatorAlertstable = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedAlertType, setSelectedAlertType] = useState("");
  const [alertTypes, setAlertTypes] = useState([]);

  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["operator-alerts", section, fromDate, toDate, selectedAlertType],
    queryFn: fetchAlerts,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length > 0 ? pages.length + 1 : undefined,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    // Fetch alert types on mount
    const loadAlertTypes = async () => {
      try {
        const types = await fetchAlertTypes();
        setAlertTypes(types);
      } catch (error) {
        console.error("Error fetching alert types:", error);
      }
    };

    loadAlertTypes();
  }, []);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD"));
      setToDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const handleAlertTypeChange = (value) => {
    setSelectedAlertType(value);
  };

  const downloadCSV = async () => {
    setLoading(true); // Start loading
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/filter/download?`;

      if (fromDate) query += `fromDate=${fromDate}&`;
      if (toDate) query += `toDate=${toDate}&`;
      if (section) query += `stationPoint=${encodeURIComponent(section)}&`;
      if (selectedAlertType)
        query += `eventDescription=${encodeURIComponent(selectedAlertType)}&`;

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed to fetch data for CSV download");
      }

      const data = await response.json();

      // Generate CSV content from the fetched data
      const csvContent = [
        [
          "S NO",
          "Section",
          "Parameter",
          "Description",
          "Type",
          "Value",
          "Date & Time",
        ],
        ...data.map((alert, index) => [
          index + 1,
          alert.stationPoint,
          alert.parameterName,
          alert.eventDescription,
          alert.eventType,
          alert.eventValue,
          moment(alert.dateTime).format("YYYY-MM-DD HH:mm:ss"),
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create file name based on applied filters
      const filters = [];
      if (fromDate) filters.push(`From-${fromDate}`);
      if (toDate) filters.push(`To-${toDate}`);
      if (section) filters.push(`Section-${section}`);
      if (selectedAlertType) filters.push(`AlertType-${selectedAlertType}`);
      const fileName = filters.length > 0 ? filters.join("_") : "AllData";
      const fullFileName = `Alertshistory-${fileName}.csv`;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            Error: {error.message}
          </TableCell>
        </TableRow>
      );
    }

    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) {
      return (
        <TableRow sx={{height: 300}}>
          <TableCell colSpan={7} align="center">
            <Empty description="No data available" />
          </TableCell>
        </TableRow>
      );
    }

    return alerts.map((alert, index) => (
      <TableRow key={alert._id || index}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{alert.stationPoint}</TableCell>
        <TableCell align="center">{alert.parameterName}</TableCell>
        <TableCell align="center">{alert.eventDescription}</TableCell>
        <TableCell align="center">{alert.eventType}</TableCell>
        <TableCell align="center">{alert.eventValue}</TableCell>
        <TableCell align="center">
          {moment.utc(alert.dateTime).format("YYYY-MM-DD HH:mm:ss")}
        </TableCell>
      </TableRow>
    ));
  };

  // Table footer row that displays the "No More Data Available" message
  const renderTableFooter = () => {
    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) return null; // Don't show footer if there's no data

    return (
      <tr ref={ref}>
        <td colSpan="7" style={{ textAlign: "center" }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            !hasNextPage && "No More Data Available" // Only show this message when all data is fetched
          )}
        </td>
      </tr>
    );
  };

  return (
    <div id="opalert-total">
      <div id="opalert-right">
        <div id="alert-right-2">
          <div id="alert-right-21">
            <label>
              Date Range:
              <Space direction="vertical" size={12}>
                <RangePicker
                  size="medium"
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
                  value={
                    fromDate && toDate
                      ? [
                          dayjs(fromDate, "YYYY-MM-DD"),
                          dayjs(toDate, "YYYY-MM-DD"),
                        ]
                      : null
                  }
                />
              </Space>
            </label>
            <label>
              Alert Type:
              <Space wrap>
                <Select
                  allowClear
                  value={selectedAlertType || "Select All"}
                  onChange={handleAlertTypeChange}
                  placeholder="Please Select"
                  size="medium"
                  style={{
                    width: 200,
                  }}
                >
                  <Option value="Select All" disabled>
                    Select All
                  </Option>
                  {alertTypes.length > 0 ? (
                    alertTypes.map((type, index) => (
                      <Option key={index} value={type}>
                        {type}
                      </Option>
                    ))
                  ) : (
                    <Option disabled>No Alert Types Available</Option>
                  )}
                </Select>
              </Space>
            </label>
            <Stationpoints
              showAll={true}
              onStationPointChange={handleStationPointChange}
            />

            <Button
              type="primary"
              icon={
                loading ? (
                  <LoadingOutlined style={{ color: "#252B3F" }} />
                ) : (
                  <FaFileCsv style={{ color: "#252B3F" }} />
                )
              }
              onClick={downloadCSV}
            ></Button>
          </div>

          <Box mt={2}>
            <CommonTable height={500} columns={tableColumns}>
              {renderTableBody()}
              {renderTableFooter()}
            </CommonTable>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default OperatorAlertstable;
