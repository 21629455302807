// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    display: flex;
    align-items: center;
   
    gap: 10px;
}

.filter-paper {
    border-radius: 10px !important;
    padding: 0 30px;
}


.custom-tabs .MuiTab-root {
  color: #000000; /* Default color for unselected tabs */
  border-right: 1px solid  #9e9e9e; ; /* Border color */
}

.custom-tabs .Mui-selected {
  color: #000000 !important; /* Color for selected tab */
}

.custom-tabs .MuiTabs-indicator {
  background-color: #1a1919; /* Color for the indicator */
}

.custom-tabs .MuiTab-root:last-of-type {
  border-right: none; /* Remove border for the last tab */
}

.calendor-container {
  
   padding: 4px;
   border-radius: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/CommonTable/CommonTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,SAAS;AACb;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;;AAGA;EACE,cAAc,EAAE,sCAAsC;EACtD,gCAAgC,IAAI,iBAAiB;AACvD;;AAEA;EACE,yBAAyB,EAAE,2BAA2B;AACxD;;AAEA;EACE,yBAAyB,EAAE,4BAA4B;AACzD;;AAEA;EACE,kBAAkB,EAAE,mCAAmC;AACzD;;AAEA;;GAEG,YAAY;GACZ,8BAA8B;AACjC","sourcesContent":[".filter-container {\n    display: flex;\n    align-items: center;\n   \n    gap: 10px;\n}\n\n.filter-paper {\n    border-radius: 10px !important;\n    padding: 0 30px;\n}\n\n\n.custom-tabs .MuiTab-root {\n  color: #000000; /* Default color for unselected tabs */\n  border-right: 1px solid  #9e9e9e; ; /* Border color */\n}\n\n.custom-tabs .Mui-selected {\n  color: #000000 !important; /* Color for selected tab */\n}\n\n.custom-tabs .MuiTabs-indicator {\n  background-color: #1a1919; /* Color for the indicator */\n}\n\n.custom-tabs .MuiTab-root:last-of-type {\n  border-right: none; /* Remove border for the last tab */\n}\n\n.calendor-container {\n  \n   padding: 4px;\n   border-radius: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
