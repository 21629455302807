import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./LighteningArrestorsfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const LightingArrestorfetch = ({ onFormButtonClick }) => {
  const [lightingArrestors, setLightingArrestors] = useState([]);
  const [lightingArrestorEditData, setLightingArrestorEditData] =
    useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchLightingArrestors = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors`
      );
      setLightingArrestors(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchLightingArrestors();
  }, []);

  const handleEditClick = (lightingArrestor) => {
    setLightingArrestorEditData(lightingArrestor);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setLightingArrestorEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setLightingArrestorEditData({
        ...lightingArrestorEditData,
        [name]: value,
      });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DD") : null;
      setLightingArrestorEditData({
        ...lightingArrestorEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...lightingArrestorEditData,

        groundpointnumber: Number(lightingArrestorEditData.groundpointnumber),
        voltagerating: Number(lightingArrestorEditData.voltagerating),
        currentrating: Number(lightingArrestorEditData.currentrating),
        responsetime: Number(lightingArrestorEditData.responsetime),
        groundresistance: Number(lightingArrestorEditData.groundresistance),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors/${lightingArrestorEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Lighting Arrestor updated successfully");
      fetchLightingArrestors();
      setLightingArrestorEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="lightingarrestorfetch-total">
      <div id="lightingarrestorfetch-right">
        <div id="lightingarrestorfetch-right-2">
          <div id="lightingarrestorfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.LightingArrestorHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Location</th>
                <th>Location Number</th>
                <th>Type of Arrestor</th>
                <th>Ground Point Number</th>
                <th>Voltage Rating</th>
                <th>Current Rating</th>
                <th>Response Time</th>
                <th>Ground Resistance</th>
                <th>Proposed Periodicity of Maintenance</th>
                <th>Previous Maintenance Done Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {lightingArrestors.map((lightingArrestor) => (
                <tr key={lightingArrestor._id}>
                  <td>{lightingArrestor.date}</td>
                  <td>{lightingArrestor.location}</td>
                  <td>{lightingArrestor.locationnumber}</td>
                  <td>{lightingArrestor.typeofarrestor}</td>
                  <td>{lightingArrestor.groundpointnumber}</td>
                  <td>{lightingArrestor.voltagerating}</td>
                  <td>{lightingArrestor.currentrating}</td>
                  <td>{lightingArrestor.responsetime}</td>
                  <td>{lightingArrestor.groundresistance}</td>
                  <td>{lightingArrestor.proposedperiodicityofmaintenance}</td>
                  <td>{lightingArrestor.previousmaintenancedonedate}</td>
                  <td>
                    <button onClick={() => handleEditClick(lightingArrestor)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal
            title="Edit Lighting Arrestor"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" },
            }}
          >
            {lightingArrestorEditData && (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Date">
                      <Input
                        name="date"
                        value={lightingArrestorEditData.date}
                        onChange={handleEditChange}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Location">
                      <Input
                        name="location"
                        value={lightingArrestorEditData.location}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Location Number">
                      <Input
                        name="locationnumber"
                        value={lightingArrestorEditData.locationnumber}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Type of Arrestor">
                      <Select
                        name="typeofarrestor"
                        value={lightingArrestorEditData.typeofarrestor}
                        onChange={(value) =>
                          handleEditChange({
                            target: { name: "typeofarrestor", value },
                          })
                        }
                      >
                        <Select.Option value="ROD">ROD</Select.Option>
                        <Select.Option value="Cable">Cable</Select.Option>
                        <Select.Option value="Station Class">
                          Station Class
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Ground Point Number">
                      <Input
                        name="groundpointnumber"
                        type="number"
                        value={lightingArrestorEditData.groundpointnumber}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Voltage Rating">
                      <Input
                        name="voltagerating"
                        type="number"
                        value={lightingArrestorEditData.voltagerating}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Current Rating">
                      <Input
                        name="currentrating"
                        type="number"
                        value={lightingArrestorEditData.currentrating}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Response Time">
                      <Input
                        name="responsetime"
                        type="number"
                        value={lightingArrestorEditData.responsetime}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Ground Resistance">
                      <Input
                        name="groundresistance"
                        type="number"
                        value={lightingArrestorEditData.groundresistance}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Proposed Periodicity of Maintenance">
                      <Input
                        name="proposedperiodicityofmaintenance"
                        value={
                          lightingArrestorEditData.proposedperiodicityofmaintenance
                        }
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Previous Maintenance Done Date">
                      <DatePicker
                        disabledDate={disableDates}
                        value={
                          lightingArrestorEditData.previousmaintenancedonedate
                            ? dayjs(
                                lightingArrestorEditData.previousmaintenancedonedate
                              )
                            : null
                        }
                        onChange={(date) =>
                          setLightingArrestorEditData({
                            ...lightingArrestorEditData,
                            previousmaintenancedonedate: date,
                          })
                        }
                        format={dateFormat}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default LightingArrestorfetch;
