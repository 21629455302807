
import { useState, React } from 'react';
import './AllTOD.css';
import TodDataComponent from './TODview';
import TODviewcomponent from './SavedTOD';
import CommonTabs from "../../Common/CommonTabs/CommonTabs";
const AllTOD = () => {
    const tabs = [
        {
          name: "Live TOD",
          label: "live-TOD",
          component: <TodDataComponent />,
        },
        {
          name: "Historical TOD",
          label: "Historical-TOD",
          component: <TODviewcomponent />,
        }
      ];


    return (
        <div id="todpage-total">
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    );
};

export default AllTOD;