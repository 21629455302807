import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Harmonic_filters.css'; // Rename the CSS file accordingly
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";

const Harmonicfilter = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const [form] = Form.useForm();
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleSubmit = async (values) => {
        try {
            const dataToSend = {
                ...values,
                meterslno: String(values.meterslno),
                capacityinva: Number(values.capacityinva),
                harmonicfrequencyrange: Number(values.harmonicfrequencyrange),
                attenuationperformance: Number(values.attenuationperformance),
                ratedvoltage: Number(values.ratedvoltage),
                ratedcurrent: Number(values.ratedcurrent),
                operationfrequency: Number(values.operationfrequency),
                efficiency: Number(values.efficiency),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters`, dataToSend);

            toast.success('Harmonic Filter added successfully');
            form.resetFields();
            fetchParentNames();
        } catch (error) {
            console.error("Error submitting data", error);
             toast.error("Error adding Harmonic filter"); 
        }
    };

    return (
        <div id='masterforms-total'>
            <div id='masterforms-right'>
                <div id='masterforms-right-2'>
                <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ date: getCurrentDate() }}
                        onFinish={handleSubmit}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h3>{Masterformsheadingsss.HarmonicFilterHeading}</h3>
                            <Button
                                type="default"
                                onClick={onViewData}
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                View Data
                            </Button>
                        </div>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Date" name="date">
                                    <Input type="date" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Parent Name" name="parentname">
                                    <Select placeholder="Select Parent Name">
                                        {parentNames.map((name, index) => (
                                            <Select.Option key={index} value={name}>
                                                {name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Meter Make" name="metermake">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Meter Serial No." name="meterslno">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Phase" name="phase">
                                    <Select placeholder="Select Phase">
                                        <Select.Option value="">Please select</Select.Option>
                                        <Select.Option value="3-Phase">3-Phase</Select.Option>
                                        <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                                        <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                                        <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Capacity (in kVA)" name="capacityinva">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Harmonic Frequency Range" name="harmonicfrequencyrange">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Attenuation Performance" name="attenuationperformance">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Rated Voltage" name="ratedvoltage">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Rated Current" name="ratedcurrent">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Connection Type" name="connectiontype">
                                    <Select placeholder="Select Type">
                                    <Select.Option value="">Please select</Select.Option>
                                        <Select.Option value="L-L">L-L</Select.Option>
                                        <Select.Option value="L-N">L-N</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Operation Frequency" name="operationfrequency">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Efficiency" name="efficiency">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Mode Select" name="autoselect">
                                <Select placeholder="Select Option">
                                <Select.Option value="">Please select</Select.Option>
                                        <Select.Option value="Auto">Yes</Select.Option>
                                        <Select.Option value="Manual">No</Select.Option>
                               </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Overcurrent Protection Available" name="overcurrentprotectionavailable">
                                    <Select placeholder="Select Option">
                                    <Select.Option value="">Please select</Select.Option>
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Overvoltage Protection Available" name="overvoltageprotectionavailable">
                                    <Select placeholder="Select Option">
                                        <Select.Option value="">Please select</Select.Option>
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cooling Method" name="coolingmethod">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Proposed Periodicity of Maintenance" name="proposedperiodicityofmaintenance">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Previous Maintenance Done Date" name="previousmaintenancedonedate">
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Harmonicfilter;
