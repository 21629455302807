import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Button as AntDButton, DatePicker, Flex, Popover } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getAlertsCount } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import lightIcon from "../../../Images/light.svg";
import manIcon from "../../../Images/man.svg";
import { errorToast } from "../../../utils/toast";
import Stationpoints from "../../Commonfns/Stationpoints";
import "./Alertscount.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Alertscount = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);

  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      station_point: "HV",
      month: dayjs(),
    },
  });

  const [chartData, setChartData] = useState({});

  const { station_point, month } = watch();

  useEffect(() => {
    ChartJS.register(ChartDataLabels);
    getAlertsCount(station_point, month.format("YYYY-MM"))
      .then((res) => {
        setChartData(res.alert_counts);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [station_point, month]);

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const getData = () => {
    const data = {
      labels: Object.keys(chartData).map((key) => key),
      datasets: [
        {
          label: "Alert Count",
          data: Object.values(chartData).map((value) => value),
          backgroundColor: Array.from({
            length: Object.entries(chartData).length,
          }).map((_color) => "#DD4848"),
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: true,
          text: "Parameters",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      // duration: 50,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
      },
      datalabels: {
        color: "white",
        display: (context) => context.dataset.data[context.dataIndex] != null,
        font: {
          // weight: "bold",
          size: 12,
        },
        anchor: "center", // Positions the label in the center of the bar
        align: "center",  // Aligns the label with the bar's center
        rotation: -90,    // Rotates the label to be vertical
      },
      
    },
  };

  const handleStationPointChange = (value) => {
    if (value.length === 0) {
      setValue(station_point, "HV");
    } else {
      setValue("station_point", value);
    }
  };

  const chartRef = React.useRef();

  const handleDownloadPdf = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) return;

    try {
      const canvas = await html2canvas(chartElement, {
        useCORS: true, // Ensures cross-origin images are loaded
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the chart into the page
      const ratio = Math.min(
        pageWidth / canvas.width,
        pageHeight / canvas.height
      );
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;

      // Calculate offsets to center the image
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("alerts_trend.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
        <Flex align="end" gap={20}>
         

          <Box>
            <div>
              <Stationpoints
                showAll={false}
                defaultStationPoint={station_point}
                onStationPointChange={handleStationPointChange}
              />
            </div>
          </Box>
          <Box>
            <div>
              <label>{t("common.selectMonthColumn")}</label>
            </div>

            <div>
              <DatePicker
                {...register("month")}
                style={{ width: "200px" }}
                defaultValue={getValues("month")}
                size={"medium"}
                picker={"month"}
                disabledDate={disableFutureMonths} // Restricts future months
                onChange={onChange}
              />
            </div>
          </Box>

          <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none", marginTop: 13 }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>

      <Paper
        ref={chartRef}
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("alertTrendsComponent.alertsTrend")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("alertTrendsComponent.whyIsThisImportantAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"100px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          <li>
                            {t("alertTrendsComponent.whatShouldYouDoAnswer")}
                          </li>
                        </Typography>

                        <Typography variant="body1">
                          <ul
                            style={{
                              margin: 5,
                              listStyleType: "square",
                            }}
                          >
                            {t(
                              "alertTrendsComponent.whatShouldYouDoAnswerBullets",
                              {
                                returnObjects: true,
                              }
                            ).map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Alertspage?section=OperatorAlertstable-1"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "start",
                            alignSelf: "start",
                            borderRadius: "4px",
                          }}
                        >
                          {t("alertTrendsComponent.alertsHistory")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("alertTrendsComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Bar className="alerts-chart" data={getData()} options={options} />
      </Paper>
    </div>
  );
};

export default Alertscount;
