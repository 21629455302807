import React, { useEffect, useState } from "react";
import "./SavedTOD.css";
import { FaFileCsv } from "react-icons/fa6";
import Stationpoints from "../../Commonfns/Stationpoints";
import { DatePicker, Space, Select, Button, Empty } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import CommonTable from "../../Common/CommonTable/CommonTable";

const tableColumns = [
  { id: 1, label: "S No", align: "center" },
  { id: 2, label: "Time", align: "center" },
  { id: 3, label: "TOD Timings", align: "center" },
  { id: 4, label: "Type", align: "center" },
  { id: 5, label: "Section", align: "center" },
  { id: 6, label: "KWh", align: "center" },
  { id: 7, label: "KVAh", align: "center" },
  { id: 8, label: "KVARh", align: "center" },
  { id: 9, label: "MD", align: "center" },
  { id: 10, label: "Avg PF", align: "center" },
];

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
};

const itemsPerPage = 20;
const { Option } = Select;

const fetchTODviewData = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDate, toDate, selectedTOD] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/todviewdata?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (section) query += `&section=${encodeURIComponent(section)}`;
  if (selectedTOD) query += `&tod_key=${encodeURIComponent(selectedTOD)}`;

  const response = await fetch(query);
  if (!response.ok) throw new Error("Failed to fetch TOD History");
  return response.json();
};

const TODviewcomponent = () => {
  const [section, setSection] = useState("");
  const [selectedTOD, setSelectedTOD] = useState("");
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);

  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const { ref, inView } = useInView({ threshold: 0.1 });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["TOD-history", section, fromDate, toDate, selectedTOD],
    queryFn: fetchTODviewData,
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.currentPage + 1 : undefined,
    onSuccess: (data) => {
      // Check if the filtered data returned is empty
      if (data.pages.every((page) => page.data.length === 0)) {
        setNoDataAvailable(true); // Set the flag to true if no data is available
      } else {
        setNoDataAvailable(false); // Reset the flag if data is found
      }
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleTODChange = (value) => {
    setSelectedTOD(value); // Set specific TOD
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD"));
      setToDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const downloadCSV = async () => {
    setLoading(true); // Start loading
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/todviewdata/download?`;

      if (fromDate) {
        query += `fromDate=${fromDate}&`;
      }
      if (toDate) {
        query += `toDate=${toDate}&`;
      }
      if (section) {
        query += `section=${encodeURIComponent(section)}&`;
      }
      if (selectedTOD) {
        query += `tod_key=${encodeURIComponent(selectedTOD)}&`;
      }

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed to fetch data for CSV download");
      }

      const data = await response.json();

      const csvContent = [
        [
          "S. No.",
          "Time",
          "TOD Timings",
          "Type",
          "Section",
          "KWh",
          "KVAh",
          "KVARh",
          "MD",
          "Avg PF",
        ],
        ...data.map((item, index) => [
          index + 1,
          item.timestamp,
          item.tod_key,
          item.Type,
          item.section,
          item.KWh,
          item.KVAh,
          item.KVArh,
          item.MD,
          item.PF,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      const filters = [];
      if (fromDate) filters.push(`From-${fromDate}`);
      if (toDate) filters.push(`To-${toDate}`);
      if (section) filters.push(`Section-${section}`);
      if (selectedTOD) filters.push(`${selectedTOD}`);
      const fileName = filters.length > 0 ? filters.join("_") : "AllData";
      const fullFileName = `TODHistory-${fileName}.csv`;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderTableBody = () => {
    const toddata = data?.pages.flatMap((page) => page.data) || [];

    return (
      <>
        {isError ? (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Error: {error?.message}
            </TableCell>
          </TableRow>
        ) : toddata?.length === 0 ? (
          <TableRow sx={{ height: 300 }}>
            <TableCell colSpan={10} align="center">
              <Empty description={"No data available"} />
            </TableCell>
          </TableRow>
        ) : (
          toddata.map((item, index) => (
            <TableRow key={item._id || index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{item.timestamp}</TableCell>
              <TableCell align="center">{item.tod_key}</TableCell>
              <TableCell align="center">{item.Type}</TableCell>
              <TableCell align="center">{item.section}</TableCell>
              <TableCell align="center">{item.KWh}</TableCell>
              <TableCell align="center">{item.KVAh}</TableCell>
              <TableCell align="center">{item.KVArh}</TableCell>
              <TableCell align="center">{item.MD}</TableCell>
              <TableCell align="center">{item.PF}</TableCell>
            </TableRow>
          ))
        )}
      </>
    );
  };

  // Table footer row that displays the "No More Data Available" message
  const renderTableFooter = () => {
    const toddata = data?.pages.flatMap((page) => page.data) || [];

    if (toddata.length === 0) return null; // Don't show footer if there's no data

    return (
      <tr ref={ref}>
        <td colSpan="10" style={{ textAlign: "center" }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            !hasNextPage && "No More Data Available" // Only show this message when all data is fetched
          )}
        </td>
      </tr>
    );
  };

  return (
    <div id="todhistory-total">
      <div id="todhistory-right">
        <div id="todhistory-right-2">
          <div id="todhistory-right-21">
            <label>
              Date Range:
              <Space direction="vertical" size={12}>
                <RangePicker
                  size="medium"
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
                  value={
                    fromDate && toDate
                      ? [
                          dayjs(fromDate, "YYYY-MM-DD"),
                          dayjs(toDate, "YYYY-MM-DD"),
                        ]
                      : null
                  }
                />
              </Space>
            </label>
            <label>
              Select TOD:
              <Space wrap>
                <Select
                  allowClear
                  value={selectedTOD || "Select All"} // To handle "Please Select"
                  onChange={handleTODChange}
                  placeholder="Please Select"
                  size="medium"
                  style={{
                    width: 200,
                  }}
                >
                  <Option value="Please Select" disabled>
                    Select All
                  </Option>
                  <Option value="TOD1 (00:00:00 - 05:59:59)">
                    TOD1 (00:00:00 - 05:59:59)
                  </Option>
                  <Option value="TOD2 (06:00:00 - 09:59:59)">
                    TOD2 (06:00:00 - 09:59:59)
                  </Option>
                  <Option value="TOD3 (10:00:00 - 17:59:59)">
                    TOD3 (10:00:00 - 17:59:59)
                  </Option>
                  <Option value="TOD4 (18:00:00 - 21:59:59)">
                    TOD4 (18:00:00 - 21:59:59)
                  </Option>
                  <Option value="TOD5 (22:00:00 - 23:59:59)">
                    TOD5 (22:00:00 - 23:59:59)
                  </Option>
                </Select>
              </Space>
            </label>
            <label>
              <Stationpoints
                showAll={true}
                onStationPointChange={handleStationPointChange}
              />
            </label>

            <Button
              type="primary"
              icon={
                loading ? (
                  <LoadingOutlined style={{ color: "#252B3F" }} />
                ) : (
                  <FaFileCsv style={{ color: "#252B3F" }} />
                )
              }
              onClick={downloadCSV}
            ></Button>
            {/* <button onClick={downloadCSV}>
                <FaFileCsv />
              </button> */}
          </div>

          <Box mt={2}>
            <CommonTable columns={tableColumns}>
              {renderTableBody()}
              {renderTableFooter()}
            </CommonTable>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default TODviewcomponent;
