

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronRight, FaChevronUp } from "react-icons/fa";
import MultiStationpoints from "../../Commonfns/MultiStationpoints"; // Multi-select component
import "./DailyConsumption.css";
import Operatorsidebar from "../Sidebar/Sidebar";
import TopBar from "../../Commonfns/Topbar/Topbar";
import { FaFileCsv } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const DailyConsumption = () => {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]); // API response data
  const [expandedSections, setExpandedSections] = useState({});
  const [sectionFilter, setSectionFilter] = useState([]); // Multi-section filter
  const [dateFilter, setDateFilter] = useState(""); // Selected date filter

  const yesterday = dayjs().subtract(1, "day").format(dateFormat);

  // Disable dates function
  const disableDates = (current) => {
    // Disable today and future dates
    return current && current >= dayjs().startOf("day");
  };

    function getYesterdayDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract one day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Fetch data whenever `sectionFilter` or `dateFilter` changes
  useEffect(() => {
    fetchData();
  }, [sectionFilter, dateFilter]);

  // Function to fetch data from the API
  const fetchData = () => {
    const queryParams = new URLSearchParams();
    if (sectionFilter.length > 0) {
      let encodedSections = sectionFilter.map((sec) => encodeURIComponent(sec));
      queryParams.append("sectionNames", encodedSections.join(",")); // Multiple sections
    }
    queryParams.append("date", dateFilter || yesterday); // Default to yesterday's date

    axios
      .get(`${process.env.REACT_APP_API_EMS_URL}/api/sections-data?${queryParams.toString()}`)
      .then((response) => {
        setSections(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sections data:", error);
      });
  };

  // Handle multi-section filter changes
  const handleStationPointChange = (selectedStationPoints) => {
    setSectionFilter(selectedStationPoints || []); // Update the filter state
  };

  // Handle date filter changes
  const handleDateChange = (value) => {
    setDateFilter(value ? dayjs(value).format(dateFormat) : ""); // Update date filter
  };
  

  // Toggle section expansion in hierarchical view
  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const flattenSections = (sections) => {
    const flatData = [];

    const flatten = (section, parentName = "") => {
      flatData.push({
        section_name: section.section_name,
        parent_name: parentName,
        date: dayjs(section.date).format("YYYY-MM-DD"), 
        consumption: section.value ?? "--",
      });

      section.childs.forEach((child) => flatten(child, section.section_name));
    };

    sections.forEach((section) => flatten(section));
    return flatData;
  };

  const downloadCSV = () => {
    try {
      setLoading(true); // Start loading
      const flatData = flattenSections(sections);

      const dateObj = dateFilter ? dayjs(dateFilter, dateFormat) : dayjs(getYesterdayDate(), dateFormat);
    const formattedDate = dateObj.format("YYYY-MM-DD"); // Use dd-MM-yyyy for the filename


      const csvContent =
        "data:text/csv;charset=utf-8," +
        ["Section Name,Parent Name,Date,Consumption"]
          .concat(
            flatData.map(
              (row) =>
                `${row.section_name},${row.parent_name},${row.date},${row.consumption}`
            )
          )
          .join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const fileName = `Consumption-Day-${formattedDate}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for Firefox
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Render sections (flat for filtered, hierarchical otherwise)
  const renderSections = () => {
    if (sectionFilter.length > 0) {
      // Flat rendering for filtered sections
      return sections.map((section) => (
        <div key={section.section_name} className="daily-consumpton-tr">
          <div className="chevron-container"></div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="date-column">{section.date}</div>
          <div className="consumption-column">{section.value || "--"}</div>
        </div>
      ));
    }

    // Hierarchical rendering for unfiltered sections
    return sections.map((section) => renderRow(section));
  };

  // Recursive rendering for hierarchical data
  const renderRow = (section, level = 0) => {
    const isExpanded = expandedSections[section.section_name];

    return (
      <React.Fragment key={section.section_name}>
        <div
          className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""} level-${level + 1}`}
        >
          <div className="chevron-container">
            {section.childs?.length > 0 ? (
              <div onClick={() => toggleSection(section.section_name)}>
                {isExpanded ? <FaChevronUp /> : <FaChevronRight />}
              </div>
            ) : (
              <div style={{ width: "16px" }}></div> // Empty space for alignment
            )}
          </div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="date-column">{section.date}</div>
          <div className="consumption-column">{section.value || "--"}</div>
        </div>
        {isExpanded && section.childs?.map((child) => renderRow(child, level + 1))}
      </React.Fragment>
    );
  };

  return (
    <div id="dailyconsumption-total">
      <div id="dailyconsumption-right">
        <div id="dailyconsumption-right-2">
          <div id="dailyconsumption-right-21">
            <label>
              <span>Select Date:</span>
              <DatePicker
                size="medium"
                onChange={handleDateChange}
                defaultValue={dayjs(yesterday, dateFormat)}
                format={dateFormat}
                disabledDate={disableDates}
                allowClear={false}
                
              />
            </label>
            <label>
              <MultiStationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <Button
              type="primary"
              icon={
                loading ? (
                  <LoadingOutlined style={{ color: "#252B3F" }} />
                ) : (
                  <FaFileCsv style={{ color: "#252B3F" }} />
                )
              }
              onClick={downloadCSV}
            />
          </div>
          <div id="dailyconsumption-right-22">
            <div id="dailyconsumption-right-221">
              <div className="chevron-container"></div>
              <div className="daily-consyumptoon-t1">Section Name</div>
              <div className="date-column">Date</div>
              <div className="consumption-column">kWh Consumption</div>
            </div>
            <div className="section-body">{renderSections()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyConsumption;

