
import React, { useState } from "react";
import Stationpoints from "../../Commonfns/Stationpoints";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaFilePdf } from "react-icons/fa6";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
const Calendercombinecontainer = ({
  selectedMonth,
  onMonthChange,
  onStationPointChange,
  defaultStationPoint,
}) => {
  const handleMonthChange = (date, dateString) => {
    onMonthChange(dateString); // Pass the formatted month (YYYY-MM) to the parent
  };

  const getCurrentMonth = () => {
    const date = new Date();
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  };

  const handleStationPointChange = (selectedStationPoint) => {
    onStationPointChange(selectedStationPoint);
  };

  const [loading, setLoading] = useState(false);

  const downloadPDF = () => {
    setLoading(true);
    // alert('Please wait, PDF is being generated...');
    const input = document.getElementById("calendercombine-bothtabs");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        const formattedMonth = selectedMonth || getCurrentMonth(); // Use current month if none selected
        const sectionName = defaultStationPoint; 
        const filename = `Calender-${formattedMonth}-${sectionName}.pdf`;
        pdf.save(filename);
        setLoading(false);
        // alert('PDF has been downloaded successfully!');
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        alert("There was an error generating the PDF.");
      });
  };

  return (
    <div id="combinecal-div">
      
      <label>
        <span>Select Month :</span>
        <DatePicker
          allowClear={false}
          size={"medium"}
          id="month"
          picker="month"
          value={selectedMonth ? dayjs(selectedMonth, "YYYY-MM") : null}
          onChange={handleMonthChange}
          disabledDate={(current) => current && current > dayjs()}
        />
      </label>

      <label>
        <Stationpoints
          allowClear={false}
          onStationPointChange={handleStationPointChange}
          defaultStationPoint={defaultStationPoint}
        />
      </label>

      <label>
        <Button
          type="primary"
          icon={
            loading ? (
              <LoadingOutlined style={{ color: "#252B3F" }} />
            ) : (
              <FaFilePdf style={{ color: "#252B3F" }} />
            )
          }
          onClick={downloadPDF}
        ></Button>
      </label>
    </div>
  );
};

export default Calendercombinecontainer;
