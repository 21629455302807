import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaFileCsv } from "react-icons/fa6";
import "./Overallalertscount.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import CommonTable from "../../../Common/CommonTable/CommonTable";
import { Box, TableCell, TableRow } from "@mui/material";

const overAlertCountColumns = [
  { id: 1, label: "S. No.", align: "center" },
  { id: 2, label: "Date", align: "center" },
  { id: 3, label: "Vr", align: "center" },
  { id: 4, label: "Vy", align: "center" },
  { id: 5, label: "Vb", align: "center" },
  { id: 6, label: "Ir", align: "center" },
  { id: 7, label: "Iy", align: "center" },
  { id: 8, label: "Ib", align: "center" },
  { id: 9, label: "PF", align: "center" },
  { id: 10, label: "KVA", align: "center" },
  { id: 11, label: "Switch", align: "center" },
  { id: 12, label: "AC Input", align: "center" },
  { id: 13, label: "Communication", align: "center" },
];

const Overallalertscount = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/overall_alerts_reports`,
        {
          params: { fromDate, toDate }, // Send filters to the server
        }
      );
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  const downloadCSV = () => {
    setLoading(true);
    try {
      const csvRows = [];
      const headers = [
        "S. No.",
        "Date",
        "Vr",
        "Vy",
        "Vb",
        "Ir",
        "Iy",
        "Ib",
        "PF",
        "KVA",
        "Switch",
        "AC Input",
        "Communication",
      ];
      csvRows.push(headers.join(","));

      filteredData.forEach((item, index) => {
        const row = [
          index + 1, // S. No.
          moment(item.date).format("YYYY-MM-DD"),
          item.Vr,
          item.Vy,
          item.Vb,
          item.Ir,
          item.Iy,
          item.Ib,
          item.PF,
          item.KVA,
          item.Switch,
          item["AC Input"],
          item.Communication,
        ];
        csvRows.push(row.join(","));
      });

      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "OverallAlertsdata.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD"));
      setToDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={13} align="center">
            Loading...
          </TableCell>
        </TableRow>
      );
    }

    if (filteredData.length === 0) {
      return (
        <TableRow sx={{ height: 500 }}>
          <TableCell colSpan={5} align="center">
            <Empty description={"No data available"} />
          </TableCell>
        </TableRow>
      );
    }

    return filteredData.map((item, index) => (
      <TableRow key={item.date || index}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{item.date}</TableCell>
        <TableCell align="center">{item.Vr}</TableCell>
        <TableCell align="center">{item.Vy}</TableCell>
        <TableCell align="center">{item.Vb}</TableCell>
        <TableCell align="center">{item.Ir}</TableCell>
        <TableCell align="center">{item.Iy}</TableCell>
        <TableCell align="center">{item.Ib}</TableCell>
        <TableCell align="center">{item.PF}</TableCell>
        <TableCell align="center">{item.KVA}</TableCell>
        <TableCell align="center">{item.Switch}</TableCell>
        <TableCell align="center">{item["AC Input"]}</TableCell>
        <TableCell align="center">{item.Communication}</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <div id="overallalertcount-total">
        <div id="overallalertcount-right-21">
          <label>
            Date Range:
            <Space direction="vertical" size={12}>
              <RangePicker
                size="medium"
                disabledDate={disabledDate}
                onChange={handleRangePickerChange}
                value={
                  fromDate && toDate
                    ? [
                        dayjs(fromDate, "YYYY-MM-DD"),
                        dayjs(toDate, "YYYY-MM-DD"),
                      ]
                    : null
                }
              />
            </Space>
          </label>

          <Button
            type="primary"
            icon={
              loading ? (
                <LoadingOutlined style={{ color: "#252B3F" }} />
              ) : (
                <FaFileCsv style={{ color: "#252B3F" }} />
              )
            }
            onClick={downloadCSV}
          />
        </div>
      </div>
      <Box mt={2}>
        <CommonTable height={500} columns={overAlertCountColumns}>
          {renderTableBody()}
        </CommonTable>
      </Box>
    </>
  );
};

export default Overallalertscount;
