


import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashTransformerhealth.css';

const DashTransformerhealth = ({ selectedMonth }) => {
    const [lossData, setLossData] = useState(null);

    const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_EMS_URL}/api/transformerloss_percentage`,
            {
              params: {
                month: selectedMonth || new Date().toISOString().slice(0, 7),
              },
            }
          );
          setLossData(response.data);
        } catch (error) {
          console.error("Error Fetching transformer health Data:", error.message);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, [selectedMonth]);


    useEffect(() => {
        if (lossData && lossData.date && lossData.transformerLoss && lossData.transformerEfficiency && lossData.oilTemp && lossData.windingTemp) {
          const chartContainer = document.getElementById("dashtransformerhealth-barchart"); // Use document.getElementById
          if (chartContainer) {
            const myChart = echarts.init(chartContainer);
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['Transformer Loss', 'Transformer Efficiency', 'Oil Temp', 'Winding Temp'],
                },
                xAxis: {
                    type: 'category',
                    data: lossData.date,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: { interval: 0, rotate: 60 }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'Efficiency & Loss (%)',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    },
                    {
                        type: 'value',
                        name: 'Temperature (°C)',
                        axisLabel: {
                            formatter: '{value} °C'
                        }
                    }
                ],
                series: [
                    {
                        name: 'Transformer Loss',
                        type: 'bar',
                        data: lossData.transformerLoss,
                        tooltip: {
                          valueFormatter: function (value) {
                              return value !== null && value !== undefined ? value + ' %' : '--';
                          }
                      },
                        itemStyle: {
                            color: '#EA4335' 
                        }
                    },
                    {
                        name: 'Transformer Efficiency',
                        type: 'bar',
                        data: lossData.transformerEfficiency,
                        tooltip: {
                          valueFormatter: function (value) {
                              return value !== null && value !== undefined ? value + ' %' : '--';
                          }
                      },
                        itemStyle: {
                            color: 'green' 
                        }
                    },
                    {
                        name: 'Oil Temp',
                        type: 'line',
                        yAxisIndex: 1,
                        data: lossData.oilTemp,
                        tooltip: {
                          valueFormatter: function (value) {
                              return value !== null && value !== undefined ? value + ' °C' : '--';
                          }
                      },
                        itemStyle: {
                            color: 'orange' 
                        }
                    },
                    {
                        name: 'Winding Temp',
                        type: 'line',
                        yAxisIndex: 1,
                        data: lossData.windingTemp,
                        tooltip: {
                          valueFormatter: function (value) {
                              return value !== null && value !== undefined ? value + ' °C' : '--';
                          }
                      },
                        itemStyle: {
                              color: '#7E95DF'
                        }
                    }
                ]
            };
    
            myChart.setOption(option);
    
            const resizeChart = () => {
              myChart.resize();
            };
    
            window.addEventListener("resize", resizeChart);
    
            return () => {
              window.removeEventListener("resize", resizeChart);
              myChart.dispose();
            };
          } else {
            console.error("Chart container not found.");
          }
        }
      }, [lossData]);

    const isDataAvailable =
    lossData &&
    lossData.transformerLoss?.length > 0 &&
    lossData.transformerEfficiency?.length > 0 &&
    lossData.oilTemp?.length > 0 &&
    lossData.windingTemp?.length > 0 ;

    return (
       
        <div id='dashtransformerhealth-total'>
            <span style={{fontWeight:'bold', fontSize:'20px'}} >Transformer Health</span>
            {!isDataAvailable ? (
                <p className="no-data" style={{textAlign:'center'}}> No Data Available</p>
            ) : (
                <>
                    <div id='dashtransformerhealth-div1'>
                        <div id='dashtransformerhealth-div2'>
                                <div id='dashtransformerhealth-div21'>
                                    <label>Tap Count (Average)* :</label>
                                    <p>{lossData.tapPosition!== null? lossData.tapPosition : 'N/A'}</p>
                                </div>
                        </div>
                    </div>
                    <div id="dashtransformerhealth-barchart" />
                </>
            )}
        </div>

    );
};

export default DashTransformerhealth;