import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Hvformfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, Modal } from "antd";
const HvformFetch = ({ onFormButtonClick }) => {
  const [hvforms, setHvforms] = useState([]);
  const [hvformseditData, setHvformsEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-incomerNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching incomer names", error);
    }
  };

  const fetchHvforms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/HVforms`
      );
      setHvforms(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchHvforms();
    fetchParentNames();
  }, []);

  const handleEditClick = (hvform) => {
    setHvformsEditData(hvform);
    setIsModalVisible(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setHvformsEditData({ ...hvformseditData, [name]: value });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setHvformsEditData(null);
  };

  const handleEditSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/HVforms/${hvformseditData._id}`,
        hvformseditData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchHvforms();
      setHvformsEditData(null);
      toast.success("HV Form updated sucessfully");
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="hvformsfetch-total">
      <div id="hvformsfetch-right">
        <div id="hvformsfetch-right-2">
          <div id="hvformsfetch-right-21">
            <label>{Masterformsheadingsss.HVHeading}</label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>

          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Parent Name</th>
                <th>Name</th>
                <th>Meter Make</th>
                <th>Meter SL.no</th>
                <th>Phase</th>
                <th>Connection Load (kVA)</th>
                <th>Threshold load</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {hvforms.map((hv) => (
                <tr key={hv._id}>
                  <td>{hv.date}</td>
                  <td>{hv.parentname}</td>
                  <td>{hv.name}</td>
                  <td>{hv.metermake}</td>
                  <td>{hv.meterslno}</td>
                  <td>{hv.phase}</td>
                  <td>{hv.connectionloadinva}</td>
                  <td>{hv.thresholdload}</td>
                  <td>
                    <button onClick={() => handleEditClick(hv)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            title="Edit HV Form"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" }, // Change background color and text color
            }}
          >
            {hvformseditData && (
              <Form layout="vertical">
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input name="date" value={hvformseditData.date} disabled />
                  </Form.Item>
                  <Form.Item label="Parent Name" name="parentname">
                    <Select
                      placeholder="Select Parent Name"
                      value={hvformseditData.parentname}
                      onChange={(value) =>
                        setHvformsEditData({
                          ...hvformseditData,
                          parentname: value,
                        })
                      }
                    >
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="name"
                      value={hvformseditData.name}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Meter Make">
                    <Input
                      name="metermake"
                      value={hvformseditData.metermake}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Meter S. No.">
                    <Input
                      name="meterslno"
                      value={hvformseditData.meterslno}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Phase" name="phase">
                    <Select
                      placeholder="Select Phase"
                      value={hvformseditData.phase}
                      onChange={(value) =>
                        setHvformsEditData({ ...hvformseditData, phase: value })
                      }
                    >
                      <Select.Option value="">Select Phase</Select.Option>
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1-Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1-Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1-Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Connection Load (kVA)">
                    <Input
                      type="number"
                      name="connectionloadinva"
                      value={hvformseditData.connectionloadinva}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Threshold load">
                    <Input
                      type="number"
                      name="thresholdload"
                      value={hvformseditData.thresholdload}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default HvformFetch;
