import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DGlogs.css";
import {
  formatDateToDDMMYYYY,
  formatDateTimeToDDMMYYYYHHMM,
} from "../Dateformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const DGlogs = ({ onViewData }) => {
  const getEndOfToday = () => {
    // Get the end of today using moment
    return moment().endOf("day").format("YYYY-MM-DDTHH:mm");
  };
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getCurrentDateTime = () => {
    const today = new Date();
    return today.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
  };
  const [formData, setFormData] = useState({
    starttime: "",
    generator:"",
    endtime: "",
    kwhinitial: "",
    kwhfinal: "",
    kwhconsumption: "",
    dieselconsumption: "",
    duration: "",
    createdby: "",
    date: getCurrentDate(),
  });

  const [errors, setErrors] = useState({
    kwhinitial: "",
    kwhfinal: "",
    starttime: "",
    endtime: "",
  });

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userDesignation, setUserDesignation] = useState("");
  const [generatorsdata, setGeneratorsData] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setLoggedInUser(user);
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username,
      }));
      setUserDesignation(user.level);
    }
  }, []);

  const fetchGeneratorsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/all-generators`
      );
      setGeneratorsData(response.data);
    } catch (error) {
      console.error("error getting Generators data");
    }
  };

  useEffect(() => {
    fetchGeneratorsData();
  }, []);

  const calculateDuration = (starttime, endtime) => {
    const startTime = moment(starttime);
    const endTime = moment(endtime);

    if (endTime.isSameOrAfter(startTime)) {
      const duration = moment.duration(endTime.diff(startTime));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();

      const formattedDuration = `${days}d ${
        hours < 10 ? "0" + hours : hours
      }h ${minutes < 10 ? "0" + minutes : minutes}m`;

      setFormData((prevState) => ({
        ...prevState,
        duration: formattedDuration,
      }));
    } else {
      // If end time is before start time, set duration to empty
      setFormData((prevState) => ({
        ...prevState,
        duration: "",
      }));
    }
  };

  const calculateKWhConsumption = (kwhinitial, kwhfinal) => {
    const initial = parseFloat(kwhinitial);
    const final = parseFloat(kwhfinal);

    if (!isNaN(initial) && !isNaN(final)) {
      return (final - initial).toFixed(2);
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation logic for each field
    if (name === "kwhinitial" || name === "kwhfinal") {
      if (value.match(/^\d{0,15}(\.\d{0,2})?$/)) {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "Enter a valid number up to 15 digits.",
        }));
        return;
      }
    } else if (name === "dieselconsumption") {
      if (value.match(/^\d{0,4}(\.\d{0,2})?$/)) {
        setErrors((prev) => ({ ...prev, dieselconsumption: "" }));
      } else {
        setErrors((prev) => ({
          ...prev,
          dieselconsumption: "Enter a valid number up to 4 digits.",
        }));
        return;
      }
    }

    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [name]: value,
      };

      const startTime = moment(updatedFormData.starttime);
      const endTime = moment(updatedFormData.endtime);
      const now = moment();
      const endOfToday = moment().endOf("day");

      let newErrors = { ...errors };

      // Validation: Ensure date is not in the future
      if (name === "starttime" || name === "endtime") {
        if (startTime.isAfter(endOfToday) || endTime.isAfter(endOfToday)) {
          newErrors[name] = "Date cannot be in the future.";
        } else {
          newErrors[name] = "";
        }

        // Check if end time is earlier than start time
        if (endTime.isBefore(startTime)) {
          newErrors.endtime = "End Time cannot be earlier than Start Time.";
        } else {
          newErrors.endtime = "";
        }

        // Update duration if times are valid
        if (!newErrors.starttime && !newErrors.endtime) {
          calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
        } else {
          // If there is an error, clear the duration
          setFormData((prevState) => ({
            ...prevState,
            duration: "",
          }));
        }
      }

      // Validation for KWh values
      if (name === "kwhinitial" || name === "kwhfinal") {
        const initial = parseFloat(updatedFormData.kwhinitial);
        const final = parseFloat(updatedFormData.kwhfinal);

        if (!isNaN(initial) && !isNaN(final) && initial > final) {
          newErrors.kwhinitial =
            "KWh Initial value cannot be greater than KWh Final value.";
        } else {
          newErrors.kwhinitial = "";
          updatedFormData.kwhconsumption = calculateKWhConsumption(
            updatedFormData.kwhinitial,
            updatedFormData.kwhfinal
          );
        }
      }
      // Update errors and form data
      setErrors(newErrors);
      return updatedFormData;
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Check if there are any validation errors
  //   const hasErrors = Object.values(errors).some((error) => error);
  //   if (hasErrors) {
  //     toast.error("Please fix all errors before submitting.");
  //     return;
  //   }

  //   // Format the date using the dateformat function
  //   const formattedDate = formatDateToDDMMYYYY(formData.date);
  //   const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(formData.starttime);
  //   const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(formData.endtime);
  //   const parsedFormData = {
  //     ...formData,
  //     date: formattedDate,
  //     starttime: formattedStartTime,
  //     endtime: formattedEndTime,
  //     vr: parseFloat(formData.vr),
  //     vb: parseFloat(formData.vb),
  //     vy: parseFloat(formData.vy),
  //     kwhinitial: parseFloat(formData.kwhinitial),
  //     kwhfinal: parseFloat(formData.kwhfinal),
  //     kwhconsumption: parseFloat(formData.kwhconsumption),
  //     dieselconsumption: parseFloat(formData.dieselconsumption),
  //   };

  //   if (
  //     !errors.kwhinitial &&
  //     !errors.kwhfinal &&
  //     !errors.starttime &&
  //     !errors.endtime
  //   ) {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_EMS_URL}/api/dg-logs`,
  //         parsedFormData
  //       );
  //       if (response.data.success) {
  //         setFormData({
  //           generator:"",
  //           starttime: "",
  //           endtime: "",
  //           kwhinitial: "",
  //           kwhfinal: "",
  //           kwhconsumption: "",
  //           dieselconsumption: "",
  //           duration: "",
  //           createdby: loggedInUser.username,
  //           date: getCurrentDate(),
  //         });
  //         toast.success("DG Form submitted successfully");
  //       }
  //     } catch (error) {
  //       console.error("Error Adding DG log", error);
  //       if (
  //         error.response &&
  //         error.response.data.message === "A log already exists for the date"
  //       ) {
  //         toast.error(
  //           "Data already exists for the Selected Date, Please select any other Date"
  //         );
  //       } else {
  //         toast.error("Failed to add DG log. Please try again later.");
  //       }
  //     }
  //   } else {
  //     toast.error("Please fix the errors before submitting.");
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Remove validation for commented-out fields
    const { generator, dieselconsumption, date } = formData;
  
    // Basic validation for only the required fields
    if (!generator || !dieselconsumption || !date) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    try {
      // Format the date using the dateformat function
      const formattedDate = formatDateToDDMMYYYY(formData.date);
      const parsedFormData = {
        generator,
        dieselconsumption: parseFloat(dieselconsumption),
        createdby: loggedInUser?.username || "Unknown",
        date: formattedDate,
      };
  
      // Submit the form data
      const response = await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/dg-logs`,
        parsedFormData
      );
      if (response.data.success) {
        setFormData({
          generator: "",
          dieselconsumption: "",
          createdby: loggedInUser?.username || "Unknown",
          date: getCurrentDate(),
        });
        toast.success("DG Form submitted successfully");
      }
    } catch (error) {
      console.error("Error Adding DG log", error);
      if (
        error.response &&
        error.response.data.message === "A log already exists for the date"
      ) {
        toast.error(
          "Data already exists for the Selected Date, Please select any other Date"
        );
      } else {
        toast.error("Failed to add DG log. Please try again later.");
      }
    }
  };
  

  const handleKeyPress = (e) => {
    const invalidChars = ["e", "E", "+", "-"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div id="dglogs-total">
      <div id="dglogs-right">
        <div id="dglogs-right-2">
          <form onSubmit={handleSubmit}>
            <div id="dglogs-divtop">
              <label>
                <button type="button" onClick={onViewData}>
                  View Data
                </button>
              </label>
            </div>
            <div id="dglogs-div1">
              <label>
                <span>
                  Date <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  max={getCurrentDate()}
                />
              </label>
            </div>

            <div id="dglogs-div1">

              <label>
                <span>
                  Generator <span id="all-mandetorymark">*</span>{" "}
                </span>

                <select
                  name="generator"
                  value={formData.generator}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select All</option>
                  {generatorsdata.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                <span>
                  Diesel Consumption <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="number"
                  name="dieselconsumption"
                  value={formData.dieselconsumption}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  required
                />
              </label>
            </div>

            {/* <div id="dglogs-div1">
              <label>
                <span>
                  Start Time <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="datetime-local"
                  name="starttime"
                  value={formData.starttime}
                  onChange={handleChange}
                  max={getEndOfToday()}
                  required
                />
                {errors.starttime && (
                  <span id="dglogs-errormessage" style={{ color: "red" }}>
                    {errors.starttime}
                  </span>
                )}
              </label>

              <label>
                <span>
                  End Time <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="datetime-local"
                  name="endtime"
                  value={formData.endtime}
                  onChange={handleChange}
                  max={getEndOfToday()}
                  required
                />
                {errors.endtime && (
                  <span id="dglogs-errormessage" style={{ color: "red" }}>
                    {errors.endtime}
                  </span>
                )}
              </label>
            </div>

            <div id="dglogs-div1">
              <label>
                <span>
                  KWh (initial readings) <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="number"
                  name="kwhinitial"
                  value={formData.kwhinitial}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  required
                />
                {errors.kwhinitial && (
                  <span id="dglogs-errormessage" style={{ color: "red" }}>
                    {errors.kwhinitial}
                  </span>
                )}
              </label>

              <label>
                <span>
                  KWh (final readings) <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="number"
                  name="kwhfinal"
                  value={formData.kwhfinal}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  required
                />
                {errors.kwhfinal && (
                  <span id="dglogs-errormessage" style={{ color: "red" }}>
                    {errors.kwhfinal}
                  </span>
                )}
              </label>
            </div>
            <div id="dglogs-div1">
              <label>
                <span>
                  KWh Consumption <span id="all-mandetorymark">*</span>
                </span>
                <input
                  name="kwhconsumption"
                  value={formData.kwhconsumption}
                  onChange={handleChange}
                  disabled
                />
              </label>

              <label>
                <span>
                  Duration <span id="all-mandetorymark">*</span>
                </span>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  disabled
                />
              </label>
            </div> */}
            <div  id="dglogs-save">
            <button  type="submit">
              Submit
            </button>
            </div>
           
          </form>
        </div>
      </div>
    </div>
  );
};

export default DGlogs;
