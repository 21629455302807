import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Button as AntDButton, DatePicker, Popover, Flex } from "antd";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getRevenueLossesTrends } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import lightIcon from "../../../Images/light.svg";
import manIcon from "../../../Images/man.svg";
import { errorToast } from "../../../utils/toast";
import "./RevenueLoss.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const RevenueLossTrend = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      month: dayjs(),
      section: null,
    },
  });

  const [chartData, setChartData] = useState([]);
  const [totalEnergyLoss, setTotalEnergyLoss] = useState(0);
  const [totalRevenueLoss, setTotalRevenueLoss] = useState(0);

  const { month, section } = watch();

  useEffect(() => {
    const sectionFilter = section || null;

    ChartJS.register(ChartDataLabels);
    getRevenueLossesTrends(month.format("YYYY-MM"), sectionFilter)
      .then((res) => {
        setTotalEnergyLoss(res.totalEnergyLoss);
        setTotalRevenueLoss(res.totalRevenueLoss);
        setChartData(res.graphdata);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [section, month]);

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const handleStationPointChange = (value) => {
    setValue("section", value);
  };

  const data = {
    labels: chartData.map((label) => label?.type),
    datasets: [
      {
        label: "Revenue Losses",
        // data: chartData.map((data) => data?.details?.energyLoss),
        data: chartData.map((data) => data.details.energyLoss),
        backgroundColor: [
          "#FF994D",
          "#4285F4",
          "#FBBC04",
          "#34A853",
          "#FF6D01",
          "#46BDC6",
          "#7BAAF7",
          "#F07B72",
          "#F07B72",
          "#FCD04F",
          "#71C287",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: {
      // duration: 50,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          padding: 33,
          textAlign: "start",
          useBorderRadius: true,
          borderRadius: 5,
        },
      },
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const typeObj = chartData.find(
              (data) => data.details.energyLoss == value
            );
            const energyLoss = typeObj?.details?.energyLoss;
            const revenueLoss = typeObj?.details?.revenueLoss;

            return [
              `Energy Loss:  ${energyLoss} kWh`,
              `Revenue Loss: ₹ ${revenueLoss} `,
            ];
          },
        },
      },
      datalabels: {
        color: "white",
        display: (context) => context.dataset.data[context.dataIndex] != null,
        font: {
          // weight: "bold",
          size: 12,
        },
        anchor: "center", // Positions the label in the center of the bar
        align: "center", // Aligns the label with the bar's center
        rotation: -90, // Rotates the label to be vertical
        formatter: (value) => {
          // Check if the value is a float
          return Number.isInteger(value) ? value : value.toFixed(2);
        },
      },
    },
  };

  const chartRef = React.useRef();

  const handleDownloadPdf = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) return;

    try {
      const canvas = await html2canvas(chartElement, {
        useCORS: true, // Ensures cross-origin images are loaded
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the chart into the page
      const ratio = Math.min(
        pageWidth / canvas.width,
        pageHeight / canvas.height
      );
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;

      // Calculate offsets to center the image
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("revenue_loss_trend.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
        <Flex align="center" gap={20}>
          <Box>
            <div>
              <label>{t("common.selectMonthColumn")}</label>
            </div>

            <div>
              <DatePicker
                {...register("month")}
                style={{ width: "200px" }}
                defaultValue={getValues("month")}
                size={"medium"}
                picker={"month"}
                disabledDate={disableFutureMonths} // Restricts future months
                onChange={onChange}
              />
            </div>
          </Box>

          <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none", marginTop: 15 }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>

      <Paper
        ref={chartRef}
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("revenueLossTrendComponent.revenueLoss")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          "revenueLossTrendComponent.whyIsThisImportantAnswer"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"50px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("revenueLossTrendComponent.whatShouldYouDoAnswer")}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Alldash?section=metric-breakdown"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "start",
                            alignSelf: "start",
                            borderRadius: "4px",
                          }}
                        >
                          {t("revenueLossTrendComponent.revenueLoss")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("revenueLossTrendComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        {/* Display Total Values */}
        <Box className="total-losses">
          <Typography variant="body1" className="total-losses-box">
            Total Revenue Loss:{" "}
            <span>
              {totalRevenueLoss === null
                ? "--"
                : `₹ ${totalRevenueLoss.toFixed(2)} `}
            </span>
          </Typography>
          <Typography variant="body1" className="total-losses-box">
            Total Energy Loss:{" "}
            <span>
              {totalEnergyLoss === null
                ? "--"
                : `${totalEnergyLoss.toFixed(2)} kWh`}
            </span>
          </Typography>
        </Box>

        <Pie className="alerts-chart" style={{ width: 400, marginLeft: '18%'}} data={data} options={options} />

        <Typography
          sx={{ textAlign: "end" }}
          dangerouslySetInnerHTML={{
            __html: t("revenueLossTrendComponent.clickBelowLegendToToggleData"),
          }}
          variant="body1"
        />
      </Paper>
    </div>
  );
};

export default RevenueLossTrend;
