import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UPS.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
const UPS = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [form] = Form.useForm();
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleSubmit = async (values) => {
        try {
            const dataToSend = {
                ...values,
                inputvoltage: Number(values.inputvoltage),
                outputvoltage: Number(values.outputvoltage),
                ratedpowerinva: Number(values.ratedpowerinva),
                operatingefficiency: Number(values.operatingefficiency),
                numberofbatteries: Number(values.numberofbatteries),
                eachbatterycapacity: Number(values.eachbatterycapacity),
                connectedloadinva: Number(values.connectedloadinva),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/ups`, dataToSend);

            toast.success('UPS added successfully');
            form.resetFields();
            fetchParentNames();
        } catch (error) {
            console.error("Error submitting data", error);
            toast.error("Error adding UPS");
        }
    };

    return (
        <div id='masterforms-total'>
            <div id='masterforms-right'>
                <div id='masterforms-right-2'>


                <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.UPSHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Parent Name" name="parentname">
                  <Select id="ant-input-style" placeholder="Select Parent Name">
                    {parentNames.map((name, index) => (
                      <Select.Option key={index} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Meter Make" name="metermake">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Meter Serial No." name="meterslno">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phase" name="phase">
                  <Select id="ant-input-style" placeholder="Select Phase">
                    <Select.Option value="">Select Phase</Select.Option>
                    <Select.Option value="3-Phase">3-Phase</Select.Option>
                    <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                    <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                    <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="UPS Topology" name="upstopology">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Input Voltage" name="inputvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Output Voltage" name="outputvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Rated Power (in kVA)" name="ratedpowerinva">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Operating Efficiency" name="operatingefficiency">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Backup Time" name="backuptime">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Connected Load (in kVA)" name="connectedloadinva">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Battery Type" name="batterytype">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Number of Batteries" name="numberofbatteries">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Each Battery Capacity" name="eachbatterycapacity">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Battery Recharge Time" name="batteryrechargetime">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Cooling System" name="coolingsystem">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Proposed Periodicity of Maintenance (UPS)" name="proposedperiodicityofmaintenanceofups">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Proposed Periodicity of Maintenance (Batteries)" name="proposedperiodicityofmaintenanceofbatteries">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Previous Maintenance Date (UPS)" name="previousmaintenancedonedateforups">
                  <DatePicker  style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Previous Maintenance Date (Batteries)" name="previousmaintenancedonedateofbatteries">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
                </div>
            </div>
        </div>
    );
};

export default UPS;
