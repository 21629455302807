// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progress-container{
    margin-left: 1vw;
    height: auto !important;
    width: auto;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;
}

.util-progressbar{
    height: 230px;
    width: 300px;
    padding: 20px 35px 20px 93px;
}

.util-progressbar .CircularProgressbar-text {
    font-family:Verdana, Geneva, Tahoma, sans-serif; 
    font-size: 3rem;
    font-weight: bold; 
    /* color: #941a1a;  */
}
.util-progressbar .CircularProgressbar-path {
    stroke-linecap: round; 
}

.util-progressbar .CircularProgressbar-trail {
    stroke-linecap: round;
}

#util-date-display{
    font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Daily-Dash/Utilization/Utilization.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,+CAA+C;IAC/C,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".circular-progress-container{\n    margin-left: 1vw;\n    height: auto !important;\n    width: auto;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n.util-progressbar{\n    height: 230px;\n    width: 300px;\n    padding: 20px 35px 20px 93px;\n}\n\n.util-progressbar .CircularProgressbar-text {\n    font-family:Verdana, Geneva, Tahoma, sans-serif; \n    font-size: 3rem;\n    font-weight: bold; \n    /* color: #941a1a;  */\n}\n.util-progressbar .CircularProgressbar-path {\n    stroke-linecap: round; \n}\n\n.util-progressbar .CircularProgressbar-trail {\n    stroke-linecap: round;\n}\n\n#util-date-display{\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
