import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { FaFileCsv } from "react-icons/fa6";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import "./ReportDowntime.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { DatePicker, Space } from "antd";
import MultiStationpoints from "../../Commonfns/MultiStationpoints";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CommonTable from "../../Common/CommonTable/CommonTable";
const itemsPerPage = 3;

const columns = [
  { id: "sNo", label: "S. No.", align: "center" },
  { id: "date", label: "Date", align: "center" },
  { id: "section", label: "Section", align: "center" },
  { id: "downtimeDuration", label: "Downtime Duration", align: "center" },
  { id: "uptimeDuration", label: "Uptime Duration", align: "center" },
];

const fetchUptimeDowntime = async ({ pageParam = 1, queryKey }) => {
  const [_, sectionNames, fromDate, toDate] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/all/downtime_Report?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (sectionNames.length > 0) {
    let encodedSections = sectionNames.map((sec) => encodeURIComponent(sec));
    query += `&sectionNames=${encodedSections.join(",")}`; // Multi-section filter
  }

  const response = await fetch(query);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const ReportDowntime_Uptime = () => {
  const [loading, setLoading] = useState(false);
  const [sectionNames, setSectionNames] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["uptime-downtime", sectionNames, fromDate, toDate],
    queryFn: fetchUptimeDowntime,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length > 0 ? pages.length + 1 : undefined,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleStationPointChange = (selectedStationPoints) => {
    setSectionNames(selectedStationPoints || []);
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD"));
      setToDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const downloadCSV = async () => {
    setLoading(true); // Start loading
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report/download?`;

      if (fromDate) query += `fromDate=${fromDate}&`;
      if (toDate) query += `toDate=${toDate}&`;
      if (sectionNames.length > 0) {
        let encodedSections = sectionNames.map((sec) =>
          encodeURIComponent(sec)
        );
        query += `&sectionNames=${encodedSections.join(",")}`; // Multi-section filter
      }

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed to fetch data for CSV download");
      }

      const data = await response.json();

      // Generate CSV content from the fetched data
      const csvContent = [
        ["S NO", "date", "section", "downtime", "uptime"],
        ...data.map((updown, index) => [
          index + 1,
          updown.date,
          updown.section,
          updown.downtime,
          updown.uptime,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create file name based on applied filters
      const filters = [];
      if (fromDate) filters.push(`From-${fromDate}`);
      if (toDate) filters.push(`To-${toDate}`);
      const fileName = filters.length > 0 ? filters.join("_") : "AllData";
      const fullFileName = `DowntimeReport-${fileName}.csv`;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            Error: {error.message}
          </TableCell>
        </TableRow>
      );
    }

    const updowns = data?.pages.flatMap((page) => page.data) || [];

    if (updowns.length === 0) {
      return (
        <TableRow sx={{ height: 500 }}>
          <TableCell colSpan={5} align="center">
            <Empty description={"No data available"} />
          </TableCell>
        </TableRow>
      );
    }

    return updowns.map((updown, index) => (
      <TableRow key={updown._id || index}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{updown.date}</TableCell>
        <TableCell align="center">{updown.section}</TableCell>
        <TableCell align="center">{updown.downtime}</TableCell>
        <TableCell align="center">{updown.uptime}</TableCell>
      </TableRow>
    ));
  };

  const renderTableFooter = () => {
    const updowns = data?.pages.flatMap((page) => page.data) || [];

    if (updowns.length === 0) return null;

    return (
      <tr ref={ref}>
        <td colSpan="5" style={{ textAlign: "center" }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            !hasNextPage && "No More Data Available"
          )}
        </td>
      </tr>
    );
  };

  return (
    <div id="reportdowntime-total">
      <div id="reportdowntime-right-21">
        <label>
          Date Range:
          <Space direction="vertical" size={12}>
            <RangePicker
              size="medium"
              disabledDate={disabledDate}
              onChange={handleRangePickerChange}
              value={
                fromDate && toDate
                  ? [dayjs(fromDate, "YYYY-MM-DD"), dayjs(toDate, "YYYY-MM-DD")]
                  : null
              }
            />
          </Space>
        </label>
        <MultiStationpoints onStationPointChange={handleStationPointChange} />

        <Button
          type="primary"
          icon={
            loading ? (
              <LoadingOutlined style={{ color: "#252B3F" }} />
            ) : (
              <FaFileCsv style={{ color: "#252B3F" }} />
            )
          }
          onClick={downloadCSV}
        />
      </div>
      <Box mt={2}> 
      <CommonTable columns={columns}>
        {renderTableBody()}
        {renderTableFooter()}
      </CommonTable>
      </Box>
     
    </div>
  );
};

export default ReportDowntime_Uptime;
