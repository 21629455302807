import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DescriptionIcon from "@mui/icons-material/Description";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import GridViewIcon from "@mui/icons-material/GridView";
import HistoryIcon from "@mui/icons-material/History";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import LoopIcon from "@mui/icons-material/Loop";
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
export const OperatorNavBarList = [
  {
    label: "Home",
    url: "/Operator-Dash",
    icon: <HomeIcon />,
  },
  // {
  //   label: "Tasks",
  //   url: "/PendingTasks",
  //   icon: <AssignmentIcon />,
  // },
  {
    label: "Live Plant Summary",
    url: "/LiveSummary",
    icon: <DescriptionIcon />,
  },
  {
    label: "Alerts",
    url: "/Alertspage",
    icon: <NewReleasesIcon />,
  },
  {
    label: "TOD Tracking",
    url: "/AllTOD",
    icon: <AutoGraphRoundedIcon />,
  },
  {
    label: "Consumption Monitoring",
    url: "/DailyConsumption",
    icon: <OfflineBoltIcon />,
    //   icon: <img className="sidebarimage" src={daily} alt="" />,
  },
  {
    label: "Sections",
    url: "/Section-Wise-View",
    icon: <GridViewIcon />,
    //   icon: <img className="sidebarimage1" src={sections} alt="" />,
  },
  //   {
  //     label: "SLD",
  //     url: "/SLD",
  //     //   icon: <img className="sidebarimage2" src={sld} alt="" />,
  //   },
  {
    label: "History Data",
    url: "/Historydata",
    icon: <HistoryIcon />,
  },
  {
    label: "Forms",
    url: "/Forms",
    icon: <TextSnippetIcon />,
    //   onClick: handleFormsClick,
  },
  {
    label: "Switch",
    url: "/Loginscreens",
    icon: <ChangeCircleIcon />,
    //   condition: level !== "Level-4",
  },
 
];

export const SupervisorNavBarList = [
  {
    label: "Home",
    url: "/Alldash",
    icon: <HomeIcon />,
  },
  {
    label: "Live Plant Summary",
    url: "/LiveSummary",
    icon: <DescriptionIcon />,
  },
  {
    label: "Critical Alerts",
    url: "/critical-alerts-tab",
    icon: <NewReleasesIcon />,
  },
  {
    label: "Monthly Calender",
    url: "/Calenderscombine",
    icon: <CalendarMonthIcon />,
  },
  {
    label: "Reporting",
    url: "/AllReports",
    icon: <ArtTrackIcon />,
  },
  {
    label: "Forms",
    url: "/Forms",
    icon: <TextSnippetIcon />,
  },
  {
    label: "Master Forms",
    url: "/Masterforms",
    icon: <SummarizeIcon />,
  },
  {
    label: "Trends",
    url: "/AllTrends",
    icon: <SignalCellularAltRoundedIcon />,
  },
  {
    label: "Load Management",
    url: "/loadgraphs",
    icon: <InsertChartOutlinedRoundedIcon />,
  },
  {
    label: "Switch",
    url: "/Loginscreens",
    icon: <ChangeCircleIcon />,
    //   condition: level !== "Level-4",
  },
  
];

export const AdminNavBarList = [
  {
    label: "Designation",
    url: "/Designation",
    icon: <ManageAccountsIcon />,
  },

  {
    label: "Super Admin",
    url: "/SuperadminRegistration",
    icon: <SupervisedUserCircleIcon />,
  },

  {
    label: "Users",
    url: "/Usercreation",
    icon: <PersonAddAltIcon />,
  },
  {
    label: "Switch",
    url: "/Loginscreens",
    icon: <ChangeCircleIcon />,
    //   condition: level !== "Level-4",
  },
 
];

export const getNavbarlist = () => {

  const currentRole = localStorage.getItem("accessedLevel");

  const role = localStorage.getItem("role");

  let navList =
    currentRole === "Level-4"
      ? OperatorNavBarList
      : currentRole === "Level-3"
      ? SupervisorNavBarList
      : currentRole === "Level-2"
      ? AdminNavBarList
      : OperatorNavBarList;

  const swtichRoute = {
    label: "Switch Role",
    icon: <LoopIcon />,
    url: "/role-selection",
  };



  // if (role == "Supervisor") navList = [...navList, swtichRoute];

  return navList;
};
