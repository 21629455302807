import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Card, DatePicker, Segmented } from "antd";
import axiosInstance from "../../../api/services/axios";
import { useQuery } from "@tanstack/react-query";
import { Box, CircularProgress, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Stationpoints from "../../Commonfns/Stationpoints";
import moment from "moment";

const LoadPattern = () => {
  const {
    register,
    setValue: setHookValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      station_point: "HV",
      range: "TD",
      dateRange: [],
    },
  });

  const { station_point, range, dateRange } = watch();

  const [startDate, endDate] = dateRange;

  const {
    data: apiData,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["todInformation", station_point, range, startDate, endDate],
    queryFn: async ({ queryKey }) => {
      const [_, station_point, range, startDate, endDate] = queryKey;
      const params = {
        stationPoint: station_point,
        fromDate: startDate?.format("YYYY-MM-DD"),
        toDate: endDate?.format("YYYY-MM-DD"),
        range,
      };
      return (await axiosInstance.get(`/api/load-pattern`, { params })).data
        ?.data;
    },

    retry: 0,
    refetchInterval: 5000, // Poll every 5 seconds
  });

  const options = {
    chart: {
      height: 600,
    },

    rangeSelector: {
      enabled: false, // Disable the range selector
    },
    series: [
      {
        name: "kVA",
        data: isSuccess && apiData?.graphData?.map((i) => [i[0], i[1]]),
        type: "area", // Set type to 'area' for filled region
        color: "#007bff", // Line color
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#ECEFFB"], // Gradient start color
            [1, "rgba(0, 123, 255, 0)"], // Gradient end color
          ],
        },
        tooltip: {
          valueDecimals: 2, // Display numbers with 2 decimal points in the tooltip
        },
      },
      {
        name: "Higher Threshold",
        data: isSuccess
          ? apiData?.graphData?.map((i) => [i[0], apiData?.highThreshold])
          : [],
        type: "line", // Set type to 'line' for a line graph
        color: "#FF0000", // Line color
        lineWidth: 2, // Set a thin line width
        dashStyle: "Dot", // Set the line to be dotted
        marker: {
          enabled: false, // Disable markers on the line graph
        },
        tooltip: {
          valueDecimals: 2, // Display numbers with 2 decimal points in the tooltip
        },
      },

      {
        name: "Lower Threshold",
        data: isSuccess
          ? apiData?.graphData?.map((i) => [i[0], apiData?.lowThreshold])
          : [],
        type: "line", // Set type to 'line' for a line graph
        color: "#FF0000", // Line color
        lineWidth: 2, // Set a thin line width
        dashStyle: "Dot", // Set the line to be dotted
        marker: {
          enabled: false, // Disable markers on the line graph
        },
        tooltip: {
          valueDecimals: 2, // Display numbers with 2 decimal points in the tooltip
        },
      },
    ],
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        // text: 'Price'
      },
    },

    tooltip: {
      useHTML: true,
      formatter: function () {
        let date = this.x;
        const value = this.y.toFixed(2);

        // Get threshold values dynamically based on the series
        const highThreshold = apiData?.highThreshold?.toFixed(2) || "N/A";
        const lowThreshold = apiData?.lowThreshold?.toFixed(2) || "N/A";

        let voltage = [];

        voltage = apiData?.graphData.find((point) => {
          return moment
            .utc(point[0])
            .isSame(moment.utc(date), "minutes");
        });

        let pf = 0;

        if (voltage) {
          pf= voltage[3]
          voltage = voltage[2];

        } else {
          voltage = "N/A";
        }

        return `
        <div style="text-align: left; width: 200px; padding: 10px; border-radius: 5px; background: white; box-shadow: 0px 2px 6px rgba(0,0,0,0.1);">
          <p id="tooltip-date"><strong>Date:</strong> ${moment
            .utc(date)
            .format("DD-MM-YYYY HH:mm:ss")}</p>
          <p id="tooltip-value"><strong>kVA:</strong> ${value}</p>
           <p id="tooltip-value"><strong>Voltage:</strong> ${voltage}</p>
             <p id="tooltip-value"><strong>PF:</strong> ${pf}</p>
          <p><strong>Higher Threshold:</strong> ${highThreshold}</p>
          <p><strong>Lower Threshold:</strong> ${lowThreshold}</p>
        </div>
      `;
      },
    },
  };

  const { RangePicker } = DatePicker;

  const onChangeStationPoint = (value) => {
    setHookValue("station_point", value);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #DFE6FA",
        //    display: "flex",
        justifyContent: "end",
        //    p: 1,
        mb: 1,
        p: 1,
      }}
    >
      <Box display={"flex"} mb={1} justifyContent={"end"} gap={2}>
        <Stationpoints
          isHorizontal
          onStationPointChange={onChangeStationPoint}
          defaultStationPoint="HV"
        />

        <Segmented
          options={["TD", "24H", "1W", "1M", "3M", "1Y", "All"]}
          onChange={(value) => {
            setHookValue("range", value);
          }}
        />

        <RangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          onChange={(value) => setHookValue("dateRange", value ? value : [])}
          type="date"
        />
      </Box>

      {isLoading && (
        <Box
          height={550}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {isSuccess && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={options}
        />
      )}
    </Paper>
  );
};

export default LoadPattern;
