

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../../Images/logo.svg';
import "../../Operator/Sidebar/Sidebar.css";
import './Supersidebar.css'
import { IoMdLogOut } from "react-icons/io";

import { BiSolidHome } from "react-icons/bi";
import { TbAlertSquareFilled } from "react-icons/tb";
import { MdDescription } from "react-icons/md";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { BiSolidReport } from "react-icons/bi";
import { SiGoogleforms } from "react-icons/si";
import { GrTasks } from "react-icons/gr";
import { GiPowerGenerator } from "react-icons/gi";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";
import { PiUserSwitchBold } from "react-icons/pi";
import { FaChartBar } from "react-icons/fa";
import { VscGraphLine } from "react-icons/vsc";
const Supervisorsidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [username, setUsername] = useState('');
    const [designation, setDesignation] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // Retrieve the user data from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setUsername(user.username);
            setDesignation(user.designation); // Assuming "level" is used for the designation
        }
    }, []);
    const onleave = () => {
        setIsOpen(false);
        setActiveDropdown(null); // Close any open dropdown when the sidebar loses focus
    };

    const onenter = () => {
        setIsOpen(true);
    };

    const toggleDropdown = (dropdownName) => {
        if (activeDropdown === dropdownName) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(dropdownName);
        }
    };

    const handleLogout = () => {
        // setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('accessedLevel');
        navigate('/');
    };

    return (
     <></>
    );
};

export default Supervisorsidebar;