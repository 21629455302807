import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Stebilizers.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
const Stebilizer = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const [form] = Form.useForm();
  const [parentNames, setParentNames] = useState([]);

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        inputvoltage: Number(values.inputvoltage),
        outputvoltage: Number(values.outputvoltage),
        ratedpowerinva: Number(values.ratedpowerinva),
        operatingefficiency: Number(values.operatingefficiency),
        correctionspeed: Number(values.correctionspeed),
        connectedloadinva: Number(values.connectedloadinva),
        undervoltageprotection: Number(values.undervoltageprotection),
        voltageregulationaccuracy: Number(values.voltageregulationaccuracy),
        overvoltageprotection: Number(values.overvoltageprotection),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/stebilizers`,
        dataToSend
      );

      toast.success("Stebilizer added successfully");
      form.resetFields();
      fetchParentNames();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Stabilizer");
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.StebilizerHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Parent Name" name="parentname">
                  <Select id="ant-input-style" placeholder="Select Parent Name">
                    {parentNames.map((name, index) => (
                      <Select.Option key={index} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Meter Make" name="metermake">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Meter Serial No." name="meterslno">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phase" name="phase">
                  <Select id="ant-input-style" placeholder="Select Phase">
                    <Select.Option value="">Select Phase</Select.Option>
                    <Select.Option value="3-Phase">3-Phase</Select.Option>
                    <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                    <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                    <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Voltage Regulation Accuracy"
                  name="voltageregulationaccuracy"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Input Voltage" name="inputvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Output Voltage" name="outputvoltage">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Rated Power (in kVA)" name="ratedpowerinva">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Operating Efficiency"
                  name="operatingefficiency"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Correction Speed" name="correctionspeed">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Automatic Voltage Regulation"
                  name="automaticvoltageregulation"
                >
                  <Select id="ant-input-style" placeholder="Select">
                    <Select.Option value="">Select</Select.Option>
                    <Select.Option value="Y">Yes</Select.Option> 
                    <Select.Option value="N">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Over Voltage Protection"
                  name="overvoltageprotection"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Under Voltage Protection"
                  name="undervoltageprotection"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Short Circuit Protection"
                  name="shortcircuitprotection"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Connected Load (in kVA)"
                  name="connectedloadinva"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Cooling System" name="coolingsystem">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Proposed Periodicity of Maintenance"
                  name="proposedperiodicityofmaintenance"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Previous Maintenance Done Date"
                  name="previousmaintenancedonedate"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Stebilizer;
