import { useState, React, useEffect } from "react";
import TopBar from "../Commonfns/Topbar/Topbar";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import DownTimeVsUpTime from "../Trends/DownTimeVsUpTime/DownTimeVsUpTime";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TodRunHoursTrend from "../Trends/TodRunHours/TodRunHours";
import CommonTabs from "../Common/CommonTabs/CommonTabs";
import CriticalAlertsPattern from "../LoadGraph/CriticalAlerts/CriticalAlerts";
import CriticalAlerts from "./CriticalAlerts";
const tabs = [
  {
    name: "Critical Alerts",
    label: "critical-alerts",
    component: <CriticalAlerts />,
  },

  {
    name: "Critical Alerts Count",
    label: "critical-alerts-count",
    component: <CriticalAlertsPattern />,
  },
];

const AllCriticalAlertsTabs = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div>
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    </div>
  );
};

export default AllCriticalAlertsTabs;
