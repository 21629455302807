import React from "react";
import "./DailyDashboard.css";
import DailyPF from "./DailyPF/DailyPF";
import DailyUtilization_Percenatage from "./Utilization/Utilization";
import DailyTODconsumption from "./TODview/TODview";
import Maxdemand from "./Maxdemand/Maxdemand";
import { Grid } from "@mui/material";
const DailyDashboard = () => {
  return (
    <div id="dailydash-total">
      <Grid container>
        <Grid sx={{ height: 300 }} item xs={12} md={4}>
          <DailyPF />
        </Grid>
        <Grid sx={{ height: 300 }} item xs={12} md={4}>
          <DailyUtilization_Percenatage />
        </Grid>
        <Grid sx={{ height: 300 }} item xs={12} md={4}>
          <Maxdemand />
        </Grid>
      </Grid>

      <Grid container>
        <Grid sx={{ height: 400, mt: 2 }} item xs={12} md={12}>
          <DailyTODconsumption />
        </Grid>
      </Grid>

      {/* <div id="dailydash-2">
        <div></div>
      </div> */}
    </div>
  );
};

export default DailyDashboard;
