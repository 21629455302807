import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Generators.css"; // Make sure the CSS file is renamed accordingly
import { Masterformsheadingsss } from "../../Operator/Data";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Mastergenerator = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [form] = Form.useForm();
  const [parentNames, setParentNames] = useState([]);

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        generatorpowerinva: Number(values.generatorpowerinva),
        voltagelevel: Number(values.voltagelevel),
        frequency: Number(values.frequency),
        enginepower: Number(values.enginepower),
        fuelconsumptionperhour: Number(values.fuelconsumptionperhour),
        fuelcosumptionper1kwh: Number(values.fuelcosumptionper1kwh),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators`,
        dataToSend
      );

      toast.success("Master Generator added successfully");
      form.resetFields();
      fetchParentNames();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Generator");
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.GeneratorHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Parent Name" name="parentname">
                  <Select id="ant-input-style" placeholder="Select Parent Name">
                    {parentNames.map((name, index) => (
                      <Select.Option key={index} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type of Generator" name="typeofgenerator">
                  <Select
                    id="ant-input-style"
                    placeholder="Select Type of Generator"
                  >
                    <Select.Option value="Solar">Solar</Select.Option>
                    <Select.Option value="Diesel Generator (DG)">
                      Diesel Generator (DG)
                    </Select.Option>
                    <Select.Option value="Waste Heat Recovery (WHR)">
                      Waste Heat Recovery (WHR)
                    </Select.Option>
                    <Select.Option value="Wind">Wind</Select.Option>
                    <Select.Option value="Hydro">Hydro</Select.Option>
                    <Select.Option value="Bio energy">Bio Energy</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Meter Make" name="metermake">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Meter Serial No." name="meterslno">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Phase" name="phase">
                  <Select id="ant-input-style" placeholder="Select Phase">
                    <Select.Option value="">Select Phase</Select.Option>
                    <Select.Option value="3-Phase">3-Phase</Select.Option>
                    <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                    <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                    <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Generator Power (in kVA)"
                  name="generatorpowerinva"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Voltage Level" name="voltagelevel">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Frequency" name="frequency">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Engine Power" name="enginepower">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Cooling System" name="coolingsystem">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Fuel Consumption per Hour"
                  name="fuelconsumptionperhour"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Fuel Consumption per 1 kWh"
                  name="fuelcosumptionper1kwh"
                >
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Start Type" name="starttype">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Proposed Periodicity of Maintenance"
                  name="proposedperiodicityofmaintenance"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Previous Maintenance Done Date"
                  name="previousmaintenancedonedate"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Mastergenerator;
