import { useState, React, useEffect } from "react";
import TopBar from "../Commonfns/Topbar/Topbar";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import DownTimeVsUpTime from "../Trends/DownTimeVsUpTime/DownTimeVsUpTime";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TodRunHoursTrend from "../Trends/TodRunHours/TodRunHours";
import CommonTabs from "../Common/CommonTabs/CommonTabs";
import LoadPattern from "./LoadPattern/LoadPattern";
import TodInformation from "./TodInformation/TodInformartion";
import CriticalAlertsPattern from "./CriticalAlerts/CriticalAlerts";

const tabs = [

   {
     name: "TOD Loads",
     label: "tod-info",
     component: <TodInformation />,
   },

  {
    name: "Load Pattern",
    label: "load-pattern",
    component: <LoadPattern />,
  },


  // {
  //   name: "Critical Alerts",
  //   label: "critical-alerts",
  //   component: <CriticalAlertsPattern />,
  // }


];

const AllLoadGraphs = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div id="trendspage-right">
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    </div>
  );
};

export default AllLoadGraphs;
