// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userfetch-total {
    background-color: #F3F6FE;
}

#userfetch-right-2 {
    margin-top: 15px;
    padding: 0px 10px 5px 10px;

    #userfetch-right-21 {
        display: flex;
        align-items: center;
        height: 75px;
        width: 100%;
        background-color: white;
        border: 1px solid #DFE6FA;
        border-radius: 10px;
        justify-content: flex-end;

        label {
            margin-left: 10px;
            align-items: center;
            margin-right: 20px;

            input {
                height: 4vh;
                border-radius: 5px;
                border: 1.5px solid #DFE6FA;
            }
        }

        button {
            background-color: #252B3F;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 8px 30px;
            cursor: pointer;
        }
    }

    table {

        button {
            background-color: #252B3F;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 5px 20px;
            cursor: pointer;
        }
    }
}


`, "",{"version":3,"sources":["webpack://./src/Components/UserManagement/Usercreation/Usercreationfetch.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;;IAE1B;QACI,aAAa;QACb,mBAAmB;QACnB,YAAY;QACZ,WAAW;QACX,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB,yBAAyB;;QAEzB;YACI,iBAAiB;YACjB,mBAAmB;YACnB,kBAAkB;;YAElB;gBACI,WAAW;gBACX,kBAAkB;gBAClB,2BAA2B;YAC/B;QACJ;;QAEA;YACI,yBAAyB;YACzB,YAAY;YACZ,YAAY;YACZ,kBAAkB;YAClB,iBAAiB;YACjB,eAAe;QACnB;IACJ;;IAEA;;QAEI;YACI,yBAAyB;YACzB,YAAY;YACZ,YAAY;YACZ,kBAAkB;YAClB,iBAAiB;YACjB,eAAe;QACnB;IACJ;AACJ","sourcesContent":["#userfetch-total {\n    background-color: #F3F6FE;\n}\n\n#userfetch-right-2 {\n    margin-top: 15px;\n    padding: 0px 10px 5px 10px;\n\n    #userfetch-right-21 {\n        display: flex;\n        align-items: center;\n        height: 75px;\n        width: 100%;\n        background-color: white;\n        border: 1px solid #DFE6FA;\n        border-radius: 10px;\n        justify-content: flex-end;\n\n        label {\n            margin-left: 10px;\n            align-items: center;\n            margin-right: 20px;\n\n            input {\n                height: 4vh;\n                border-radius: 5px;\n                border: 1.5px solid #DFE6FA;\n            }\n        }\n\n        button {\n            background-color: #252B3F;\n            color: white;\n            border: none;\n            border-radius: 4px;\n            padding: 8px 30px;\n            cursor: pointer;\n        }\n    }\n\n    table {\n\n        button {\n            background-color: #252B3F;\n            color: white;\n            border: none;\n            border-radius: 4px;\n            padding: 5px 20px;\n            cursor: pointer;\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
