// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App > .routes-container {
  flex: 1 1;
}

.custom-marquee {
  position: absolute; /* Absolute positioning */
  top: 0; /* Place it at the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Make it take the full width of the screen */
  z-index: 10; /* Make sure the marquee is above other content */
  color: red;
  /* background-color: white; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,kBAAkB,EAAE,yBAAyB;EAC7C,MAAM,EAAE,2BAA2B;EACnC,OAAO,EAAE,sBAAsB;EAC/B,WAAW,EAAE,8CAA8C;EAC3D,WAAW,EAAE,iDAAiD;EAC9D,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.App > .routes-container {\n  flex: 1;\n}\n\n.custom-marquee {\n  position: absolute; /* Absolute positioning */\n  top: 0; /* Place it at the bottom */\n  left: 0; /* Align to the left */\n  width: 100%; /* Make it take the full width of the screen */\n  z-index: 10; /* Make sure the marquee is above other content */\n  color: red;\n  /* background-color: white; */\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
