
import React, { useEffect, useState } from 'react';
import { FaChevronUp, FaChevronRight } from "react-icons/fa";
import staticdata from './Staticdata.json';
import './HomeLoad.css';
import { CircularProgress } from "@mui/material";

import axios from 'axios';

const SectionRenderer = ({ sectionName, sectionData, staticdata }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSection = () => {
        setIsExpanded(!isExpanded);
    };

    // Function to determine progress bar color for normal loads
    const getProgressBarColor = (percentage) => {
        if (percentage < 90) {
            return 'green';
        } else if (percentage >= 90 && percentage < 95) {
            return 'orange';
        } else if (percentage >= 95) {
            return 'red';
        }
    };

    // Function to determine progress bar color for generators
    const getProgressBarColorforGenerators = (percentage) => {
        if (percentage < 5) {
            return 'green';
        } else if (percentage >= 5 && percentage < 10) {
            return 'orange';
        } else if (percentage >= 10) {
            return 'red';
        }
    };

    const hasChildren = sectionData && sectionData.children && Object.keys(sectionData.children).length > 0;

    // Determine which color function to use based on the "generator" flag
    const isGenerator = sectionData.generator;
    const getColorFunction = isGenerator ? getProgressBarColorforGenerators : getProgressBarColor;

    return (
        <div>
            <div className={`section ${isExpanded ? 'expanded-section' : ''}`}>
                {hasChildren ? (
                    <button
                        id='livesummary-dropbtn'
                        className={isExpanded ? 'livesummary-dropbtn isExpanded' : 'livesummary-dropbtn'}
                        onClick={toggleSection}
                    >
                        {isExpanded ? <FaChevronUp /> : <FaChevronRight />}
                    </button>
                ) : (
                    <div style={{ width: "36px" }}></div> // Fixed-width placeholder
                )}

                <div className="section-name">{sectionName}</div>
                <div className="section-communication" style={{ color: staticdata[sectionName]?.communication === 'online' ? 'green' : 'red' }}>
                    {staticdata[sectionName]?.communication }
                </div>
                <div className="section-imaxvalue">{staticdata[sectionName]?.imax }</div>

                {/* Progress Bar */}
                <div className={`progress-bar-container ${getColorFunction(staticdata[sectionName]?.percentage)}`}>
                    <div
                        className={`progress-bar ${getColorFunction(staticdata[sectionName]?.percentage)}`}
                        style={{ width: `${staticdata[sectionName]?.percentage || 0}%`, backgroundColor: getColorFunction(staticdata[sectionName]?.percentage) }}
                    >
                        <span id='progress-inside-percentage'>{staticdata[sectionName]?.percentage || 0}%</span>
                    </div>
                </div>

                <div className="threshold">{staticdata[sectionName]?.threshold}</div>
            </div>

            {hasChildren && isExpanded && (
                <div className="child-sections">
                    {Object.entries(sectionData.children).map(([childName, childData]) => (
                        <SectionRenderer
                            key={childName}
                            sectionName={childName}
                            sectionData={childData}
                            staticdata={staticdata}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};




// Livesummary component
function Livesummary() {

    const [sectionHierarchy, setSectionHierarchy] = useState(null);

    useEffect(() => {
        // Fetch the section hierarchy from the backend API
        const fetchHierarchy = async () => {
            try {

                const response = await axios .get(`${process.env.REACT_APP_API_EMS_URL}/api/getectionshierarchy`)
                setSectionHierarchy(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching section hierarchy:', error);
            }
        };

        fetchHierarchy();
    }, []);

    if (!sectionHierarchy) {
        return <div>Loading...</div>;
    }

    
    return (
        <div id='homeload-total'>
            <div id='livesummary-right-3'>
                <div id='livesummary-right-31'>

                   <div id='livesummary-right-headflex1'>
                        <div id='livesummary-legend-flex' >
                            <div id='livesummary-right-headflex12'> <span> Loads :&lt; 90% <span id='btn1'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt;= 90% to 95%  <span id='btn2'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt; 95%  <span id='btn3'></span> </span></div>
                        </div>
                        <div id='livesummary-legend-flex' >
                            <div id='livesummary-right-headflex12'> <span> Generators :&lt; 5% <span id='btn1'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span> &gt;= 5% to 10%  <span id='btn2'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt; 10%  <span id='btn3'></span> </span></div>
                        </div>

                    </div>

                    <div id='livesummary-right-headflex'>
                        <div id='livesummary-sectionname'>Section Name</div>
                        <div id='livesummary-communication'>Communication</div>
                        <div id='livesummary-imaxvalue'>I max</div>
                        <div id='livesummary-loadbar'>Load Bar</div>
                        <div id='livesummary-threshold'>Rated Value</div>
                    </div>
                    {Object.entries(sectionHierarchy).map(([sectionName, sectionData]) => (
                        <SectionRenderer
                            key={sectionName}
                            sectionName={sectionName}
                            sectionData={sectionData}
                            staticdata={staticdata}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Livesummary;

