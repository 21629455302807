// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#maxdemand-total {
    margin-left: 1vw;
    height: auto !important;
    width: auto !important;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;
}

#maxdemand-div2 {
    padding: 60px 100px 65px 0px;
    font-size: 70px;
    font-weight: 400;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#maxdemand-inside {
    font-size: 10px;
}


/* 
@media screen and (min-width: 1024px) {
    #maxdemand-total {
        min-height: 27vh;
        width: 26.5vw;
    }
    #maxdemand-div2 {
        padding: 58px 0px 50px 0px;
        font-size: 70px;
    }
    
    #maxdemand-inside {
        margin-top: 38px;
    }
}

@media screen and (min-width: 1440px) {
    #maxdemand-total {
        min-height: 26vh;
        width: 25.8vw;
    }
    #maxdemand-div2 {
        padding: 70px 0px 50px 0px;
        font-size: 70px;
    }
    
    #maxdemand-inside {
        margin-top: 42px;
    }
   
}
@media screen and (min-width: 1920px) {
    #maxdemand-total {
        min-height: 36vh;
        width: 24vw;
    }
    #maxdemand-div2 {
        padding: 115px 0px 50px 0px;
        font-size: 70px;
    }
    
    #maxdemand-inside {
        margin-top: 107px;
    }
   
} */`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Daily-Dash/Maxdemand/Maxdemand.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;IAChB,gDAAgD;AACpD;;AAEA;IACI,eAAe;AACnB;;;AAGA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6CG","sourcesContent":["#maxdemand-total {\n    margin-left: 1vw;\n    height: auto !important;\n    width: auto !important;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n#maxdemand-div2 {\n    padding: 60px 100px 65px 0px;\n    font-size: 70px;\n    font-weight: 400;\n    font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n\n#maxdemand-inside {\n    font-size: 10px;\n}\n\n\n/* \n@media screen and (min-width: 1024px) {\n    #maxdemand-total {\n        min-height: 27vh;\n        width: 26.5vw;\n    }\n    #maxdemand-div2 {\n        padding: 58px 0px 50px 0px;\n        font-size: 70px;\n    }\n    \n    #maxdemand-inside {\n        margin-top: 38px;\n    }\n}\n\n@media screen and (min-width: 1440px) {\n    #maxdemand-total {\n        min-height: 26vh;\n        width: 25.8vw;\n    }\n    #maxdemand-div2 {\n        padding: 70px 0px 50px 0px;\n        font-size: 70px;\n    }\n    \n    #maxdemand-inside {\n        margin-top: 42px;\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    #maxdemand-total {\n        min-height: 36vh;\n        width: 24vw;\n    }\n    #maxdemand-div2 {\n        padding: 115px 0px 50px 0px;\n        font-size: 70px;\n    }\n    \n    #maxdemand-inside {\n        margin-top: 107px;\n    }\n   \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
