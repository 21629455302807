import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PFcorrectors.css'; 
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
const PFcorrectors = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [form] = Form.useForm();
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);


    const handleSubmit = async (values) => {
        try {
            const dataToSend = {
                ...values,
                ratedpowerinva: Number(values.ratedpowerinva),
                operatingvoltage: Number(values.operatingvoltage),
                numberofstages: Number(values.numberofstages),
                numberofcellsineachstage: Number(values.numberofcellsineachstage),
                eachcellcapacity: Number(values.eachcellcapacity),
                presenttestedvalueofeachcell: Number(values.presenttestedvalueofeachcell),
                pfcefficiency: Number(values.pfcefficiency),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors`, dataToSend);

            toast.success('PF Corrector added successfully');
            form.resetFields();
            fetchParentNames();
        } catch (error) {
            console.error("Error submitting data", error);
            toast.error("Error adding PF Corrector");
        }
    };

    return (
        <div id='masterforms-total'> 
            <div id='masterforms-right'> 
                <div id='masterforms-right-2'>

                <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ date: getCurrentDate() }}
                        onFinish={handleSubmit}
                    >
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <h3>{Masterformsheadingsss.PFCorrectorHeading}</h3>
                            <Button
                                type="default"
                                onClick={onViewData}
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                View Data
                            </Button>
                        </div>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Date" name="date">
                                    <Input id="ant-input-style" type="date" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Parent Name" name="parentname">
                                    <Select id="ant-input-style" placeholder="Select Parent Name">
                                        {parentNames.map((name, index) => (
                                            <Select.Option key={index} value={name}>
                                                {name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Meter Make" name="metermake">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Meter Serial No." name="meterslno">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Phase" name="phase">
                                    <Select id="ant-input-style" placeholder="Select Phase">
                                        <Select.Option value="">Select Phase</Select.Option>
                                        <Select.Option value="3-Phase">3-Phase</Select.Option>
                                        <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                                        <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                                        <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Rated Power (in kVA)" name="ratedpowerinva">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Operating Voltage" name="operatingvoltage">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Mode Select" name="autoselect">
                                    <Select id="ant-input-style" placeholder="Select Option">
                                        <Select.Option value="Auto">Auto</Select.Option>
                                        <Select.Option value="Manual">Manual</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Capacitor Type" name="capacitortype">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Number of Stages" name="numberofstages">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Number of Cells in Each Stage" name="numberofcellsineachstage">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Each Cell Capacity" name="eachcellcapacity">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Present Tested Value of Each Cell" name="presenttestedvalueofeachcell">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="PFC Efficiency" name="pfcefficiency">
                                    <Input id="ant-input-style" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Overcurrent Protection Available" name="overcurrentprotectionavailable">
                                    <Select id="ant-input-style" placeholder="Select Option">
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Overvoltage Protection Available" name="overvoltageprotectionavailable">
                                    <Select id="ant-input-style" placeholder="Select Option">
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Proposed Periodicity of Maintenance" name="proposedperiodicityofmaintenance">
                                    <Input id="ant-input-style" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Previous Maintenance Done Date" name="previousmaintenancedonedate">
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    backgroundColor: "#252B3F",
                                    borderColor: "#252B3F",
                                    color: "#fff",
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default PFcorrectors;
