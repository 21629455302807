import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './UPSfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const UPSfetch = ({ onFormButtonClick }) => {
    const [UPSs, setUPSs] = useState([]);
    const [UPSEditData, setUPSEditData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [parentNames, setParentNames] = useState([]);

    // Fetch parent names from API
    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    // Fetch UPS data from API
    const fetchUPSs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/ups`);
            setUPSs(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
        fetchUPSs();
    }, []);

    // Handle click on Edit button
    const handleEditClick = (UPS) => {
        setUPSEditData(UPS);
        setIsModalVisible(true);
    };

      // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setUPSEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setUPSEditData({ ...UPSEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setUPSEditData({
        ...UPSEditData,
        [field]: formattedDate,
      });
    }
  };

    // Handle submission of edited UPS data
    const handleEditSubmit = async () => {
        try {

            const dataToSend = {
                ...UPSEditData,
                inputvoltage: Number(UPSEditData.inputvoltage),
                outputvoltage: Number(UPSEditData.outputvoltage),
                ratedpowerinva: Number(UPSEditData.ratedpowerinva),
                operatingefficiency: Number(UPSEditData.operatingefficiency),
                numberofbatteries: Number(UPSEditData.numberofbatteries),
                eachbatterycapacity: Number(UPSEditData.eachbatterycapacity),
                connectedloadinva: Number(UPSEditData.connectedloadinva),
            };

            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/ups/${UPSEditData._id}`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success("UPS updated successfully");
            fetchUPSs(); // Refresh UPS data after update
            setUPSEditData(null); // Clear edit form
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='upsfetch-total'>
            <div id='upsfetch-right'>
                <div id='upsfetch-right-2'>
                    <div id='upsfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.UPSHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>UPS Topology</th>
                                <th>Input Voltage</th>
                                <th>Output Voltage</th>
                                <th>Rated Power (kVA)</th>
                                <th>Operating Efficiency</th>
                                <th>Backup Time</th>
                                <th>Connected Load (kVA)</th>
                                <th>Battery Type</th>
                                <th>Number of Batteries</th>
                                <th>Each Battery Capacity</th>
                                <th>Battery Recharge Time</th>
                                <th>Cooling System</th>
                                <th>Proposed Periodicity of Maintenance (UPS)</th>
                                <th>Proposed Periodicity of Maintenance (Batteries)</th>
                                <th>Previous Maintenance Date (UPS)</th>
                                <th>Previous Maintenance Date (Batteries)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {UPSs.map((UPS) => (
                                <tr key={UPS._id}>
                                    <td>{UPS.date}</td>
                                    <td>{UPS.parentname}</td>
                                    <td>{UPS.name}</td>
                                    <td>{UPS.metermake}</td>
                                    <td>{UPS.meterslno}</td>
                                    <td>{UPS.phase}</td>
                                    <td>{UPS.upstopology}</td>
                                    <td>{UPS.inputvoltage}</td>
                                    <td>{UPS.outputvoltage}</td>
                                    <td>{UPS.ratedpowerinva}</td>
                                    <td>{UPS.operatingefficiency}</td>
                                    <td>{UPS.backuptime}</td>
                                    <td>{UPS.connectedloadinva}</td>
                                    <td>{UPS.batterytype}</td>
                                    <td>{UPS.numberofbatteries}</td>
                                    <td>{UPS.eachbatterycapacity}</td>
                                    <td>{UPS.batteryrechargetime}</td>
                                    <td>{UPS.coolingsystem}</td>
                                    <td>{UPS.proposedperiodicityofmaintenanceofups}</td>
                                    <td>{UPS.proposedperiodicityofmaintenanceofbatteries}</td>
                                    <td>{UPS.previousmaintenancedonedateforups}</td>
                                    <td>{UPS.previousmaintenancedonedateofbatteries}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(UPS)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <Modal
  title="Edit UPS"
  visible={isModalVisible}
  onOk={handleEditSubmit}
  onCancel={handleCancel}
  okText="Update"
  cancelText="Cancel"
  okButtonProps={{
    style: { backgroundColor: "#252B3F", color: "#fff" },
  }}
>
  {UPSEditData && (
    <Form layout="vertical">
      <div className="input-row">
        <Form.Item label="Date">
          <Input
            name="date"
            value={UPSEditData.date}
            disabled
          />
        </Form.Item>
        <Form.Item label="Parent Name">
          <Select
            value={UPSEditData.parentname}
            onChange={(value) =>
              setUPSEditData({
                ...UPSEditData,
                parentname: value,
              })
            }
          >
            <Select.Option value="">Select Parent Name</Select.Option>
            {parentNames.map((name, index) => (
              <Select.Option key={index} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Name">
          <Input
            name="name"
            value={UPSEditData.name}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Meter Make">
          <Input
            name="metermake"
            value={UPSEditData.metermake}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Meter SL No">
          <Input
            name="meterslno"
            value={UPSEditData.meterslno}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Phase">
          <Select
            value={UPSEditData.phase}
            onChange={(value) =>
              setUPSEditData({
                ...UPSEditData,
                phase: value,
              })
            }
          >
            <Select.Option value="">Select Phase</Select.Option>
            <Select.Option value="3-Phase">3-Phase</Select.Option>
            <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
            <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
            <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="UPS Topology">
          <Input
            name="upstopology"
            value={UPSEditData.upstopology}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Input Voltage">
          <Input
            type="number"
            name="inputvoltage"
            value={UPSEditData.inputvoltage}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Output Voltage">
          <Input
            type="number"
            name="outputvoltage"
            value={UPSEditData.outputvoltage}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Rated Power (kVA)">
          <Input
            type="number"
            name="ratedpowerinva"
            value={UPSEditData.ratedpowerinva}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Operating Efficiency">
          <Input
            type="number"
            name="operatingefficiency"
            value={UPSEditData.operatingefficiency}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Backup Time">
          <Input
            name="backuptime"
            value={UPSEditData.backuptime}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Connected Load (kVA)">
          <Input
            type="number"
            name="connectedloadinva"
            value={UPSEditData.connectedloadinva}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Battery Type">
          <Input
            name="batterytype"
            value={UPSEditData.batterytype}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Number of Batteries">
          <Input
            type="number"
            name="numberofbatteries"
            value={UPSEditData.numberofbatteries}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Each Battery Capacity">
          <Input
            type="number"
            name="eachbatterycapacity"
            value={UPSEditData.eachbatterycapacity}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Battery Recharge Time">
          <Input
            name="batteryrechargetime"
            value={UPSEditData.batteryrechargetime}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Cooling System">
          <Input
            name="coolingsystem"
            value={UPSEditData.coolingsystem}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Proposed Periodicity of Maintenance (UPS)">
          <Input
            name="proposedperiodicityofmaintenanceofups"
            value={UPSEditData.proposedperiodicityofmaintenanceofups}
            onChange={handleEditChange}
          />
        </Form.Item>
        <Form.Item label="Proposed Periodicity of Maintenance (Batteries)">
          <Input
            name="proposedperiodicityofmaintenanceofbatteries"
            value={UPSEditData.proposedperiodicityofmaintenanceofbatteries}
            onChange={handleEditChange}
          />
        </Form.Item>
      </div>

      <div className="input-row">
        <Form.Item label="Previous Maintenance Date (UPS)">
          <DatePicker
            value={UPSEditData.previousmaintenancedonedateforups ? dayjs(UPSEditData.previousmaintenancedonedateforups) : null}
            onChange={(date) =>
              handleEditChange(date, "previousmaintenancedonedateforups")
            }
            format={dateFormat}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Previous Maintenance Date (Batteries)">
          <DatePicker
            value={UPSEditData.previousmaintenancedonedateofbatteries ? dayjs(UPSEditData.previousmaintenancedonedateofbatteries) : null}
            onChange={(date) =>
              handleEditChange(date, "previousmaintenancedonedateofbatteries")
            }
            format={dateFormat}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
    </Form>
  )}
</Modal>


                </div>
            </div>
        </div>
    );
};

export default UPSfetch;
