import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Stebilizersfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const Stebilizersfetch = ({ onFormButtonClick }) => {
  const [stebilizers, setStebilizers] = useState([]);
  const [stebilizerEditData, setStebilizerEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  const fetchStebilizers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/stebilizers`
      );
      setStebilizers(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
    fetchStebilizers();
  }, []);

  const handleEditClick = (stebilizer) => {
    setStebilizerEditData(stebilizer);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStebilizerEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setStebilizerEditData({ ...stebilizerEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setStebilizerEditData({
        ...stebilizerEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...stebilizerEditData,
        inputvoltage: Number(stebilizerEditData.inputvoltage),
        outputvoltage: Number(stebilizerEditData.outputvoltage),
        ratedpowerinva: Number(stebilizerEditData.ratedpowerinva),
        operatingefficiency: Number(stebilizerEditData.operatingefficiency),
        correctionspeed: Number(stebilizerEditData.correctionspeed),
        connectedloadinva: Number(stebilizerEditData.connectedloadinva),
        undervoltageprotection: Number(
          stebilizerEditData.undervoltageprotection
        ),
        voltageregulationaccuracy: Number(
          stebilizerEditData.voltageregulationaccuracy
        ),
        overvoltageprotection: Number(stebilizerEditData.overvoltageprotection),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/stebilizers/${stebilizerEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Stebilizer updated successfully");
      fetchStebilizers();
      setStebilizerEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="stebilizersfetch-total">
      <div id="stebilizersfetch-right">
        <div id="stebilizersfetch-right-2">
          <div id="stebilizersfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.StebilizerHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Parent Name</th>
                <th>Meter Make</th>
                <th>Meter SL No</th>
                <th>Phase</th>
                <th>Voltage Regulation Accuracy</th>
                <th>Input Voltage</th>
                <th>Output Voltage</th>
                <th>Rated Power (kVA)</th>
                <th>Operating Efficiency</th>
                <th>Correction Speed</th>
                <th>Automatic Voltage Regulation</th>
                <th>Over Voltage Protection</th>
                <th>Under Voltage Protection</th>
                <th>Short Circuit Protection</th>
                <th>Connected Load (kVA)</th>
                <th>Cooling System</th>
                <th>Proposed Periodicity of Maintenance</th>
                <th>Previous Maintenance Done Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {stebilizers.map((stebilizer) => (
                <tr key={stebilizer._id}>
                  <td>{stebilizer.date}</td>
                  <td>{stebilizer.name}</td>
                  <td>{stebilizer.parentname}</td>
                  <td>{stebilizer.metermake}</td>
                  <td>{stebilizer.meterslno}</td>
                  <td>{stebilizer.phase}</td>
                  <td>{stebilizer.voltageregulationaccuracy}</td>
                  <td>{stebilizer.inputvoltage}</td>
                  <td>{stebilizer.outputvoltage}</td>
                  <td>{stebilizer.ratedpowerinva}</td>
                  <td>{stebilizer.operatingefficiency}</td>
                  <td>{stebilizer.correctionspeed}</td>
                  <td>{stebilizer.automaticvoltageregulation}</td>
                  <td>{stebilizer.overvoltageprotection}</td>
                  <td>{stebilizer.undervoltageprotection}</td>
                  <td>{stebilizer.shortcircuitprotection}</td>
                  <td>{stebilizer.connectedloadinva}</td>
                  <td>{stebilizer.coolingsystem}</td>
                  <td>{stebilizer.proposedperiodicityofmaintenance}</td>
                  <td>{stebilizer.previousmaintenancedonedate}</td>
                  <td>
                    <button onClick={() => handleEditClick(stebilizer)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal
            title="Edit Stebilizer"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" },
            }}
          >
            {stebilizerEditData && (
              <Form layout="vertical">
                {/* Date and Parent Name */}
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input
                      name="date"
                      value={stebilizerEditData.date}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Parent Name">
                    <Select
                      value={stebilizerEditData.parentname}
                      onChange={(value) =>
                        setStebilizerEditData({
                          ...stebilizerEditData,
                          parentname: value,
                        })
                      }
                    >
                      <Select.Option value="">Select Parent Name</Select.Option>
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* Name and Meter Make */}
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="name"
                      value={stebilizerEditData.name}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Meter Make">
                    <Input
                      name="metermake"
                      value={stebilizerEditData.metermake}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Meter Serial No. and Phase */}
                <div className="input-row">
                  <Form.Item label="Meter Serial No.">
                    <Input
                      name="meterslno"
                      value={stebilizerEditData.meterslno}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Phase">
                    <Select
                      value={stebilizerEditData.phase}
                      onChange={(value) =>
                        setStebilizerEditData({
                          ...stebilizerEditData,
                          phase: value,
                        })
                      }
                    >
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1-Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1-Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1-Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* Voltage Regulation Accuracy and Input Voltage */}
                <div className="input-row">
                  <Form.Item label="Voltage Regulation Accuracy">
                    <Input
                      name="voltageregulationaccuracy"
                      value={stebilizerEditData.voltageregulationaccuracy}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Input Voltage">
                    <Input
                      name="inputvoltage"
                      type="number"
                      value={stebilizerEditData.inputvoltage}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Output Voltage and Rated Power (VA) */}
                <div className="input-row">
                  <Form.Item label="Output Voltage">
                    <Input
                      name="outputvoltage"
                      type="number"
                      value={stebilizerEditData.outputvoltage}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Rated Power (kVA)">
                    <Input
                      name="ratedpowerinva"
                      type="number"
                      value={stebilizerEditData.ratedpowerinva}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Operating Efficiency and Correction Speed */}
                <div className="input-row">
                  <Form.Item label="Operating Efficiency">
                    <Input
                      name="operatingefficiency"
                      type="number"
                      value={stebilizerEditData.operatingefficiency}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Correction Speed">
                    <Input
                      name="correctionspeed"
                      type="number"
                      value={stebilizerEditData.correctionspeed}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Automatic Voltage Regulation and Over Voltage Protection */}
                <div className="input-row">
                  <Form.Item label="Automatic Voltage Regulation">
                    <Input
                      name="automaticvoltageregulation"
                      value={stebilizerEditData.automaticvoltageregulation}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Over Voltage Protection">
                    <Input
                      name="overvoltageprotection"
                      value={stebilizerEditData.overvoltageprotection}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Under Voltage Protection and Short Circuit Protection */}
                <div className="input-row">
                  <Form.Item label="Under Voltage Protection">
                    <Input
                      name="undervoltageprotection"
                      value={stebilizerEditData.undervoltageprotection}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Short Circuit Protection">
                    <Input
                      name="shortcircuitprotection"
                      value={stebilizerEditData.shortcircuitprotection}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Connected Load and Cooling System */}
                <div className="input-row">
                  <Form.Item label="Connected Load (kVA)">
                    <Input
                      name="connectedloadinva"
                      type="number"
                      value={stebilizerEditData.connectedloadinva}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Cooling System">
                    <Input
                      name="coolingsystem"
                      value={stebilizerEditData.coolingsystem}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Proposed Periodicity of Maintenance and Previous Maintenance Done Date */}
                <div className="input-row">
                  <Form.Item label="Proposed Periodicity of Maintenance">
                    <Input
                      name="proposedperiodicityofmaintenance"
                      type="number"
                      value={
                        stebilizerEditData.proposedperiodicityofmaintenance
                      }
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Previous Maintenance Done Date">
                    <DatePicker
                      disabledDate={disableDates}
                      value={
                        stebilizerEditData.previousmaintenancedonedate
                          ? dayjs(
                              stebilizerEditData.previousmaintenancedonedate
                            )
                          : null
                      }
                      onChange={(date) =>
                        handleEditChange(date, "previousmaintenancedonedate")
                      }
                      format={dateFormat}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Stebilizersfetch;
