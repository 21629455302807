import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Details/UserContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from "../../../Images/logo1.svg";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import { Button, Card, Checkbox, Form, Input } from "antd";
import useScreenSize from "../../Hooks/ScreenSize";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
const LoginPage = () => {
  const { isXs } = useScreenSize();
  const [allUsers, setAllUsers] = useState([]);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const responseSuperadmins = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins`
      );
      const responseUsers = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/users`
      );

      // Combine data from both APIs into one array
      const superadmins = responseSuperadmins.data.map((admin) => ({
        ...admin,
        type: "superadmin",
      }));
      const users = responseUsers.data.map((user) => ({
        ...user,
        type: "user",
      }));
      const combinedUsers = [...superadmins, ...users];

      setAllUsers(combinedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };


  const handleLogin = (values) => {
    const { username, password } = values;
    const user = allUsers.find(
      (u) => u.username === username && u.password === password
    );

    if (!user) {
      toast.error("Username and password do not match");
      return;
    }

    if (!user.status) {
      toast.error("Contact admin, you are inactive");
      return;
    }

    // Save user details in context and localStorage
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));

    if (user.level === "Level-4") {
      localStorage.setItem("accessedLevel", "Level-4");
    }

    // Navigate based on level
    switch (user.level) {
      case "Level-1":
      case "Level-2":
      case "Level-3":
        navigate("/Loginscreens");
        break;
      case "Level-4":
        navigate("/Operator-Dash");
        break;
      default:
        navigate("/");
        break;
    }
  };


  return (
    <div
      style={{
        height: "100vh",
        alignContent: "center",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        bordered={false}
        cover={
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <img
                alt="Patternlab-logo"
                width={"234px"}
                height={"150px"}
                src={logo}
              />
            </div>
          </div>
        }
        style={{
          width: isXs ? "100%" : "35%",
          padding: "15px 15px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "start",
        }}
      >
        <Form
          name="login"
          onFinish={handleLogin}
          autoComplete="off"
          layout="vertical"
          style={{
            padding: "0px",
            marginBottom: "0px",
          }}
        >
          <Form.Item
            label={
              <Typography sx={{ fontWeight: "bold" }}>Username</Typography>
            }
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter your username" />
          </Form.Item>

          <Form.Item
            label={
              <Typography sx={{ fontWeight: "bold" }}>Password</Typography>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password size="large" placeholder="Enter your password" />
          </Form.Item>

          <Form.Item
            style={{
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#6683D0",
                color: "#fff",
                borderRadius: "8px",
                height: "50px",
                width: "198px",
                marginTop: "30px",
              }}
              type="primary"
              htmlType="submit"
            >
              <Typography>Login</Typography>
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;
