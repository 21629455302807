import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Box,
  Pagination,
  styled,
  tableCellClasses,
  Typography,
} from "@mui/material";
import "./CommonTable.css";

const CommonTable = ({
  tableHeader,
  columns,
  pageCount,
  children,
  selectedTabValue,
  onTabChange = () => {},
  onPageChange = () => {},
  isWithPagination = false,
  colBgColor = "#F3F6F9",
  colTextColor = "#000000",
  component = Paper,
  height,
}) => {
  return (
    <Box>
      <TableContainer
        component={component}
        sx={{
          width: "100%",
          maxWidth: "100%",
          padding: 0,
          marginBottom: 2,
          height: "inherit",
          maxHeight: height || "calc(77vh)",
          overflowX: "auto",
          overflowY: "auto",
          // Styles for small devices (xs)
          "@media (max-width: 1200px)": {
            maxWidth: "calc(100vw - 130px)", // Ensure the width is 100% of the viewport
            overflowX: "auto", // Enable horizontal scrolling
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    backgroundColor: colBgColor,
                    color: colTextColor,
                  }}
                  align={column?.align || "center"}
                  width={column?.minWidth}
                  key={column.id}
                  colSpan={1}
                >
                  <Typography
                    variant="tabTitle"
                    sx={{ fontWeight: 600, color: colTextColor }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>

        <Divider />

        {isWithPagination && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
            }}
          >
            <Pagination
              onChange={onPageChange}
              count={pageCount}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        )}
      </TableContainer>
      {/* <Divider /> */}
    </Box>
  );
};

export default CommonTable;
