import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./RcpeReport.css";
import { getRcpeReports } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import {
  getData,
  getProdVsRunHoursOptions,
  getRunHoursOptions,
  timeStringToDecimalHours,
} from "../../../utils/trends";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Affix,
  Anchor,
  DatePicker,
  Popover,
  Tabs,
  Button as AntDButton,
  Flex,
  Form,
} from "antd";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useInView } from "react-intersection-observer";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FaFilePdf } from "react-icons/fa6";

const { TabPane } = Tabs;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const tabs = [
  "Energy Intensity",
  "Run Hours",
  "Consumption",
  "Production",
  "Production Vs Run Hours",
];

const RcpeTrend = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const [tabValue, setTabValue] = useState("Energy Intensity");

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      month: dayjs(),
    },
  });

  const [energyIntensity, setEnergyIntensity] = useState({
    dateLabels: [],
    baseValue: 0,
    values: [],
  });
  const [runHours, setRunHours] = useState({
    dateLabels: [],
    values: [],
  });
  const [consumption, setConsumption] = useState({
    dateLabels: [],
    values: [],
  });
  const [production, setProduction] = useState({
    dateLabels: [],
    values: [],
  });

  const [productionVsRunHours, setProductionVsRunHours] = useState({
    dateLabels: [],
    prodcutionValues: [],
    runHoursValues: [],
  });

  const { month } = watch();

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const { ref: chartRef1 } = useInView({
    // triggerOnce: true, // Trigger only once when in view
    onChange: (inView) => {
      if (inView) {
        setTabValue(tabs[0]); // Update the tab value when the first chart is in view
      }
    },
  });

  const { ref: chartRef2 } = useInView({
    // triggerOnce: true, // Trigger only once when in view
    onChange: (inView) => {
      if (inView) {
        setTabValue(tabs[1]); // Update the tab value when the second chart is in view
      }
    },
  });

  const { ref: chartRef3 } = useInView({
    // triggerOnce: true, // Trigger only once when in view
    onChange: (inView) => {
      if (inView) {
        setTabValue(tabs[2]); // Update the tab value when the second chart is in view
      }
    },
  });

  const { ref: chartRef4 } = useInView({
    // triggerOnce: true, // Trigger only once when in view
    onChange: (inView) => {
      if (inView) {
        setTabValue(tabs[3]); // Update the tab value when the second chart is in view
      }
    },
  });

  const { ref: chartRef5 } = useInView({
    // triggerOnce: true, // Trigger only once when in view
    onChange: (inView) => {
      if (inView) {
        setTabValue(tabs[4]); // Update the tab value when the second chart is in view
      }
    },
  });

  useEffect(() => {
    ChartJS.register(ChartDataLabels);
    getRcpeReports(month.format("YYYY-MM"))
      .then((res) => {
        setEnergyIntensity({
          dateLabels: res.energy_intensity.date_labels,
          baseValue: res.energy_intensity.base_value,
          values: res.energy_intensity.values,
        });
        setRunHours({
          dateLabels: res.run_hours.date_labels,
          values: res.run_hours.values,
        });

        setConsumption({
          dateLabels: res.consumption.date_labels,
          values: res.consumption.values,
        });

        setProduction({
          dateLabels: res.production.date_labels,
          values: res.production.values,
        });

        setProductionVsRunHours({
          dateLabels: res.productionvsrunhours.date_labels,
          prodcutionValues: res.productionvsrunhours.prodcution_values,
          runHoursValues: res.productionvsrunhours.run_hours_values,
        });
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [month]);

  const getEnergyIntensityData = () => {
    return {
      labels: energyIntensity.dateLabels.map((item) =>
        moment.utc(item).format("DD")
      ),
      datasets: [
        {
          type: "line",
          label: "Base Line",
          data: energyIntensity.dateLabels.map(
            (_date) => energyIntensity.baseValue
          ),
          backgroundColor: "#DB4545",
          borderColor: "#DB4545",
          borderWidth: 2,
          borderDash: [5, 5],
          pointRadius: 0,
          tension: 0,
          datalabels: {
            display: false, // Hides labels on the chart
          },
        },
        {
          label: "Energy Intensity",
          data: energyIntensity.values,
          backgroundColor: energyIntensity.dateLabels.map((_date) => "#6174D7"),
          borderWidth: 1,
          maxBarThickness: 100,
        },
      ],
    };
  };

  const getOptions = (yLabel = "") => {
    return {
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false, // Hides grid lines on the x-axis
          },
          title: {
            display: true,
            text: "Date",
            font: {
              size: 15, // Font size for x-axis title
              weight: "bold", // Makes the x-axis title bold
            },
          },
        },
        y: {
          grid: {
            display: false, // Hides grid lines on the x-axis
          },
          beginAtZero: true,
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 15, // Font size for x-axis title
              weight: "bold", // Makes the x-axis title bold
            },
          },
        },
      },
      animation: {
        // duration: 50,
        // easing: undefined,
      },
      plugins: {
        tooltip: {
          mode: "index", // Highlight both bars on the same date
          intersect: false,
          backgroundColor: "white", // Set the tooltip background color to white
          titleColor: "black", // Set the title text color to black for contrast
          bodyColor: "black",
          borderColor: "black",
          borderWidth: 1,
          yAlign: "bottom", // Ensures tooltip is positioned above the bar
          caretSize: 5, // Adjust size of the tooltip caret
          caretPadding: 0,
          titleFont: {
            size: 15, // Increase title font size
          },
          bodyFont: {
            size: 14, // Increase body font size
          },
          padding: 12, // Increase padding inside the tooltip
          boxPadding: 10, // Padding around colored boxes
          boxWidth: 30, // Width of color indicator boxes
          boxHeight: 30, // Height of color indicator boxes
          callbacks: {
            title: function (tooltipItems) {
              const label = tooltipItems[0].label; // Get the label for the current bar
              return `Date:  ${label}-${month.format("MM-YYYY")}`;
            },
          },
        },
        datalabels: {
          color: "white",
          display: (context) => context.dataset.data[context.dataIndex] != null,
          font: {
            // weight: "bold",
            size: 12,
          },
          anchor: "center", // Positions the label in the center of the bar
          align: "center", // Aligns the label with the bar's center
          rotation: -90, // Rotates the label to be vertical
        },
      },
    };
  };

  const getProdVsRunHoursData = () => {
    return {
      labels: productionVsRunHours.dateLabels.map((item) =>
        moment.utc(item).format("DD")
      ),
      datasets: [
        {
          label: "Production",
          data: productionVsRunHours.prodcutionValues,
          backgroundColor: productionVsRunHours.dateLabels.map(
            (_date) => "#D7E0EA"
          ),
          borderWidth: 1,
          maxBarThickness: 100,
        },
        {
          label: "Run Hours",
          data: productionVsRunHours.runHoursValues.map((data) =>
            timeStringToDecimalHours(data)
          ),
          backgroundColor: productionVsRunHours.dateLabels.map(
            (_date) => "#BBAEAE"
          ),
          borderWidth: 1,
          maxBarThickness: 100,
        },
      ],
    };
  };

  const handleDownloadPdf = async () => {
    try {
      // Array of chart elements
      const chartElements = document.querySelectorAll(".chart-container"); // Ensure each chart has a `chart-container` class.

      if (!chartElements.length) return;

      const pdf = new jsPDF("portrait", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      for (let i = 0; i < chartElements.length; i += 2) {
        // Process two charts at a time
        for (let j = 0; j < 2; j++) {
          const chartIndex = i + j;
          if (chartIndex >= chartElements.length) break;

          const chartElement = chartElements[chartIndex];
          const canvas = await html2canvas(chartElement, {
            useCORS: true,
          });

          const imgData = canvas.toDataURL("image/png");

          const imgWidth = pageWidth - 20; // Leave margin
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const yOffset = j === 0 ? 10 : pageHeight / 2; // Position for top or bottom half

          pdf.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
        }

        // Add a new page if there are more charts
        if (i + 2 < chartElements.length) {
          pdf.addPage();
        }
      }

      pdf.save("rcpe_trend.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          maxWidth: "86%",
          border: "1px solid #DFE6FA",
        }}
        className="rcpe-filter-container"
      >
        <Box>
          <Typography className="description">
            {t(
              "rcpeTrendComponent.RunHoursConsumptionProductionEnergyIntensity"
            )}
          </Typography>
        </Box>
        <Flex align="center" gap={20}>
         

          <Box>
            <div>
              <Form layout="vertical">
                <Form.Item label={t("common.selectMonthColumn")}>
                  <DatePicker
                    {...register("month")}
                    style={{ width: "200px" }}
                    defaultValue={getValues("month")}
                    size={"medium"}
                    picker={"month"}
                    disabledDate={disableFutureMonths} // Restricts future months
                    onChange={onChange}
                  />
                </Form.Item>
              </Form>
            </div>
          </Box>

          <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none" }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>

      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Box flexGrow={1} width={"85%"}>
          {/* energy_intensity */}

          <Paper
            key={"Energy Intensity"}
            id={"Energy Intensity"}
            ref={chartRef1}
            className="chart-container"
            elevation={0}
            sx={{
              border: "1px solid #DFE6FA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {t("rcpeTrendComponent.energyIntensity")}
              </Typography>

              <Flex gap={10}>
                <Box>
                  <Flex>
                    <Typography>
                      Base Line:{" "}
                      <span style={{ color: "#DB4545" }}>
                        {energyIntensity?.baseValue || 0}
                      </span>
                    </Typography>
                  </Flex>
                </Box>

                <Popover
                  placement="leftBottom"
                  content={
                    <Box maxWidth={600}>
                      <Box className="static-content-container">
                        <Box className="imp-container">
                          <img src={chatIcon} width={"40px"} height={"40px"} />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.whyIsThisImportanQuestion")}
                            </Typography>
                            <Typography variant="body1">
                              {t("rcpeTrendComponent.whyIsThisImportantAnswer")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="static-content-container">
                        <Box
                          className="imp-container"
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "start",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <img
                              style={{ alignSelf: "start" }}
                              src={manIcon}
                              width={"60px"}
                              height={"40px"}
                            />

                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ color: "#447F44", fontWeight: "bold" }}
                              >
                                {t("common.WhatShouldYouDoQuestion")}
                              </Typography>
                              <Typography variant="body1">
                                <ul
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    listStylePosition: "inside",
                                  }}
                                >
                                  {t(
                                    "rcpeTrendComponent.whatShouldYouDoAnswer",
                                    {
                                      returnObjects: true,
                                    }
                                  ).map((point, index) => (
                                    <li key={index}>{point}</li>
                                  ))}
                                </ul>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="static-content-container">
                        <Box className="imp-container">
                          <img src={lightIcon} width={"40px"} height={"40px"} />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.howWillYouImproveQuestion")}
                            </Typography>
                            <Typography variant="body1">
                              {t("rcpeTrendComponent.howWillYouImproveAnswer")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  }
                  // title="Title"
                >
                  <Box
                    type="button"
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.1,
                    }}
                    // onClick={() => setOpen()}
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize="medium"
                      color="disabled"
                    />
                    <Typography
                      fontSize={15}
                      style={{ display: "inline", color: "gray" }}
                    >
                      How to use?
                    </Typography>
                  </Box>
                </Popover>
              </Flex>
            </Box>

            <Bar
              className="alerts-chart"
              data={getEnergyIntensityData()}
              options={getOptions("Intensity")}
            />
          </Paper>

          {/* runHours */}

          <Paper
            key={"Run Hours"}
            id={"Run Hours"}
            elevation={0}
            ref={chartRef2}
            className="chart-container"
            sx={{
              border: "1px solid #DFE6FA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {t("rcpeTrendComponent.runHours")}
              </Typography>

              <Popover
                placement="leftBottom"
                content={
                  <Box maxWidth={600}>
                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={chatIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.whyIsThisImportanQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.whyIsThisImportantAnswerRunHours"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box
                        className="imp-container"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <img
                            style={{ alignSelf: "start" }}
                            src={manIcon}
                            width={"40px"}
                            height={"40px"}
                          />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.WhatShouldYouDoQuestion")}
                            </Typography>
                            <Typography variant="body1">
                              {t(
                                "rcpeTrendComponent.whatShouldYouDoAnswerRunHours"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={lightIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.howWillYouImproveQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.howWillYouImproveAnswerRunHours"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
                // title="Title"
              >
                <Box
                  type="button"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.1,
                  }}
                  // onClick={() => setOpen()}
                >
                  <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
                  <Typography
                    fontSize={15}
                    style={{ display: "inline", color: "gray" }}
                  >
                    How to use?
                  </Typography>
                </Box>
              </Popover>
            </Box>
            <Bar
              className="alerts-chart"
              data={getData(
                "Run Hours",
                runHours.dateLabels.map((item) =>
                  moment.utc(item).format("DD")
                ),
                runHours.values.map((val) => timeStringToDecimalHours(val))
              )}
              options={getRunHoursOptions("Hours", month)}
            />
          </Paper>

          {/* consumption */}

          <Paper
            key={"Consumption"}
            id={"Consumption"}
            ref={chartRef3}
            elevation={0}
            className="chart-container"
            sx={{
              border: "1px solid #DFE6FA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {t("rcpeTrendComponent.consumption")}
              </Typography>

              <Popover
                placement="leftBottom"
                content={
                  <Box maxWidth={600}>
                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img
                          style={{ alignSelf: "start" }}
                          src={chatIcon}
                          width={"40px"}
                          height={"40px"}
                        />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.whyIsThisImportanQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.whyIsThisImportantAnswerConsumption"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box
                        className="imp-container"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <img
                            style={{ alignSelf: "start" }}
                            src={manIcon}
                            width={"70px"}
                            height={"40px"}
                          />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.WhatShouldYouDoQuestion")}
                            </Typography>
                            <Typography variant="body1">
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  listStylePosition: "inside",
                                }}
                              >
                                {t(
                                  "rcpeTrendComponent.whatShouldYouDoAnswerConsumption",
                                  {
                                    returnObjects: true,
                                  }
                                ).map((point, index) => (
                                  <li key={index}>{point}</li>
                                ))}
                              </ul>
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Link to={"/AllReports?section=daily-consumption"}>
                            <Button
                              sx={{
                                textTransform: "none",
                                backgroundColor: "#447F44",
                                color: "white",
                                width: "150px",
                                justifySelf: "end",
                                alignSelf: "end",
                                borderRadius: "4px",
                              }}
                            >
                              {t("rcpeTrendComponent.dailyConsumption")}
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={lightIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.howWillYouImproveQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.howWillYouImproveAnswerConsumption"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
                // title="Title"
              >
                <Box
                  type="button"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.1,
                  }}
                  // onClick={() => setOpen()}
                >
                  <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
                  <Typography
                    fontSize={15}
                    style={{ display: "inline", color: "gray" }}
                  >
                    How to use?
                  </Typography>
                </Box>
              </Popover>
            </Box>

            <Bar
              className="alerts-chart"
              data={getData(
                "Consumption",
                consumption.dateLabels.map((item) =>
                  moment.utc(item).format("DD")
                ),
                consumption.values
              )}
              options={getOptions("Kwh")}
            />
          </Paper>

          {/* production */}
          <Paper
            key={"Production"}
            id={"Production"}
            ref={chartRef4}
            elevation={0}
            className="chart-container"
            sx={{
              border: "1px solid #DFE6FA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {t("rcpeTrendComponent.production")}
              </Typography>

              <Popover
                placement="leftBottom"
                content={
                  <Box maxWidth={600}>
                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={chatIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.whyIsThisImportanQuestion")}
                          </Typography>

                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.whyIsThisImportantAnswerProduction"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box
                        className="imp-container"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <img
                            style={{ alignSelf: "start" }}
                            src={manIcon}
                            width={"40px"}
                            height={"40px"}
                          />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.WhatShouldYouDoQuestion")}
                            </Typography>
                            <Typography variant="body1">
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  listStylePosition: "inside",
                                }}
                              >
                                {t(
                                  "rcpeTrendComponent.whatShouldYouDoAnswerProduction",
                                  {
                                    returnObjects: true,
                                  }
                                ).map((point, index) => (
                                  <li key={index}>{point}</li>
                                ))}
                              </ul>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={lightIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.howWillYouImproveQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.howWillYouImproveAnswerProduction"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
                // title="Title"
              >
                <Box
                  type="button"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.1,
                  }}
                  // onClick={() => setOpen()}
                >
                  <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
                  <Typography
                    fontSize={15}
                    style={{ display: "inline", color: "gray" }}
                  >
                    How to use?
                  </Typography>
                </Box>
              </Popover>
            </Box>

            <Bar
              className="alerts-chart"
              data={getData(
                "Production",
                production.dateLabels.map((item) =>
                  moment.utc(item).format("DD")
                ),
                production.values
              )}
              options={getOptions("Production")}
            />
          </Paper>

          {/* production vs run hours */}

          <Paper
            key={"Production Vs Run Hours"}
            id={"Production Vs Run Hours"}
            ref={chartRef5}
            className="chart-container"
            elevation={0}
            sx={{
              border: "1px solid #DFE6FA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {t("rcpeTrendComponent.productionVsRunHours")}
              </Typography>

              <Popover
                placement="leftBottom"
                content={
                  <Box maxWidth={600}>
                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={chatIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.whyIsThisImportanQuestion")}
                          </Typography>
                          <Typography variant="body1">
                            {t(
                              "rcpeTrendComponent.whyIsThisImportantAnswerProductionVsRunHours"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box
                        className="imp-container"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <img
                            style={{ alignSelf: "start" }}
                            src={manIcon}
                            width={"55px"}
                            height={"40px"}
                          />

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#447F44", fontWeight: "bold" }}
                            >
                              {t("common.WhatShouldYouDoQuestion")}
                            </Typography>

                            <Typography variant="body1">
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  listStylePosition: "inside",
                                }}
                              >
                                {t(
                                  "rcpeTrendComponent.whatShouldYouDoAnswerProductionVsRunHours",
                                  {
                                    returnObjects: true,
                                  }
                                ).map((point, index) => (
                                  <li key={index}>{point}</li>
                                ))}
                              </ul>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="static-content-container">
                      <Box className="imp-container">
                        <img src={lightIcon} width={"40px"} height={"40px"} />

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#447F44", fontWeight: "bold" }}
                          >
                            {t("common.howWillYouImproveQuestion")}
                          </Typography>

                          <Typography variant="body1">
                            <ul
                              style={{
                                padding: 0,
                                margin: 0,
                                listStylePosition: "inside",
                              }}
                            >
                              {t(
                                "rcpeTrendComponent.howWillYouImproveAnswerProductionVsRunHours",
                                {
                                  returnObjects: true,
                                }
                              ).map((point, index) => (
                                <li key={index}>{point}</li>
                              ))}
                            </ul>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
                // title="Title"
              >
                <Box
                  type="button"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.1,
                  }}
                  // onClick={() => setOpen()}
                >
                  <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
                  <Typography
                    fontSize={15}
                    style={{ display: "inline", color: "gray" }}
                  >
                    How to use?
                  </Typography>
                </Box>
              </Popover>
            </Box>

            <Bar
              className="alerts-chart"
              data={getProdVsRunHoursData()}
              options={getProdVsRunHoursOptions("Time", month)}
            />
          </Paper>
        </Box>

        <Box>
          <Affix style={{ backgroundColor: "transparent" }} offsetTop={100}>
            <Anchor
              replace
              items={[
                "Energy Intensity",
                "Run Hours",
                "Consumption",
                "Production",
                "Production Vs Run Hours",
              ].map((item) => ({
                key: item,
                href: `#${item}`,
                title: item,
              }))}
            />
          </Affix>
        </Box>
      </Box>
    </div>
  );
};

export default RcpeTrend;
