import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DatePicker, Flex, Form } from "antd";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/services/axios";
import Stationpoints from "../../Commonfns/Stationpoints";
import "./TodInformation.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const TodInformation = () => {
  const { t } = useTranslation();

  const { setValue: setHookValue, watch } = useForm({
    defaultValues: {
      station_point: "HV",
      date: dayjs(),
    },
  });

  const { station_point, date } = watch();

  const {
    data: apiData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["todInformation", station_point, date.format("YYYY-MM-DD")],
    queryFn: async () =>
      (
        await axiosInstance.get(
          `/api/kvagraphdata?stationPoint=${station_point}&date=${date.format(
            "YYYY-MM-DD"
          )}`
        )
      ).data,
    refetchInterval: 5000,
  });

  const options = {
    chart: {
      height: 600,
    },
    series: Object.keys(apiData?.data?.graph_data || {}).map((key, index) => ({
      name: `TOD ${index + 1}`,
      data:
        apiData?.data?.graph_data[key]?.labels?.map((label, idx) => [
          label,
          apiData?.data?.graph_data[key]?.values[idx],
        ]) || [],
      type: "area",
      color: apiData?.data?.graph_data[key]?.borderColor,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, "#ECEFFB"],
          [1, apiData?.data?.graph_data[key]?.fillColor],
        ],
      },
    })),
    xAxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
    },
    navigator: {
      enabled: true, // Enable the navigator (scroller)
      series: Object.keys(apiData?.data?.graph_data || {}).map(
        (key, index) => ({
          name: `TOD ${index + 1}`,
          data:
            apiData?.data?.graph_data[key]?.labels?.map((label, idx) => [
              label,
              apiData?.data?.graph_data[key]?.values[idx],
            ]) || [],
          type: "line", // Use line type for the navigator for better visibility
          color: ["#2DC23D", "#D82D2D", "#4864D7", "#D82D2D", "#2DC23D"][
            index % 5
          ],
        })
      ),
    },
    rangeSelector: {
      enabled: false, // Disable the range selector
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },

    tooltip: {
      useHTML: true,
      formatter: function () {
        const name = this.series.name;

        const value = this.y.toFixed(2);

        const date = this.x;

        return `
        <div 
              style="text-align: left; width: 200px; padding: 10px; border-radius: 5px; background: white; box-shadow: 0px 2px 6px rgba(0,0,0,0.1);"
        >
          
              <p 
                  id="tooltip-date" 
                  style="color: black; font-weight: 500;"
              >
                  <strong>
                    TOD:
                  </strong> 
                  ${name}
              </p>
              <p 
                  id="tooltip-date" 
                  style="color: black; font-weight: 500;"
              >
                  <strong 
                    style="color: black"
                  >
                  Date:
                  </strong> 
                  ${moment.utc(date).format("DD-MM-YYYY HH:mm:ss")}
              </p>
              <p 
                  id="tooltip-value" 
                  style="color: black; font-weight: 500;"
              >
                  <strong>
                    kVA:
                  </strong> 
                  ${value}
              </p>
      
        </div>
      `;
      },
    },
  };

  const onChangeStationPoint = (value) => {
    setHookValue("station_point", value);
  };

  return (
    <>
      <Box>
        <Paper elevation={0} className="paper-container">
          <Box className="total-data-container">
            <Box>
              <Typography variant="h6" className="tod-heading">
                TOD Information
              </Typography>
            </Box>

            <Box className="filter-container">
              <Form layout="inline">
                <Stationpoints
                  isHorizontal
                  onStationPointChange={onChangeStationPoint}
                  defaultStationPoint="HV"
                  allowClear={false}
                />

                <Form.Item>
                  <DatePicker
                    style={{ width: "150px", marginLeft: "10px" }}
                    defaultValue={dayjs()}
                    disabledDate={(current) => current && current > dayjs()}
                    onChange={(value) => setHookValue("date", value)}
                    allowClear={false}
                  />
                </Form.Item>
              </Form>
            </Box>
          </Box>
          <Box height={70} className="data-container">
            <Box
              flexGrow={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={"1px dashed #e9ebec"}
            >
              <Box textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#495057",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  {apiData?.data?.TotalData?.total_consumption
                    ? Number(apiData.data.TotalData.total_consumption).toFixed(
                        2
                      )
                    : "--"}
                </Typography>
                <Typography
                  sx={{
                    color: "#878A99",
                  }}
                >
                  {t("loadMangement.totalConsumption")}
                </Typography>
              </Box>
            </Box>

            <Box
              flexGrow={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={"1px dashed #e9ebec"}
            >
              <Box textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#495057",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  {apiData?.data?.TotalData?.maximum_demand
                    ? Number(apiData.data.TotalData.maximum_demand).toFixed(2)
                    : "--"}
                </Typography>
                <Typography
                  sx={{
                    color: "#878A99",
                  }}
                >
                  {t("loadMangement.CMD")}
                </Typography>
              </Box>
            </Box>

            <Box
              flexGrow={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={"1px dashed #e9ebec"}
            >
              <Box textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#4E7F53",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  {apiData?.data?.TotalData?.total_incentives
                    ? Number(apiData.data.TotalData.total_incentives).toFixed(2)
                    : "--"}
                  %
                </Typography>
                <Typography
                  sx={{
                    color: "#878A99",
                  }}
                >
                  {t("loadMangement.totalIncentive")}
                </Typography>
              </Box>
            </Box>

            <Box
              flexGrow={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={"1px dashed #e9ebec"}
            >
              <Box textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#DF4343",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  {apiData?.data?.TotalData?.total_penalty
                    ? Number(apiData.data.TotalData.total_penalty).toFixed(2)
                    : "--"}
                  %
                </Typography>
                <Typography
                  sx={{
                    color: "#878A99",
                  }}
                >
                  {t("loadMangement.totalPenalty")}
                </Typography>
              </Box>
            </Box>

            <Box
              flexGrow={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              // borderRight={"1px dashed #e9ebec"}
            >
              <Box textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#6C81C9",
                    fontSize: 20,
                    fontWeight: 700,
                  }}
                >
                  {apiData?.data?.TotalData?.total_common
                    ? Number(apiData.data.TotalData.total_common).toFixed(2)
                    : "--"}
                  %
                </Typography>
                <Typography
                  sx={{
                    color: "#878A99",
                  }}
                >
                  {t("loadMangement.totalCommon")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"end"} gap={1} mr={1}>
            {[
              ...new Map(
                apiData?.data?.cardsData?.map((tod) => [tod.type, tod])
              ).values(),
            ].map((tod, index) => (
              <Flex gap={5}>
                <Box
                  key={index}
                  sx={{
                    backgroundColor: tod.bgColor,
                    px: 2,
                  }}
                ></Box>
                <Box>{tod.type}</Box>
              </Flex>
            ))}
          </Box>
          {isLoading && (
            <Box
              height={500}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <CircularProgress />
            </Box>
          )}

          {isSuccess && (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="stockChart"
              options={options}
            />
          )}

          <Box display={"flex"}>
            {apiData?.data?.cardsData?.map((tod, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: tod.bgColor,
                  borderRight: "none",
                  borderLeft: "none",
                  flexGrow: 1,
                  mb: 2,
                  mt: 2,
                }}
              >
                <Box
                  flexGrow={1}
                  p={0.6}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box textAlign={"left"} pl={1}>
                    <Typography
                      sx={{
                        color: "#495057",
                        fontWeight: 700,
                        fontSize: 16,
                        mb: 1,
                      }}
                    >
                      {tod.title} {":  "} {tod.hours}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#495057",
                        fontWeight: 500,
                        fontSize: 14,
                        mb: 0.5,
                      }}
                    >
                      {t("loadMangement.load")}:{Number(tod.load).toFixed(2)}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#495057",
                        fontWeight: 500,
                        fontSize: 14,
                        mb: 0.5,
                      }}
                    >
                      {t("loadMangement.consumption")}:{" "}
                      {Number(tod.consumption).toFixed(2)} kWh
                    </Typography>

                    <Typography
                      sx={{
                        color: "#495057",
                        fontWeight: 500,
                        fontSize: 14,
                        mb: 0.5,
                      }}
                    >
                      {t("loadMangement.usagePercentage")}:{" "}
                      {Number(tod.usage).toFixed(2)} %
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default TodInformation;
