import React, { useState } from "react";
import axios from "axios";
import { Form, Input, Button, Row, Col } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Incomers.css"; // Add custom styles here
import { Masterformsheadingsss } from "../../Operator/Data";

const Incomers = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [form] = Form.useForm();
  const initialFormData = {
    date: getCurrentDate(),
    name: "",
    fromsubstation: "",
    voltagelevel: "",
    departmentmeterMF: "",
    ctratioofdepartmentMF: "",
    ptratioofdepartmentMF: "",
  };

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        voltagelevel: Number(values.voltagelevel),
        departmentmeterMF: Number(values.departmentmeterMF),
        ctratioofdepartmentMF: Number(values.ctratioofdepartmentMF),
        ptratioofdepartmentMF: Number(values.ptratioofdepartmentMF),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/incomers`,
        dataToSend
      );
      toast.success("Incomers added successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Incomer");
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={initialFormData}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3> {Masterformsheadingsss.incomersHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F", // Custom background color
                  borderColor: "#252B3F",
                  color: "#fff", // Text color
                }}
              >
                View Data
              </Button>
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="date"
                //   rules={[{ required: true, message: "Please select a date!" }]}
                >
                  <Input type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                //   rules={[{ required: true, message: "Please enter a name!" }]}
                >
                  <Input
                   id="ant-input-style"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="From Substation"
                  name="fromsubstation"
                //   rules={[
                //     { required: true, message: "Please enter a substation!" },
                //   ]}
                >
                  <Input
                  id="ant-input-style"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Voltage Level"
                  name="voltagelevel"
                //   rules={[
                //     {
                //       required: true,
                //       message: "Please enter a voltage level!",
                //     },
                //   ]}
                >
                  <Input
                    type="number"
                   id="ant-input-style"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Department Meter MF"
                  name="departmentmeterMF"
                //   rules={[
                //     { required: true, message: "Please enter the meter MF!" },
                //   ]}
                >
                  <Input
                    type="number"
                   id="ant-input-style"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="CT Ratio of Department MF"
                  name="ctratioofdepartmentMF"
                //   rules={[
                //     { required: true, message: "Please enter the CT ratio!" },
                //   ]}
                >
                  <Input
                    type="number"
                    id="ant-input-style"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="PT Ratio of Department MF"
                  name="ptratioofdepartmentMF"
                //   rules={[
                //     { required: true, message: "Please enter the PT ratio!" },
                //   ]}
                >
                  <Input
                    type="number"
                   id="ant-input-style"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F", // Custom background color
                  borderColor: "#252B3F",
                  color: "#fff", // Text color
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Incomers;
