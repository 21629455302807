import React, { useEffect, useRef, useState } from "react";
import "./Tab1.css";
import Dashcriticalalerts from "./Dashcriticalalerts/Dashcriticalalerts";
import DashCalendar from "./DashCalender/DashCalender";
import DashTOD_runtime from "./TOD_runtime/TOD_runtime";
import DashPending_Task from "./DashPending_Task/DashPending_Task";
import Newunfilledforms from "./NewUnfilledForms/Newunfilledforms";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { Button, Card, Col, DatePicker, Form, Row, Statistic } from "antd";
import dayjs from "dayjs";
import { LikeOutlined } from "@ant-design/icons";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Stationpoints from "../../Commonfns/Stationpoints";

import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/services/axios";
import { useQuery } from "@tanstack/react-query";
import { Tooltip as AntdToolTip } from "antd";
import SettingsIcon from "@mui/icons-material/Settings";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7182BD",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const sourceColors = {
  Solar: "#61B061",

  Wind: "#5EACB1",

  DG: "#E79978",

  Grid: "#CDCDCD",

  WHRs: "#DEE25E",

  Hydro: "#639AC7",

  Thermal: "#BB8651",
};

const Tab1 = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      month: dayjs(),
      stationPoint: "HV",
    },
  });

  const { month, stationPoint } = watch();

  const {
    data: dashboardData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["alerts", month.format("YYYY-MM")],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `/api/dashboard-data?month=${month.format("YYYY-MM")}`
      );
      return res.data;
    },
    refetchOnMount: false,
  });

  const { data: consumptionData } = useQuery({
    queryKey: ["cons", month.format("YYYY-MM"), stationPoint],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `/api/dashboard-consumption?month=${month.format(
          "YYYY-MM"
        )}&section=${stationPoint}`
      );
      return res.data;
    },
    refetchOnMount: false,
  });

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };
  const chartRef = useRef(null);

  const chartRef1 = useRef(null);

  useEffect(() => {
    ChartJS.unregister(ChartDataLabels);
  }, []);

  const handleStationPointChange = (value) => {
    if (value.length === 0) {
      setValue("stationPoint", "HV");
    } else {
      setValue("stationPoint", value);
    }
  };

  const data = {
    labels: consumptionData?.date,
    datasets: [
      {
        label: "Consumption",
        data: consumptionData?.consumption,
        backgroundColor: ["#7182BD"],
        borderColor: ["#7182BD"],
        borderWidth: 1,
        datalables: {
          display: false,
        },
      },
    ],
  };

  const stackedData = {
    labels: dashboardData?.consumptionbysource?.dates,
    datasets: isSuccess
      ? dashboardData?.consumptionbysource?.sources?.map((data, index) => {
          const [key, value] = Object.entries(data)[0]; // Get the key and values of each object
          return {
            label: key,
            data: value.values, // Assign the values array to the data field
            backgroundColor: sourceColors[key] || "#000", // Assign colors based on index
          };
        })
      : [],
  };

  useEffect(() => {
    const chart = chartRef.current?.chartInstance ?? chartRef.current;

    // Check if the chart and tooltip are ready
    if (chart && chart.tooltip && chart.getDatasetMeta(0).data[0]) {
      chart.tooltip.setActiveElements([{ datasetIndex: 0, index: 0 }], {
        x: 0,
        y: 0,
      });
      chart.update();
    }
  }, [consumptionData, chartRef]);

  useEffect(() => {
    const chart = chartRef1.current?.chartInstance ?? chartRef1.current;

    // Check if the chart and tooltip are ready
    if (chart && chart.tooltip && chart.getDatasetMeta(0).data[0]) {
      chart.tooltip.setActiveElements([{ datasetIndex: 0, index: 0 }], {
        x: 0,
        y: 0,
      });
      chart.update();
    }
  }, [dashboardData, chartRef1]);

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: false,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        display: false, // H
        grid: {
          display: true, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        ticks: {
          display: false, // Hides grid lines on the
          callback: function (value) {
            return value / 100; // Show y-axis labels in thousands
          },
        },
        title: {
          display: false,
          text: "Consuption",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,
        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label; // Get the label for the current bar
            return `Date:  ${label}-${month.format("MM-YYYY")}`;
          },
        },
      },
    },
  };

  const stackedOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 50,
        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label; // Get the label for the current bar
            return `Date:  ${label}-${month.format("MM-YYYY")}`;
          },
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        stacked: true, // Enable stacking for the X-axis
      },
      y: {
        display: false, // H
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        stacked: true, // Enable stacking for the Y-axis
        min: 0,
        ticks: {
          display: false, // Hides grid lines on the
          callback: function (value) {
            return value / 100; // Show y-axis labels in thousands
          },
        },
        // max: 5, // Ensure this matches the number of labels minus one
      },
    },
  };

  return (
    <div id="tab1-total">
      {/* <div id='tab1-1'>
                <div>
                  
                    <Newunfilledforms/>
                </div>
                <div id='tab1-insided1' >
                    <Dashcriticalalerts />

                </div>
            </div> */}
      <Box p={0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mb: "10px",
          }}
        >
          <Form layout="inline">
            <Box
              style={{
                width: "300px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                gap: "13px",
              }}
            >
              <Typography>Month:</Typography>
              <Form.Item>
                <DatePicker
                  {...register("month")}
                  style={{ width: "250px" }}
                  defaultValue={getValues("month")}
                  size={"large"}
                  picker={"month"}
                  disabledDate={disableFutureMonths} // Restricts future months
                  onChange={onChange}
                />
              </Form.Item>
            </Box>
          </Form>
        </Box>

        <Row gutter={[10, 10]} style={{ marginBottom: "10px" }}>
          <Col sm={24} md={8} span={8}>
            <Card style={{ border: "1px solid #DFE6FA", textAlign: "center" }}>
              <Box display="flex" alignItems="center" gap={2}>
                <CurrencyRupeeIcon
                  // color="success"
                  style={{
                    fontSize: 40,
                    color: "#bf8d2e",
                    // border: "1px solid #4caf50",
                    // borderRadius: "50%",
                    // color: "#4caf50", // Green color for the bill icon
                  }}
                />

                <Typography>Estimated Bill: </Typography>

                <Typography style={{ fontWeight: "bold" }}>
                  {Number.isInteger(dashboardData?.estimatedbill)
                    ? dashboardData?.estimatedbill
                    : dashboardData?.estimatedbill?.toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Col>

          <Col sm={24} md={8} span={8}>
            <Card style={{ border: "1px solid #DFE6FA", textAlign: "center" }}>
              <Box display="flex" alignItems="center" gap={1}>
                <OfflineBoltIcon
                  color="error"
                  style={{
                    fontSize: 40,
                    // color: "#1E88E5",
                  }}
                />

                <Typography>Accumulated Consumption:</Typography>

                <Typography style={{ fontWeight: "bold" }}>
                  {Number.isInteger(dashboardData?.accumulatedconsumption)
                    ? dashboardData?.accumulatedconsumption
                    : dashboardData?.accumulatedconsumption?.toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Col>

          <Col sm={24} md={8} span={8}>
            <Card style={{ border: "1px solid #DFE6FA", textAlign: "center" }}>
              <Box display="flex" gap={1} alignItems="center">
                <AddCircleIcon
                  color="success"
                  style={{
                    fontSize: 40,
                    // color: "#43A047",
                  }}
                />
                <Typography>Accumulated Generation:</Typography>

                <Typography style={{ fontWeight: "bold" }}>
                  {Number.isInteger(dashboardData?.accumulatedgeneration)
                    ? dashboardData?.accumulatedgeneration
                    : dashboardData?.accumulatedgeneration?.toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col sm={24} md={14} style={{ height: "706px" }} span={14}>
            <Paper
              elevation={0}
              style={{
                height: "48%",
                padding: "5px",
                border: "1px solid #DFE6FA",
                borderRadius: "8px",
                marginBottom: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={"bold"} fontFamily={"DM Sans"}>
                  {t("rcpeTrendComponent.consumption")}
                </Typography>

                <Stationpoints
                  isHorizontal
                  onStationPointChange={handleStationPointChange}
                  defaultStationPoint={getValues("stationPoint")}
                />
              </Box>

              <Bar
                style={{ maxHeight: "300px" }}
                ref={chartRef}
                data={data}
                options={options}
              />
            </Paper>

            <Paper
              elevation={0}
              style={{
                height: "48%",
                padding: "5px",
                border: "1px solid #DFE6FA",
                borderRadius: "8px",
              }}
            >
              <Typography fontWeight={"bold"} fontFamily={"DM Sans"}>
                Consumption By Source
              </Typography>

              {/* <Typography>Consumption</Typography> */}

              {isSuccess &&
                dashboardData?.consumptionbysource?.dates?.length > 0 && (
                  <Bar
                    style={{ maxHeight: "310px" }}
                    ref={chartRef1}
                    // className="alerts-chart"
                    data={stackedData}
                    options={stackedOptions}
                  />
                )}
            </Paper>
          </Col>
          <Col sm={24} md={10} span={10}>
            <Paper
              elevation={0}
              style={{
                height: "687px",
                padding: "5px",
                border: "1px solid #DFE6FA",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={"bold"} fontFamily={"DM Sans"}>
                  Critical Alert Count & Consumption for each section
                </Typography>

                <Button
                  onClick={() =>
                    navigate(`/critical-alerts-tab?section=critical-alerts`)
                  }
                >
                  <Typography fontSize={13} fontFamily={"DM Sans"}>
                    View More
                  </Typography>
                </Button>
              </Box>

              <TableContainer
                sx={{ mt: 2, borderRadius: "8px", maxHeight: 630 }}
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Section</StyledTableCell>
                      <StyledTableCell align="center">
                        Critical Alert Count
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Consumption (kWh)
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && (
                      <TableRow sx={{ height: "300px" }}>
                        <TableCell align="center" colSpan={3}>
                          <CircularProgress />
                        </TableCell>
                        {/*  */}
                      </TableRow>
                    )}
                    {dashboardData?.alerts.length > 0 &&
                      !isLoading &&
                      dashboardData?.alerts?.map((row) => (
                        <AntdToolTip
                          color="#7182BD"
                          placement="bottom"
                          title={
                            "Click to see critical alerts of " + row.section
                          }
                        >
                          <TableRow
                            type="button"
                            onClick={() =>
                              navigate(
                                `/critical-alerts-tab?section=critical-alerts&station-point=${row.section}`
                              )
                            }
                            sx={{ cursor: "pointer" }}
                            fontFamily={"DM Sans"}
                          >
                            <TableCell fontFamily={"DM Sans"}>
                              {row.section}
                            </TableCell>
                            <TableCell fontFamily={"DM Sans"} align="center">
                              {Number.isInteger(row.count)
                                ? row.count
                                : row.count?.toFixed(2)}
                            </TableCell>
                            <TableCell fontFamily={"DM Sans"} align="center">
                              {Number.isInteger(row.kwh)
                                ? row.kwh
                                : row.kwh?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </AntdToolTip>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Col>
        </Row>

        <DashTOD_runtime />
        <DashCalendar />
      </Box>
      <div></div>

      <div id="tab1-2">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Tab1;
