
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Input, Form } from 'antd';
import './Incomersfetch.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Masterformsheadingsss } from '../../Operator/Data';

const Incomersfetch = ({ onFormButtonClick }) => {
    const [incomers, setIncomers] = useState([]);
    const [incomerseditData, setIncomersEditData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchIncomers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/incomers`);
            setIncomers(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchIncomers();
    }, []);

    const handleEditClick = (incomers) => {
        setIncomersEditData(incomers);
        setIsModalVisible(true);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setIncomersEditData({ ...incomerseditData, [name]: value });
    };

    const handleEditSubmit = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/incomers/${incomerseditData._id}`, incomerseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success('Incomer updated successfully');
            fetchIncomers();
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
            toast.error('Error Updating Incomer');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div id="masterformsfetch-total">
            <div id="masterformsfetch-right">
                <div id="masterformsfetch-right-2">
                    <div id="masterformsfetch-right-21">
                        <label>
                        {Masterformsheadingsss.incomersHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>Add New</button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>From Substation</th>
                                <th>Voltage level</th>
                                <th>Department meter MF</th>
                                <th>CT Ratio of department MF</th>
                                <th>PT Ratio of department MF</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incomers.map((incomer) => (
                                <tr key={incomer._id}>
                                    <td>{incomer.date}</td>
                                    <td>{incomer.name}</td>
                                    <td>{incomer.fromsubstation}</td>
                                    <td>{incomer.voltagelevel}</td>
                                    <td>{incomer.departmentmeterMF}</td>
                                    <td>{incomer.ctratioofdepartmentMF}</td>
                                    <td>{incomer.ptratioofdepartmentMF}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(incomer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Modal
                        title="Edit Incomers"
                        visible={isModalVisible}
                        onOk={handleEditSubmit}
                        onCancel={handleCancel}
                        okText="Update"
                        cancelText="Cancel"
                        okButtonProps={{
                            style: { backgroundColor: '#252B3F', color: '#fff' }, // Change background color and text color
                        }}
                    >
                        {incomerseditData && (
                            <Form layout="vertical">
                                <div className="input-row">
                                    <Form.Item label="Date">
                                        <Input name="date" value={incomerseditData.date} disabled />
                                    </Form.Item>
                                    <Form.Item label="Name">
                                        <Input
                                            name="name"
                                            value={incomerseditData.name}
                                            onChange={handleEditChange}
                                            size='medium'
                                        />
                                    </Form.Item>
                                </div>
                                <div className="input-row">
                                    <Form.Item label="From Substation">
                                        <Input
                                            name="fromsubstation"
                                            value={incomerseditData.fromsubstation}
                                            onChange={handleEditChange}
                                             size='medium'
                                        />
                                    </Form.Item>
                                    <Form.Item label="Voltage level">
                                        <Input
                                            type="number"
                                            name="voltagelevel"
                                            value={incomerseditData.voltagelevel}
                                            onChange={handleEditChange}
                                             size='medium'
                                        />
                                    </Form.Item>
                                </div>
                                <div className="input-row">
                                    <Form.Item label="Department meter MF">
                                        <Input
                                            type="number"
                                            name="departmentmeterMF"
                                            value={incomerseditData.departmentmeterMF}
                                            onChange={handleEditChange}
                                             size='medium'
                                        />
                                    </Form.Item>
                                    <Form.Item label="CT Ratio of department MF">
                                        <Input
                                            type="number"
                                            name="ctratioofdepartmentMF"
                                            value={incomerseditData.ctratioofdepartmentMF}
                                            onChange={handleEditChange}
                                             size='medium'
                                        />
                                    </Form.Item>
                                </div>
                                <div className="input-row">
                                    <Form.Item label="PT Ratio of department MF">
                                        <Input
                                            type="number"
                                            name="ptratioofdepartmentMF"
                                            value={incomerseditData.ptratioofdepartmentMF}
                                            onChange={handleEditChange}
                                             size='medium'                                       />
                                    </Form.Item>
                                </div>
                            </Form>
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default Incomersfetch;
