import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Button as AntDButton, DatePicker, Flex, Popover } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getDownTimeUptimeReports } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import lightIcon from "../../../Images/light.svg";
import manIcon from "../../../Images/man.svg";
import {
  decimalHoursToTimeString,
  hoursToTimeString,
  timeStringToDecimalHours
} from "../../../utils/trends";
import Stationpoints from "../../Commonfns/Stationpoints";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,

);

const DownTimeVsUpTime = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const { control, register, formState, setValue, reset, watch, getValues } = useForm({
    defaultValues: {
      station_point: "HV",
      month: dayjs(),
    },
  });

  const [upTimeData, setUpTimeData] = useState([]);

  const [downTimeData, setDownTimeData] = useState([]);

  const [labels, setLabels] = useState([]);

  const { station_point, month } = watch();

  useEffect(() => {
    ChartJS.register(ChartDataLabels);
    getDownTimeUptimeReports(station_point, month.format('YYYY-MM')).then((data) => {
      setUpTimeData(data.uptimeData);
      setLabels(data.dateLabels);
      setDownTimeData(data.downtimeData);
    });
  }, [station_point, month]);


  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const data = {
    labels: labels.map((label) => moment.utc(label).format('DD')),
    datasets: [
      {
        label: "UpTime Hours",
        data:  upTimeData ?  upTimeData?.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ) : [],
        backgroundColor: "#6174D7",
        maxBarThickness: 100,
      },
      {
        label: "DownTime Hours",
        data: downTimeData ?  downTimeData?.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ): [],
        backgroundColor: "#EA4335",
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index", // Highlight both bars on the same date
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
      onHover: function (event, chartElement) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      },
    },

    animation: {
      // duration: 0,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        mode: "index", // Highlight both bars on the same date
        intersect: false,
        // animation: false,
        // duration: 0,
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: 'bottom', // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label; // Get the label for the current bar
            return `Date:  ${label}-${month.format("MM-YYYY")}`;
          },
          label: function (context) {
            // Get the value from the dataset

            const datasetLabel = context.dataset.label;
            const value = context.raw;
            // Convert the value to HH:MM:SS format
            const timeString = decimalHoursToTimeString(value);
            // Return the formatted time string
            return `${datasetLabel}: ${timeString}`;
          },
        },
      },
      datalabels: {
        color: "white",
        display: (context) => context.dataset.data[context.dataIndex] != null,
        font: {
          // weight: "bold",
          size: 12,
        },
        anchor: "center", // Positions the label in the center of the bar
        align: "center",  // Aligns the label with the bar's center
        rotation: -90,    // Rotates the label to be vertical
        formatter: (value) => {
          // Check if the value is a float
          return Number.isInteger(value) ? value : value.toFixed(2);
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        ticks: {
          callback: (value) => hoursToTimeString(value),
          beginAtZero: true,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
  };

  const handleStationPointChange = (value) => {
    setValue("station_point", value);
  };

  const chartRef = React.useRef();

  const handleDownloadPdf = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) return;

    try {
      const canvas = await html2canvas(chartElement, {
        useCORS: true, // Ensures cross-origin images are loaded
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the chart into the page
      const ratio = Math.min(
        pageWidth / canvas.width,
        pageHeight / canvas.height
      );
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;

      // Calculate offsets to center the image
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("downtime_vs_uptime_trend.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
         <Flex align="end" gap={20}>
         
        <Box>
          <div>
            <Stationpoints
              onStationPointChange={handleStationPointChange}
              defaultStationPoint="HV"
            />
          </div>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
          <DatePicker
                  {...register("month")}
                  style={{ width: "200px" }}
                  defaultValue={getValues("month")}
                  size={"medium"}
                  picker={"month"}
                  disabledDate={disableFutureMonths} // Restricts future months
                  onChange={onChange}
                />
          </div>
        </Box>

        <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none", marginTop: 13 }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>
      <Paper
        elevation={0}
        ref={chartRef}
        sx={{
          border: "1px solid #DFE6FA",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("downTimeTrendsComponent.downtimeTrends")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("downTimeTrendsComponent.whyIsThisImportantAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"85px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("downTimeTrendsComponent.whatShouldYouDoAnswer")}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Forms?section=Breakdownlogsfetch"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "end",
                            alignSelf: "end",
                            borderRadius: "4px",
                          }}
                        >
                          {t("downTimeTrendsComponent.breakdownLogs")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("downTimeTrendsComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Bar className="alerts-chart" data={data} options={options} />
      </Paper>
    </div>
  );
};

export default DownTimeVsUpTime;
