

import React from 'react';
import './MonthlyDashboard.css';
import { supervisordashbuttons } from '../../Operator/Data';
import { DatePicker, Space } from 'antd';
import dayjs from "dayjs";

const MonthlyDashboard = ({ selectedMonth, onMonthChange }) => {

  const getCurrentMonth = () => {
    const date = new Date();
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  };
  const handleMonthChange = (date, dateString) => {
    onMonthChange(dateString); // Pass the formatted month (YYYY-MM) to the parent
  };


  const formatMonthYear = (monthString) => {
    const date = new Date(monthString);
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options); // e.g., "August 2024"
  };

  return (
    <div id='monthlydash-div'>
      {/* <div id='monthlydash-div1'>
        <span id='monthlydash-bold' >{supervisordashbuttons.button3Headingfordash}</span>
        <span> &nbsp;{formatMonthYear(selectedMonth)}</span>

      </div> */}
      <div 
       style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "15px",
        marginTop: "10px",
      }}
      
      id='monthlydash-div2' >
        <span>Month:</span>

        <DatePicker  
         style={{ width: "250px" }}
         size={"large"}
         id="month" 
         picker="month" 
         value={selectedMonth ? dayjs(selectedMonth, 'YYYY-MM') : null}
         onChange={handleMonthChange}
         disabledDate={(current) => current && current > dayjs()}
        />
      </div>
    </div>
  )
}

export default MonthlyDashboard;
