

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Designation.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Typography, Card, Select } from "antd";
const Designation = () => {
  const [form] = Form.useForm();
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setLoggedInUser(user);
      form.setFieldsValue({
        createdby: user.username,
      });
    }
  }, [form]);

  const handleSubmit = async (values) => {
    const designationData = {
      ...values,
      createdby: loggedInUser?.username,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/designations`,
        designationData
      );
      toast.success("Designation Added Successfully");
      form.resetFields();
    } catch (error) {
      if (error.response?.data?.message === "Designation already exists") {
        toast.error("Designation name already exists. Please choose a different name.");
      } else {
        toast.error("Failed to add designation. Please try again later.");
      }
    }
  };

  return (
    <div
    style={{
      height: "100vh",
      alignContent: "center",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Card
      bordered={false}
      style={{
        width: "50%",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        padding: "10px 20px 10px 20px",
      }}
    >
     <div style={{ display:"flex" ,justifyContent:"flex-end" ,textAlign: "center", marginBottom:"10px" }}>
        <Link to="/DesignationTable">
          <Button>View Data</Button>
        </Link>
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={<Typography.Text strong>Select Level</Typography.Text>}
              name="level"
              rules={[{ required: true, message: "Please select a level!" }]}
            >
              <Select placeholder="Select Level">
                <Select.Option value="Level-2">Level-2</Select.Option>
                <Select.Option value="Level-3">Level-3</Select.Option>
                <Select.Option value="Level-4">Level-4</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<Typography.Text strong>Designation Name</Typography.Text>}
              name="designation"
              rules={[{ required: true, message: "Please enter the designation!" }]}
            >
              <Input placeholder="Enter Designation" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button htmlType="submit" style={{marginTop:"10px" ,width: "200px", backgroundColor:"#252B3F", color:"#ffff", border:"none" }}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>

      <div style={{ marginTop: "20px" }}>
        <Typography.Title level={3}>Note:</Typography.Title>
        <Typography.Paragraph>
          <strong>Level-1: </strong>
          Executive Leadership (e.g., CXO, CEO, COO, CTO, CFO)
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Level-2: </strong>
          Administrator
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Level-3: </strong>
          Supervisory Roles (e.g., Plant Head, Operations Manager, Supervisor, Shift Supervisor)
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Level-4: </strong>
          Operational Staff (e.g., Operator, Technician, Associate, Field Staff)
        </Typography.Paragraph>
        <Typography.Paragraph>
          Please feel free to customize the designation name as per your organization.
        </Typography.Paragraph>
      </div>
    </Card>
    <ToastContainer />
  </div>
  );
};

export default Designation;


