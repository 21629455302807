// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sectionpoints-total{
    margin-top: 15px;
    background-color: #F3F6FE;
    height: auto !important;
}

#sectionpoints-filter{
    display: flex;
    justify-content: flex-end;
    padding: 0px 40px 0px 0px;
    margin-bottom: -70px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Sectionpointview/Sectionpoints.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,oBAAoB;AACxB","sourcesContent":["#sectionpoints-total{\n    margin-top: 15px;\n    background-color: #F3F6FE;\n    height: auto !important;\n}\n\n#sectionpoints-filter{\n    display: flex;\n    justify-content: flex-end;\n    padding: 0px 40px 0px 0px;\n    margin-bottom: -70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
