// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#opehome-total {
    background-color: #F3F6FE;
    height: auto !important;
    background: cover;
}

#opehome-right-2{
    display: flex;
    padding:0px 10px 5px 10px;
    width: 100%;
    gap: 20px;
}

#opehome-content{
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Home/Home.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n#opehome-total {\n    background-color: #F3F6FE;\n    height: auto !important;\n    background: cover;\n}\n\n#opehome-right-2{\n    display: flex;\n    padding:0px 10px 5px 10px;\n    width: 100%;\n    gap: 20px;\n}\n\n#opehome-content{\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
