// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dailypf {
    height: auto;
    width: auto;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;
}

#gauge-chart {
    height: 400px;
    width: 350px;
}

#dailypf-flex{
    display: flex;

    /* #daily-pf-inside{
        margin-top: 20vh;
        span{

        }
    } */
}

#daily-pf-inside{
  margin-top: -120px;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Daily-Dash/DailyPF/DailyPF.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;;IAEb;;;;;OAKG;AACP;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":["#dailypf {\n    height: auto;\n    width: auto;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n#gauge-chart {\n    height: 400px;\n    width: 350px;\n}\n\n#dailypf-flex{\n    display: flex;\n\n    /* #daily-pf-inside{\n        margin-top: 20vh;\n        span{\n\n        }\n    } */\n}\n\n#daily-pf-inside{\n  margin-top: -120px;\n  font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
