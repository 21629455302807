import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Alertsdurationfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const Alertsdurationfetch = ({ onFormButtonClick }) => {
  const [alertsDuration, setAlertsDuration] = useState([]);
  const [alertEditData, setAlertEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchAlertsDuration = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/alertsduration`
      );
      setAlertsDuration(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchAlertsDuration();
  }, []);

  const handleEditClick = (alert) => {
    setAlertEditData(alert);
    setIsModalVisible(true);
  };
  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setAlertEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setAlertEditData({ ...alertEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DD") : null;
      setAlertEditData({
        ...alertEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...alertEditData,
        duration: Number(alertEditData.duration),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/alertsduration/${alertEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Alert Duration updated successfully");
      fetchAlertsDuration();
      setAlertEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  const alertOptions = [
    { value: "", label: "Select Alert Type" },
    { value: "Voltage loss", label: "Voltage loss" },
    { value: "Abnormal PF loss", label: "Abnormal PF loss" },
    { value: "THD loss", label: "THD loss" },
    { value: "Unbalanced loads", label: "Unbalanced loads" },
    { value: "Negative PF loss", label: "Negative PF loss" },
    { value: "Idle time loss", label: "Idle time loss" },
    { value: "Transformer loss", label: "Transformer loss" },
    { value: "Utilization loss", label: "Utilization loss" },
    { value: "Currents loss", label: "Currents loss" },
    { value: "TOD loss", label: "TOD loss" },
    { value: "KVA", label: "KVA" },
  ];

  return (
    <div id="alertsdurationfetch-total">
      <div id="alertsdurationfetch-right">
        <div id="alertsdurationfetch-right-2">
          <div id="alertsdurationfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.AlertsdurationHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {" "}
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Alert Type</th>
                <th>Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {alertsDuration.map((alert) => (
                <tr key={alert._id}>
                  <td>{alert.date}</td>
                  <td>{alert.alert_type}</td>
                  <td>{alert.duration}</td>
                  <td>
                    <button onClick={() => handleEditClick(alert)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal
            title="Edit Alert Duration"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" },
            }}
          >
            {alertEditData && (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Date">
                      <Input
                        name="date"
                        value={alertEditData.date}
                        onChange={handleEditChange}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Select Alert Type">
                      <Select
                        name="alert_type"
                        value={alertEditData.alert_type}
                        onChange={(value) =>
                          handleEditChange({
                            target: { name: "alert_type", value },
                          })
                        }
                      >
                        {alertOptions.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Duration (in Mins)">
                      <Input
                      type="number"
                        name="duration"
                        value={alertEditData.duration}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Alertsdurationfetch;
