import React, { useEffect } from "react";
import Box from "@mui/material/Box";
// import { useUserDetails } from "../../../Hooks/useUserDetails";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import SideBar from "../SideBar";
import { DrawerState } from "../../../api/client/drawer.state";

const AppBarLayout = ({ children }) => {
  // const { token, loading } = useUserDetails();

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!loading && !token) {
  //     navigate("/login");
  //   }
  // }, [token]);


  const setDrawerClose = DrawerState((state) => state.setDrawerClose);

  useEffect(() => {
    setDrawerClose(false);
    return () => {
      setDrawerClose(false);
    };
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        padding: 0,
        paddingTop: 10,
        backgroundColor: "#F3F6FE",
      
      }}
    >
      <SideBar />
      <Box flexGrow={1}>{children}</Box>
      <FloatButton.BackTop />
    </Box>
  );
};

export default AppBarLayout;
