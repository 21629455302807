
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendertab.css';
import { DrawerState } from '../../../api/client/drawer.state';

const Calendartab = ({ selectedMonth, selectedStationPoint }) => {
    const [ calendarevents, setCalendarEvents] = useState([]); 
    const open = DrawerState((state) => state.isOpen);
    const calendarRef = useRef(null);

    const fetchCalendarEvents = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_EMS_URL}/api/calendar-events`,
            {
              params: {
                month: selectedMonth || new Date().toISOString().slice(0, 7),
                stationPoint: selectedStationPoint || "HV",
              },
            }
          );
          const formattedEvents = response.data.map(event => ({
            title: `Event on ${event.date}`,
            start: event.date,
            extendedProps: {
                Section: event.stationPoint,
                energyIntensity: event.Energy_Intensity || 'N/A',
                md: event.Maximum_Demand_kVA || 'N/A',
                pf: event.PF || 'N/A',
                production: event.Production || 'N/A',
                runTime: event.Runtime || 'N/A',
                utilization: event.Utilization || 'N/A',
                kWh: event.kWh_consumption || 'N/A'
            }
        }));
          setCalendarEvents(formattedEvents); // Set data from API response
        } catch (error) {
            console.error('Error fetching calendar events:', error);
        }
    };
    
    useEffect(() => {
        fetchCalendarEvents();
    }, [selectedMonth, selectedStationPoint]);


    useEffect(() => {
        updateCalendarView();
    }, [selectedMonth]);

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                fetchCalendarEvents();
            }, 300);
        }
    }, [open]);
    
    const updateCalendarView = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const [year, month] = selectedMonth.split('-');
            calendarApi.gotoDate(new Date(year, month - 1));
        }
    };

    const renderEventContent = (eventInfo) => {
        // const { title } = eventInfo.event;
        const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

        const renderValue = (value) => value === null ? 'N/A' : value;

        return (
            <div id='calendereventstab-fulcaltop'>
                <label>kWh: {renderValue(kWh)}</label>
                {/* <span>Section: {renderValue(Section)}</span> */}
                <span>MD: {renderValue(md)}</span>
                <span>PF: {renderValue(pf)}</span>
                <span>Run Time: {renderValue(runTime)}</span>
                <span>Production: {renderValue(production)}</span>
                <span>Energy Intensity: {renderValue(energyIntensity)}</span>
                <span>Utilization (%): {renderValue(utilization)}</span>
            </div>
        );
    };

    return (
        <div id='calendercombine-right-4'>
            <p>Monthly Calender Overview of - {selectedStationPoint} </p>
            <div id='calendercombine-fulcal'>
                <FullCalendar
                    ref={calendarRef}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        right: 'today',
                        left: 'title',
                    }}
                    themeSystem="Simplex"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    events={calendarevents}
                    eventContent={renderEventContent}
                    dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
                    datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
                    height="100%" // Ensures it adapts to the container
                    contentHeight="auto" // Ensures dynamic height
                />
            </div>
        </div>
    );
};

export default Calendartab;
