import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './VFDfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const VFDfetch = ({ onFormButtonClick }) => {
    const [vfds, setVfds] = useState([]);
    const [vfdEditData, setVfdEditData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [parentNames, setParentNames] = useState([]);

    const disableDates = (current) => {
        // Disable today and future dates
        return current && current.isAfter(dayjs().endOf("day"));
      };

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    const fetchVfds = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/vfds`);
            setVfds(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
        fetchVfds();
    }, []);

    const handleEditClick = (vfd) => {
        setVfdEditData(vfd);
        setIsModalVisible(true);
    };

      // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setVfdEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setVfdEditData({ ...vfdEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setVfdEditData({
        ...vfdEditData,
        [field]: formattedDate,
      });
    }
  };

    const handleEditSubmit = async () => {
        try {

            const dataToSend = {
                ...vfdEditData,
                capacityinvw: Number(vfdEditData.capacityinvw),
                voltagelevel: Number(vfdEditData.voltagelevel),
                inputvoltage: Number(vfdEditData.inputvoltage),
                outputvoltage: Number(vfdEditData.outputvoltage),
                outputfrequency: Number(vfdEditData.outputfrequency),
                frequencycontrolaccuracy: Number(vfdEditData.frequencycontrolaccuracy),
                accelerationanddeaccelarationrates: Number(vfdEditData.accelerationanddeaccelarationrates),
                energyefficiency: Number(vfdEditData.energyefficiency),
                harmonicmigartion: Number(vfdEditData.harmonicmigartion),
            };

            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/vfds/${vfdEditData._id}`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success("VFD updated successfully");
            fetchVfds();
            setVfdEditData(null);
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='vfdfetch-total'>
            <div id='vfdfetch-right'>
                <div id='vfdfetch-right-2'>
                    <div id='vfdfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.VFDHeading}</span>

                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th> Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Capacity in kW</th>
                                <th>Voltage Level</th>
                                <th>Input Voltage</th>
                                <th>Output Voltage</th>
                                <th>Output Frequency</th>
                                <th>Frequency Control Accuracy</th>
                                <th>Acceleration/Deceleration Rates</th>
                                <th>Energy Efficiency</th>
                                <th>Control Type</th>
                                <th>Overcurrent Protection</th>
                                <th>Overvoltage Protection</th>
                                <th>Regenerative Capability</th>
                                <th>Harmonic Mitigation</th>
                                <th>Compatibility</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vfds.map((vfd) => (
                                <tr key={vfd._id}>
                                    <td>{vfd.date}</td>
                                    <td>{vfd.parentname}</td>
                                    <td>{vfd.name}</td>
                                    <td>{vfd.metermake}</td>
                                    <td>{vfd.meterslno}</td>
                                    <td>{vfd.phase}</td>
                                    <td>{vfd.capacityinvw}</td>
                                    <td>{vfd.voltagelevel}</td>
                                    <td>{vfd.inputvoltage}</td>
                                    <td>{vfd.outputvoltage}</td>
                                    <td>{vfd.outputfrequency}</td>
                                    <td>{vfd.frequencycontrolaccuracy}</td>
                                    <td>{vfd.accelerationanddeaccelarationrates}</td>
                                    <td>{vfd.energyefficiency}</td>
                                    <td>{vfd.controltype}</td>
                                    <td>{vfd.overcurrentprotectionavailable}</td>
                                    <td>{vfd.overvoltageprotectionavailable}</td>
                                    <td>{vfd.regenartivecapability}</td>
                                    <td>{vfd.harmonicmigartion}</td>
                                    <td>{vfd.compatability}</td>
                                    <td>{vfd.proposedperiodicityofmaintenance}</td>
                                    <td>{vfd.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(vfd)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Modal
    title="Edit VFD"
    visible={isModalVisible}
    onOk={handleEditSubmit}
    onCancel={handleCancel}
    okText="Update"
    cancelText="Cancel"
    okButtonProps={{ style: { backgroundColor: "#252B3F", color: "#fff" } }}
>
    {vfdEditData && (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Date">
                        <Input
                            name="date"
                            value={vfdEditData.date}
                            onChange={handleEditChange}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Parent Name">
                        <Select
                            value={vfdEditData.parentname}
                            onChange={(value) =>
                                setVfdEditData({
                                    ...vfdEditData,
                                    parentname: value,
                                })
                            }
                        >
                            {parentNames.map((name, index) => (
                                <Select.Option key={index} value={name}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Name">
                        <Input
                            name="name"
                            value={vfdEditData.name}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Meter Make">
                        <Input
                            name="metermake"
                            value={vfdEditData.metermake}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Meter Serial No.">
                        <Input
                            name="meterslno"
                            value={vfdEditData.meterslno}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Phase">
                        <Select
                            value={vfdEditData.phase}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'phase', value } })
                            }
                        >
                            <Select.Option value="3-Phase">3-Phase</Select.Option>
                            <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                            <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                            <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Capacity in kW">
                        <Input
                            name="capacityinvw"
                            type="number"
                            value={vfdEditData.capacityinvw}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Voltage Level">
                        <Input
                            name="voltagelevel"
                            type="number"
                            value={vfdEditData.voltagelevel}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Input Voltage">
                        <Input
                            name="inputvoltage"
                            type="number"
                            value={vfdEditData.inputvoltage}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Output Voltage">
                        <Input
                            name="outputvoltage"
                            type="number"
                            value={vfdEditData.outputvoltage}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Output Frequency">
                        <Input
                            name="outputfrequency"
                            type="number"
                            value={vfdEditData.outputfrequency}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Frequency Control Accuracy">
                        <Input
                            name="frequencycontrolaccuracy"
                            type="number"
                            value={vfdEditData.frequencycontrolaccuracy}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Acceleration/Deceleration Rates">
                        <Input
                            name="accelerationanddeaccelarationrates"
                            type="number"
                            value={vfdEditData.accelerationanddeaccelarationrates}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Energy Efficiency">
                        <Input
                            name="energyefficiency"
                            type="number"
                            value={vfdEditData.energyefficiency}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Control Type">
                        <Input
                            name="controltype"
                            value={vfdEditData.controltype}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Overcurrent Protection">
                        <Select
                            value={vfdEditData.overcurrentprotectionavailable}
                            onChange={(value) =>
                                setVfdEditData({
                                    ...vfdEditData,
                                    overcurrentprotectionavailable: value,
                                })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Overvoltage Protection">
                        <Select
                            value={vfdEditData.overvoltageprotectionavailable}
                            onChange={(value) =>
                                setVfdEditData({
                                    ...vfdEditData,
                                    overvoltageprotectionavailable: value,
                                })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Regenerative Capability">
                        <Select
                            value={vfdEditData.regenartivecapability}
                            onChange={(value) =>
                                setVfdEditData({
                                    ...vfdEditData,
                                    regenartivecapability: value,
                                })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Harmonic Mitigation">
                        <Input
                            name="harmonicmigartion"
                            type="number"
                            value={vfdEditData.harmonicmigartion}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Compatibility">
                        <Input
                            name="compatability"
                            value={vfdEditData.compatability}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Proposed Periodicity of Maintenance">
                        <Input
                            name="proposedperiodicityofmaintenance"
                            value={vfdEditData.proposedperiodicityofmaintenance}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Previous Maintenance Done Date">
                        <DatePicker
                            disabledDate={disableDates}
                            value={
                                vfdEditData.previousmaintenancedonedate
                                    ? dayjs(vfdEditData.previousmaintenancedonedate)
                                    : null
                            }
                            onChange={(date) =>
                                setVfdEditData({
                                    ...vfdEditData,
                                    previousmaintenancedonedate: date,
                                })
                            }
                            format={dateFormat}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )}
</Modal>


                </div>
            </div>
        </div>
    );
};

export default VFDfetch;
