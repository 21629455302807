
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SupervisorDashHeader } from '../Operator/Data';
import { supervisordashbuttons } from '../Operator/Data';
import './Alldash.css';
import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
import DailyDashboard from './Daily-Dash/DailyDashboard';
import MonthlyDashboardContainer from './Monthly-Dash/MonthlyDashboardContainer';
import Tab3 from './Tab3/Tab3';
import Tab1 from './Tab1/Tab1';
import CommonTabs from "../Common/CommonTabs/CommonTabs";
const Alldash = () => {

    const tabs = [
        {
          name: "Overview",
          label: "over-view",
          component: <Tab1 />,
        },
        {
          name: "Data Summary",
          label: "data-summary",
          component: <DailyDashboard />,
        },
        {
            name: "Metric Breakdown",
            label: "metric-breakdown",
            component: <MonthlyDashboardContainer />,
          },
          {
            name: "Energy Billing",
            label: "energy-billing",
            component: <Tab3 />,
          }

      ];

    return (
        <div>
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    );
};

export default Alldash;
