// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#masterforms-total {
    padding: 20px;
}

#masterforms-right-2 {
   max-width: 800px;
   margin: auto;
   background: #fff;
   padding: 20px;
   border-radius: 8px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    form {
        padding: 0px 30px 0px 30px;
    }
}


#ant-input-style{
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MasterForms/Incomers/Incomers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;GACG,gBAAgB;GAChB,YAAY;GACZ,gBAAgB;GAChB,aAAa;GACb,kBAAkB;GAClB,yCAAyC;;IAExC;QACI,0BAA0B;IAC9B;AACJ;;;AAGA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":["#masterforms-total {\n    padding: 20px;\n}\n\n#masterforms-right-2 {\n   max-width: 800px;\n   margin: auto;\n   background: #fff;\n   padding: 20px;\n   border-radius: 8px;\n   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n\n    form {\n        padding: 0px 30px 0px 30px;\n    }\n}\n\n\n#ant-input-style{\n    border-radius: 5px;\n    border: 1px solid #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
