import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const CommonTabs = ({ tabs, defaultTab }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [activeSection, setActiveSection] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);

  const handleChange = (_event, newValue) => {
    setActiveSection(newValue);
    setActiveComponent(tabs.find((tab) => tab.label === newValue)?.component);
    navigate(`?section=${newValue}`, { replace: true });
  };

  useEffect(() => {
    const section = searchParams.get("section");

    if (section && tabs.some((tab) => tab.label === section)) {
      setActiveComponent(tabs.find((tab) => tab.label === section)?.component);
      setActiveSection(section);
    } else {
      const defaultSection = defaultTab || tabs[0].label;
      setActiveSection(defaultSection);
      setActiveComponent(
        tabs.find((tab) => tab.label === defaultSection)?.component
      );
      navigate(`?section=${defaultSection}`, { replace: true });
    }
  }, [location.search, tabs, defaultTab, navigate]);

  return (
    <div style={{ marginBottom: "5px", maxWidth: window.innerWidth - 50 }}>
      <div id="content-right">
        <Box sx={{ p: 2, px: 4, display: "flex", justifyContent: "center" , alignItems:"center"}}>
          <Tabs
            sx={{
              ".MuiTab-root": {
                borderColor: "#9EADDF",
                borderWidth: 1,
                borderStyle: "solid",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTabs-flexContainer": {
                overflowX: "auto",
                display: "flex",
                justifyContent: "center",
              },
            }}
            value={activeSection}
            onChange={handleChange}
            textColor="inherit"
            scrollButtons="auto"
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.label}
                value={tab.label}
                label={tab.name}
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "175px",
                  height: "55px",
                  alignContent: "center",
                  mx: 1,
                  color: activeSection === tab.label ? "#ffffff" : "#000000",
                  backgroundColor: activeSection === tab.label ? "#7182BD" : "#D8E1FF",
                  border:
                    activeSection === tab.label ? "none" : "1px solid #abb9e7",
                  borderRadius: 2,
                  textTransform: "none",
                  alignItems: "center",
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ pl: 1, pr:1 }}>{activeComponent}</Box>
      </div>
    </div>
  );
};

export default CommonTabs;
