import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import Stationpoints from "../../Commonfns/Stationpoints";
import Operatorsidebar from "../Sidebar/Sidebar";
import TopBar from "../../Commonfns/Topbar/Topbar";
import { FaFileCsv } from "react-icons/fa6";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import "./Historydata.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { DatePicker, Space, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MultiStationpoints from "../../Commonfns/MultiStationpoints";
import CommonTable from "../../Common/CommonTable/CommonTable";
const itemsPerPage = 20;

const columns = [
  { id: "sNo", label: "S. No.", align: "center" },
  { id: "division", label: "Division", align: "center" },
  { id: "section", label: "Section", align: "center" },
  { id: "date", label: "Date", align: "center" },
  { id: "time", label: "Time", align: "center" },
  { id: "vr", label: "Vr", align: "center" },
  { id: "vy", label: "Vy", align: "center" },
  { id: "vb", label: "Vb", align: "center" },
  { id: "vavg", label: "Vavg", align: "center" },
  { id: "ir", label: "Ir", align: "center" },
  { id: "iy", label: "Iy", align: "center" },
  { id: "ib", label: "Ib", align: "center" },
  { id: "iavg", label: "Iavg", align: "center" },
  { id: "imax", label: "Imax", align: "center" },
  { id: "fr", label: "Fr", align: "center" },
  { id: "thdvr", label: "THDvr", align: "center" },
  { id: "thdvy", label: "THDvy", align: "center" },
  { id: "thdvb", label: "THDvb", align: "center" },
  { id: "thdir", label: "THDir", align: "center" },
  { id: "thdiy", label: "THDiy", align: "center" },
  { id: "thdib", label: "THDib", align: "center" },
  { id: "vry", label: "Vry", align: "center" },
  { id: "vyb", label: "Vyb", align: "center" },
  { id: "vbr", label: "Vbr", align: "center" },
  { id: "pf", label: "Pf", align: "center" },
  { id: "kw", label: "kW", align: "center" },
  { id: "kva", label: "kVA", align: "center" },
  { id: "kwh", label: "kWh", align: "center" },
  { id: "kvarh", label: "KVArh", align: "center" },
  { id: "kvah", label: "KVAh", align: "center" },
];

const fetchAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDate, toDate] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/historydata/filter?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (section.length > 0) {
    let encodedSections = section.map((sec) => encodeURIComponent(sec));
    query += `&stationPoint=${encodedSections.join(",")}`; // Multi-section filter
  }

  const response = await fetch(query);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const getTodayStartAndEnd = () => {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");

  // Start of the day: 00:00
  const startOfDay = `${yyyy}-${mm}-${dd} 00:00`;

  // End of the day: 23:59
  const endOfDay = `${yyyy}-${mm}-${dd} 23:59`;

  return { startOfDay, endOfDay };
};

const OperatorAlertstable = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("");
  const { startOfDay, endOfDay } = getTodayStartAndEnd();
  const [fromDate, setFromDate] = useState(startOfDay);
  const [toDate, setToDate] = useState(endOfDay);

  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const disabledTime = (date) => {
    if (date && date.isSame(dayjs(), "day")) {
      return {
        disabledHours: () => [...Array(24).keys()].slice(dayjs().hour() + 1), // Disable future hours
      };
    }
    return {};
  };

  const { ref, inView } = useInView({ threshold: 0 });
  // Use a state to control the polling interval
  const [pollingEnabled, setPollingEnabled] = useState(true);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["operator-alerts", section, fromDate, toDate],
    queryFn: fetchAlerts,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length > 0 ? pages.length + 1 : undefined,
    refetchInterval: pollingEnabled ? 5000 : false, // Control polling using state
    onSettled: () => {
      // Disable polling if a fetch is in progress
      setPollingEnabled(!isFetchingNextPage);
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint || []);
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD HH:mm"));
      setToDate(dates[1].format("YYYY-MM-DD HH:mm"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const downloadCSV = async () => {
    setLoading(true); // Start loading
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/historydata/filter/download?`;

      if (fromDate) query += `fromDate=${fromDate}&`;
      if (toDate) query += `toDate=${toDate}&`;
      if (section.length > 0) {
        let encodedSections = section.map((sec) => encodeURIComponent(sec));
        query += `&stationPoint=${encodedSections.join(",")}`; // Multi-section filter
      }

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed to fetch data for CSV download");
      }

      const data = await response.json();

      // Generate CSV content from the fetched data
      const csvContent = [
        [
          "S No",
          "deviceEUi",
          "division",
          "stationPoint",
          "Date",
          "Time",
          "utcTimestamp",
          "Vr",
          "Vy",
          "Vb",
          "Vavg",
          "Ir",
          "Iy",
          "Ib",
          "Iavg",
          "Imax",
          "Fr",
          "THDvr",
          "THDvy",
          "THDvb",
          "THDir",
          "THDiy",
          "THDib",
          "Vry",
          "Vyb",
          "Vbr",
          "Pf",
          "kW",
          "kVA",
          "kWh",
          "KVArh",
          "KVAh",
        ],
        ...data.map((alert, index) => [
          index + 1,
          alert.deviceEUi,
          alert.division,
          alert.stationPoint,
          alert.Date,
          alert.Time,
          alert.utcTimestamp,
          alert.Vr,
          alert.Vy,
          alert.Vb,
          alert.Vavg,
          alert.Ir,
          alert.Iy,
          alert.Ib,
          alert.Iavg,
          alert.Imax,
          alert.Fr,
          alert.THDvr,
          alert.THDvy,
          alert.THDvb,
          alert.THDir,
          alert.THDiy,
          alert.THDib,
          alert.Vry,
          alert.Vyb,
          alert.Vbr,
          alert.Pf,
          alert.kW,
          alert.kVA,
          alert.kWh,
          alert.KVArh,
          alert.KVAh,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create file name based on applied filters
      const filters = [];
      if (fromDate) filters.push(`From-${fromDate}`);
      if (toDate) filters.push(`To-${toDate}`);
      if (section) filters.push(`Section-${section}`);
      const fileName = filters.length > 0 ? filters.join("_") : "AllData";
      const fullFileName = `HistoryData-${fileName}.csv`;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            Error: {error.message}
          </TableCell>
        </TableRow>
      );
    }

    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) {
      return (
        <TableRow sx={{ height: 500 }}>
          <TableCell colSpan={columns.length} align="center">
            <Empty description={"No data available"} />
          </TableCell>
        </TableRow>
      );
    }

    return alerts.map((alert, index) => (
      <TableRow key={alert._id || index}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{alert.division}</TableCell>
        <TableCell align="center">{alert.stationPoint}</TableCell>
        <TableCell align="center">{alert.Date}</TableCell>
        <TableCell align="center">{alert.Time}</TableCell>
        <TableCell align="center">{alert.Vr}</TableCell>
        <TableCell align="center">{alert.Vy}</TableCell>
        <TableCell align="center">{alert.Vb}</TableCell>
        <TableCell align="center">{alert.Vavg}</TableCell>
        <TableCell align="center">{alert.Ir}</TableCell>
        <TableCell align="center">{alert.Iy}</TableCell>
        <TableCell align="center">{alert.Ib}</TableCell>
        <TableCell align="center">{alert.Iavg}</TableCell>
        <TableCell align="center">{alert.Imax}</TableCell>
        <TableCell align="center">{alert.Fr}</TableCell>
        <TableCell align="center">{alert.THDvr}</TableCell>
        <TableCell align="center">{alert.THDvy}</TableCell>
        <TableCell align="center">{alert.THDvb}</TableCell>
        <TableCell align="center">{alert.THDir}</TableCell>
        <TableCell align="center">{alert.THDiy}</TableCell>
        <TableCell align="center">{alert.THDib}</TableCell>
        <TableCell align="center">{alert.Vry}</TableCell>
        <TableCell align="center">{alert.Vyb}</TableCell>
        <TableCell align="center">{alert.Vbr}</TableCell>
        <TableCell align="center">{alert.Pf}</TableCell>
        <TableCell align="center">{alert.kW}</TableCell>
        <TableCell align="center">{alert.kVA}</TableCell>
        <TableCell align="center">{alert.kWh}</TableCell>
        <TableCell align="center">{alert.KVAR}</TableCell>
        <TableCell align="center">{alert.KVAh}</TableCell>
      </TableRow>
    ));
  };

  // Table footer row that displays the "No More Data Available" message
  const renderTableFooter = () => {
    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) return null; // Don't show footer if there's no data

    return (
      <tr ref={ref}>
        <td colSpan="30" style={{ textAlign: "center" }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            !hasNextPage && "No More Data Available" // Only show this message when all data is fetched
          )}
        </td>
      </tr>
    );
  };

  return (
    <Box sx={{  }}>
      <div style={{padding: "0px 5px"}}>
        <div id="historydata-right">
          <div id="historydata-right-2">
            <div id="historydata-right-21">
              <label>
                Date Range:
                <Space direction="vertical" size={12}>
                  <RangePicker
                    showTime={{
                      format: "HH:mm",
                      disabledTime,
                    }}
                    format="YYYY-MM-DD HH:mm"
                    size="medium"
                    disabledDate={disabledDate}
                    onChange={handleRangePickerChange}
                    value={
                      fromDate && toDate
                        ? [
                            dayjs(fromDate, "YYYY-MM-DD HH:mm"),
                            dayjs(toDate, "YYYY-MM-DD HH:mm"),
                          ]
                        : null
                    }
                  />
                </Space>
              </label>

              <MultiStationpoints
                onStationPointChange={handleStationPointChange}
              />

              <Button
                type="primary"
                icon={
                  loading ? (
                    <LoadingOutlined style={{ color: "#252B3F" }} />
                  ) : (
                    <FaFileCsv style={{ color: "#252B3F" }} />
                  )
                }
                onClick={downloadCSV}
              ></Button>
            </div>
          </div>
        </div>
      </div>

      <Box
        sx={{
          width: "95vw",
          px: 2,
        }}
      >
        <CommonTable height={500} columns={columns}>
          {renderTableBody()}
          {renderTableFooter()}
        </CommonTable>
      </Box>
    </Box>
  );
};

export default OperatorAlertstable;
