import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./ScoreCard.css";
import patternLabLogo from "../../../Images/logo1.svg";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getScoreCardData } from "../../../api/services/reports/reports.service";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6E6E6",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderRight: "1px solid #D9D9D9",
  "&:last-child": {
    borderRight: "none",
  },
}));

const arrowDownIcon = () => (
  <SouthOutlinedIcon
    fontSize="large"
    sx={{
      textAlign: "center",
      color: "#f2f1f1",
    }}
  />
);

const ScoreCard = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const isAfterFirstWeek = moment().isAfter(moment().startOf('month').add(6, 'days'));

  // Get the current month in "YYYY-MM" format
  const currentMonth = moment().format("YYYY-MM");
  // Get last month in "YYYY-MM" format
  const lastMonth = moment().subtract(1, 'month').format("YYYY-MM");

  const { register, setValue, watch } = useForm({
    defaultValues: {
      month: isAfterFirstWeek ? currentMonth : lastMonth,
    },
  });

  const [weeklyReportData, setWeeklyReportData] = useState([]);

  const [totalWeekData, setTotalWeekData] = useState([]);

  const [efficiencyData, setEfficiencyData] = useState({});

  const tableHeaderData = [
    { id: "seriel number", label: t("scoreCard.slNo"), minWidth: 30 },
    { id: "lossType", label: t("scoreCard.lossType"), minWidth: 170 },
    { id: "week01", label: t("scoreCard.week01"), minWidth: 170 },
    { id: "week02", label: t("scoreCard.week02"), minWidth: 170 },
    { id: "week03", label: t("scoreCard.week03"), minWidth: 170 },
    { id: "week04", label: t("scoreCard.week04"), minWidth: 170 },
    {
      id: "currentEfficiency",
      label: t("scoreCard.currentEfficiency"),
      minWidth: 170,
    },
    {
      id: "targetEfficiency",
      label: t("scoreCard.targetEfficiency"),
      minWidth: 170,
    },
    { id: "grade", label: t("scoreCard.grade"), minWidth: 170 },
  ];

  const paperRef = useRef();

  const downloadPDF = () => {
    const element = paperRef.current;

    html2canvas(element, {
      scale: 2.25, // Adjust the scale for higher resolution
      useCORS: true, // Helps with cross-origin content
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1);

      // Create a jsPDF instance with Letter size (8.5 x 11 inches)
      const pdf = new jsPDF("portrait", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      // pdf.internal.scaleFactor = 2.25;

      // Get the canvas width and height
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the aspect ratio to fit the content into the PDF page size
      const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

      // Calculate the image size in the PDF, keeping the aspect ratio intact
      // const imgWidth = canvasWidth * ratio;
      const imgHeight = canvasHeight * ratio;

      // Add the image to the PDF
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, imgHeight);

      // Save the PDF
      pdf.save(`ScoreCard - ${moment(month).format("MMMM_YYYY")}.pdf`);
    });
  };

  const { month } = watch();

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["scoreCard", month],
    queryFn: () => getScoreCardData(month),
    staleTime: 0,
    retry: 0,
  });

  useEffect(() => {
    if (data && isSuccess) {
      console.log(data);
      setWeeklyReportData(data.data.week_report_data);
      setTotalWeekData(data.data.total_report);
      setEfficiencyData(data.data.efficiency_data);
    }
  }, [isSuccess, month, data]);

  const weeks = [1, 2, 3, 4];

  const gradeImg = {
    A: "media/gradeA.png",
    B: "media/gradeB.png",
    C: "media/gradeC.png",
    D: "media/gradeD.png",
    F: "media/gradeE.png",
  };

  const gradeBgColor = {
    A: "#9FF5A9",
    B: "#D5F1AF",
    C: "#F8F0CC",
    D: "#FAE5D1",
    F: "#F1CECF",
  };

  return (
    <div className="main_container">
      <Paper
        sx={{
          p: 1,
          mb: 2,
          gap: 5,
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        <Button
          onClick={downloadPDF}
          sx={{ textTransform: "none", fontWeight: 600 }}
          variant="contained"
          color="none"
          endIcon={<PictureAsPdfIcon />}
        >
          {t("common.exportPDF")}
        </Button>

        <div>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>
          <input
            {...register("month")}
            style={{ height: "30px" }}
            type="month"
            ref={monthInputRef}
            defaultValue={isAfterFirstWeek ? currentMonth : lastMonth} // Default value logic
            max={isAfterFirstWeek?  currentMonth : lastMonth} // Maximum month is the current month
            onClick={() => monthInputRef.current.showPicker()}
            onChange={(e) => setValue("month", e.target.value)}
          />
        </div>
      </Paper>
      {isError ? (
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          An error occurred while fetching the data.
        </div>
      ) : (
        <>
          <Paper ref={paperRef} sx={{ pb: 3 }}>
            <div className="banner-container">
              <Box
                sx={{ display: "flex", justifyContent: "space-between", m: 5 }}
              >
                <img style={{height:'80px', width:'100px'}} src={patternLabLogo} alt="logo" />
                <img style={{height:'80px', width:'100px'}} src={patternLabLogo} alt="logo" />
              </Box>
            </div>

            <div className="content-container">
              <div>
                <Typography className="score-card-title">
                  {t("scoreCard.plantScoreCard")}
                </Typography>
                <Typography className="score-card-description">
                  {moment(month).format("MMMM - YYYY")}
                </Typography>
                <Typography
                  className="score-card-description"
                  dangerouslySetInnerHTML={{
                    __html: t("scoreCard.scoreCardDescription"),
                  }}
                />
              </div>
            </div>

            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 100,
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <>
                <div className="table-container">
                  <Typography sx={{ fontWeight: 900, mb: 1 }}>
                    {t("scoreCard.keyAchievements")}
                  </Typography>

                  {weeklyReportData.length > 0 && (
                    <TableContainer>
                      <Table sx={{}} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {tableHeaderData.map((column) => (
                              <StyledTableCell
                                key={column.id}
                                align="center"
                                style={{ fontWeight: "bold" }}
                              >
                                {column.label}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell></TableCell>

                            <TableCell
                              align="left"
                              sx={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              {t("scoreCard.energyEfficiency")}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor:
                                  gradeBgColor[efficiencyData?.grade],
                              }}
                            >
                              {/* {efficiencyData?.current_efficiency || "--"} % */}
                              {efficiencyData?.current_efficiency ? `${efficiencyData.current_efficiency} %` : "--"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor:
                                  gradeBgColor[efficiencyData?.grade],
                              }}
                            >
                              {/* {efficiencyData?.target_efficiency || "--"} % */}
                              {efficiencyData?.target_efficiency ? `${efficiencyData.target_efficiency} %` : "--"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor:
                                  gradeBgColor[efficiencyData?.grade],
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 2,
                                  }}
                                >
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {efficiencyData?.grade}
                                  </Typography>

                                  <img
                                    src={gradeImg[efficiencyData?.grade]}
                                    width={"50px"}
                                    height={"50px"}
                                    alt="Grade Icon"
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>

                          {weeklyReportData.map((weekData, index) => {
                            const lossType = Object.keys(weekData)[0];
                            const lossValues = weekData[lossType];

                            return (
                              <TableRow key={index}>
                                <StyledTableCell align="center">
                                  {index + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {lossType}
                                </StyledTableCell>
                                {weeks.map((week) => {
                                  const obj =
                                    lossValues.find(
                                      (item) => item.week === week
                                    ) || {};

                                  return (
                                    <StyledTableCell key={week} align="center">
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          gap: 0.5,
                                        }}
                                      >
                                        <Typography>
                                          {/* {obj?.value || "--"} % */}
                                          {obj?.value ? `${obj.value} %` : "--"}
                                        </Typography>

                                        {obj?.value && (
                                          <>
                                            {obj?.arrow_flag ? (
                                              <ArrowDropDownOutlinedIcon
                                                sx={{ color: "#36A642" }}
                                                fontSize="large"
                                              />
                                            ) : (
                                              <ArrowDropUpOutlinedIcon
                                                sx={{ color: "#F03840" }}
                                                fontSize="large"
                                              />
                                            )}
                                          </>
                                        )}
                                      </Box>
                                    </StyledTableCell>
                                  );
                                })}
                                {Array.from({ length: 3 }).map((item) => (
                                  <StyledTableCell key={item} align="center">
                                    --
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            );
                          })}

                          <TableRow>
                            <TableCell></TableCell>

                            <StyledTableCell align="left">
                              <Typography
                                sx={{ fontWeight: "bold", fontSize: "20px" }}
                              >
                                {t("scoreCard.totalLoss")}
                              </Typography>
                            </StyledTableCell>

                            {totalWeekData.length > 0 &&
                              totalWeekData.map((item) => (
                                <StyledTableCell key={item.week} align="center">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Typography>
                                      {/* {item?.value || "--"} % */}
                                      {item?.value ? `${item.value} %` : "--"}
                                    </Typography>

                                    {item?.value && (
                                      <>
                                        {item?.arrow_flag ? (
                                          <ArrowDropDownOutlinedIcon
                                            sx={{ color: "#36A642" }}
                                            fontSize="large"
                                          />
                                        ) : (
                                          <ArrowDropUpOutlinedIcon
                                            sx={{ color: "#F03840" }}
                                            fontSize="large"
                                          />
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </StyledTableCell>
                              ))}

                            {Array.from({
                              length: 7 - totalWeekData.length,
                            }).map((item) => (
                              <StyledTableCell key={item} align="center">
                                --
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </>
            )}

            <div className="suggestion-container">
              <Typography className="report-header">
                {t("common.howToUseThisReportQuestion")}
              </Typography>

              <Box sx={{ pl: 7, pr: 10, pt: 5, display: "flex" }}>
                <Box sx={{ width: "50%" }}>
                  <Box sx={{ mb: 10 }}>
                    <Typography variant="body1" className="question">
                      {t("common.whyIsThisImportanQuestion")}
                    </Typography>
                    <Typography variant="body1" className="answer">
                      {t("scoreCard.whyIsThisImportantAnswer")}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 10 }}>
                    <Typography variant="body1" className="question">
                      {t("common.WhatShouldYouDoQuestion")}
                    </Typography>
                    <Typography variant="body1" className="answer">
                      {t("scoreCard.whatShouldYouDoAnswer")}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 10 }}>
                    <Typography variant="body1" className="question">
                      {t("common.howWillYouImproveQuestion")}
                    </Typography>
                    <Typography variant="body1" className="answer">
                      {t("scoreCard.howWillYouImproveAnswer")}
                    </Typography>
                  </Box>
                </Box>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: "#F2F1F1",
                    borderWidth: "1px",
                  }}
                />
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Typography textAlign={"center"} className="question">
                    {t("scoreCard.pathToResolve")}
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      p: 5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.goTo")}
                      </span>
                      <div className="box" style={{ alignSelf: "start" }}>
                        {t("scoreCard.homeDashboard")}
                      </div>

                      {arrowDownIcon()}

                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.select")}
                      </span>
                      <div className="box" style={{ alignSelf: "start" }}>
                        {t("scoreCard.criticalAlerts")}
                      </div>

                      {arrowDownIcon()}

                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.resolve")}
                      </span>
                      <div className="box" style={{ textAlign: "start" }}>
                        {t("scoreCard.resolve1Points", {
                          returnObjects: true,
                        }).map((point) => (
                          <li style={{ textAlign: "start" }}>{point}</li>
                        ))}
                      </div>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.goTo")}
                      </span>
                      <div className="box">{t("scoreCard.homeDashboard")}</div>

                      {arrowDownIcon()}

                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.select")}
                      </span>
                      <div className="box">{t("scoreCard.revenueLosses")}</div>

                      {arrowDownIcon()}

                      <span style={{ alignSelf: "start" }}>
                        {t("scoreCard.resolve")}
                      </span>
                      <div className="box" style={{ textAlign: "start" }}>
                        {t("scoreCard.resolve2Points", {
                          returnObjects: true,
                        }).map((point) => (
                          <li style={{ textAlign: "start" }}>{point}</li>
                        ))}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ pl: 7, pr: 10 }}>
                <Typography className="question">
                  {t("scoreCard.gradingSystem")}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {t("scoreCard.gradingSystemList", {
                    returnObjects: true,
                  }).map((gradeItem) => (
                    <Box key={gradeItem.grade}>
                      <Box
                        sx={{
                          textAlign: "center",
                          height: "100px",
                          width: "150px",
                          border: `1px solid ${gradeItem.borderColor}`,
                          backgroundColor: gradeItem.color,
                          p: 2,
                        }}
                      >
                        <Typography textAlign={"center"} className="question">
                          {gradeItem.grade}
                        </Typography>
                        <Typography textAlign={"center"} className="answer">
                          {gradeItem.description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default ScoreCard;
