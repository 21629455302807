import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LVform.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select } from "antd";
const LVform = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [form] = Form.useForm();
    const [parentNames, setParentNames] = useState([]);

    const initialFormData = {
        date: getCurrentDate(),
        lvId: '',
        name: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        connectionloadinva: '',
        thresholdload: '',
    };

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching incomer names', error);
        }
    };

    // Fetch incomer names on component mount
    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleSubmit = async (values) => {
        try {
            const dataToSend = {
                ...values,
                connectionloadinva: Number(values.connectionloadinva),
                thresholdload: Number(values.thresholdload)
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/LVforms`, dataToSend);

            toast.success('LV form added successfully');
            form.resetFields();
            fetchParentNames();
        } catch (error) {
            console.error("Error submitting data", error);
            toast.error("Error adding LV Form");
        }
    };

    return (
        <div id="masterforms-total">
        <div id="masterforms-right">
          <div id="masterforms-right-2">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialFormData}
              onFinish={handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3> {Masterformsheadingsss.LVHeading}</h3>
                <Button
                  type="default"
                  onClick={onViewData}
                  style={{
                    backgroundColor: "#252B3F", // Custom background color
                    borderColor: "#252B3F",
                    color: "#fff", // Text color
                  }}
                >
                  View Data
                </Button>
              </div>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Date" name="date">
                    <Input type="date" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Parent Name" name="parentname">
                    <Select id="ant-input-style" placeholder="Select Parent Name">
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Name" name="name">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
  
                <Col span={12}>
                  <Form.Item label="Meter Make" name="metermake">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Meter S. No." name="meterslno">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Phase" name="phase">
                    <Select id="ant-input-style" placeholder="Select Phase">
                      <Select.Option value="">Select Phase</Select.Option>
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                      <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                      <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Connection Load (kVA)"
                    name="connectionloadinva"
                  >
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Threshold Load" name="thresholdload">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>
  
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#252B3F", // Custom background color
                    borderColor: "#252B3F",
                    color: "#fff", // Text color
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
};

export default LVform;
