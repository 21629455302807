import { useState, React } from "react";
import Status from "./Status";
import Analog from "./Analog";
import Accumulator from "./Accumulator";
import Operatorsidebar from "../Sidebar/Sidebar";
import { Operatorheadingss } from "../Data";
import "./Sectionpoints.css";
import Stationpoints from "../../Commonfns/Stationpoints";
import CommonTabs from "../../Common/CommonTabs/CommonTabs";
const Sectionpoints = () => {
  const [section, setSection] = useState("");

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const tabs = [
    {
      name: "Status",
      label: "status",
      component: <Status selectedSection={section} />,
    },
    {
      name: "Data Logs",
      label: "data-logs",
      component: <Analog selectedSection={section} />,
    },
    {
      name: "Accumulator",
      label: "accumulator",
      component: <Accumulator selectedSection={section} />,
    },
  ];

  return (
    <div >
      <div id="sectionpoints-filter">
        <label>
          <Stationpoints
            showAll={true}
            onStationPointChange={handleStationPointChange}
          />
        </label>
      </div>
      <div>
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    </div>
  );
};

export default Sectionpoints;
