// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#todview-total{
    background-color: #F3F6FE;
    height: auto !important;
}

#todview-right-2{
    padding: 0px 0px 5px 0px;
    #todview-right-21{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 75px;
        width: 100%;
        background-color: white;
        border: 1px solid #DFE6FA;
        border-radius: 10px;
        label{
            margin-right: 20px;
            display: flex;
            flex-direction: column;
        }
       
    }
    #todview-right-3{
        table{
            margin-top: 1vh;
            background-color: white;
            width: 100%;
            height: 500px;
            text-align: center;
            overflow-y: scroll;
            display: block;
            border: 1px solid #DFE6FA;
        }

        /* Adjust height dynamically for different screen sizes */

        th, td {
  
            width: calc(91vw / 5); /* Adjust this to fit your column count */
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/TODview/TODview.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB;QACI,aAAa;QACb,mBAAmB;QACnB,yBAAyB;QACzB,YAAY;QACZ,WAAW;QACX,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB;YACI,kBAAkB;YAClB,aAAa;YACb,sBAAsB;QAC1B;;IAEJ;IACA;QACI;YACI,eAAe;YACf,uBAAuB;YACvB,WAAW;YACX,aAAa;YACb,kBAAkB;YAClB,kBAAkB;YAClB,cAAc;YACd,yBAAyB;QAC7B;;QAEA,yDAAyD;;QAEzD;;YAEI,qBAAqB,EAAE,yCAAyC;QACpE;IACJ;AACJ","sourcesContent":["#todview-total{\n    background-color: #F3F6FE;\n    height: auto !important;\n}\n\n#todview-right-2{\n    padding: 0px 0px 5px 0px;\n    #todview-right-21{\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        height: 75px;\n        width: 100%;\n        background-color: white;\n        border: 1px solid #DFE6FA;\n        border-radius: 10px;\n        label{\n            margin-right: 20px;\n            display: flex;\n            flex-direction: column;\n        }\n       \n    }\n    #todview-right-3{\n        table{\n            margin-top: 1vh;\n            background-color: white;\n            width: 100%;\n            height: 500px;\n            text-align: center;\n            overflow-y: scroll;\n            display: block;\n            border: 1px solid #DFE6FA;\n        }\n\n        /* Adjust height dynamically for different screen sizes */\n\n        th, td {\n  \n            width: calc(91vw / 5); /* Adjust this to fit your column count */\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
