import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Mastertransformerfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const Mastertransformerfetch = ({ onFormButtonClick }) => {
  const [masterTransformers, setMasterTransformers] = useState([]);
  const [masterTransformerEditData, setMasterTransformerEditData] =
    useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-hvincomerNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  const fetchMasterTransformers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers`
      );
      setMasterTransformers(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchMasterTransformers();
    fetchParentNames();
  }, []);

  // Handle edit click
  const handleEditClick = (section) => {
    setMasterTransformerEditData(section);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setMasterTransformerEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setMasterTransformerEditData({
        ...masterTransformerEditData,
        [name]: value,
      });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setMasterTransformerEditData({
        ...masterTransformerEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...masterTransformerEditData,
        primaryvoltage: Number(masterTransformerEditData.primaryvoltage),
        secondaryvoltage: Number(masterTransformerEditData.secondaryvoltage),
        kvarating: Number(masterTransformerEditData.kvarating),
        fullloadcurrent: Number(masterTransformerEditData.fullloadcurrent),
        impedence: Number(masterTransformerEditData.impedence),
        idleloadcurrent: Number(masterTransformerEditData.idleloadcurrent),
        slno: Number(masterTransformerEditData.slno),
        dimentions: Number(masterTransformerEditData.dimentions),
        weight: Number(masterTransformerEditData.weight),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers/${masterTransformerEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Master Transformer updated successfully");
      fetchMasterTransformers();
      setMasterTransformerEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="mastertransformerfetch-total">
      <div id="mastertransformerfetch-right">
        <div id="mastertransformerfetch-right-2">
          <div id="mastertransformerfetch-right-21">
            <label>{Masterformsheadingsss.TransformerHeading}</label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Parent Name</th>
                <th>Transformer Name</th>
                <th>Phase</th>
                <th>Primary Voltage</th>
                <th>Secondary Voltage</th>
                <th>kVA Rating</th>
                <th>Full Load Current</th>
                <th>Impedence</th>
                <th>Idle Load Current</th>
                <th>Vector Type</th>
                <th>Coolant Type</th>
                <th>Make</th>
                <th>Serial No.</th>
                <th>IEC Standard</th>
                <th>ISO Standard</th>
                <th>Dimensions</th>
                <th>Weight</th>
                <th>Proposed Periodicity of Maintenance</th>
                <th>Previous Maintenance Done Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {masterTransformers.map((masterTransformer) => (
                <tr key={masterTransformer._id}>
                  <td>{masterTransformer.date}</td>
                  <td>{masterTransformer.name}</td>
                  <td>{masterTransformer.parentname}</td>
                  <td>{masterTransformer.transformername}</td>
                  <td>{masterTransformer.phase}</td>
                  <td>{masterTransformer.primaryvoltage}</td>
                  <td>{masterTransformer.secondaryvoltage}</td>
                  <td>{masterTransformer.kvarating}</td>
                  <td>{masterTransformer.fullloadcurrent}</td>
                  <td>{masterTransformer.impedence}</td>
                  <td>{masterTransformer.idleloadcurrent}</td>
                  <td>{masterTransformer.vectortype}</td>
                  <td>{masterTransformer.coolanttype}</td>
                  <td>{masterTransformer.make}</td>
                  <td>{masterTransformer.slno}</td>
                  <td>{masterTransformer.iecstandard}</td>
                  <td>{masterTransformer.isostandard}</td>
                  <td>{masterTransformer.dimentions}</td>
                  <td>{masterTransformer.weight}</td>
                  <td>{masterTransformer.proposedperiodicityofmaintenance}</td>
                  <td>{masterTransformer.previousmaintenancedonedate}</td>
                  <td>
                    <button onClick={() => handleEditClick(masterTransformer)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            title="Edit Transformer"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" },
            }}
          >
            {masterTransformerEditData && (
              <Form layout="vertical">
                {/* Date and Parent Name */}
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input
                      name="date"
                      value={masterTransformerEditData.date}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Parent Name">
                    <Select
                      value={masterTransformerEditData.parentname}
                      onChange={(value) =>
                        setMasterTransformerEditData({
                          ...masterTransformerEditData,
                          parentname: value,
                        })
                      }
                    >
                      <Select.Option value="">Select Parent Name</Select.Option>
                      {parentNames.map((name) => (
                        <Select.Option key={name} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* Name and Transformer Name */}
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="name"
                      value={masterTransformerEditData.name}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Transformer Name">
                    <Input
                      name="transformername"
                      value={masterTransformerEditData.transformername}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Phase and Primary Voltage */}
                <div className="input-row">
                  <Form.Item label="Phase">
                    <Select
                      value={masterTransformerEditData.phase}
                      onChange={(value) =>
                        setMasterTransformerEditData({
                          ...masterTransformerEditData,
                          phase: value,
                        })
                      }
                    >
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1-Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1-Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1-Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Primary Voltage">
                    <Input
                      type="number"
                      name="primaryvoltage"
                      value={masterTransformerEditData.primaryvoltage}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Secondary Voltage and KVA Rating */}
                <div className="input-row">
                  <Form.Item label="Secondary Voltage">
                    <Input
                      type="number"
                      name="secondaryvoltage"
                      value={masterTransformerEditData.secondaryvoltage}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="kVA Rating">
                    <Input
                      type="number"
                      name="kvarating"
                      value={masterTransformerEditData.kvarating}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Full Load Current and Impedence */}
                <div className="input-row">
                  <Form.Item label="Full Load Current">
                    <Input
                      type="number"
                      name="fullloadcurrent"
                      value={masterTransformerEditData.fullloadcurrent}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Impedence">
                    <Input
                      type="number"
                      name="impedence"
                      value={masterTransformerEditData.impedence}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                
                {/* Vector Type and idle load */}
              
                <div className="input-row">
                <Form.Item label="Idle Load Current">
                    <Input
                      name="idleloadcurrent"
                      value={masterTransformerEditData.idleloadcurrent}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Vector Type">
                    <Input
                      name="vectortype"
                      value={masterTransformerEditData.vectortype}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                
                </div>

                {/* SL No and coolent type */}
               
                <div className="input-row">
                <Form.Item label="Coolant Type">
                    <Input
                      name="coolanttype"
                      value={masterTransformerEditData.coolanttype}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Serial No.">
                    <Input
                      type="number"
                      name="slno"
                      value={masterTransformerEditData.slno}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                
                </div>

                {/* ISO Standard and IEC standard */}
                <div className="input-row">
                <Form.Item label="IEC Standard">
                    <Input
                      name="iecstandard"
                      value={masterTransformerEditData.iecstandard}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="ISO Standard">
                    <Input
                      name="isostandard"
                      value={masterTransformerEditData.isostandard}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                
                </div>

                {/* Weight and Dinemstion */}
                <div className="input-row">
                <Form.Item label="Dimensions">
                    <Input
                      type="number"
                      name="dimentions"
                      value={masterTransformerEditData.dimentions}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Weight">
                    <Input
                      type="number"
                      name="weight"
                      value={masterTransformerEditData.weight}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Previous Maintenance Done Date & praposed */}
                <div className="input-row">
                <Form.Item label="Proposed Periodicity of Maintenance">
                    <Input
                      name="proposedperiodicityofmaintenance"
                      value={
                        masterTransformerEditData.proposedperiodicityofmaintenance
                      }
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Previous Maintenance Done Date">
                    <DatePicker
                      disabledDate={disableDates}
                      value={
                        masterTransformerEditData.previousmaintenancedonedate
                          ? dayjs(
                              masterTransformerEditData.previousmaintenancedonedate
                            )
                          : null
                      }
                      onChange={(date) =>
                        handleEditChange(date, "previousmaintenancedonedate")
                      }
                      format={dateFormat}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
          ;
        </div>
      </div>
    </div>
  );
};

export default Mastertransformerfetch;
