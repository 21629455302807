import React, { useEffect, useState } from "react";
import axios from "axios";
import * as echarts from "echarts";
import "./DashPF.css";

const DashPF = ({ selectedMonth }) => {
  const [powerFactorData, setPowerFactorData] = useState(null); // Set initial state to null

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/powerfactor`,
        {
          params: {
            month: selectedMonth || new Date().toISOString().slice(0, 7),
          },
        }
      );
      setPowerFactorData(response.data);
    } catch (error) {
      console.error("Error Fetching PF Data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth]);

  useEffect(() => {
    if (powerFactorData && powerFactorData.date && powerFactorData.values) {
      const chartContainer = document.getElementById("dashpf-barchart"); // Use document.getElementById
      if (chartContainer) {
        const myChart = echarts.init(chartContainer);
        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // legend: {
          //   data: ["Power Factor"],
          // },
          grid: {
            left: "1%",
            right: "1%",
            bottom: "1%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: powerFactorData.date,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 45, // Rotate labels for better readability
            },
          },
          yAxis: {
            type: "value",
            nameLocation: 'middle',
            min: 0,
            max: 1,
            interval: 0.2,
            axisLabel: {
              formatter: (value) => value.toFixed(2),
            },
          },
          series: [
            {
              name: "Power Factor",
              type: "bar",
              barWidth: "40%",
              data: powerFactorData.values,
              itemStyle: {
                color: "#7E95DF",
              },
            },
          ],
        };

        myChart.setOption(option);

        const resizeChart = () => {
          myChart.resize();
        };

        window.addEventListener("resize", resizeChart);

        return () => {
          window.removeEventListener("resize", resizeChart);
          myChart.dispose();
        };
      } else {
        console.error("Chart container not found.");
      }
    }
  }, [powerFactorData]);

  const isDataAvailable =
    powerFactorData &&
    powerFactorData.date?.length > 0 &&
    powerFactorData.values?.length > 0;

  return (
    <div id="dashpf-total">
      <span>Power Factor (PF)</span>
      {!isDataAvailable ? (
        <p className="no-data" style={{ textAlign: "center" }}>
          No Data Available
        </p>
      ) : (
        <div id="dashpf-barchart" />
      )}
    </div>
  );
};

export default DashPF;
