// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mastergeneratorfetch-total {
    background-color: #F3F6FE;
}

#mastergeneratorfetch-right-2 {
    margin-top: 15px;
    padding: 0px 10px 5px 10px;

    #mastergeneratorfetch-right-21 {
        display: flex;
        align-items: center;
        height: 75px;
        width: 100%;
        background-color: white;
        border: 1px solid #DFE6FA;
        border-radius: 10px;
        justify-content: space-between;
        label {
            margin-left: 10px;
            align-items: center;
            margin-right: 20px;
        }

        button {
            background-color: #252B3F;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 8px 30px;
            cursor: pointer;
        }
    }

    table {
        margin-top: 2vh;
        background-color: white;
        width: 93vw;
        height: 500px;
        overflow-y: scroll;
        /* overflow-x: scroll; */
        display: block;

        th, td {
  
            width: calc(93vw / 9); 
        }

        button {
            background-color: #252B3F;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 5px 20px;
            cursor: pointer;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/MasterForms/Generators/Generatorsfetch.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;;IAE1B;QACI,aAAa;QACb,mBAAmB;QACnB,YAAY;QACZ,WAAW;QACX,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB,8BAA8B;QAC9B;YACI,iBAAiB;YACjB,mBAAmB;YACnB,kBAAkB;QACtB;;QAEA;YACI,yBAAyB;YACzB,YAAY;YACZ,YAAY;YACZ,kBAAkB;YAClB,iBAAiB;YACjB,eAAe;QACnB;IACJ;;IAEA;QACI,eAAe;QACf,uBAAuB;QACvB,WAAW;QACX,aAAa;QACb,kBAAkB;QAClB,wBAAwB;QACxB,cAAc;;QAEd;;YAEI,qBAAqB;QACzB;;QAEA;YACI,yBAAyB;YACzB,YAAY;YACZ,YAAY;YACZ,kBAAkB;YAClB,iBAAiB;YACjB,eAAe;QACnB;IACJ;AACJ","sourcesContent":["#mastergeneratorfetch-total {\n    background-color: #F3F6FE;\n}\n\n#mastergeneratorfetch-right-2 {\n    margin-top: 15px;\n    padding: 0px 10px 5px 10px;\n\n    #mastergeneratorfetch-right-21 {\n        display: flex;\n        align-items: center;\n        height: 75px;\n        width: 100%;\n        background-color: white;\n        border: 1px solid #DFE6FA;\n        border-radius: 10px;\n        justify-content: space-between;\n        label {\n            margin-left: 10px;\n            align-items: center;\n            margin-right: 20px;\n        }\n\n        button {\n            background-color: #252B3F;\n            color: white;\n            border: none;\n            border-radius: 4px;\n            padding: 8px 30px;\n            cursor: pointer;\n        }\n    }\n\n    table {\n        margin-top: 2vh;\n        background-color: white;\n        width: 93vw;\n        height: 500px;\n        overflow-y: scroll;\n        /* overflow-x: scroll; */\n        display: block;\n\n        th, td {\n  \n            width: calc(93vw / 9); \n        }\n\n        button {\n            background-color: #252B3F;\n            color: white;\n            border: none;\n            border-radius: 4px;\n            padding: 5px 20px;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
