import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Harmonic_filtersfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const Harmonicfiltersfetch = ({ onFormButtonClick }) => {
    const [harmonicFilters, setHarmonicFilters] = useState([]);
    const [harmonicFilterEditData, setHarmonicFilterEditData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [parentNames, setParentNames] = useState([]);

    const disableDates = (current) => {
        // Disable today and future dates
        return current && current.isAfter(dayjs().endOf("day"));
      };
    

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    const fetchHarmonicFilters = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters`); // Adjust endpoint URL
            setHarmonicFilters(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
        fetchHarmonicFilters();
    }, []);


    const handleEditClick = (harmonicFilter) => {
        setHarmonicFilterEditData(harmonicFilter);
        setIsModalVisible(true);
    }

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setHarmonicFilterEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setHarmonicFilterEditData({ ...harmonicFilterEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setHarmonicFilterEditData({
        ...harmonicFilterEditData,
        [field]: formattedDate,
      });
    }
  };

    const handleEditSubmit = async () => {
        try {

            const dataToSend = {
                ...harmonicFilterEditData,
                meterslno: String(harmonicFilterEditData.meterslno),
                capacityinva: Number(harmonicFilterEditData.capacityinva),
                harmonicfrequencyrange: Number(harmonicFilterEditData.harmonicfrequencyrange),
                attenuationperformance: Number(harmonicFilterEditData.attenuationperformance),
                ratedvoltage: Number(harmonicFilterEditData.ratedvoltage),
                ratedcurrent: Number(harmonicFilterEditData.ratedcurrent),
                operationfrequency: Number(harmonicFilterEditData.operationfrequency),
                efficiency: Number(harmonicFilterEditData.efficiency),
            };

            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters/${harmonicFilterEditData._id}`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success("Harmonic Filter updated successfully");
            fetchHarmonicFilters();
            setHarmonicFilterEditData(null);
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='harmonicfiltersfetch-total'>
            <div id='harmonicfiltersfetch-right'>
                <div id='harmonicfiltersfetch-right-2'>
                    <div id='harmonicfiltersfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.HarmonicFilterHeading}</span>

                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Capacity (kVA)</th>
                                <th>Harmonic Frequency Range</th>
                                <th>Attenuation Performance</th>
                                <th>Rated Voltage</th>
                                <th>Rated Current</th>
                                <th>Connection Type</th>
                                <th>Operation Frequency</th>
                                <th>Efficiency</th>
                                <th>Mode Select</th>
                                <th>Overcurrent Protection</th>
                                <th>Overvoltage Protection</th>
                                <th>Cooling Method</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {harmonicFilters.map((harmonicFilter) => (
                                <tr key={harmonicFilter._id}>
                                    <td>{harmonicFilter.date}</td>
                                    <td>{harmonicFilter.parentname}</td>
                                    <td>{harmonicFilter.name}</td>
                                    <td>{harmonicFilter.metermake}</td>
                                    <td>{harmonicFilter.meterslno}</td>
                                    <td>{harmonicFilter.phase}</td>
                                    <td>{harmonicFilter.capacityinva}</td>
                                    <td>{harmonicFilter.harmonicfrequencyrange}</td>
                                    <td>{harmonicFilter.attenuationperformance}</td>
                                    <td>{harmonicFilter.ratedvoltage}</td>
                                    <td>{harmonicFilter.ratedcurrent}</td>
                                    <td>{harmonicFilter.connectiontype}</td>
                                    <td>{harmonicFilter.operationfrequency}</td>
                                    <td>{harmonicFilter.efficiency}</td>
                                    <td>{harmonicFilter.autoselect}</td>
                                    <td>{harmonicFilter.overcurrentprotectionavailable}</td>
                                    <td>{harmonicFilter.overvoltageprotectionavailable}</td>
                                    <td>{harmonicFilter.coolingmethod}</td>
                                    <td>{harmonicFilter.proposedperiodicityofmaintenance}</td>
                                    <td>{harmonicFilter.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(harmonicFilter)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

<Modal
    title="Edit Harmonic Filter"
    visible={isModalVisible}
    onOk={handleEditSubmit}
    onCancel={handleCancel}
    okText="Update"
    cancelText="Cancel"
    okButtonProps={{ style: { backgroundColor: "#252B3F", color: "#fff" } }}
>
    {harmonicFilterEditData && (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Date">
                        <Input
                            name="date"
                            value={harmonicFilterEditData.date}
                            onChange={handleEditChange}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Parent Name">
                        <Select
                            value={harmonicFilterEditData.parentname}
                            onChange={(value) =>
                                setHarmonicFilterEditData({
                                    ...harmonicFilterEditData,
                                    parentname: value,
                                })
                            }
                        >
                            {parentNames.map((name, index) => (
                                <Select.Option key={index} value={name}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Name">
                        <Input
                            name="name"
                            value={harmonicFilterEditData.name}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Meter Make">
                        <Input
                            name="metermake"
                            value={harmonicFilterEditData.metermake}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Meter Serial No.">
                        <Input
                            name="meterslno"
                            value={harmonicFilterEditData.meterslno}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Phase">
                        <Select
                            name="phase"
                            value={harmonicFilterEditData.phase}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'phase', value } })
                            }
                        >
                            <Select.Option value="3-Phase">3-Phase</Select.Option>
                            <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                            <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                            <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Capacity (kVA)">
                        <Input
                            name="capacityinva"
                            type="number"
                            value={harmonicFilterEditData.capacityinva}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Harmonic Frequency Range">
                        <Input
                            name="harmonicfrequencyrange"
                            type="number"
                            value={harmonicFilterEditData.harmonicfrequencyrange}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Attenuation Performance">
                        <Input
                            name="attenuationperformance"
                            type="number"
                            value={harmonicFilterEditData.attenuationperformance}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Rated Voltage">
                        <Input
                            name="ratedvoltage"
                            type="number"
                            value={harmonicFilterEditData.ratedvoltage}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Rated Current">
                        <Input
                            name="ratedcurrent"
                            type="number"
                            value={harmonicFilterEditData.ratedcurrent}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Connection Type">
                        <Select
                            value={harmonicFilterEditData.connectiontype}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'connectiontype', value } })
                            }
                        >
                            <Select.Option value="L-L">L-L</Select.Option>
                            <Select.Option value="L-N">L-N</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Operation Frequency">
                        <Input
                            name="operationfrequency"
                            type="number"
                            value={harmonicFilterEditData.operationfrequency}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Efficiency">
                        <Input
                            name="efficiency"
                            type="number"
                            value={harmonicFilterEditData.efficiency}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Mode Select">
                        <Select
                            value={harmonicFilterEditData.autoselect}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'autoselect', value } })
                            }
                        >
                            <Select.Option value="Auto">Auto</Select.Option>
                            <Select.Option value="Manual">Manual</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Overcurrent Protection">
                        <Select
                            value={harmonicFilterEditData.overcurrentprotectionavailable}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'overcurrentprotectionavailable', value } })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Overvoltage Protection">
                        <Select
                            value={harmonicFilterEditData.overvoltageprotectionavailable}
                            onChange={(value) =>
                                handleEditChange({ target: { name: 'overvoltageprotectionavailable', value } })
                            }
                        >
                            <Select.Option value="Y">Yes</Select.Option>
                            <Select.Option value="N">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Cooling Method">
                        <Input
                            name="coolingmethod"
                            value={harmonicFilterEditData.coolingmethod}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Proposed Periodicity of Maintenance">
                        <Input
                            name="proposedperiodicityofmaintenance"
                            type="number"
                            value={harmonicFilterEditData.proposedperiodicityofmaintenance}
                            onChange={handleEditChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Previous Maintenance Done Date">
                        <DatePicker
                            disabledDate={disableDates}
                            value={
                                harmonicFilterEditData.previousmaintenancedonedate
                                    ? dayjs(harmonicFilterEditData.previousmaintenancedonedate)
                                    : null
                            }
                            onChange={(date) =>
                                setHarmonicFilterEditData({
                                    ...harmonicFilterEditData,
                                    previousmaintenancedonedate: date,
                                })
                            }
                            format={dateFormat}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            </Form>
    )}
</Modal>
                </div>
            </div>
        </div>
    );
};

export default Harmonicfiltersfetch;
