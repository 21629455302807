
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Transformermonitoring.css';
import { useLocation } from 'react-router-dom';
import { formatDateToDDMMYYYY } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const convertToDateInputFormat = (dateString) => {
    const [dd, mm, yyyy] = dateString.split('-');
    return `${yyyy}-${mm}-${dd}`;
};

const Transformermonitoring = ({ onViewData }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const unfilledDate = queryParams.get('date') || '';

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [transformers, setTransformers] = useState([]);
    const [formData, setFormData] = useState({
        vr: '',
        vb: '',
        vy: '',
        transformer: '',
        KWhinitial: '',
        KWhfinal: '',
        KWh: '',
        pf: '',
        oilTemp: '',
        windingTemp: '',
        oilLevelMain: '',
        oilLevelOLTC: '',
        createdby: '',
        Tapposition: '',
        date: unfilledDate ? convertToDateInputFormat(unfilledDate) : getCurrentDate(),
    });

    const [errors, setErrors] = useState({
        KWhinitial: '',
        KWhfinal: '',
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            console.log('Fetched Transformers:', response.data);
            setTransformers(response.data);
        } catch (error) {
            console.error('Error fetching Transformers:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);



    const calculateKWhConsumption = (KWhinitial, KWhfinal) => {
        const initial = parseFloat(KWhinitial);
        const final = parseFloat(KWhfinal);

        if (!isNaN(initial) && !isNaN(final)) {
            return (final - initial).toFixed(2);
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'date') {
            setFormData(prevState => ({
                ...prevState,   
                [name]: value
            }));
            return;
        }
    

        if (name === 'transformer') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
            return;
        }
    

        // Define patterns for each input
        const patterns = {
            vr: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vr
            vb: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vb
            vy: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vy
            KWhinitial: /^\d{0,13}(\.\d{0,2})?$/, // 15 digits, 2 decimals for KWh initial
            KWhfinal: /^\d{0,13}(\.\d{0,2})?$/, // 15 digits, 2 decimals for KWh final
            pf: /^\d{1,2}(\.\d{0,2})?$/, // 4 digits, no decimals for PF
            oilTemp: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Temp
            windingTemp: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Winding Temp
            oilLevelMain: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Level Main
            oilLevelOLTC: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Level OLTC
            Tapposition: /^\d{0,2}(\.\d{0,2})?$/, // 4 digits, 2 decimals for Tap Position
        };

        const pattern = patterns[name];

        if (pattern && pattern.test(value) || value === '') {

            setFormData(prevState => {
                const updatedFormData = {
                    ...prevState,
                    [name]: value
                };

                if (name === 'KWhinitial' || name === 'KWhfinal') {
                    const { KWhinitial, KWhfinal } = updatedFormData;
                    const initial = parseFloat(KWhinitial);
                    const final = parseFloat(KWhfinal);

                    if (!isNaN(initial) && !isNaN(final) && initial > final) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            KWhinitial: 'KWh Initial value cannot be greater than KWh final value.'
                        }));
                    } else {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            KWhinitial: ''
                        }));
                        updatedFormData.KWh = calculateKWhConsumption(KWhinitial, KWhfinal);
                    }
                }

                return updatedFormData;
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any validation error exists in the errors object
        const hasErrors = Object.values(errors).some(errorMessage => errorMessage !== '');

        // If there are any errors, prevent form submission and show an error toast
        if (hasErrors) {
            toast.error('Please fix the errors before submitting');
            return; // Stop form submission if there are any errors
        }

        const formattedDate = formatDateToDDMMYYYY(formData.date);

        const parsedFormData = {
            ...formData,
            date: formattedDate,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`, parsedFormData);
            if (response.data.success) {
                toast.success('Transformer Monitoring data submitted successfully');
                setFormData({
                    vr: '',
                    vb: '',
                    vy: '',
                    transformer: '',
                    KWhinitial: '',
                    KWhfinal: '',
                    KWh: '',
                    pf: '',
                    oilTemp: '',
                    windingTemp: '',
                    oilLevelMain: '',
                    oilLevelOLTC: '',
                    Tapposition: '',
                    createdby: loggedInUser.username,
                    date: unfilledDate ? convertToDateInputFormat(unfilledDate) : getCurrentDate(),
                });
            }
        } catch (error) {
            console.error('Error Adding Transformer monitoring log', error);
            if (error.response && error.response.data.message === 'A log already exists for the date') {
                toast.error('Data already exists for the Selected Date and Transformer, Please select any other Date');
            } else {
                toast.error('Failed to add Transformer monitoring log. Please try again later.');
            }
        }
    };


    const renderInputField = (label, name, type = "text", required = false, disabled = false) => {
        let maxLength;
        let step;

        // Define maxLength and step for each input field
        switch (name) {
            case 'vr':
            case 'vb':
            case 'vy':
                maxLength = 8; // 8 digits for voltage inputs
                step = "0.01"; // Step to allow 2 decimal places
                break;
            case 'KWhinitial':
            case 'KWhfinal':
                maxLength = 15; // 15 digits for KWh inputs
                step = "0.01"; // Step to allow 2 decimal places
                break;
            case 'pf':
                maxLength = 4; // 4 digits for PF
                break;
            case 'oilTemp':
            case 'windingTemp':
            case 'oilLevelMain':
            case 'oilLevelOLTC':
                maxLength = 5; // 5 digits for temperature and oil levels
                step = "0.01"; // Step to allow 2 decimal places
                break;
            case 'Tapposition':
                maxLength = 4; // 4 digits for Tap Position
                step = "0.01"; // Step to allow 2 decimal places
                break;
            default:
                maxLength = 10; // Default for other fields
        }

        const handleKeyPress = (e) => {
            const invalidChars = ["e", "E", "+", "-"];
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        };

        return (
            <label className='input-label'>
                <span>{label} <span id='all-mandetorymark'>*</span></span>
                <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    maxLength={maxLength} // Restrict max length
                    step={step || undefined} // Set step if defined
                    onKeyPress={handleKeyPress}
                    {...(required && { required: true })}
                    {...(disabled && { disabled: true })}
                />
                {errors[name] && <span id='Transformermonitoring-errormessage'>{errors[name]}</span>}
            </label>
        );
    };

    const renderInputFieldwithdate = (label, name, type = "date", required = false) => (
        <label className='input-label'>
            <span>{label} <span id='all-mandetorymark'>*</span></span>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                max={getCurrentDate()}
                {...(required && { required: true })}
            />
        </label>
    );

    return (
        <div id='Transformermonitoring-total'>

            <div id='Transformermonitoring-right'>

                <div id='Transformermonitoring-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='Transformermonitoring-divtop'>

                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='Transformermonitoring-div1'>
                            <label>
                                <span>Transformer <span id='all-mandetorymark'>*</span></span>
                                <select
                                    className="tplan-input"
                                    name="transformer"
                                    value={formData.transformer}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    {transformers.length > 0 ? (
                                        transformers.map((item, index) => (
                                            <option key={index} value={item.name || item}>
                                                {item.name || item}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Transformers Available</option>
                                    )}
                                </select>
                            </label>
                            {renderInputFieldwithdate("Date", "date", "date", true)}
                        </div>

                        <div id='Transformermonitoring-div1' >
                            {renderInputField("Vr", "vr", "number",)}
                            {renderInputField("Vb", "vb", "number", )}
                        </div>
                        <div id='Transformermonitoring-div1'>
                            {renderInputField("Vy", "vy", "number", )}
                            {renderInputField("kWh (initial reading)", "KWhinitial", "number", )}
                        </div>

                        <div id='Transformermonitoring-div1'>
                            {renderInputField("kWh (final reading)", "KWhfinal", "number", )}
                            {renderInputField("kWh Consumption", "KWh", "number",)}
                        </div>

                        <div id='Transformermonitoring-div1'>
                            {renderInputField("PF", "pf", "number",)}
                            {renderInputField("Oil Temp - °C", "oilTemp", "number", true)}
                        </div>

                        <div id='Transformermonitoring-div1'>
                            {renderInputField("Winding Temperature - °C", "windingTemp", "number", true)}
                            {renderInputField("Oil level in main conservator - °C", "oilLevelMain", "number", true)}
                        </div>
                        <div id='Transformermonitoring-div1'>
                            {renderInputField("Oil level in OLTC conservator - °C", "oilLevelOLTC", "number", true)}
                            {renderInputField("Tap Count", "Tapposition", "number", true)}
                        </div>
                        <div  id='Transformermonitoring-save'>
                        <button  type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Transformermonitoring;
