import React, { useEffect, useState } from "react";
import axios from "axios";
import "./REportsRCPE.css";
import { FaFileCsv } from "react-icons/fa6";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { Box, TableCell, TableRow } from "@mui/material";

const energyTableColumns = [
  { id: 1, label: "S. No.", align: "center" },
  { id: 2, label: "Date", align: "center" },
  { id: 3, label: "Run Time", align: "center" },
  { id: 4, label: "Consumption", align: "center" },
  { id: 5, label: "Production", align: "center" },
  { id: 6, label: "Energy Intensity", align: "center" },
];

const ReportRCPE_Report = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/rcpe-reports`,
        {
          params: { fromDate, toDate }, // Send filters to the server
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD"));
      setToDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const downloadCSV = () => {
    setLoading(true);
    try {
      const csvRows = [];
      const headers = [
        "S. No.",
        "Date",
        "KVAh Consumption",
        "Production Count",
        "Energy Intensity",
        "Run Time",
      ];
      csvRows.push(headers.join(","));

      data.forEach((item, index) => {
        const row = [
          index + 1, // S. No.
          moment(item.date).format("YYYY-MM-DD"),
          item.KVAh_consumption || 0,
          item.Production_Count || 0,
          item.Energy_Intensity || 0,
          item.Uptime || 0,
        ];
        csvRows.push(row.join(","));
      });

      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "RCPE_Report.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            Loading...
          </TableCell>
        </TableRow>
      );
    }

    if (data.length === 0) {
      return (
        <TableRow sx={{ height: 500 }}>
          <TableCell colSpan={5} align="center">
            <Empty description={"No data available"} />
          </TableCell>
        </TableRow>
      );
    }

    return data.map((item, index) => (
      <TableRow key={item.date}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{item.date}</TableCell>
        <TableCell align="center">{item.Uptime || 0}</TableCell>
        <TableCell align="center">{item.KVAh_consumption || 0}</TableCell>
        <TableCell align="center">{item.Production_Count || 0}</TableCell>
        <TableCell align="center">{item.Energy_Intensity || 0}</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <div id="reportrcpe-total">
        <div id="reportrcpe-right-21">
          <label>
            Date Range:
            <Space direction="vertical" size={12}>
              <RangePicker
                size="medium"
                disabledDate={disabledDate}
                onChange={handleRangePickerChange}
                value={
                  fromDate && toDate
                    ? [
                        dayjs(fromDate, "YYYY-MM-DD"),
                        dayjs(toDate, "YYYY-MM-DD"),
                      ]
                    : null
                }
              />
            </Space>
          </label>

          <Button
            type="primary"
            icon={
              loading ? (
                <LoadingOutlined style={{ color: "#252B3F" }} />
              ) : (
                <FaFileCsv style={{ color: "#252B3F" }} />
              )
            }
            onClick={downloadCSV}
          />
        </div>
      </div>

      <Box mt={2}>
        <CommonTable
          columns={energyTableColumns}
          height={500}
          isWithPagination={false}
        >
          {renderTableBody()}
        </CommonTable>
      </Box>
    </>
  );
};

export default ReportRCPE_Report;
