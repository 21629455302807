import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import axiosInstance from "../../../api/services/axios";
import { DatePicker, Flex, Space } from "antd";
import dayjs from "dayjs";
import Stationpoints from "../../Commonfns/Stationpoints";
import CommonTable from "../../Common/CommonTable/CommonTable";

//Create TabCell for header with bold

const columns = [
  { id: 1, label: "Section", align: "center" },
  { id: 2, label: "Start Date & Time", align: "center" },
  { id: 3, label: "End Date & Time", align: "center" },
  { id: 4, label: "Max KWh", align: "center" },
  { id: 5, label: "Min KWh", align: "center" },
  { id: 6, label: "Consumption", align: "center" },
];

const CustomTabCell = ({ children }) => (
  <TableCell align="center" style={{ fontWeight: "bold", fontSize: 15 }}>
    {children}
  </TableCell>
);

const LiveConsumption = () => {
  //usefetch query to fetch live consumption data

  const [section, setSection] = useState("");

  const [fromDate, setFromDate] = useState(
    dayjs().startOf("day").format("YYYY-MM-DD HH:mm")
  );
  const [toDate, setToDate] = useState(
    dayjs().endOf("day").format("YYYY-MM-DD HH:mm")
  );

  const { data } = useQuery({
    queryKey: ["live-consumption", section, fromDate, toDate],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/api/calculated-consumption?fromDate=${fromDate}&toDate=${toDate}&stationPoint=${section}`
      );
      return response.data;
    },
  });

  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // Disable hours on the current day
  const disabledTime = (date) => {
    if (date && date.isSame(dayjs(), "day")) {
      return {
        disabledHours: () => [...Array(24).keys()].slice(dayjs().hour() + 1), // Disable future hours
      };
    }
    return {};
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD HH:mm"));
      setToDate(dates[1].format("YYYY-MM-DD HH:mm"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  return (
    <div>
      <Flex justify="end" gap={10} style={{ marginBottom: 10 }}>
        <div>
          <div>Date Range:</div>
          <Space direction="vertical" size={12}>
            <RangePicker
              showTime={{
                format: "HH:mm",
                disabledTime,
              }}
              format="YYYY-MM-DD HH:mm"
              size="medium"
              disabledDate={disabledDate}
              onChange={handleRangePickerChange}
              value={
                fromDate && toDate
                  ? [
                      dayjs(fromDate, "YYYY-MM-DD HH:mm"),
                      dayjs(toDate, "YYYY-MM-DD HH:mm"),
                    ]
                  : null
              }
            />
          </Space>
        </div>
        <Stationpoints
          defaultStationPoint={section}
          showAll={true}
          onStationPointChange={handleStationPointChange}
        />
        {/* <Button
              type="primary"
              icon={
                loading ? (
                  <LoadingOutlined style={{ color: "#252B3F" }} />
                ) : (
                  <FaFileCsv style={{ color: "#252B3F" }} />
                )
              }
              onClick={downloadCSV}
            ></Button> */}
      </Flex>

      <Box>
        <CommonTable isWithPagination={false} columns={columns}>
          {data &&
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{item.stationPoint}</TableCell>
                <TableCell align="center">{item.fromDateTime}</TableCell>
                <TableCell align="center">{item.toDateTime}</TableCell>
                <TableCell align="center">{item.maxKWh}</TableCell>
                <TableCell align="center">{item.minKWh}</TableCell>
                <TableCell align="center">
                  {item.consumption ? item.consumption.toFixed(2) : null}
                </TableCell>
              </TableRow>
            ))}
        </CommonTable>
      </Box>
    </div>
  );
};

export default LiveConsumption;
