import { create } from "zustand";

export const DrawerState = create((set) => ({
  isOpen: false,
  setDrawerOpen: () => set({ isOpen: true }),
  setDrawerClose: () => set({ isOpen: false }),
  selectedUrl: undefined,
  setUrl: (url) => set({ selectedUrl: url }),
  openSubmenus: {},
  setOpenSubmenus: (index) =>
    set((state) => {
      return {
        openSubmenus: {
          ...state.openSubmenus,
          [index]: !state.openSubmenus[index],
        },
      };
    }),
}));
