// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#super-alldash-right-2{
    margin-top: 15px;
    padding: 0px 25px 25px 25px;
    #super-alldash-right-21{
        
        #super-alldash-right-23{
            display: flex;
            gap: 20px;
            padding: 10px 0px 25px 20px ;
            justify-content: center;
            button{
                padding: 10px 8px;
                width: 15vw;
                border-radius: 5px;
                /* border: 1.5px solid #DFE6FA; */
                cursor: pointer;
                background-color: #D8E1FF ;
                border: 1.5px solid #abb9e7; 
            }
            button.active{
                background-color: #252B3F ;
                color: white;
                border: 1.5px solid #DFE6FA; 
                box-shadow: #DFE6FA 0px 2px 8px 0px;
            }
        }
    }
}



@media screen and (min-width: 1024px) {
    #super-alldash-right-2{
        padding: 0px 10px 25px 10px;
    }
}

@media screen and (min-width: 1440px) {
   
   
}
@media screen and (min-width: 1920px) {
   
   
}

`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Alldash.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B;;QAEI;YACI,aAAa;YACb,SAAS;YACT,4BAA4B;YAC5B,uBAAuB;YACvB;gBACI,iBAAiB;gBACjB,WAAW;gBACX,kBAAkB;gBAClB,iCAAiC;gBACjC,eAAe;gBACf,0BAA0B;gBAC1B,2BAA2B;YAC/B;YACA;gBACI,0BAA0B;gBAC1B,YAAY;gBACZ,2BAA2B;gBAC3B,mCAAmC;YACvC;QACJ;IACJ;AACJ;;;;AAIA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;;;AAGA;AACA;;;AAGA","sourcesContent":["\n#super-alldash-right-2{\n    margin-top: 15px;\n    padding: 0px 25px 25px 25px;\n    #super-alldash-right-21{\n        \n        #super-alldash-right-23{\n            display: flex;\n            gap: 20px;\n            padding: 10px 0px 25px 20px ;\n            justify-content: center;\n            button{\n                padding: 10px 8px;\n                width: 15vw;\n                border-radius: 5px;\n                /* border: 1.5px solid #DFE6FA; */\n                cursor: pointer;\n                background-color: #D8E1FF ;\n                border: 1.5px solid #abb9e7; \n            }\n            button.active{\n                background-color: #252B3F ;\n                color: white;\n                border: 1.5px solid #DFE6FA; \n                box-shadow: #DFE6FA 0px 2px 8px 0px;\n            }\n        }\n    }\n}\n\n\n\n@media screen and (min-width: 1024px) {\n    #super-alldash-right-2{\n        padding: 0px 10px 25px 10px;\n    }\n}\n\n@media screen and (min-width: 1440px) {\n   \n   \n}\n@media screen and (min-width: 1920px) {\n   \n   \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
