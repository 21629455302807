// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#accumulator-totalcontent{
    margin-top: 1vh;
    table{
        margin-top: 2vh;
        background-color: white;
        width: auto !important;
        height: 500px;
        text-align: center;
        overflow-y: scroll;
        display: block;
        border: 1px solid #DFE6FA;

        th, td {
            width: calc(92vw / 6); /* Adjust this to fit your column count */
        }
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Sectionpointview/Accumulator.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf;QACI,eAAe;QACf,uBAAuB;QACvB,sBAAsB;QACtB,aAAa;QACb,kBAAkB;QAClB,kBAAkB;QAClB,cAAc;QACd,yBAAyB;;QAEzB;YACI,qBAAqB,EAAE,yCAAyC;QACpE;IACJ;;AAEJ","sourcesContent":["#accumulator-totalcontent{\n    margin-top: 1vh;\n    table{\n        margin-top: 2vh;\n        background-color: white;\n        width: auto !important;\n        height: 500px;\n        text-align: center;\n        overflow-y: scroll;\n        display: block;\n        border: 1px solid #DFE6FA;\n\n        th, td {\n            width: calc(92vw / 6); /* Adjust this to fit your column count */\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
