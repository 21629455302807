// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paper-container {
  border: 1px solid #dfe6fa;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.total-data-container {
  display: flex;
  justify-content: space-between;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tod-heading {
  font-weight: 700;
  color: #495057;
  padding-left: 1rem;
  margin-top: 0.5rem !important;
}

.data-container {
  background-color: #f9fbfc;
  border: 1px dashed #e9ebec;
  border-right: none;
  border-left: none;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LoadGraph/TodInformation/TodInformation.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".paper-container {\n  border: 1px solid #dfe6fa;\n  justify-content: flex-end;\n  margin-bottom: 1rem;\n}\n\n.total-data-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.filter-container {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.tod-heading {\n  font-weight: 700;\n  color: #495057;\n  padding-left: 1rem;\n  margin-top: 0.5rem !important;\n}\n\n.data-container {\n  background-color: #f9fbfc;\n  border: 1px dashed #e9ebec;\n  border-right: none;\n  border-left: none;\n  display: flex;\n  margin-bottom: 1rem;\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
