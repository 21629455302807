// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dailydash-1{
    display: flex;
    flex-wrap: wrap;
}

#dailydash-2{
    margin-top: 1vh;
    display: flex;

}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Daily-Dash/DailyDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;;AAEjB","sourcesContent":["#dailydash-1{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n#dailydash-2{\n    margin-top: 1vh;\n    display: flex;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
