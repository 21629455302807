// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.livealerts-right-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 75px;
  width: 100%;
  background-color: white !important;
  border: 1px solid #dfe6fa;
  border-radius: 10px;
  padding: 10px !important;

  label {
    margin-right: 20px;
    margin-top: 10px;
    span {
      font-size: 13px;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/LiveAlerts/LiveAlerts.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kCAAkC;EAClC,yBAAyB;EACzB,mBAAmB;EACnB,wBAAwB;;EAExB;IACE,kBAAkB;IAClB,gBAAgB;IAChB;MACE,eAAe;IACjB;EACF;AACF","sourcesContent":["\n.livealerts-right-2 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  height: 75px;\n  width: 100%;\n  background-color: white !important;\n  border: 1px solid #dfe6fa;\n  border-radius: 10px;\n  padding: 10px !important;\n\n  label {\n    margin-right: 20px;\n    margin-top: 10px;\n    span {\n      font-size: 13px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
