import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { DatePicker, Form, Segmented, TreeSelect } from "antd";
import dayjs from "dayjs";
import "chartjs-adapter-moment";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./api/services/axios";
import Sectionpoints from "./Components/Operator/Sectionpointview/Sectionpoints";
import Stationpoints from "./Components/Commonfns/Stationpoints";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const apiData = {
  status: 200,
  message: "success",
  data: {
    date: "2024-11-28",
    section: "HV",
    lowThreshold: 30,
    highThreshold: 909,
    TotalData: {
      total_consumption: -3.56,
      maximum_demand: 0,
      total_incentives: 0,
      total_penalty: 0,
      total_common: 20,
    },
    cardsData: [
      {
        type: "Incentive",
        title: "TOD1",
        hours: "00:00:00 to 05:59:59",
        load: 0,
        consumption: 7.1599999999998545,
        usage: 0,
        bgColor: "#D8FFDC",
      },
      {
        type: "Penalty",
        title: "TOD2",
        hours: "06:00:00 to 09:59:59",
        load: 0,
        consumption: 3440.06,
        usage: 0,
        bgColor: "#FFE9E9",
      },
      {
        type: "Common",
        title: "TOD3",
        hours: "10:00:00 to 17:59:59",
        load: 0,
        consumption: 101.26999999999998,
        usage: 0,
        bgColor: "#ECEFFB",
      },
      {
        type: "Penalty",
        title: "TOD4",
        hours: "18:00:00 to 21:59:59",
        load: 0,
        consumption: -3.56,
        usage: 0,
        bgColor: "#FFE9E9",
      },
      {
        type: "Incentive",
        title: "TOD5",
        hours: "22:00:00 to 23:59:59",
        load: 0,
        consumption: 0,
        usage: 0,
        bgColor: "#D8FFDC",
      },
    ],
    graph_data: {
      tod1: {
        labels: [
          "2024-11-28 00:30:30",
          "2024-11-28 01:00:30",
          "2024-11-28 02:00:30",
          "2024-11-28 03:00:30",
        ],
        values: [196.4, 150, 196.4, 150],
      },
      tod2: {
        labels: ["2024-11-28 07:00:30", "2024-11-28 08:00:30"],
        values: [196.4, 196.4],
      },
      tod3: {
        labels: ["2024-11-28 10:00:30"],
        values: [196.4],
      },
      tod4: {
        labels: ["2024-11-28 19:00:30", "2024-11-28 20:00:30"],
        values: [200, 176],
      },
      tod5: {
        labels: [
          "2024-11-28 22:15:30",
          "2024-11-28 22:12:30",
          "2024-11-28 23:12:30",
        ],
        values: [250, 196.4, 250],
      },
    },
  },
};
const TodInformation = () => {
  // const labels = Array.from({ length: 25 }, (_, i) => `${i}:00`); // Labels for each hour
  const handle = useFullScreenHandle();
  // Sample data (hourly values)

  const [isFullScreen, setIsFullScreen] = useState(false);

  const {} = useQuery({
    queryKey: ["todInformation"],
    queryFn: async () => {
      return axiosInstance.get("/api/kvagraphdata?");
    },
  });
  const generateData = () => {
    const allLabels = [
      ...apiData.data.graph_data.tod1.labels,
      ...apiData.data.graph_data.tod2.labels,
      ...apiData.data.graph_data.tod3.labels,
      ...apiData.data.graph_data.tod4.labels,
      ...apiData.data.graph_data.tod5.labels,
    ].map((date) => new Date(date)); // Convert all to Date objects

    // Sort and deduplicate
    const labels = [...new Set(allLabels)].sort((a, b) => a - b);

    // Create a mapping of each TOD's values to the combined labels
    const mapValuesToLabels = (tod) => {
      const labelMap = new Map(
        apiData.data.graph_data[tod].labels.map((label, idx) => [
          new Date(label).getTime(),
          apiData.data.graph_data[tod].values[idx],
        ])
      );

      // Generate values array based on the combined labels
      return labels.map((label) => labelMap.get(label.getTime()) || null); // Fill missing data with null
    };

    const datasets = [
      {
        label: "0-6 Hours",
        data: mapValuesToLabels("tod1"),
        borderColor: "#000",
        backgroundColor: "#D8FFDC",
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 5,
        fill: true,
        datalabels: {
          display: false,
        },
      },
      {
        label: "6-10 Hours",
        data: mapValuesToLabels("tod2"),
        borderColor: "#000",
        backgroundColor: "#FFE9E9",
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 5,
        fill: true,
        datalabels: {
          display: false,
        },
      },
      {
        label: "10-18 Hours",
        data: mapValuesToLabels("tod3"),
        borderColor: "#000",
        backgroundColor: "#ECEFFB",
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 5,
        fill: true,
        datalabels: {
          display: false,
        },
      },
      {
        label: "18-22 Hours",
        data: mapValuesToLabels("tod4"),
        borderColor: "#000",
        backgroundColor: "#FFE9E9",
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 5,
        fill: true,
        datalabels: {
          display: false,
        },
      },
      {
        label: "22-24 Hours",
        data: mapValuesToLabels("tod5"),
        borderColor: "#000",
        backgroundColor: "#D8FFDC",
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 5,
        fill: true,
        datalabels: {
          display: false,
        },
      },
    ];

    return { labels, datasets };
  };
  const { labels, datasets } = generateData();
  const data = {
    labels,
    datasets,
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
        align: "center",
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom",
        caretSize: 5,
        caretPadding: 0,
        titleFont: {
          size: 15,
        },
        bodyFont: {
          size: 14,
        },
        padding: 12,
        boxPadding: 10,
        boxWidth: 30,
        boxHeight: 30,
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: "time",
        time: {
          unit: "hour",
          stepSize: 1,
          displayFormats: {
            hour: "HH:mm",
          },
        },
        ticks: {
          source: "auto",
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          // stepSize: 5,
          callback: (value) => `${value}`, // Display as integers without decimals
        },
      },
    },
  };

  console.log({
    isFullScreen,
  });

  const [value, setValue] = useState();
  const onChange = (newValue) => {
    setValue(newValue);
  };
  const onPopupScroll = (e) => {
    console.log("onPopupScroll", e);
  };

  const treeData = [
    {
      value: "parent 1",
      title: "Top 5 sections",
      selectable: false,
      children: [
        {
          value: "leaf1",
          title: "HV",
        },
        {
          value: "leaf2",
          title: "Solar",
        },
        {
          value: "leaf3",
          title: "Section3",
        },
        {
          value: "leaf4",
          title: "Section4",
        },
        {
          value: "leaf5",
          title: "Section5",
        },
        {
          value: "leaf6",
          title: "Section6",
        },
      ],
    },

    {
      value: "parent 2",
      title: "Least 5 sections",
      selectable: false,
      children: [
        {
          value: "leaf1",
          title: "HV",
        },
        {
          value: "leaf2",
          title: "Solar",
        },
        {
          value: "leaf3",
          title: "Section3",
        },
        {
          value: "leaf4",
          title: "Section4",
        },
        {
          value: "leaf5",
          title: "Section5",
        },
        {
          value: "leaf6",
          title: "Section6",
        },
      ],
    },

    {
      value: "leaf1",
      title: "HV",
    },
    {
      value: "leaf2",
      title: "Solar",
    },
    {
      value: "leaf3",
      title: "Section3",
    },
    {
      value: "leaf4",
      title: "Section4",
    },
    {
      value: "leaf5",
      title: "Section5",
    },
    {
      value: "leaf6",
      title: "Section6",
    },
  ];

  //   useEffect(() => {
  //     if (isFullScreen) {
  //       window.location.reload();
  //     }
  //   }, [isFullScreen]);
  return (
    <>
      <FullScreen
        onChange={(isActive) => setIsFullScreen(isActive)} // Track full-screen state
        handle={handle}
      >
        <Box p={isFullScreen ? 0 : 1}>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid #DFE6FA",
              //    display: "flex",
              justifyContent: "end",
              //    p: 1,
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pt: isFullScreen ? 2 : 1,
              }}
              //   pl={2}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: "#495057",
                  ml: 1,
                }}
              >
                TOD Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  // cursor: "pointer",
                }}
              >
                {/* <Segmented
                  size="medium"
                  options={["Currents/Load (I)", "Consumption (kWh)"]}
                  onChange={(value) => {
                    console.log(value); // string
                  }}
                /> */}

                {/* <TreeSelect
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  value={value}
                  dropdownStyle={{
                    maxHeight: 500,
                    overflow: "auto",
                  }}
                  placeholder="Please select section"
                  allowClear
                  //   treeDefaultExpandAll
                  onChange={onChange}
                  treeData={treeData}
                  onPopupScroll={onPopupScroll}
                /> */}

                <Stationpoints defaultStationPoint="HV" />

                <Form layout="inline">
                  <Form.Item>
                    <DatePicker
                      style={{ width: "150px" }}
                      defaultValue={dayjs()}
                      disabledDate={(current) => current && current > dayjs()}
                      // onChange={(value) => handleDateChange(value)}
                    />
                  </Form.Item>
                </Form>

                {!isFullScreen && (
                  <IconButton size="small" onClick={handle.enter}>
                    <FullscreenIcon fontSize="large" />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box
              height={70}
              sx={{
                backgroundColor: "#f9fbfc",
                border: "1px dashed #e9ebec",
                borderRight: "none",
                borderLeft: "none",
                display: "flex",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                flexGrow={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRight={"1px dashed #e9ebec"}
              >
                <Box textAlign={"center"}>
                  <Typography
                    sx={{
                      color: "#495057",
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                  >
                    50
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878A99",
                    }}
                  >
                    Total Consumption
                  </Typography>
                </Box>
              </Box>

              <Box
                flexGrow={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRight={"1px dashed #e9ebec"}
              >
                <Box textAlign={"center"}>
                  <Typography
                    sx={{
                      color: "#495057",
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                  >
                    50
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878A99",
                    }}
                  >
                    CMD
                  </Typography>
                </Box>
              </Box>

              <Box
                flexGrow={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRight={"1px dashed #e9ebec"}
              >
                <Box textAlign={"center"}>
                  <Typography
                    sx={{
                      color: "#4E7F53",
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                  >
                    50%
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878A99",
                    }}
                  >
                    Total Incentive
                  </Typography>
                </Box>
              </Box>

              <Box
                flexGrow={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRight={"1px dashed #e9ebec"}
              >
                <Box textAlign={"center"}>
                  <Typography
                    sx={{
                      color: "#DF4343",
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                  >
                    50%
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878A99",
                    }}
                  >
                    Total Penalty
                  </Typography>
                </Box>
              </Box>

              <Box
                flexGrow={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                // borderRight={"1px dashed #e9ebec"}
              >
                <Box textAlign={"center"}>
                  <Typography
                    sx={{
                      color: "#6C81C9",
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    50%
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878A99",
                    }}
                  >
                    Total Common
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Line
              style={{ maxHeight: isFullScreen ? undefined : "500px" }}
              data={data}
              options={options}
            />
            <Box display={"flex"}>
              {[
                {
                  type: "Incentive",

                  bgColor: "#D8FFDC",
                  flexGrow: 10,
                },
                {
                  type: "Penalty",

                  bgColor: "#FFE9E9",
                  flexGrow: 5,
                },
                {
                  type: "Normal",

                  bgColor: "#ECEFFB",
                  flexGrow: 12.5,
                },
                {
                  type: "Penalty",

                  bgColor: "#FFE9E9",
                  flexGrow: 5,
                },
                {
                  type: "Incentive",

                  bgColor: "#D8FFDC",
                  flexGrow: 1.2,
                },
              ].map((tod, index) => (
                <Box
                  key={index}
                  sx={{
                    // backgroundColor: tod.bgColor,
                    // border: "1px dashed #e9ebec",
                    borderRight: "none",
                    borderLeft: "none",

                    flexGrow: tod.flexGrow,
                    mb: 0,
                    mt: 2,
                  }}
                >
                  <Box
                    flexGrow={1}
                    p={0.6}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box textAlign={"center"} pl={1}>
                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 700,
                          fontSize: 16,
                          mb: 1,
                        }}
                      >
                        {tod.type}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display={"flex"}>
              {[
                {
                  type: "Incentive",
                  title: "TOD1",
                  hours: "0 to 6 Hours",
                  kwh: 2323,
                  load: 1241,
                  consumption: 1245,
                  usage: 100,
                  bgColor: "#D8FFDC",
                  flexGrow: 12.5,
                },
                {
                  type: "Penalty",
                  title: "TOD1",
                  hours: "6 to 10 Hours",
                  kwh: 2323,
                  load: 1241,
                  consumption: 1245,
                  usage: 100,
                  bgColor: "#FFE9E9",
                  flexGrow: 2.5,
                },
                {
                  type: "Normal",
                  title: "TOD1",
                  hours: "10 to 18 Hours",
                  kwh: 2323,
                  load: 1241,
                  consumption: 1245,
                  usage: 100,
                  bgColor: "#ECEFFB",
                  flexGrow: 16,
                },
                {
                  type: "Penalty",
                  title: "TOD1",
                  hours: "18 to 22 Hours",
                  kwh: 2323,
                  load: 1241,
                  consumption: 1245,
                  usage: 100,
                  bgColor: "#FFE9E9",
                  flexGrow: 0,
                },
                {
                  type: "Incentive",
                  title: "TOD",
                  hours: "22 to 24 Hours",
                  kwh: 2323,
                  load: 1241,
                  consumption: 1245,
                  usage: 100,
                  bgColor: "#D8FFDC",
                  flexGrow: 0,
                },
              ].map((tod, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: tod.bgColor,
                    // border: "1px dashed #e9ebec",
                    borderRight: "none",
                    borderLeft: "none",

                    flexGrow: tod.flexGrow,
                    mb: 2,
                    mt: 2,
                  }}
                >
                  <Box
                    flexGrow={1}
                    p={0.6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box textAlign={"left"} pl={1}>
                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 700,
                          fontSize: 16,
                          mb: 1,
                        }}
                      >
                        {tod.title} {":  "} {tod.hours}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 500,
                          fontSize: 14,
                          mb: 0.5,
                        }}
                      >
                        kWh: {tod.kwh} kWh
                      </Typography>

                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 500,
                          fontSize: 14,
                          mb: 0.5,
                        }}
                      >
                        Load: {tod.load}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 500,
                          fontSize: 14,
                          mb: 0.5,
                        }}
                      >
                        Consumption: {tod.consumption} kWh
                      </Typography>

                      <Typography
                        sx={{
                          color: "#495057",
                          fontWeight: 500,
                          fontSize: 14,
                          mb: 0.5,
                        }}
                      >
                        Usage(%): {tod.usage} %
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
      </FullScreen>
    </>
  );
};

export default TodInformation;
