import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./Accumulator.css";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { TableCell, TableRow } from "@mui/material";
import { Empty } from "antd";

const columns = [
  { id: "division", label: "Division", align: "center" },
  { id: "section", label: "Section", align: "center" },
  { id: "timestamp", label: "Timestamp", align: "center" },
  { id: "kWh", label: "kWh", align: "center" },
  { id: "KVArh", label: "KVArh", align: "center" },
  { id: "KVAh", label: "KVAh", align: "center" },
];

const Accumulator = ({ selectedSection }) => {
  const [mqttData, setMqttData] = useState([]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SECTIONS_URL);

  //   socket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from WebSocket server');
  //   });

  //   socket.on('mqtt_data', (data) => {
  //     console.log('Received data:', data);

  //     setMqttData((prevData) => {
  //       const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
  //       if (existingDataIndex !== -1) {
  //         // Update existing data if deviceEUi already exists in state
  //         const updatedData = [...prevData];
  //         updatedData[existingDataIndex] = data;
  //         return updatedData;
  //       } else {
  //         // Add new data if deviceEUi is not in state
  //         return [data, ...prevData];
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);


  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_SECTIONS_URL);

    // When a message is received from the server, update the state
    ws.onmessage = (event) => {

      const data = JSON.parse(event.data);

      setMqttData(data);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => ws.close();
  }, []);

  const filteredData = selectedSection
    ? mqttData.filter((data) => data.section === selectedSection)
    : mqttData;

  return (
    <CommonTable columns={columns}>

      {
        filteredData.length === 0 && (
          <TableRow sx={{height: 300}}>
            <TableCell colSpan={columns.length}>
              <Empty description={"No data available"} />
            </TableCell>
          </TableRow>
        )
      }
      {filteredData.map((data) => (
        <TableRow key={data.deviceEUi}>
          <TableCell align="center">{data.division}</TableCell>
          <TableCell align="center">{data.section}</TableCell>
          <TableCell align="center">{data.utcTimestamp}</TableCell>
          <TableCell align="center">{data.kWh}</TableCell>
          <TableCell align="center">{data.KVArh}</TableCell>
          <TableCell align="center">{data.KVAh}</TableCell>
        </TableRow>
      ))}
    </CommonTable>
  );
};

export default Accumulator;
