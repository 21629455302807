// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    padding: 10px;
    /* border-radius: 16px !important; */
    margin-bottom: 10px;
    display:  flex;
    align-items: center !important;
    justify-content: end;
    gap: 20px;
  }
  
  .chart-container {
    padding: 10px;
  }
  
  .alerts-chart {
    max-height: 500px !important;
    margin-bottom: 30px;
  }
  
  .static-content-container {
    padding: 10px;
    background-color: #daecd4 !important;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .imp-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100% !important;
  }
  
  .total-losses{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  .total-losses-box{
    padding: 7px 12px;
    border: 1px solid #E76868;
    border-radius: 5px;
    background-color: #F4E6E6;

    span{
      font-size: 15px;
      font-weight: bold;
    }

  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Trends/RevenueLoss/RevenueLoss.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,cAAc;IACd,8BAA8B;IAC9B,oBAAoB;IACpB,SAAS;EACX;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;EACzB;EACA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;;IAEzB;MACE,eAAe;MACf,iBAAiB;IACnB;;EAEF","sourcesContent":[".filter-container {\n    padding: 10px;\n    /* border-radius: 16px !important; */\n    margin-bottom: 10px;\n    display:  flex;\n    align-items: center !important;\n    justify-content: end;\n    gap: 20px;\n  }\n  \n  .chart-container {\n    padding: 10px;\n  }\n  \n  .alerts-chart {\n    max-height: 500px !important;\n    margin-bottom: 30px;\n  }\n  \n  .static-content-container {\n    padding: 10px;\n    background-color: #daecd4 !important;\n    border-radius: 8px;\n    margin-bottom: 10px;\n  }\n  \n  .imp-container {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    width: 100% !important;\n  }\n  \n  .total-losses{\n    display: flex;\n    gap: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n  }\n  .total-losses-box{\n    padding: 7px 12px;\n    border: 1px solid #E76868;\n    border-radius: 5px;\n    background-color: #F4E6E6;\n\n    span{\n      font-size: 15px;\n      font-weight: bold;\n    }\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
