import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./Status.css";
import { Box, TableCell, TableRow } from "@mui/material";
import CommonTable from "../../Common/CommonTable/CommonTable";
import { Empty } from "antd";

const columns = [
  { id: "division", label: "Division", align: "center" },
  { id: "section", label: "Section", align: "center" },
  { id: "timestamp", label: "Timestamp", align: "center" },
  { id: "parameterName", label: "Parameter Name", align: "center" },
  { id: "parameterValue", label: "Parameter Value", align: "center" },
];

const Status = ({ selectedSection }) => {
  const [mqttData, setMqttData] = useState([]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SECTIONS_URL);

  //   socket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from WebSocket server');
  //   });

  //   socket.on('mqtt_data', (data) => {
  //     console.log('Received data:', data);

  //     setMqttData((prevData) => {
  //       const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
  //       if (existingDataIndex !== -1) {
  //         // Update existing data if deviceEUi already exists in state
  //         const updatedData = [...prevData];
  //         updatedData[existingDataIndex] = data;
  //         return updatedData;
  //       } else {
  //         // Add new data if deviceEUi is not in state
  //         return [data, ...prevData];
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_SECTIONS_URL);

    // When a message is received from the server, update the state
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setMqttData(data);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => ws.close();
  }, []);

  const getACInputStatus = (data) => {
    const { Vr, Vy, Vb } = data;
    return Vr !== 0 || Vy !== 0 || Vb !== 0 ? "Healthy" : "Failure";
  };

  const getSwitchStatus = (data) => {
    const { Ir, Iy, Ib } = data;
    return Ir !== 0 || Iy !== 0 || Ib !== 0 ? "On" : "Off";
  };

  const filteredData = selectedSection
    ? mqttData.filter((data) => data.section === selectedSection)
    : mqttData;

  return (
    <Box>
      <CommonTable columns={columns}>
        {filteredData.length === 0 && (
          <TableRow sx={{height: 300}}>
            <TableCell colSpan={columns.length}>
              <Empty description={"No data available"} />
            </TableCell>
          </TableRow>
        )}
        {filteredData.map((data) => (
          <React.Fragment key={data.deviceEUi}>
            <TableRow>
              <TableCell align="center">{data.division}</TableCell>
              <TableCell align="center">{data.section}</TableCell>
              <TableCell align="center">{data.utcTimestamp}</TableCell>
              <TableCell align="center">AC Input</TableCell>
              <TableCell align="center">{getACInputStatus(data)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">{data.division}</TableCell>
              <TableCell align="center">{data.section}</TableCell>
              <TableCell align="center">{data.utcTimestamp}</TableCell>
              <TableCell align="center">Switch</TableCell>
              <TableCell align="center">{getSwitchStatus(data)}</TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </CommonTable>
    </Box>
  );
};

export default Status;
