// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#todhistory-total {
  background-color: #f3f6fe;
  height: auto !important;
}
#todhistory-right-2 {
  padding: 0px 0px 5px 0px;

  #todhistory-right-21 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 75px;
    width: 100%;
    background-color: white;
    border: 1px solid #dfe6fa;
    border-radius: 10px;

    label {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }

    button {
      padding: 4.5px 2px;
      background: transparent;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-size: 25px;
      margin-right: 20px;
    }
  }

  #todhistory-right-3 {
    table {
      margin-top: 1vh;
      background-color: white;
      width: 100%;
      height: 500px;
      text-align: center;
      overflow-y: scroll;
      display: block;
      border: 1px solid #dfe6fa;
    }
    th,
    td {
      width: calc(91vw / 10);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Operator/TODview/SavedTOD.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,wBAAwB;;EAExB;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;;IAEnB;MACE,aAAa;MACb,sBAAsB;MACtB,kBAAkB;IACpB;;IAEA;MACE,kBAAkB;MAClB,uBAAuB;MACvB,kBAAkB;MAClB,YAAY;MACZ,eAAe;MACf,eAAe;MACf,kBAAkB;IACpB;EACF;;EAEA;IACE;MACE,eAAe;MACf,uBAAuB;MACvB,WAAW;MACX,aAAa;MACb,kBAAkB;MAClB,kBAAkB;MAClB,cAAc;MACd,yBAAyB;IAC3B;IACA;;MAEE,sBAAsB;IACxB;EACF;AACF","sourcesContent":["#todhistory-total {\n  background-color: #f3f6fe;\n  height: auto !important;\n}\n#todhistory-right-2 {\n  padding: 0px 0px 5px 0px;\n\n  #todhistory-right-21 {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    height: 75px;\n    width: 100%;\n    background-color: white;\n    border: 1px solid #dfe6fa;\n    border-radius: 10px;\n\n    label {\n      display: flex;\n      flex-direction: column;\n      margin-right: 20px;\n    }\n\n    button {\n      padding: 4.5px 2px;\n      background: transparent;\n      border-radius: 5px;\n      border: none;\n      cursor: pointer;\n      font-size: 25px;\n      margin-right: 20px;\n    }\n  }\n\n  #todhistory-right-3 {\n    table {\n      margin-top: 1vh;\n      background-color: white;\n      width: 100%;\n      height: 500px;\n      text-align: center;\n      overflow-y: scroll;\n      display: block;\n      border: 1px solid #dfe6fa;\n    }\n    th,\n    td {\n      width: calc(91vw / 10);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
