import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Empty } from "antd"; // Import Ant Design Select component
import "./Stationpoints.css";

const MultiStationpoints = ({
  onStationPointChange,
  defaultStationPoint = "",
  showAll = false,
  isHorizontal = false,
}) => {
  const [stationpointsdata, setStationpointsdata] = useState([]);
  const [error, setError] = useState(null);
  const [selectedStationPoint, setSelectedStationPoint] =
    useState(defaultStationPoint);

  // Update selected station point if defaultStationPoint changes
  useEffect(() => {
    if (defaultStationPoint) {
      setSelectedStationPoint(defaultStationPoint);
    }
  }, [defaultStationPoint]);

  // Fetch station points data on component mount

  const fetchStationpointsdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`
      );
      setStationpointsdata(response.data);
    } catch (error) {
      setError(error);
      setStationpointsdata([]);
    }
  };
  useEffect(() => {
    fetchStationpointsdata();
  }, []);

  const handleStationPointChange = (value) => {
    setSelectedStationPoint(value || ""); // Set to empty string if value is null
    onStationPointChange(value || "");
  };

  // Prepare options for the Ant Design Select component
  const options = stationpointsdata.map((point) => ({
    value: point["Station point"],
    label: point["Station point"],
  }));

  return (
    <div className="station-select-container">
      {isHorizontal && (
        <span style={{ marginRight: "5px" }}>Select Sections: </span>
      )}
      <label>
        {!isHorizontal && "Select Sections:"}
        <Select
          showSearch
          allowClear
          mode="multiple"
          style={{
            minWidth: 500,
            border: "none",
            boxShadow: "none",
            outline: "none",
            // height: "30px",
            zIndex: "1",
            // display: 'inline-block',
          }}
          dropdownStyle={{
            zIndex: 1050, // Ensure the dropdown appears on top
          }}
          size="medium"
          // style={{ width: 250 }}
          placeholder="Search to Select"
          optionFilterProp="label"
          value={selectedStationPoint || []}
          onChange={handleStationPointChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent={<Empty description="No Sections available" />}
          options={[
            ...(showAll ? [{ value: "", label: "Select All" }] : []),
            ...options,
          ]}
        />
      </label>
    </div>
  );
};

export default MultiStationpoints;
