import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import Stationpoints from "../Commonfns/Stationpoints";
import TopBar from "../Commonfns/Topbar/Topbar";
import { FaFileCsv } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import "./CriticalAlerts.css";
import { DatePicker, Space, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import CommonTable from "../Common/CommonTable/CommonTable";

const itemsPerPage = 20;
const { Option } = Select;

const alertColumns = [
  { id: 1, label: "S. No.", align: "center" },
  { id: 2, label: "Token ID", align: "center" },
  { id: 3, label: "Alert Type", align: "center" },
  { id: 4, label: "Section", align: "center" },
  { id: 5, label: "Parameter", align: "center" },
  { id: 6, label: "Value", align: "center" },
  { id: 7, label: "Start Time", align: "center" },
  { id: 8, label: "End Time", align: "center" },
  { id: 9, label: "Duration", align: "center" },
];

const fetchAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDate, toDate, selectedAlertType] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/criticalalerts/filter?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (section) query += `&section_name=${encodeURIComponent(section)}`;
  if (selectedAlertType)
    query += `&specific_alert_type=${encodeURIComponent(selectedAlertType)}`;

  const response = await fetch(query);
  if (!response.ok) throw new Error("Failed to fetch alerts");
  return response.json();
};

const fetchAlertTypes = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_EMS_URL}/api/unique-alert-types-criticalalerts`
  );
  if (!response.ok) throw new Error("Failed to fetch alert types");
  return response.json();
};

const CriticalAlerts = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [alertTypes, setAlertTypes] = useState([]);
  const [selectedAlertType, setSelectedAlertType] = useState("");
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // Disable hours on the current day
  const disabledTime = (date) => {
    if (date && date.isSame(dayjs(), "day")) {
      return {
        disabledHours: () => [...Array(24).keys()].slice(dayjs().hour() + 1), // Disable future hours
      };
    }
    return {};
  };

  const { ref, inView } = useInView({ threshold: 0.1 });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["critical-alerts", section, fromDate, toDate, selectedAlertType],
    queryFn: fetchAlerts,
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.currentPage + 1 : undefined,
    onSuccess: (data) => {
      // Check if the filtered data returned is empty
      if (data.pages.every((page) => page.data.length === 0)) {
        setNoDataAvailable(true); // Set the flag to true if no data is available
      } else {
        setNoDataAvailable(false); // Reset the flag if data is found
      }
    },
  });

  useEffect(() => {
    // Fetch alert types on mount
    const loadAlertTypes = async () => {
      try {
        const types = await fetchAlertTypes();
        setAlertTypes(types);
      } catch (error) {
        console.error("Error fetching alert types:", error);
      }
    };

    loadAlertTypes();
  }, []);

  const handleDiagnoseClick = (token_id) => {
    // console.log("Navigating to diagnose page for token ID:", token_id);
    navigate(`/Diagnose/${token_id}`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const sectionParam = queryParams.get("station-point") || "";
    const fromParam = queryParams.get("from") || "";
    const toParam = queryParams.get("to") || "";
    const selectedTypeParam = queryParams.get("selectedType") || "";

    setSection(sectionParam);
    setFromDate(fromParam);
    setToDate(toParam);
    setSelectedAlertType(selectedTypeParam);
  }, []);

  // Update query parameters when any of the state values change
  useEffect(() => {
    const queryParams = new URLSearchParams();

    queryParams.set("section", "critical-alerts");
    if (section) queryParams.set("station-point", section);
    if (fromDate) queryParams.set("from", fromDate);
    if (toDate) queryParams.set("to", toDate);
    if (selectedAlertType) queryParams.set("selectedType", selectedAlertType);

    navigate(`?${queryParams.toString()}`);
  }, [section, fromDate, toDate, selectedAlertType, navigate]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setFromDate(dates[0].format("YYYY-MM-DD HH:mm"));
      setToDate(dates[1].format("YYYY-MM-DD HH:mm"));
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleAlertTypeChange = (value) => {
    setSelectedAlertType(value);
  };

  const downloadCSV = async () => {
    setLoading(true); // Start loading
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/criticalalerts/filter/download?`;

      if (fromDate) {
        query += `fromDate=${fromDate}&`;
      }
      if (toDate) {
        query += `toDate=${toDate}&`;
      }
      if (section) {
        query += `section_name=${encodeURIComponent(section)}&`;
      }
      if (selectedAlertType) {
        query += `specific_alert_type=${encodeURIComponent(
          selectedAlertType
        )}&`;
      }

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed to fetch data for CSV download");
      }

      const data = await response.json();

      const csvContent = [
        [
          "S NO",
          "Section",
          "Token Id",
          "Alert Type",
          "Critical Alert Parameter",
          "Critical Alert Value",
          "Start Time",
          "End Time",
          "Duration",
        ],
        ...data.map((alert, index) => [
          index + 1,
          alert.section_name,
          alert.token_id,
          alert.specific_alert_type,
          alert.critical_alert_parameter,
          alert.critical_alert_value,
          alert.start_time,
          alert.end_time,
          alert.duration,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create file name based on applied filters
      const filters = [];
      if (fromDate) filters.push(`From-${fromDate}`);
      if (toDate) filters.push(`To-${toDate}`);
      if (section) filters.push(`Section-${section}`);
      if (selectedAlertType) filters.push(`AlertType-${selectedAlertType}`);
      const fileName = filters.length > 0 ? filters.join("_") : "AllData";
      const fullFileName = `CriticalAlerts-${fileName}.csv`;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data for CSV download:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={9} align="center">
            Error: {error.message}
          </TableCell>
        </TableRow>
      );
    }

    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) {
      return (
        <TableRow sx={{ height: 300 }}>
          <TableCell colSpan={alertColumns.length + 1} align="center">
            <Empty description="No data available" />
          </TableCell>
        </TableRow>
      );
    }

    return alerts.map((alert, index) => (
      <TableRow key={alert._id || index}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{alert.tokenId}</TableCell>
        <TableCell align="center">{alert.specificAlertType}</TableCell>
        <TableCell align="center">{alert.sectionName}</TableCell>
        <TableCell align="center">{alert.criticalAlertParameter}</TableCell>
        <TableCell align="center">{alert.criticalAlertValue}</TableCell>
        <TableCell align="center">{alert.startTime}</TableCell>
        <TableCell align="center">{alert.endTime}</TableCell>
        <TableCell align="center">{alert.duration}</TableCell>
      </TableRow>
    ));
  };

  // Table footer row that displays the "No More Data Available" message
  const renderTableFooter = () => {
    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) return null; // Don't show footer if there's no data

    return (
      <tr ref={ref}>
        <td colSpan="9" style={{ textAlign: "center" }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            !hasNextPage && "No More Data Available" // Only show this message when all data is fetched
          )}
        </td>
      </tr>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      {/* <Supervisorsidebar /> */}
      <div id="criticalalets-right">
        {/* <TopBar section="Criticalalertsheading" /> */}
        <div id="criticalalets-right-2">
          <div id="criticalalets-right-21">
            <label>
              Date Range:
              <Space direction="vertical" size={12}>
                <RangePicker
                  showTime={{
                    format: "HH:mm",
                    disabledTime,
                  }}
                  format="YYYY-MM-DD HH:mm"
                  size="medium"
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
                  value={
                    fromDate && toDate
                      ? [
                          dayjs(fromDate, "YYYY-MM-DD HH:mm"),
                          dayjs(toDate, "YYYY-MM-DD HH:mm"),
                        ]
                      : null
                  }
                />
              </Space>
            </label>
            <label>
              Alert Type:
              <Space wrap>
                <Select
                  allowClear
                  value={selectedAlertType || "Select All"} // To handle "Please Select"
                  onChange={handleAlertTypeChange}
                  placeholder="Please Select"
                  size="medium"
                  style={{
                    width: 200,
                  }}
                >
                  <Option value="Please Select" disabled>
                    Select All
                  </Option>
                  {alertTypes.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Space>
            </label>
            <Stationpoints
              defaultStationPoint={section}
              showAll={true}
              onStationPointChange={handleStationPointChange}
            />
            <Button
              type="primary"
              icon={
                loading ? (
                  <LoadingOutlined style={{ color: "#252B3F" }} />
                ) : (
                  <FaFileCsv style={{ color: "#252B3F" }} />
                )
              }
              onClick={downloadCSV}
            ></Button>
          </div>

          {/* <div id="criticalalets-right-3">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Token ID</th>
                  <th>Alert Type</th>
                  <th>Section</th>
                  <th>Parameter</th>
                  <th>Value</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {renderTableBody()}
                {renderTableFooter()}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>

      <Box mt={2}>
        <CommonTable columns={alertColumns} isWithPagination={false}>
          {renderTableBody()}
          {renderTableFooter()}
        </CommonTable>
      </Box>
    </div>
  );
};

export default CriticalAlerts;

{
  /* <td>
          {!alert.tokenId.startsWith("Kv") && (
            <button
              onClick={() => handleDiagnoseClick(alert.tokenId)}
              disabled={!alert.tokenIdExist} 
              style={{
                backgroundColor: alert.tokenIdExist ? "" : "gray",
                cursor: alert.tokenIdExist ? "pointer" : "not-allowed",
              }}
            >
              Diagnose
            </button>
          )}
        </td> */
}
