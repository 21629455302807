import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Productionentryfetch.css';
import { FaFileCsv } from "react-icons/fa6";
import { formatDateToDDMMYYYY } from '../../Operator/OperatorForms/Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, Space, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const Productionentryfetch = ({ onFormButtonClick }) => {
    const [productionEntries, setProductionEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [errors, setErrors] = useState({ productionCount: '' });

    
    dayjs.extend(customParseFormat);
    const { RangePicker } = DatePicker;
    const disabledDate = (current) => {
      return current && current > dayjs().endOf("day");
    };

    const handleRangePickerChange = (dates) => {
        if (dates) {
          setFromDate(dates[0].format("YYYY-MM-DD"));
          setToDate(dates[1].format("YYYY-MM-DD"));
        } else {
          setFromDate("");
          setToDate("");
        }
      };

    useEffect(() => {
        fetchProductionEntries();
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
    }, []);

    useEffect(() => {
        filterEntriesByDate();
    }, [fromDate, toDate, productionEntries]);

    const fetchProductionEntries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
            setProductionEntries(response.data);
        } catch (error) {
            console.error('Error fetching production entries', error);
        }
    };

    const filterEntriesByDate = () => {
        let filtered = productionEntries;

        if (fromDate) {
            filtered = filtered.filter(entry => {
                const entryDate = new Date(entry.date.split('-').reverse().join('-')); // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
                return entryDate >= new Date(fromDate);
            });
        }

        if (toDate) {
            filtered = filtered.filter(entry => {
                const entryDate = new Date(entry.date.split('-').reverse().join('-')); // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
                return entryDate <= new Date(toDate);
            });
        }

        setFilteredEntries(filtered);
    };


    const handleEditClick = (entry) => {
        setSelectedEntry({
            ...entry,
            date: entry.date.split('-').reverse().join('-'), // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
        });
        setIsPopupOpen(true);
    };

    const handlePopupChange = (e) => {
        const { name, value } = e.target;

        if (name === 'productionCount') {
            // Regex to allow up to 10 digits before the decimal and exactly 2 digits after
            const regex = /^\d{0,10}(\.\d{0,2})?$/;
        
            if (!regex.test(value)) {
            //   toast.error('Please enter a valid number with up to 12 digits including 2 decimal places.');
              return;
            }
          }

        setSelectedEntry((prev) => ({
            ...prev,
            [name]: value
        }));

         // Reset errors for the field on input
         setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleUpdate = async () => {
        const newErrors = {};

        if (!selectedEntry.productionCount) {
            newErrors.productionCount = 'Production Count is required.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast.error('Please fill all required fields before updating.');
            return;
        }
 

        const formattedDate = formatDateToDDMMYYYY(selectedEntry.date);

        const updatedEntry = {
            ...selectedEntry,
            date: formattedDate,
            modifiedby: loggedInUser.username,
            modifiedat: new Date().toISOString()
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry/${selectedEntry._id}`, updatedEntry);
            if (response.data.success) {
                setIsPopupOpen(false);
                fetchProductionEntries(); // Fetch updated production entries
                toast.success('Production entry updated successfully!');
            } else {
                toast.error('Failed to update production entry');
            }
        } catch (error) {
            console.error('Error updating data:', error);
            if (error.response && error.response.data.message === 'A log already exists for the date') {
                toast.error('Data already exists for the selected date, Please select another date.');
            } else {
                toast.error('Failed to Update Production entry. Please try again later.');
            }
        }
    };


    const downloadCSV = () => {
        // Define the headers for the CSV file
        const headers = ['Date', 'Production Count'];

        // Map the filtered data to CSV format
        const rows = filteredEntries.map(entry => [
            entry.date,
            entry.productionCount,
        ]);

        // Combine headers and rows
        const csvContent = [
            headers.join(','), // Join headers with commas
            ...rows.map(row => row.join(',')) // Join each row's data with commas
        ].join('\n'); // Combine each row with a newline character

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Production_Entry.csv');
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const handleKeyPress = (e) => {
        const invalidChars = ["e", "E", "+", "-"];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };
    
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };
    return (

        <div id='productionentryfetch-total'>

            <div id='productionentryfetch-right'>

                <div id='productionentryfetch-right-2'>
                    <div id='productionentryfetch-right-21'>
                              <label>
              Date Range:
              <Space direction="vertical" size={12}>
                <RangePicker
                  size="medium"
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
                  value={
                    fromDate && toDate
                      ? [
                          dayjs(fromDate, "YYYY-MM-DD"),
                          dayjs(toDate, "YYYY-MM-DD"),
                        ]
                      : null
                  }
                />
              </Space>
            </label>

                        <label id='formbutton-left'>
                            <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                        </label>
                        <label>
                            <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>

                                <th>Production Count</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.length === 0 ? (
                                <tr>
                                    <td colSpan="7">No data available for selected filters.</td>
                                </tr>
                            ) : (
                                filteredEntries.map((entry) => (
                                    <tr key={entry._id}>
                                        <td>{entry.date}</td>

                                        <td>{entry.productionCount}</td>
                                        <td><button onClick={() => handleEditClick(entry)}>Edit</button></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                        <div id='productionentryfetch-popup'>
                            <h3>Edit Production Entry</h3>
                            {selectedEntry && (
                                <form>
                                    <div id='productionentryfetch-popup1'>
                                        <label>
                                            <span>Date<span id='all-mandetorymark'>*</span></span>
                                            <input type='date' name='date' value={selectedEntry.date} onChange={handlePopupChange} max={getTodayDate()} required />
                                        </label>
                                        <label>
                                            <span>Production Count<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='productionCount' 
                                            value={selectedEntry.productionCount || ''}
                                            onChange={handlePopupChange} step="0.01"  onKeyPress={handleKeyPress} required/>
                                            {errors.productionCount && (
                                            <div className="productionentryfetch-errormessage">{errors.productionCount}</div>
                                        )}
                                        </label>

                                    </div>
                                    <div  id='productionentryfetch-popup-update'>
                                    <button  type="button" onClick={handleUpdate}>Update</button>

                                    </div>

                                </form>
                            )}
                        </div>
                    </Popup>
                </div>
            </div>
        </div>

    )
}

export default Productionentryfetch