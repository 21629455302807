import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Usercreation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Typography, Select } from "antd";
const Usercreation = () => {
  const [form] = Form.useForm();
  const [designations, setDesignations] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_EMS_URL}/api/designationsotherthanlevel1`
        );
        setDesignations(response.data);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchDesignations();

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setLoggedInUser(user);
      form.setFieldsValue({
        createdby: user.username, // Set createdby as default in the form
      });
    }
  }, [form]);

  const handleFinish = async (values) => {

    const dataToSubmit = {
      ...values,
      createdby: loggedInUser?.username || "unknown",
    };

    const { username, password } = dataToSubmit;

    if (password === username) {
      toast.error("Password should not be the same as Username.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/users`,
        dataToSubmit
      );
      toast.success("User created successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error creating user:", error.response ? error.response.data : error.message);
  
      if (
        error.response &&
        error.response.data.message === "Username already exists"
      ) {
        toast.error("Username already exists. Please choose a different username.");
      } else {
        toast.error("Failed to create user. Please try again later.");
      }
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{
              designation: "",
              level: "",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to="/Userfetch">
                <Button type="default">View Data</Button>
              </Link>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<Typography.Text strong>Designation</Typography.Text>}
                  name="designation"
                  rules={[
                    { required: true, message: "Please select a Designation" },
                  ]}
                >
                  <Select
                    placeholder="Select Designation"
                    onChange={(value) => {
                      const selectedDesignation = designations.find(
                        (desig) => desig.designation === value
                      );
                      form.setFieldsValue({
                        level: selectedDesignation?.level || "",
                      });
                    }}
                  >
                    {designations.map((desig) => (
                      <Select.Option key={desig._id} value={desig.designation}>
                        {desig.designation}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<Typography.Text strong>Name</Typography.Text>}
                  name="name"
                  rules={[{ required: true, message: "Please enter Name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<Typography.Text strong>Username</Typography.Text>}
                  name="username"
                  rules={[
                    { required: true, message: "Please enter Username" },
                    {
                      pattern: /^[a-z0-9]+$/,
                      message:
                        "Username should only contain lowercase letters and numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<Typography.Text strong>Password</Typography.Text>}
                  name="password"
                  rules={[
                    { required: true, message: "Please enter Password" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("username") !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password should not be the same as Username."
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Typography.Text strong>Confirm Password</Typography.Text>
                  }
                  name="confirmpassword"
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: "Please confirm your Password" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match.")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#252B3F",
                  color: "#fff",
                  border: "none",
                  width: "200px",
                }}
                htmlType="submit"
              >
                Register
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Usercreation;
