import React, { useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CustomBarChart = () => {
  const tooltipRef = useRef(null);

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };



  const generateData = () => {
    const data = [];
    const labels = [];
    for (let i = 0; i < 1440; i++) {
      // Generate random values between 1000 and 1500
      const value = Math.random() * (10 - 0) + 0;
      data.push(Math.round(value));
      labels.push(new Date(2024, 0, 1, 0, i).toISOString());
    }
    return { data, labels };
  };

  const { data: dataValues, labels } = generateData();

  // Data for the chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: dataValues,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "white";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "auto";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "10px";

      // Create table container
      const table = document.createElement("table");
      table.style.margin = "0px";

      // Attach table and tooltip element
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);

      tooltipRef.current = tooltipEl;

      // Ensure tooltip visibility during hover
      tooltipEl.addEventListener("mouseover", () => {
        tooltipRef.current.style.opacity = 1;
      });
      tooltipEl.addEventListener("mouseout", () => {
        tooltipRef.current.style.opacity = 0;
      });
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      if (tooltipRef.current) {
        tooltipRef.current.style.opacity = 0;
      }
      return;
    }

    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const buttonId = "custom-tooltip-button"; // Add a unique ID
      tooltipEl.innerHTML = `
      
       <table style="margin: 0px;">
  ${titleLines
    .map((title) => `<thead><tr><th>${title}</th></tr></thead>`)
    .join("")}
  ${bodyLines
    .map((body) => `<tbody><tr><td>${body}</td></tr></tbody>`)
    .join("")}
</table>

<div style="text-align: right">
  <button id="${buttonId}" style="display: block; margin-bottom: 10px; margin-left: 20px; padding: 10px; background-color: #fff; color: black; border: none; border-radius: 3px; cursor: pointer; text-decoration: underline;">
    Click Me
  </button>
</div>

         
      `;

      // Attach click event listener to the button after rendering
      const button = document.getElementById(buttonId);
      if (button) {
        button.onclick = () => alert("Button inside tooltip clicked!");
      }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
        align: "center",
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom",
        caretSize: 5,
        caretPadding: 0,
        titleFont: {
          size: 15,
        },
        bodyFont: {
          size: 14,
        },
        padding: 12,
        boxPadding: 10,
        boxWidth: 30,
        boxHeight: 30,
        enabled: true,
        mode: "index",
        intersect: false,
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          stepSize: 1,
          displayFormats: {
            hour: 'HH:mm',
          },
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
        },
        beginAtZero: true,
        ticks: {
          // stepSize: 5,
          callback: (value) => `${value}`, // Display as integers without decimals
        },
      },
    },
  };
  

  return <Line data={data} options={options} />;
};

export default CustomBarChart;
