import React, { useState } from "react";
import axios from "axios";
import "./Alertsduration.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";

const Alertsduration = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
        const dataToSend = {
            ...values,
            duration: Number(values.duration),
          };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/alertsduration`,dataToSend
      );
      toast.success("Alert Duration Added Successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Error adding Alerts Duration");
    }
  };

  const alertOptions = [
    { value: "", label: "Select Alert Type" },
    { value: "Voltage loss", label: "Voltage loss" },
    { value: "Abnormal PF loss", label: "Abnormal PF loss" },
    { value: "THD loss", label: "THD loss" },
    { value: "Unbalanced loads", label: "Unbalanced loads" },
    { value: "Negative PF loss", label: "Negative PF loss" },
    { value: "Idle time loss", label: "Idle time loss" },
    { value: "Transformer loss", label: "Transformer loss" },
    { value: "Utilization loss", label: "Utilization loss" },
    { value: "Currents loss", label: "Currents loss" },
    { value: "TOD loss", label: "TOD loss" },
    { value: "KVA", label: "KVA" },
  ];

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.AlertsdurationHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Alert Type" name="alert_type">
                  <Select
                    id="ant-input-style"
                    placeholder="Select Alert Type"
                    style={{ width: "100%" }}
                  >
                    {alertOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Duration (in Mins)" name="duration">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Alertsduration;
