import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Input, Select, Row, Col } from "antd";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./Designationfetch.css";
import CommonTable from "../../Common/CommonTable/CommonTable";
const DesignationTable = () => {
  const [designations, setDesignations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  const tableColumns = [
    { id: 1, label: "Designation ID", align: "center" },
    { id: 2, label: "Designation Level", align: "center" },
    { id: 3, label: "Designation Name", align: "center" },
    { id: 4, label: "Status", align: "center" },
    { id: 5, label: "Created By", align: "center" },
    { id: 6, label: "Created At", align: "center" },
    { id: 7, label: "Modified By", align: "center" },
    { id: 8, label: "Modified At", align: "center" },
    { id: 9, label: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchDesignations();
    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUser(user);
  }, []);

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/designations`
      );
      setDesignations(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleEditClick = (designation) => {
    setSelectedRow(designation);
    form.setFieldsValue(designation);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
    form.resetFields();
  };

  const handleEditSubmit = async (values) => {
    const updatedDesignationData = {
      ...selectedRow,
      ...values,
      modifiedby: loggedInUser.username,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/designations/${selectedRow._id}`,
        updatedDesignationData
      );
      toast.success("Designation updated successfully");
      fetchDesignations();
      handleCancel();
    } catch (error) {
      console.error("Error updating designation:", error);
      if (error.response?.data?.message === "Designation name must be unique") {
        toast.error(
          "Designation name already exists. Please choose a different name."
        );
      } else {
        toast.error("Failed to update designation. Please try again later.");
      }
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/designations/${id}/toggle-status`,
        {
          status: !currentStatus,
          modifiedby: "currentLoggedInUser", // Replace with the actual logged-in user
        }
      );
      fetchDesignations();
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  return (
    <div id="designationfecth-total">
      <div id="designationfecth-right">
        <div id="designationfecth-right-2">
          <div id="designationfecth-right-21">
            <label>
              <Link to="/Designation">
                <button>Add New</button>
              </Link>
            </label>
          </div>
          <Box
            mt={2}
            sx={{
              width: "93vw",
            }}
          >
            <CommonTable
              height={500}
              columns={tableColumns}
              isWithPagination={false}
            >
              {designations.map((designation) => (
                <TableRow key={designation._id}>
                  <TableCell align="center">
                    {designation.designation_id}
                  </TableCell>
                  <TableCell align="center">{designation.level}</TableCell>
                  <TableCell align="center">
                    {designation.designation}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant={designation.status ? "contained" : "outlined"}
                      color={designation.status ? "primary" : "default"}
                      onClick={() =>
                        handleToggleStatus(designation._id, designation.status)
                      }
                    >
                      {designation.status ? "Active" : "Inactive"}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{designation.createdby}</TableCell>
                  <TableCell align="center">{designation.createdat}</TableCell>
                  <TableCell align="center">{designation.modifiedby}</TableCell>
                  <TableCell align="center">{designation.modifiedat}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleEditClick(designation)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </CommonTable>
          </Box>

          <Modal
            title="Edit Designation"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleEditSubmit}
              initialValues={selectedRow}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Designation ID" name="designation_id">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Level"
                    name="level"
                    rules={[
                      { required: true, message: "Please select a level" },
                    ]}
                  >
                    <Select>
                      <Option value="Level-2">Level-2</Option>
                      <Option value="Level-3">Level-3</Option>
                      <Option value="Level-4">Level-4</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Designation Name"
                    name="designation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the designation name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginRight: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#252B3F", color: "#fff" }}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DesignationTable;
