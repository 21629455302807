
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './SuperadminRegistration.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Typography } from "antd";
const SuperadminRegistration = () => {
  const [form] = Form.useForm();
  const [loggedInUser, setLoggedInUser] = useState(null);
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setLoggedInUser(user);
      form.setFieldsValue({
        createdby: user.username,
      });
    }
    fetchDesignationForLevel1();
  }, [form]);

  const fetchDesignationForLevel1 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
      const level1Designation = response.data.find((desig) => desig.level === "Level-1");
      if (level1Designation) {
        form.setFieldsValue({
          designation: level1Designation.designation,
        });
      }
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleSubmit = async (values) => {

    if (values.password !== values.confirmpassword) {
      toast.error("Passwords do not match. Please re-enter.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins`,
        values
      );
      toast.success("Superadmin Registered Successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error creating superadmin:", error);
      if (error.response && error.response.data.message === "Username already exists") {
        toast.error("Username already exists. Please choose a different username.");
      } else {
        toast.error("Failed to register superadmin. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchDesignationForLevel1();
  }, []);

  return (

    <div id="masterforms-total">
    <div id="masterformsright">
      <div id="masterforms-right-2">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            level: "Level-1",
            designation: "Superadmin",
          }}
        >
          <div style={{display:"flex", justifyContent:"flex-end"}} >
            <Link to="/SuperadminTable">
              <Button type="default">View Data</Button>
            </Link>
          </div>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Level" name="level">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Designation" name="designation">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter Name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please enter Username" },
                  {
                    pattern: /^[a-z0-9]+$/,
                    message: "Username should only contain lowercase letters and numbers.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter Password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("username") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Password should not be the same as Username."));
                    },
                  }),
                ]}
              >
                <Input.Password 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmpassword"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Please confirm your Password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Passwords do not match."));
                    },
                  }),
                ]}
              >
                <Input.Password
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ textAlign: "center" }}>
            <Button style={{marginTop:"10px",backgroundColor:"#252B3F", color:"#ffff", border:"none", width:"200px"}} htmlType="submit">
              Register
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
  );
};

export default SuperadminRegistration;
