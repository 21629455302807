
import React, { useState, useEffect } from "react";
import "./Fixedvariables.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import dayjs from "dayjs";
const Fixedvariables = () => {
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dataId, setDataId] = useState(null);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`
        );
        const data = await response.json();
        if (data.length > 0) {
          form.setFieldsValue({
            ...data[0],
            date: dayjs(data[0].date), // Ensure date is a dayjs object
          });
          setDataId(data[0]._id);
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [form]);

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...values, date: new Date().toISOString() }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        setIsSubmitted(true);
        setDataId(result.insertedId); // Assuming the response contains the inserted ID
        toast.success("Basic Plant details added successfully");
      } else {
        toast.error("Error submitting form");
      }
    } catch (error) {
      toast.error("Error submitting form");
    }
  };

  const handleUpdate = async (values) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables/${dataId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...values, date: new Date().toISOString() }),
        }
      );
      if (response.ok) {
        setIsEditing(false);
        toast.success("Basic Plant details updated successfully");
      } else {
        toast.error("Error updating form");
      }
    } catch (error) {
      toast.error("Error updating form");
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            onFinish={isEditing ? handleUpdate : handleSubmit}
            initialValues={{
              tods: [
                {
                  name: "TOD1",
                  time_range: "00:00:00 - 05:59:59",
                  type: "Incentive",
                },
              ],
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",    
                alignItems: "center",
              }}
            >
              <h3>Basic Plant Details</h3>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Updated Date" name="date">
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Plant Name"
                  name="plantname"
                  rules={[
                    { required: true, message: "Please enter the Plant Name" },
                  ]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Location/Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Location/Address",
                    },
                  ]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Type of Industry"
                  name="typeofindustry"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Type of Industry",
                    },
                  ]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Contracted Max Demand (CMD)"
                  name="Contracted_max_demand_(CMD)"
                  rules={[{ required: true, message: "Please enter CMD" }]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Multiplying Factor (MF)"
                  name="Multiplying_actor_(MF)"
                  rules={[{ required: true, message: "Please enter MF" }]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Energy Intensity"
                  name="energyintesity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Energy Intensity",
                    },
                  ]}
                >
                  <Input disabled={isSubmitted && !isEditing} />
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="tods">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row key={key} gutter={16} align="middle">
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          label="TOD Name"
                        >
                          <Input disabled={isSubmitted && !isEditing} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "time_range"]}
                          fieldKey={[fieldKey, "time_range"]}
                          label="Time Range"
                        >
                          <Input disabled={isSubmitted && !isEditing} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "type"]}
                          fieldKey={[fieldKey, "type"]}
                          label="Type"
                        >
                          <Input disabled={isSubmitted && !isEditing} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {fields.length > 1 && (
                          <Button
                            type="dashed"
                            onClick={() => remove(name)}
                            block
                            icon={<IoIosRemoveCircle />}
                            style={{
                              marginBottom: "10px",
                              color: "#FF4D4F", // Red color for a cancel button
                              borderColor: "#FF4D4F",
                            }}
                          >
                            Remove TOD
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                  {isEditing && (
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<IoIosAddCircle />}
                    >
                      Add TOD
                    </Button>
                  )}
                </>
              )}
            </Form.List>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {!isSubmitted && (
                <Button
                  style={{
                    backgroundColor: "#252B3F",
                    color: "white",
                    border: "none",
                  }}
                  htmlType="submit"
                >
                  {Masterformsheadingsss.Submitheading}
                </Button>
              )}
              {isSubmitted && !isEditing && (
                <Button
                  style={{
                    backgroundColor: "#252B3F",
                    color: "white",
                    border: "none",
                  }}
                  type="default"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              )}
              {isSubmitted && isEditing && (
                <Button
                  style={{
                    backgroundColor: "#252B3F",
                    color: "white",
                    border: "none",
                  }}
                  htmlType="submit"
                >
                  {Masterformsheadingsss.Updateheading}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Fixedvariables;
