import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { Button as AntDButton, DatePicker, Flex, Popover } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa6";
import { getTodTrendsData } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import lightIcon from "../../../Images/light.svg";
import manIcon from "../../../Images/man.svg";
import Stationpoints from "../../Commonfns/Stationpoints";
import "./TodRunHours.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TodRunHoursTrend = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);

  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      station_point: "HV",
      month: dayjs(),
    },
  });

  const [chartData, setChartData] = useState([]);

  const { station_point, month } = watch();

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  useEffect(() => {
    ChartJS.unregister(ChartDataLabels);

    getTodTrendsData(station_point, month.format("YYYY-MM"))
      .then((res) => {
        setChartData(res);
      })
      .catch((error) => {
        // errorToast(error.message || "failed to fetch Tod Trends");
      });
  }, [station_point, month]);

  const timeToHours = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };

  const prepareChartData = (chartSetData) => {
    const datasets = [];
    const labels = chartSetData.map((data) => data.date);

    // Define colors for uptime and downtime
    const colors = {
      tod1: "#528F36",
      tod2: "#C26060",
      tod3: "#859AE0",
      tod4: "#C26060",
      tod5: "#528F36",
    };

    const downtimeColors = {
      tod1: "#D7E7DC",
      tod2: "#EDE4E4",
      tod3: "#F3F6FE",
      tod4: "#EDE4E4",
      tod5: "#D7E7DC",
    };

    // Define TOD ranges (hours)
    const todRanges = {
      tod1: { min: 0, max: 6 }, // 0-6h
      tod2: { min: 6, max: 10 }, // 6-10h
      tod3: { min: 10, max: 18 }, // 10-18h
      tod4: { min: 18, max: 22 }, // 18-22h
      tod5: { min: 22, max: 24 }, // 22-24h
    };

    // Define base values for stacking
    const baseValues = {
      tod1: 0,
      tod2: 1,
      tod3: 2,
      tod4: 3,
      tod5: 4,
    };

    chartSetData.forEach((data) => {
      const date = data.date;

      // Process each TOD
      Object.keys(data).forEach((tod) => {
        if (tod.startsWith("tod")) {
          const todData = data[tod];

          const range = todRanges[tod];
          const baseValue = baseValues[tod];

          // Calculate TOD range difference
          const todRangeSize = range.max - range.min;

          // Process uptime segments
          todData.uptime_segment.forEach((segment) => {
            const startTime =
              (timeToHours(segment.start_time.substring(11)) - range.min) /
                todRangeSize +
              baseValue;
            const endTime =
              (timeToHours(segment.end_time.substring(11)) - range.min) /
                todRangeSize +
              baseValue;

            datasets.push({
              label: `${tod} Uptime`,
              data: [
                {
                  x: date,
                  y: [startTime, endTime],
                  segment,
                  day: data.view_more_data,
                },
              ],
              backgroundColor: colors[tod],
              borderColor: colors[tod],
              borderWidth: 1,
            });
          });

          // Process downtime segments
          todData.downtime_segment.forEach((segment) => {
            const startTime =
              (timeToHours(segment.start_time.substring(11)) - range.min) /
                todRangeSize +
              baseValue;
            const endTime =
              (timeToHours(segment.end_time.substring(11)) - range.min) /
                todRangeSize +
              baseValue;

            datasets.push({
              label: `${tod} DownTime`,
              data: [
                {
                  x: date,
                  y: [startTime, endTime],
                  segment,
                  day: data.view_more_data,
                },
              ],
              backgroundColor: downtimeColors[tod], // Transparent
              borderColor: colors[tod],
              borderWidth: 1,
            });
          });
        }
      });
    });

    return {
      labels,
      datasets,
    };
  };

  const chartData1 = prepareChartData(chartData);

  const options = {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: true, // Shows grid lines on the y-axis
          lineWidth: 2,
          color: function (context) {
            return [
              "#528F36",
              "#C26060",
              "#859AE0",
              "#859AE0",
              "#C26060",
              "#528F36",
            ];
          },
        },
        beginAtZero: true,
        min: 0,
        max: 5, // Ensure this matches the number of labels minus one
        ticks: {
          stepSize: 1, // Ensure each tick corresponds to a label
          callback: function (value, index) {
            const labels = [
              "00:00:00",
              "06:00:00",
              "10:00:00",
              "18:00:00",
              "22:00:00",
              "24:00:00",
            ];
            return labels[value]; // Return the corresponding label for each tick
          },
          color: function (context) {
            return [
              "#528F36",
              "#C26060",
              "#859AE0",
              "#859AE0",
              "#C26060",
              "#528F36",
            ];
          },
        },
      },
    },
    animation: {
      duration: 0,
      // easing: undefined,
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        // boxWidth: 30, // Width of color indicator boxes
        // boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          label: function (context) {
            // Get the value from the dataset
            let datasetLabel = context.dataset.label;

            // Capitalize the first letter of the dataset label
            datasetLabel =
              datasetLabel.slice(0, 3).toUpperCase() + datasetLabel.slice(3);

            const value = context.raw;

            const dataOfTheDay = value.day;

            const {
              total_consumption,
              incentive_consumption,
              common_consumption,
              penalty_consumption,
              total_run_hours,
              incentive_run_hours,
              common_run_hours,
              penalty_run_hours,
            } = dataOfTheDay;

            const segment = value.segment;

            // Return the formatted time string
            return [
              datasetLabel,
              "--------------------",
              `Consumption: ${segment.consumption}`,
              `RunHours: ${segment.duration}`,
              `Start Time: ${segment.start_time.substring(11)}`,
              `End Time: ${segment.end_time.substring(11)}`,
              "",
              "Day Overview ↓",
              "--------------------",
              `Total Consumption: ${total_consumption}`,
              `Incentive Consumption: ${incentive_consumption}`,
              `Common Consumption: ${common_consumption}`,
              `Penalty Consumption: ${penalty_consumption}`,
              `Total Run Hours: ${total_run_hours}`,
              `Incentive Run Hours: ${incentive_run_hours}`,
              `Common Run Hours: ${common_run_hours}`,
              `Penalty Run Hours: ${penalty_run_hours}`,
            ];
          },
        },
      },
      datalabels: {
        color: "white",
        display: (context) => context.dataset.data[context.dataIndex] != null,
        font: {
          // weight: "bold",
          size: 12,
        },
        anchor: "center", // Positions the label in the center of the bar
        align: "center",  // Aligns the label with the bar's center
        rotation: -90,    // Rotates the label to be vertical
      },
    },
  };

  const handleStationPointChange = (value) => {
    if (value.length === 0) {
      setValue(station_point, "HV");
    } else {
      setValue("station_point", value);
    }
  };

  const chartRef = React.useRef();

  const handleDownloadPdf = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) return;

    try {
      const canvas = await html2canvas(chartElement, {
        useCORS: true, // Ensures cross-origin images are loaded
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the chart into the page
      const ratio = Math.min(
        pageWidth / canvas.width,
        pageHeight / canvas.height
      );
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;

      // Calculate offsets to center the image
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("tod_run_hours_trend.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
        <Flex align="end" gap={20}>
         

          <Box>
            <div>
              <Stationpoints
                onStationPointChange={handleStationPointChange}
                defaultStationPoint="HV"
              />
            </div>
          </Box>
          <Box>
            <div>
              <label>{t("common.selectMonthColumn")}</label>
            </div>

            <div>
              <DatePicker
                {...register("month")}
                style={{ width: "200px" }}
                defaultValue={getValues("month")}
                size={"medium"}
                picker={"month"}
                disabledDate={disableFutureMonths} // Restricts future months
                onChange={onChange}
              />
            </div>
          </Box>
          <AntDButton
            onClick={handleDownloadPdf}
            style={{ textTransform: "none", marginTop: 13 }}
            size="large"
            icon={<FaFilePdf />}
            iconPosition="end"
          />
        </Flex>
      </Paper>

      <Paper
        ref={chartRef}
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("todVsRunHoursComponent.todConsumptionVsRunHours")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img
                      style={{ alignSelf: "start" }}
                      src={chatIcon}
                      width={"40px"}
                      height={"40px"}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>

                      <Typography variant="body1">
                        <ul
                          style={{
                            padding: 0,
                            margin: 0,

                            listStylePosition: "inside",
                          }}
                        >
                          {t(
                            "todVsRunHoursComponent.whyIsThisImportantAnswer",
                            {
                              returnObjects: true,
                            }
                          ).map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </Typography>

                      <Typography variant="body1">
                        <ul
                          style={{
                            margin: 5,
                            listStyleType: "square",
                          }}
                        >
                          {t(
                            "todVsRunHoursComponent.whyIsThisImportantAnswerBullets",
                            {
                              returnObjects: true,
                            }
                          ).map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"60px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>

                        <Typography variant="body1">
                          <ul
                            style={{
                              padding: 0,
                              margin: 0,

                              listStylePosition: "inside",
                            }}
                          >
                            {t("todVsRunHoursComponent.whatShouldYouDoAnswer", {
                              returnObjects: true,
                            }).map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>

                      <Typography variant="body1">
                        <ul
                          style={{
                            padding: 0,
                            margin: 0,

                            listStylePosition: "inside",
                          }}
                        >
                          {t("todVsRunHoursComponent.howWillYouImproveAnswer", {
                            returnObjects: true,
                          }).map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        {/* Static Legends */}
        <div style={{ display: "flex", justifyContent: "end", margin: "10px" }}>
          <div style={{ marginRight: "10px" }}>
            <span
              style={{
                backgroundColor: "#528F36",
                paddingRight: "10px",
                paddingLeft: "10px",
                margin: "5px",
              }}
            ></span>
            {t("todVsRunHoursComponent.incentives")}
          </div>
          <div style={{ marginRight: "10px" }}>
            <span
              style={{
                backgroundColor: "#C26060",
                paddingRight: "10px",
                paddingLeft: "10px",
                margin: "5px",
              }}
            ></span>
            {t("todVsRunHoursComponent.penalty")}
          </div>

          <div style={{ marginRight: "10px" }}>
            <span
              style={{
                backgroundColor: "#859AE0",
                paddingRight: "10px",
                paddingLeft: "10px",
                margin: "5px",
              }}
            ></span>
            {t("todVsRunHoursComponent.common")}
          </div>
        </div>
        <Bar className="alerts-chart" data={chartData1} options={options} />
      </Paper>
    </div>
  );
};

export default TodRunHoursTrend;
