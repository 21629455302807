import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import OperatorAlertstable from "./Components/Operator/AlertsTable/Alertstable";
import LiveData from "./Components/Operator/Livedata/Livedata";
import Historydata from "./Components/Operator/HistoryData/Historydata";
import LiveAlerts from "./Components/Operator/LiveAlerts/LiveAlerts";
import Statussummaryview from "./Components/Operator/StatusSummary/Statussummaryview";
import Sectionpoints from "./Components/Operator/Sectionpointview/Sectionpoints";
import TODview from "./Components/Operator/TODview/TODview";
import Breakdownlogs from "./Components/Operator/OperatorForms/Breakdownlogs/Breakdownlogs";
import Transformermonitoring from "./Components/Operator/OperatorForms/Tranformermonitoringlogs/Transformermonitoring";
import Transformermonitoringfetch from "./Components/Operator/OperatorForms/Tranformermonitoringlogs/Transformermonitoringfetch";
import Breakdownlogsfetch from "./Components/Operator/OperatorForms/Breakdownlogs/Breakdownlogsfetch";
import DGlogs from "./Components/Operator/OperatorForms/DGlogs/DGlogs";
import DGlogsfetch from "./Components/Operator/OperatorForms/DGlogs/DGlogsfetch";
import DailyConsumption from "./Components/Operator/DailyConsumption/DailyConsumption";
import Tranformerform from "./Components/Masterdata/Tranformerform/Tranformerform";
import Transformerformfetch from "./Components/Masterdata/Tranformerform/Tranformerformfetch";
import Areaform from "./Components/Masterdata/Areaform/Areafrom";
import Areaformfetch from "./Components/Masterdata/Areaform/Areaformfetch";
import TODviewcomponent from "./Components/Operator/TODview/SavedTOD";
import LiveSummary from "./Components/Livesummary/Livesummary";
import PowerBills from "./Components/Supervisor/PowerBills/PowerBills";
import Productionentry from "./Components/Supervisor/Productionentry/Productionentry";
import CriticalAlerts from "./Components/CriticalAlerts/CriticalAlerts";
import EnergyConsumption from "./Components/EnergyConsumption&Billing/EnergyConsumption";
import PF_MD from "./Components/PF&MD/PF&MD";
import Monthly_PF from "./Components/PF&MD/Monthly_PF";
import PowerBillsfetch from "./Components/Supervisor/PowerBills/PowerBillsfetch";
import Operator_Dash from "./Components/Operator/Home/Home";
import Revenueloss from "./Components/Revenuelosspage/Revenueloss";
import Energyintensity from "./Components/Energyintensity/Energyintensity";
import Incomers from "./Components/MasterForms/Incomers/Incomers";
import Utilization_Percenatage from "./Components/Utilization%/Utilization%";
import Alertscount from "./Components/Trends/Alerts/Alertscount";
import Downtime_Uptime from "./Components/Downtime_Uptime/Downtime_Uptime";
import Transformerhealth from "./Components/Transformerloss/Transformerloss";
import Groundingpoints from "./Components/MasterForms/Groundingpoints/Groundingpoints";
import GroundingpointsFetch from "./Components/MasterForms/Groundingpoints/Groundingpointsfetch";
import Incomersfetch from "./Components/MasterForms/Incomers/Incomersfetch";
import HVform from "./Components/MasterForms/HVform/HVform";
import HvformFetch from "./Components/MasterForms/HVform/HVformfetch";
import Parentdetails from "./Components/MasterForms/Parentdetails/Parentdetails";
import Mastertransformerform from "./Components/MasterForms/MasterTransformer/Mastertransformer";
import Mastertransformerfetch from "./Components/MasterForms/MasterTransformer/Mastertransformerfetch";
import LVform from "./Components/MasterForms/LVform/LVform";
import LVformFetch from "./Components/MasterForms/LVform/LVformfetch";
import Mastergenerator from "./Components/MasterForms/Generators/Generators";
import Mastergeneratorfetch from "./Components/MasterForms/Generators/Generstorsfetch";
import Sections from "./Components/MasterForms/Sections/Sections";
import Sectionsfetch from "./Components/MasterForms/Sections/Sectionsfetch";
import Mastermachine from "./Components/MasterForms/Machine/Machine";
import Mastermachinefetch from "./Components/MasterForms/Machine/Machinefetch";
import UPS from "./Components/MasterForms/UPS/UPS";
import UPSfetch from "./Components/MasterForms/UPS/UPSfetch";
import Stebilizer from "./Components/MasterForms/Stabilizers/Stebilizers";
import Stebilizersfetch from "./Components/MasterForms/Stabilizers/Stebilizersfetch";
import PFcorrectors from "./Components/MasterForms/PFcorrectors/PFcorrectors";
import PFcorrectorsfetch from "./Components/MasterForms/PFcorrectors/PFcorrectorsfetch";
import Harmonicfilter from "./Components/MasterForms/Harmonic_filters/Harmonic_filters";
import Harmonicfiltersfetch from "./Components/MasterForms/Harmonic_filters/Harmonic_filtersfetch";
import VFD from "./Components/MasterForms/VFD/VFD";
import VFDfetch from "./Components/MasterForms/VFD/VFDfetch";
import LightingArrestor from "./Components/MasterForms/LighteningArrestors/LighteningArrestors";
import LightingArrestorfetch from "./Components/MasterForms/LighteningArrestors/LighteningArrestorsfetch";
import Tariff from "./Components/MasterForms/Tariff/Tariff";
import Alertsduration from "./Components/MasterForms/Alertsduration/Alertsduration";
import Alertsdurationfetch from "./Components/MasterForms/Alertsduration/Alertsdurationfetch";
import Diagnose from "./Components/Diagnose/Diagnosepage/Diagnosepage";
import Voltageregulator from "./Components/Diagnose/Voltagediagnose/Voltageregulator/Voltageregulator";
import Stabilizerform from "./Components/Diagnose/Voltagediagnose/Stabilizerform/Stabilizerform";
import Stabilizerformfetch from "./Components/Diagnose/Voltagediagnose/Stabilizerform/Stabilizerformfetch";
import Voltageregulatorfetch from "./Components/Diagnose/Voltagediagnose/Voltageregulator/Voltageregulatorfetch";
import Groundingresistance from "./Components/Diagnose/Voltagediagnose/Groundingresistance/Groundingresistance";
import Groundingresistancefetch from "./Components/Diagnose/Voltagediagnose/Groundingresistance/Groundingresistancefetch";
import EnvironmentalFactors from "./Components/Diagnose/Voltagediagnose/EnvironmentalFactors/EnvironmentalFactors";
import EnvironmentalFactorsfetch from "./Components/Diagnose/Voltagediagnose/EnvironmentalFactors/EnvironmentalFactorsfetch";
import Powersurge_Groundingresistance from "./Components/Diagnose/Powersurge/Groundingresistance/Groundingresistance";
import Powersurge_Groundingresistancefetch from "./Components/Diagnose/Powersurge/Groundingresistance/Groundingresistancefetch";
import Internal_Fault from "./Components/Diagnose/Powersurge/Internal_Fault/Internal_Fault";
import InternalFaultfetch from "./Components/Diagnose/Powersurge/Internal_Fault/internal_Faultfetch";
import Harmonic_Form from "./Components/Diagnose/Harmonicdistortion/Harmonic_Form/Harmonic_Form";
import Harmonic_Formfetch from "./Components/Diagnose/Harmonicdistortion/Harmonic_Form/Harmonic_Formfetch";
import Power_Quality_Form from "./Components/Diagnose/Harmonicdistortion/Power_Quality_Form/Power_Quality_Form";
import Power_Quality_Formfetch from "./Components/Diagnose/Harmonicdistortion/Power_Quality_Form/Power_Quality_Formfetch";
import Oil_Winding_Temperature_Form from "./Components/Diagnose/Unbalancedloads/Oil_Winding_Temperature_Form/Oil_Winding_Temperature_Form";
import Oil_Winding_Temperature_Formfetch from "./Components/Diagnose/Unbalancedloads/Oil_Winding_Temperature_Form/Oil_Winding_Temperature_Formfetch";
import Hotspot_Test_Form from "./Components/Diagnose/Unbalancedloads/Hotspot_Test_Form/Hotspot_Test_Form";
import Hotspot_Test_Formfetch from "./Components/Diagnose/Unbalancedloads/Hotspot_Test_Form/Hotspot_Test_Formfetch";
import Impedence_Test_Formfetch from "./Components/Diagnose/Unbalancedloads/Impedence_Test_Form/Impedence_Test_Formfetch";
import Impedance_Test_Form from "./Components/Diagnose/Unbalancedloads/Impedence_Test_Form/Impedence_Test_Form";
import Loose_Connection_and_Corrosion_Form from "./Components/Diagnose/Unbalancedloads/Loose_Connection_and_Corrosion_Form/Loose_Connection_and_Corrosion_Form";
import Loose_Connection_and_Corrosion_Formfetch from "./Components/Diagnose/Unbalancedloads/Loose_Connection_and_Corrosion_Form/Loose_Connection_and_Corrosion_Formfetch";
import APFC_Signalling_Manual_Form from "./Components/Diagnose/LeadPF/APFC_Signalling_Manual_Form/APFC_Signalling_Manual_Form";
import APFC_Signalling_Manual_Formfetch from "./Components/Diagnose/LeadPF/APFC_Signalling_Manual_Form/APFC_Signalling_Manual_Formfetch";
import Capacitance_Manual_Form from "./Components/Diagnose/LowPF/Capacitance_Manual_Form/Capacitance_Manual_Form";
import Capacitance_Manual_Formfetch from "./Components/Diagnose/LowPF/Capacitance_Manual_Form/Capacitance_Manual_Formfetch";
import Pending_Tasks from "./Components/Diagnose/Pendingtasks/Pending_Tasks";
import Designation from "./Components/UserManagement/Designation/Designation";
import DesignationTable from "./Components/UserManagement/Designation/Designationfetch";
import SuperadminRegistration from "./Components/UserManagement/Superadmin/SuperadminRegistration";
import SuperadminTable from "./Components/UserManagement/Superadmin/SuperadminRegistrationfetch";
import LoginPage from "./Components/UserManagement/Login/Login";
import { UserProvider } from "./Components/UserManagement/Details/UserContext";
import UserdetailsHeader from "./Components/UserManagement/Details/Userdetailshead";
import ProtectedRoute from "./Components/UserManagement/Details/Protect";
import Usercreation from "./Components/UserManagement/Usercreation/Usercreation";
import Userfetch from "./Components/UserManagement/Usercreation/Usercreationfetch";
import Main_transformerform from "./Components/Maintenanceforms/Transformerform/Transformerform";
import Main_TransformerFormFetch from "./Components/Maintenanceforms/Transformerform/Transformerformfetch";

import Voltagediagnose from "./Components/RevenuelossDiagnose/Voltagediagnose/Voltagediagnose";
import Powersurgediagnose from "./Components/RevenuelossDiagnose/Powersurgediagnose/Powersurgediagnose";
import Harmonicdistortiondiagnose from "./Components/RevenuelossDiagnose/Harmonicdistortion/Harmonicdistortion";
import Unbalancedloaddiagnose from "./Components/RevenuelossDiagnose/Unbalancedloads/Unbalancedloads";
import LowPFdiagnose from "./Components/RevenuelossDiagnose/LowPF/LowPF";
import LeadPFdiagnose from "./Components/RevenuelossDiagnose/LeadPF/LeadPF";
import Idle_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Idle_Loss_Revenue_Diagnose/Idle_Loss_Revenue_Diagnose";
import TOD_loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/TOD_loss_Revenue_Diagnose/TOD_loss_Revenue_Diagnose";
import Utilization_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Utilization_Loss_Revenue_Diagnose/Utilization_Loss_Revenue_Diagnose";
import Transformer_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Transformer_Loss_Revenue_Diagnose/Transformer_Loss_Revenue_Diagnose";
import TOD_Run_Time_Week from "./Components/TOD_Run_Time/TOD_Run_Time_Week/TOD_Run_Time_Week";
import TOD_Run_Time_Month from "./Components/TOD_Run_Time/TOD_Run_Time/TOD_Run_Time";
import RCPE_Report from "./Components/RCPE_Report/RCPE_Report";
import Type_of_load_Consumption from "./Components/Type_of_load_Consumption/Type_of_load_Consumption";
import GeneratorsKPI from "./Components/GeneratorsKPI/GeneratorsKPI";
import Stationpoints from "./Components/Commonfns/Stationpoints";
import Tasks from "./Components/Tasks/Tasks";
import PendingTasks from "./Components/Tasks/PendingTasks";
import Alldash from "./Components/SuperDashboards/Alldash";
import ReportsAlertscount from "./Components/Reports/AlertsCount/ReportAlertcount";
import ReportDowntime_Uptime from "./Components/Reports/Downtimereport/ReportDowntime";
import ReportRCPE_Report from "./Components/Reports/RCPEreports/ReportsRCPE";
import AllReports from "./Components/Reports/Allreports/Allreports";
import Doitlater from "./Components/Tasks/Doitlater/Doitlater";
import Todotasks from "./Components/Tasks/Todotasks/Todotasks";
import TotalTaskcounts from "./Components/Diagnose/Pendingtasks/TaksCounts/TotalTaskcounts";
import Formheadercomponent from "./Components/Formscomponent/Formheadercompo/Formheadercomponent";
import Calenderscombine from "./Components/Calender/Calenderscombine/Calenderscombine";
import Loginscreens from "./Components/UserManagement/Loginscreens/Loginscreens";
import DailyConsumptionprint from "./Components/Operator/DailyConsumption/Dailyconsumptionprint";
import Alertspage from "./Components/Operator/Alertspage/Alertspage";
import AllTOD from "./Components/Operator/TODview/AllTOD";
import Formscomponent from "./Components/Operator/OperatorForms/Formscomponent/Formscomponent";
import AllTrends from "./Components/Trends/Alltrends";
import Fixedvariables from "./Components/MasterForms/Fixedvariables/Fixedvariables";
import TargetEfficiency from "./Components/Operator/OperatorForms/TargetEfficiency/TargetEfficiency";
import RestrictedRoute from "./Components/UserManagement/Details/RestrictedRoute";
import TodInformation from "./line";
import AllLoadGraphs from "./Components/LoadGraph/LoadTabs";
import AllCriticalAlertsTabs from "./Components/CriticalAlerts/CriticalAlertTabs";

export const supervisorRoutes = [

];

export const routes = [
    { path: "/Operator-Dash", element: <Operator_Dash />, heading: "Home" },
    { path: "/Alertspage", element: <Alertspage />, heading: "Alerts" },
    { path: "/Pending_Tasks", element: <Pending_Tasks /> },
    { path: "/PendingTasks", element: <PendingTasks />, heading: "Pending Tasks" },
    { path: "/LiveSummary", element: <LiveSummary />, heading: "Live Plant Summary"},
    { path: "/Forms", element: <Formscomponent />, heading: "Forms" },
    { path: "/TODHistory", element: <TODviewcomponent /> },
    { path: "/AllTOD", element: <AllTOD />, heading: "TOD Tracking"  },
    { path: "/DailyConsumption", element: <DailyConsumption />, heading:"Consumption Monitoring" },
    { path: "/Section-Wise-View", element: <Sectionpoints />, heading:"Sections" },
    { path: "/TODview", element: <TODview /> },
    { path: "/Live-Alerts", element: <LiveAlerts /> },
    { path: "/Historical-Alerts", element: <OperatorAlertstable /> },
    { path: "/Livedata", element: <LiveData /> },
    { path: "/Historydata", element: <Historydata />, heading:"History Data" },
    { path: "/Breakdownlogs", element: <Breakdownlogs /> },
    { path: "/Breakdownlogsfetch", element: <Breakdownlogsfetch /> },
    { path: "/Transformermonitoring", element: <Transformermonitoring /> },
    { path: "/Transformermonitoringfetch", element: <Transformermonitoringfetch /> },
    { path: "/DGlogs", element: <DGlogs /> },
    { path: "/DGlogsfetch", element: <DGlogsfetch /> },
    { path: "/targetEfficiency", element: <TargetEfficiency /> },
    { path: "/PowerBills", element: <PowerBills /> },
    { path: "/PowerBillsfetch", element: <PowerBillsfetch /> },
    { path: "/Productionentry", element: <Productionentry /> },
    { path: "/Voltagediagnose", element: <Voltagediagnose /> },
    { path: "/Powersurgediagnose", element: <Powersurgediagnose /> },
    { path: "/Harmonicdistortiondiagnose", element: <Harmonicdistortiondiagnose /> },
    { path: "/Unbalancedloaddiagnose", element: <Unbalancedloaddiagnose /> },
    { path: "/LowPFdiagnose", element: <LowPFdiagnose /> },
    { path: "/LeadPFdiagnose", element: <LeadPFdiagnose /> },
    { path: "/Idle_Loss_Revenue_Diagnose", element: <Idle_Loss_Revenue_Diagnose /> },
    { path: "/TOD_loss_Revenue_Diagnose", element: <TOD_loss_Revenue_Diagnose /> },
    { path: "/Utilization_Loss_Revenue_Diagnose", element: <Utilization_Loss_Revenue_Diagnose /> },
    { path: "/Transformer_Loss_Revenue_Diagnose", element: <Transformer_Loss_Revenue_Diagnose /> },
    { path: "/Diagnose/:token_id", element: <Diagnose /> },

    // ------------------------ Supervisor ---------------

    { path: "/Statussummary", element: <Statussummaryview /> },
    { path: "/Tranformerform", element: <Tranformerform /> },
    { path: "/Transformerformfetch", element: <Transformerformfetch /> },
    { path: "/Areaform", element: <Areaform /> },
    { path: "/Areaformfetch", element: <Areaformfetch /> },
    { path: "/EnergyConsumption", element: <EnergyConsumption /> },
    { path: "/PF_MD", element: <PF_MD /> },
    { path: "/Monthly_PF", element: <Monthly_PF /> },
    { path: "/Revenueloss", element: <Revenueloss /> },
    { path: "/Energyintensity", element: <Energyintensity /> },
    { path: "/Utilization_Percenatage", element: <Utilization_Percenatage /> },
    { path: "/loadgraphs", element: <AllLoadGraphs />, heading:"Load Management"},
    { path: "/AllTrends", element: <AllTrends />, heading:"Trends" },
    { path: "/Alertscount", element: <Alertscount /> },
    { path: "/Downtime_Uptime", element: <Downtime_Uptime /> },
    { path: "/Transformerhealth", element: <Transformerhealth /> },
    { path: "/TOD_Run_Time_Week", element: <TOD_Run_Time_Week /> },
    { path: "/TOD_Run_Time_Month", element: <TOD_Run_Time_Month /> },
    { path: "/RCPE_Report", element: <RCPE_Report /> },
    { path: "/Type_of_load_Consumption", element: <Type_of_load_Consumption /> },
    { path: "/GeneratorsKPI", element: <GeneratorsKPI /> },
    { path: "/Incomers", element: <Incomers /> },
    { path: "/Incomersfetch", element: <Incomersfetch /> },
    { path: "/Groundingpoints", element: <Groundingpoints /> },
    { path: "/GroundingpointsFetch", element: <GroundingpointsFetch /> },
    { path: "/HVform", element: <HVform /> },
    { path: "/HVformFetch", element: <HvformFetch /> },
    { path: "/Parentdetails", element: <Parentdetails /> },
    { path: "/Mastertransformerform", element: <Mastertransformerform /> },
    { path: "/Mastertransformerfetch", element: <Mastertransformerfetch /> },
    { path: "/LVform", element: <LVform /> },
    { path: "/LVformFetch", element: <LVformFetch /> },
    { path: "/Mastergenerator", element: <Mastergenerator /> },
    { path: "/Mastergeneratorfetch", element: <Mastergeneratorfetch /> },
    { path: "/Sections", element: <Sections /> },
    { path: "/Sectionsfetch", element: <Sectionsfetch /> },
    { path: "/Mastermachine", element: <Mastermachine /> },
    { path: "/Mastermachinefetch", element: <Mastermachinefetch /> },
    { path: "/UPS", element: <UPS /> },
    { path: "/UPSfetch", element: <UPSfetch /> },
    { path: "/Stebilizer", element: <Stebilizer /> },
    { path: "/Stebilizersfetch", element: <Stebilizersfetch /> },
    { path: "/PFcorrectors", element: <PFcorrectors /> },
    { path: "/PFcorrectorsfetch", element: <PFcorrectorsfetch /> },
    { path: "/Harmonicfilter", element: <Harmonicfilter /> },
    { path: "/Harmonicfiltersfetch", element: <Harmonicfiltersfetch /> },
    { path: "/VFD", element: <VFD /> },
    { path: "/VFDfetch", element: <VFDfetch /> },
    { path: "/LightingArrestor", element: <LightingArrestor /> },
    { path: "/LightingArrestorfetch", element: <LightingArrestorfetch /> },
    { path: "/Tariff", element: <Tariff /> },
    { path: "/Alertsduration", element: <Alertsduration /> },
    { path: "/Alertsdurationfetch", element: <Alertsdurationfetch /> },
    { path: "/Fixedvariables", element: <Fixedvariables /> },
    { path: "/CriticalAlerts", element: <CriticalAlerts /> },
    { path: "/critical-alerts-tab", element: <AllCriticalAlertsTabs />, heading:"Critical Alerts" },
    { path: "/Voltageregulator/:token_id", element: <Voltageregulator /> },
    { path: "/Voltageregulatorfetch", element: <Voltageregulatorfetch /> },
    { path: "/Stabilizerform", element: <Stabilizerform /> },
    { path: "/Stabilizerformfetch", element: <Stabilizerformfetch /> },
    { path: "/VoltageGroundingresistance/:token_id", element: <Groundingresistance /> },
    { path: "/VoltageGroundingresistancefetch", element: <Groundingresistancefetch /> },
    { path: "/EnvironmentalFactors/:token_id", element: <EnvironmentalFactors /> },
    { path: "/EnvironmentalFactorsfetch", element: <EnvironmentalFactorsfetch /> },
    { path: "/Powersurge_Groundingresistance/:token_id", element: <Powersurge_Groundingresistance /> },
    { path: "/Powersurge_Groundingresistancefetch", element: <Powersurge_Groundingresistancefetch /> },
    { path: "/Internal_Fault/:token_id", element: <Internal_Fault /> },
    { path: "/Internal_Faultfetch", element: <InternalFaultfetch /> },
    { path: "/Harmonic_Form/:token_id", element: <Harmonic_Form /> },
    { path: "/Harmonic_Formfetch", element: <Harmonic_Formfetch /> },
    { path: "/Power_Quality_Form/:token_id", element: <Power_Quality_Form /> },
    { path: "/Power_Quality_Formfetch", element: <Power_Quality_Formfetch /> },
    { path: "/Oil_Winding_Temperature_Form/:token_id", element: <Oil_Winding_Temperature_Form /> },
    { path: "/Oil_Winding_Temperature_Formfetch", element: <Oil_Winding_Temperature_Formfetch /> },
    { path: "/Impedance_Test_Form/:token_id", element: <Impedance_Test_Form /> },
    { path: "/Impedence_Test_Formfetch", element: <Impedence_Test_Formfetch /> },
    { path: "/Loose_Connection_and_Corrosion_Form/:token_id", element: <Loose_Connection_and_Corrosion_Form /> },
    { path: "/Loose_Connection_and_Corrosion_Formfetch", element: <Loose_Connection_and_Corrosion_Formfetch /> },
    { path: "/Hotspot_Test_Form/:token_id", element: <Hotspot_Test_Form /> },
    { path: "/Hotspot_Test_Formfetch", element: <Hotspot_Test_Formfetch /> },
    { path: "/APFC_Signalling_Manual_Form/:token_id", element: <APFC_Signalling_Manual_Form /> },
    { path: "/APFC_Signalling_Manual_Formfetch", element: <APFC_Signalling_Manual_Formfetch /> },
    { path: "/Capacitance_Manual_Form/:token_id", element: <Capacitance_Manual_Form /> },
    { path: "/Capacitance_Manual_Formfetch", element: <Capacitance_Manual_Formfetch /> },
    { path: "/Designation", element: <Designation />, heading:"Designations" },
    { path: "/DesignationTable", element: <DesignationTable />, heading:"Designations" },
    { path: "/SuperadminRegistration", element: <SuperadminRegistration />, heading:"Super Admin" },
    { path: "/SuperadminTable", element: <SuperadminTable />, heading:"Super Admin" },
    { path: "/Usercreation", element: <Usercreation /> , heading:"User Details"},
    { path: "/Userfetch", element: <Userfetch />, heading:"User Details" },
    { path: "/Main_transformerform", element: <Main_transformerform /> },
    { path: "/Main_TransformerFormFetch", element: <Main_TransformerFormFetch /> },
    { path: "/UserdetailsHeader", element: <UserdetailsHeader /> },
    { path: "/Stationpoints", element: <Stationpoints /> },
    { path: "/Alldash", element: <Alldash /> , heading: "Home"},
    { path: "/AllReports", element: <AllReports /> , heading:"Reports"},
    { path: "/ReportsAlertscount", element: <ReportsAlertscount /> },
    { path: "/ReportDowntime_Uptime", element: <ReportDowntime_Uptime /> },
    { path: "/ReportRCPE_Report", element: <ReportRCPE_Report /> },
    { path: "/Tasks", element: <Tasks /> },
    { path: "/Doitlater", element: <Doitlater /> },
    { path: "/Todotasks", element: <Todotasks /> },
    { path: "/TotalTaskcounts", element: <TotalTaskcounts /> },
    { path: "/Masterforms", element: <Formheadercomponent />, heading:"Master Forms" },
    { path: "/Calenderscombine", element: <Calenderscombine />, heading:"Monthly Calender" },
    { path: "/DailyConsumptionprint", element: <DailyConsumptionprint /> },
    { path: "/avanti", element: <TodInformation /> },

    // { 
    //     path: "supervisor", 
    //     element: <RestrictedRoute><Routes>
    //         {supervisorRoutes.map((route, index) => (
    //             <Route key={index} path={route.path} element={route.element} />
    //         ))}
    //     </Routes></RestrictedRoute>
    // },
];
