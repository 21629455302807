import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import "./SuperadminRegistrationfetch.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, Row, Col } from "antd";
import { Table, TableBody, TableCell, TableRow, Box } from "@mui/material";
import CommonTable from "../../Common/CommonTable/CommonTable";

const SuperadminTable = () => {
  const [superadmins, setSuperadmins] = useState([]);
  const [editData, setEditData] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const tableColumns = [
    { id: 1, label: "Superadmin ID", align: "center" },
    { id: 2, label: "Designation", align: "center" },
    { id: 3, label: "Name", align: "center" },
    { id: 4, label: "Username", align: "center" },
    { id: 5, label: "Password", align: "center" },
    { id: 6, label: "Confirm Password", align: "center" },
    { id: 7, label: "Status", align: "center" },
    { id: 8, label: "Created By", align: "center" },
    { id: 9, label: "Created At", align: "center" },
    { id: 10, label: "Modified By", align: "center" },
    { id: 11, label: "Modified At", align: "center" },
    { id: 12, label: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchSuperadmins();
    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUser(user);
  }, []);

  const fetchSuperadmins = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins`
      );
      setSuperadmins(response.data);
    } catch (error) {
      console.error("Error fetching superadmins:", error);
    }
  };

  const handleEditClick = (superadmin) => {
    setEditData(superadmin);
    setValidationErrors({});
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditData(null);
  };

  const handleEditSubmit = async (values) => {
    // Validate form inputs
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      toast.error("Please fix all errors before submitting.");
      return;
    }

    const updatedSuperadminData = {
      ...editData,
      ...values,
      modifiedby: loggedInUser.username, // Update modifiedby field with logged-in user's username
    };

    try {
      // console.log('Submitting Edit Data:', updatedSuperadminData);
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins/${editData._id}`,
        updatedSuperadminData
      );
      // console.log('Response:', response.data);
      fetchSuperadmins();
      setIsModalVisible(false);
      setEditData(null);
      toast.success("Superadmin updated successfully");
    } catch (error) {
      console.error(
        "Error updating superadmin:",
        error.response || error.message
      );
      if (
        error.response &&
        error.response.data.message === "Superadmin username must be unique"
      ) {
        toast.error(
          "Username already exists. Please choose a different username."
        );
      } else {
        toast.error("Failed to update superadmin. Please try again later.");
      }
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    console.log(
      `Toggling status for ID: ${id}, Current Status: ${currentStatus}`
    );
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins/${id}/toggle-status`,
        {
          status: !currentStatus,
          modifiedby: loggedInUser.username, // Update modifiedby field with logged-in user's username
        }
      );
      console.log(`Toggle status response: `, response.data);
      fetchSuperadmins();
    } catch (error) {
      console.error(
        "Error toggling status:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="super-regfetch-total">
      <div id="super-regfetch-right">
        <div id="super-regfetch-right-2">
          <div id="super-regfetch-right-21">
            <label>
              <Link to="/SuperadminRegistration">
                <button>Add New</button>
              </Link>
            </label>
          </div>

          <Box
            mt={2}
            sx={{
              width: "93vw",
            }}
          > 
                    <CommonTable
            height={500}
            columns={tableColumns}
            isWithPagination={false}
          >
            {superadmins.map((superadmin) => (
              <TableRow key={superadmin._id}>
                <TableCell align="center">{superadmin.superadmin_id}</TableCell>
                <TableCell align="center">{superadmin.designation}</TableCell>
                <TableCell align="center">{superadmin.name}</TableCell>
                <TableCell align="center">{superadmin.username}</TableCell>
                <TableCell align="center">{superadmin.password}</TableCell>
                <TableCell align="center">
                  {superadmin.confirmpassword}
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() =>
                      handleToggleStatus(superadmin._id, superadmin.status)
                    }
                  >
                    {superadmin.status ? "Active" : "Inactive"}
                  </Button>
                </TableCell>
                <TableCell align="center">{superadmin.createdby}</TableCell>
                <TableCell align="center">{superadmin.createdat}</TableCell>
                <TableCell align="center">{superadmin.modifiedby}</TableCell>
                <TableCell align="center">{superadmin.modifiedat}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEditClick(superadmin)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </CommonTable>
          </Box>
          <Modal
            title="Edit Super Admin"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null} // Remove default footer buttons
          >
            {editData && (
              <Form
                layout="vertical"
                onFinish={handleEditSubmit}
                initialValues={{
                  name: editData.name,
                  username: editData.username,
                  password: editData.password,
                  confirmpassword: editData.confirmpassword,
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      initialValue={editData.name}
                      rules={[
                        { required: true, message: "Please enter the Name" },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Username"
                      name="username"
                      initialValue={editData.username}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Username",
                        },
                        {
                          pattern: /^[a-z0-9]+$/,
                          message:
                            "Username should only contain lowercase letters and numbers.",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            username: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Password"
                      name="password"
                      initialValue={editData.password}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Password",
                        },
                        {
                          validator: (_, value) => {
                            if (value && value === editData.username) {
                              return Promise.reject(
                                "Password should not be the same as Username."
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmpassword"
                      initialValue={editData.confirmpassword}
                      dependencies={["password"]} // Ensure this field depends on "password"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm the Password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Passwords do not match.");
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            confirmpassword: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button style={{ marginRight: "8px" }} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#252B3F",
                      color: "#ffff",
                      border: "none",
                    }}
                    htmlType="submit" // Submit the form when clicked
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SuperadminTable;
