
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TODview.css';

const DailyTODconsumption = () => {
    const [data, setData] = useState([{
        date: 'N/A',
        incentives: { runHours: 'N/A', consumption: 'N/A' },
        penalty: { runHours: 'N/A', consumption: 'N/A' },
        normal: { runHours: 'N/A', consumption: 'N/A' }
    }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-hv-yesterday`);
                if (response.data.length > 0) {
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div id='dailytod-total'>
            {data.map((item, index) => (
                <div key={index} >
                    <div id='dailytod-div-1'>
                        <div id='dailytod-div-12'>
                            <span>TOD consumption (kWh)</span>
                        </div>

                        <div id='dailytod-div-123'>
                            <div>
                                <div id='dailytod-value'>{item.incentives.consumption}</div>
                                <div id='dailytod-tohead-in'> Incentive </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div id='dailytod-value'>{item.penalty.consumption}</div>
                                <div id='dailytod-tohead-pe'> Penalty </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div id='dailytod-value'>{item.normal.consumption}</div>
                                <div id='dailytod-tohead-no'> Normal </div>
                            </div>
                        </div>

                    </div>
                    <hr></hr>
                    <div id='dailytod-div-2'>
                        <div id='dailytod-div-21'>
                            <span>Run Hours (durations)</span>
                        </div>
                      
                        <div id='dailytod-div-1234'>
                            <div>
                                <div id='dailytod-value1'>{item.incentives.runHours}</div>
                                <div id='dailytod-tohead-in'> Incentive </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div id='dailytod-value1'>{item.penalty.runHours}</div>
                                <div id='dailytod-tohead-pe'> Penalty </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div id='dailytod-value1'>{item.normal.runHours}</div>
                                <div id='dailytod-tohead-no'> Normal </div>
                            </div>
                        </div>

                    </div>
                    <div id='dailytod-last'>Last Updated :{item.date}</div>
                </div>
            ))}
        </div>
    );
};

export default DailyTODconsumption;


