
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './MaxdemsndkVA.css';

const DashMaxdemsndkVA = ({ selectedMonth }) => {
    const [maxDemandData, setMaxDemandData] = useState([]); // Store raw max demand data
    const [maxValue, setMaxValue] = useState(null); // Store max value fetched from the API
    const [threshold90, setThreshold90] = useState(null); // 90% of max value
    const [threshold95, setThreshold95] = useState(null);
    const maxDemandChartRef = 'dashmaxdemand-barchart'; // ID of the chart container

    // Fetch the maximum demand value
    const fetchMaxValue = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`);
            if (response.data && response.data.length > 0) {
                const maxVal = parseFloat(response.data[0]["Contracted_max_demand_(CMD)"]);
                if (!isNaN(maxVal)) {
                    setMaxValue(maxVal);
                    setThreshold90(maxVal * 0.90); // Calculate 90%
                    setThreshold95(maxVal * 0.95);// Calculate 95%
                } else {
                    console.error("Invalid max value fetched");
                }
            }
        } catch (error) {
            console.error("Error fetching the max value:", error.message);
        }
    };

    // Fetch the max demand data for the selected month
    const fetchMaxDemandData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_EMS_URL}/api/maxdemand`,
                {
                    params: {
                        month: selectedMonth || new Date().toISOString().slice(0, 7),
                    },
                }
            );
            setMaxDemandData(response.data);
        } catch (error) {
            console.error("Error fetching max demand data:", error.message);
        }
    };

    // Fetch both the max value and demand data on mount or when the month changes
    useEffect(() => {
        fetchMaxValue();
        fetchMaxDemandData();
    }, [selectedMonth]);

    // Initialize the chart using `getElementById`
    useEffect(() => {
        if (
            maxDemandData.date?.length > 0 &&
            maxValue !== null &&
            threshold90 !== null &&
            threshold95 !== null
        ) {
            const chartContainer = document.getElementById(maxDemandChartRef);
            if (chartContainer) {
                const myChart = echarts.init(chartContainer);

                // const threshold90 = maxValue * 0.90;
                // const threshold95 = maxValue * 0.95;

                const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                        },
                    },
                    // legend: {
                    //     data: ['Maximum Demand (kVA)'],
                    // },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: maxDemandData.date,
                            axisLabel: { interval: 0, rotate: 60 },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            max: maxValue,
                            axisLabel: {
                                formatter: '{value} kVA',
                            },
                            axisPointer: {
                                snap: true,
                            },
                        },
                    ],
                    visualMap: {
                        show: false,
                        pieces: [
                            {
                                lte: threshold90,
                                color: 'green'
                            },
                            {
                                gt: threshold90,
                                lte: threshold95,
                                color: 'orange'
                            },
                            {
                                gt: threshold95,
                                color: 'red'
                            }
                        ]
             
                        
                    },
                    series: [
                        {
                            name: 'Maximum Demand',
                            type: 'line',
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series',
                            },
                            data: maxDemandData.values.map(value => parseFloat(value.toFixed(2))),
                            tooltip: {
                                valueFormatter: function (value) {
                                  return value + ' kVA';
                                }
                              },
                        },
                    ],
                };

                myChart.setOption(option);

                const handleResize = () => {
                    myChart.resize();
                };

                window.addEventListener('resize', handleResize);

                return () => {
                    window.removeEventListener('resize', handleResize);
                    myChart.dispose();
                };
            }
        }
    }, [maxDemandData, maxValue, threshold90, threshold95]);

    // Check if data is available
    const isDataAvailable =
        maxDemandData.date?.length > 0 &&
        maxDemandData.values?.length > 0;

    return (
        <div id="dashmaxdemand-total">
            <div id="dashmaxdemand-right-1">
                <label>
                    <span id="dashmaxdemand-heaading">Maximum Demand (kVA)</span>
                </label>
                <label id="dashmaxdemand-right-headflex1">
                    <div id="dashmaxdemand-right-headflex12">
                        <span>kVA: &lt; {threshold90}<span id="btn1"></span></span>
                    </div>
                    <div id="dashmaxdemand-right-headflex12">
                    <span>kVA: &gt;= {threshold90} to {threshold95} <span id="btn2"></span></span>
                    </div>
                    <div id="dashmaxdemand-right-headflex12">
                    <span>kVA: &gt; {threshold95} <span id="btn3"></span></span>
                    </div>
                </label>
            </div>

            {!isDataAvailable ? (
                <p className="no-data" style={{ textAlign: 'center' }}>No Data Available</p>
            ) : (
                <div id={maxDemandChartRef} className="chart-container"></div>
            )}
        </div>
    );
};

export default DashMaxdemsndkVA;
