import { errorToast } from "../../../utils/toast";
import axiosInstance from "../axios";

import trendsRoutes from "./routes.json";

export const getAlertsCount = async (station, month) => {
  const params = {
    station,
    month,
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_ALERTS_COUNT, { params })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching alerts count");
  }
};

export const getDownTimeUptimeReports = async (station, month) => {
  const params = {
    station,
    month,
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_DOWNTIME_UPTIME_REPORTS, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching downtime and uptime reports");
  }
};

export const getRevenueLossesTrends = async (month, section) => {
  const params = {
    month, section
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_REVENUE_LOSSES, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching revenue losses trends");
  }
};

export const getRcpeReports = async (month) => {
  const params = {
    month,
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_RCPE_REPORTS, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching RCPE trends");
  }
};

export const getTodTrendsData = async (section, month) => {
  const params = {
    section,
    month,
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_TOD_TRENDS, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching Tod trends");
  }
};

export const getDistributionLosses = async (year) => {
  const params = {
    year,
  };

  try {
    return (
      await axiosInstance.get(trendsRoutes.API.GET_DISTRIBUTION_LOSSES, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching distribution losses");
  }
};
