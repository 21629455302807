import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Sections.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sections = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [form] = Form.useForm();
  const initialFormData = {
    date: getCurrentDate(),
    NAME: "",
    SELECT: "",
    Sectionid: "",
    "METER MAKE": "",
    "METER SL_NO": "",
    PHASE: "",
    "Load rating": "",
    "TYPE OF LOAD": "",
    "Total AMPS": "",
    "PROPOSED PERIODICITY OF MAINTENANCE": "",
    "PREVIOUS MAINTENANCE DONE DATE": "",
    MAC: "",
    Sid: "",
    Division: "",
    "Station point": "",
    Key: "",
    "Low voltage": "",
    "High voltage": "",
    "Low frequency": "",
    "High frequency": "",
    "High warning current": "",
    "High alert current": "",
    "High alert PF": "",
    "Low alert PF": "",
    "Full load current": "",
    "Idle Amps": "",
    distributionpoint: "",
    source: "",
    idlekva: "",
  };

  const [parentNames, setParentNames] = useState([]);

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  useEffect(() => {
    fetchParentNames();
  }, []);


  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        "Load rating": Number(values["Load rating"]),
        "Total AMPS": Number(values["Total AMPS"]),
        Sid: Number(values.Sid),
        "Low voltage": Number(values["Low voltage"]),
        "High voltage": Number(values["High voltage"]),
        "Low frequency": Number(values["Low frequency"]),
        "High frequency": Number(values["High frequency"]),
        "High warning current": Number(values["High warning current"]),
        "High alert current": Number(values["High alert current"]),
        "High alert PF": Number(values["High alert PF"]),
        "Low alert PF": Number(values["Low alert PF"]),
        "Full load current": Number(values["Full load current"]),
        "Idle Amps": Number(values["Idle Amps"]),
        idlekva: Number(values["idlekva"]),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/sections`,
        dataToSend
      );

      toast.success("Section added successfully");
      form.resetFields();
      fetchParentNames();
    } catch (error) {
        console.error("Error submitting data", error);
        toast.error("Error adding Section");
    }
  };

  return (
      <div id="masterforms-total">
        <div id="masterforms-right">
          <div id="masterforms-right-2">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialFormData}
              onFinish={handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>    {Masterformsheadingsss.SectionsHeading}</h3>
                <Button
                  type="default"
                  onClick={onViewData}
                  style={{
                    backgroundColor: "#252B3F",
                    borderColor: "#252B3F",
                    color: "#fff",
                  }}
                >
                  View Data
                </Button>
              </div>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Date" name="date">
                    <Input id="ant-input-style" type="date" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Parent Name" name="SELECT">
                    <Select
                      id="ant-input-style"
                      placeholder="Select Parent Name"
                    >
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Name" name="NAME">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Meter Make" name="METER MAKE">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Meter S. No." name="METER SL_NO">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Phase" name="PHASE">
                    <Select id="ant-input-style" placeholder="Select Phase">
                    <Select.Option value="">Select Phase</Select.Option>
                      <Select.Option value="3-Phase">3 Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1 Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1 Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1 Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Load Rating (in kVA)" name="Load rating">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Type of Load" name="TYPE OF LOAD">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Total AMPS" name="Total AMPS">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Low Voltage" name="Low voltage">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="High Voltage" name="High voltage">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Low Frequency" name="Low frequency">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="High Frequency" name="High frequency">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="High Warning Current"
                    name="High warning current"
                  >
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="High Alert Current"
                    name="High alert current"
                  >
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="High Alert PF" name="High alert PF">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Low Alert PF" name="Low alert PF">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Full Load Current" name="Full load current">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Idle Amps" name="Idle Amps">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="MAC"
                    name="MAC"
                    rules={[{ required: true, message: "Please enter a MAC address"}]}
                  >
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Sid"
                    name="Sid"
                    rules={[{ required: true, message:"Please enter a SID" }]}
                  >
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Division" name="Division">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Station Point"
                    name="Station point"
                    rules={[{ required: true, message:"Please enter a Station Point " }]}
                  >
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Key" name="Key">
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Proposed Periodicity of Maintenance"
                    name="PROPOSED PERIODICITY OF MAINTENANCE"
                  >
                    <Input id="ant-input-style" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Previous Maintenance Done Date"
                    name="PREVIOUS MAINTENANCE DONE DATE"
                  >
                     <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Distribution Point"
                    name="distributionpoint"
                  >
                    <Select id="ant-input-style" placeholder="Select Value">
                    <Select.Option value="Feeding Point">
                      Feeding Point
                    </Select.Option>
                    <Select.Option value="Loading Point">
                      Loading Point
                    </Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Source" name="source">
                    <Select id="ant-input-style" placeholder="Select Source">
                      <Select.Option value="YES">YES</Select.Option>
                      <Select.Option value="NO">NO</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Idle kVA" name="idlekva">
                    <Input type="number" id="ant-input-style" />
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#252B3F",
                    borderColor: "#252B3F",
                    color: "#fff",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

  );
};

export default Sections;
