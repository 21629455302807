import React, { useState } from "react";
import axios from "axios";
import "./Groundingpoints.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
const Groundingpoints = ({ onViewData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        ...values,
        resistance: Number(values.resistance),
      };

      await axios.post(
        `${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints`,
        dataToSend
      );
      toast.success("Grounding Points added successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error submitting data", error);
    }
  };

  return (
    <div id="masterforms-total">
      <div id="masterforms-right">
        <div id="masterforms-right-2">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ date: getCurrentDate() }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{Masterformsheadingsss.GroundingpointHeading}</h3>
              <Button
                type="default"
                onClick={onViewData}
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                View Data
              </Button>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date" name="date">
                  <Input id="ant-input-style" type="date" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Location" name="location">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Location Number" name="locationNumber">
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Resistance" name="resistance">
                  <Input id="ant-input-style" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Proposed Periodicity of Maintenance"
                  name="periodicityOfMaintenance"
                >
                  <Input id="ant-input-style" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Previous Maintenance Done Date"
                  name="previousMaintenanceDate"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#252B3F",
                  borderColor: "#252B3F",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Groundingpoints;
