

import React, { useEffect, useState } from "react";
import axios from "axios";
import * as echarts from "echarts";
import { DatePicker } from "antd";
import "./Energycousumption.css";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
const DashEnergyConsumption = () => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      year: dayjs(),
    },
  });

  const year = watch("year").year();

  const fetchData = async (year) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/energybillingfordashboard`,
        {
          params: { year }, // Pass the selected year as a parameter
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching the data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(year); 
  }, [year]); 

  useEffect(() => {
    if (!loading && data) {
      const chartDom = document.getElementById("main");
      const myChart = echarts.init(chartDom);

      const months = data.months;

      const deptFixedCharges = data.dept.dept_fixedcharges;
      const deptWithoutFixedCharges = data.dept.dept_withoutfixedcharges;
      const deptTotal = data.dept.dept_total;

      const softFixedCharges = data.software.software_fixedcharges;
      const softWithoutFixedCharges = data.software.software_withoutfixedcharges;
      const softTotal = data.software.Software_total;

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            const monthIndex = months.findIndex((month) => month === params[0].axisValue);

             // Check if the values are null or zero
             const deptTotalBill = deptTotal[monthIndex] !== null ? deptTotal[monthIndex] : "--";
             const softTotalBill = softTotal[monthIndex] !== null ? softTotal[monthIndex] : "--";
             const deptWithoutFixedCharge = deptWithoutFixedCharges[monthIndex] !== null ? deptWithoutFixedCharges[monthIndex] : "--";
             const deptFixedCharge = deptFixedCharges[monthIndex] !== null ? deptFixedCharges[monthIndex] : "--";
             const softWithoutFixedCharge = softWithoutFixedCharges[monthIndex] !== null ? softWithoutFixedCharges[monthIndex] : "--";
             const softFixedCharge = softFixedCharges[monthIndex] !== null ? softFixedCharges[monthIndex] : "--";
            return `
               <strong>Department Generated Total Bill: ${deptTotalBill}</strong><br/>
                ${params[0].marker} Department Generated Bill Without Fixed Charge: ${deptWithoutFixedCharge}<br/>
                ${params[1].marker} Department Generated Fixed Charge: ${deptFixedCharge}<br/>
                <strong>Patternlab Generated Total Bill: ${softTotalBill}</strong><br/>
                ${params[2].marker} Patternlab Generated Bill Without Fixed Charge: ${softWithoutFixedCharge}<br/>
                ${params[3].marker} Patternlab Generated Fixed Charge: ${softFixedCharge}
            `;
          },
        },
        legend: {
          data: [
            "Department generated - without fixed charges",
            "Department generated - fixed charges",
            "Patternlab generated - without fixed charges",
            "Patternlab generated - fixed charges",
          ],
        },
        xAxis: {
          type: "category",
          data: months,
          axisLabel: {
            formatter: (value) => value,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Department generated - without fixed charges",
            type: "bar",
            stack: "Department",
            data: deptWithoutFixedCharges,
            itemStyle: {
              color: "#987070", // Custom color
            },
            barWidth: 30,
          },
          {
            name: "Department generated - fixed charges",
            type: "bar",
            stack: "Department",
            data: deptFixedCharges,
            itemStyle: {
              color: "#C39898",
            },
            barWidth: 30,
          },
          {
            name: "Patternlab generated - without fixed charges",
            type: "bar",
            stack: "Patternlab",
            data: softWithoutFixedCharges,
            itemStyle: {
              color: "#5F6F65",
            },
            barWidth: 30,
          },
          {
            name: "Patternlab generated - fixed charges",
            type: "bar",
            stack: "Patternlab",
            data: softFixedCharges,
            itemStyle: {
              color: "#9CA986",
            },
            barWidth: 30,
          },
        ],
      };

      myChart.setOption(option);
    }
  }, [data, loading]);



  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("year");
  };

  const onChange = (value) => {
    if (value) {
      setValue("year", value);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "15px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <span>Year:</span>
        <DatePicker
          {...register("year")}
          style={{ width: "250px" }}
          defaultValue={getValues("year")}
          size={"large"}
          picker={"year"}
          disabledDate={disableFutureMonths} // Restricts future months
          onChange={onChange}
        />
      </div>
      <div id="dashenergy-total">
        <span>Energy consumption & billing</span>
        <div id="main" className="dashenergy-chart"></div>
      </div>
    </div>
  );
};

export default DashEnergyConsumption;
