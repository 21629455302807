// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#overallalertcount-right-21{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 75px;
    width: 100%;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    label{
       margin-right: 20px;
        display: flex;
        flex-direction: column;
        input[type=date]{
            height: 4.2vh;
            border-radius: 5px;
            border: 1.5px solid #DFE6FA;
        }
        select{
            height: 4.6vh;
            padding: 0px 10px;
            border-radius: 5px;
            border: 1.5px solid #DFE6FA; 
        }
        span{
            font-size: 13px;
        }
       
    }
    button{
        padding: 4.5px 2px;
        /* margin-left: 1vw; */
        background: transparent;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font-size: 25px;
        margin-right: 20px;
    }
}

#overallalertcount-total{
    table{
        margin-top: 1vh;
        background-color: white;
        width: 100%;
        height: 75vh;
        overflow-y:scroll;
        display:block;
    }
    th, td {
  
        width: calc(100% / 12); 
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/Reports/AlertsCount/Overallalertscount/Overallalertscount.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB;OACG,kBAAkB;QACjB,aAAa;QACb,sBAAsB;QACtB;YACI,aAAa;YACb,kBAAkB;YAClB,2BAA2B;QAC/B;QACA;YACI,aAAa;YACb,iBAAiB;YACjB,kBAAkB;YAClB,2BAA2B;QAC/B;QACA;YACI,eAAe;QACnB;;IAEJ;IACA;QACI,kBAAkB;QAClB,sBAAsB;QACtB,uBAAuB;QACvB,kBAAkB;QAClB,YAAY;QACZ,eAAe;QACf,eAAe;QACf,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,uBAAuB;QACvB,WAAW;QACX,YAAY;QACZ,iBAAiB;QACjB,aAAa;IACjB;IACA;;QAEI,sBAAsB;IAC1B;;AAEJ","sourcesContent":["#overallalertcount-right-21{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    height: 75px;\n    width: 100%;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    label{\n       margin-right: 20px;\n        display: flex;\n        flex-direction: column;\n        input[type=date]{\n            height: 4.2vh;\n            border-radius: 5px;\n            border: 1.5px solid #DFE6FA;\n        }\n        select{\n            height: 4.6vh;\n            padding: 0px 10px;\n            border-radius: 5px;\n            border: 1.5px solid #DFE6FA; \n        }\n        span{\n            font-size: 13px;\n        }\n       \n    }\n    button{\n        padding: 4.5px 2px;\n        /* margin-left: 1vw; */\n        background: transparent;\n        border-radius: 5px;\n        border: none;\n        cursor: pointer;\n        font-size: 25px;\n        margin-right: 20px;\n    }\n}\n\n#overallalertcount-total{\n    table{\n        margin-top: 1vh;\n        background-color: white;\n        width: 100%;\n        height: 75vh;\n        overflow-y:scroll;\n        display:block;\n    }\n    th, td {\n  \n        width: calc(100% / 12); \n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
