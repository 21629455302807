import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import Logo from "../../../Images/logo1.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useNavigate } from "react-router-dom";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { Button } from "antd";
const AppBarComponent = ({ pageName = "" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [viewLink, setViewLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setLoggedInUser(user);
    }
  }, []);

  const handleIconButtonClick = () => {
    navigate(`/Alertspage?section=alerts-history`);
  };

  // Determine the link based on access level
  useEffect(() => {
    const accessLevel = localStorage.getItem("accessedLevel");

    if (accessLevel === "Level-3") {
      setViewLink(
        "https://patternlab.portal.trainn.co/share/VVDp1nKfl5G8O2IdrnIL7g"
      );
    } else if (accessLevel === "Level-4") {
      setViewLink(
        "https://patternlab.portal.trainn.co/share/OVGypoFXLaq929QZZ2599g"
      );
    } else if (accessLevel === "Level-2") {
      setViewLink(
        "https://patternlab.portal.trainn.co/share/1l95MhdZXRIq6v6NDnt0IA"
      );
    }
  }, []);

  const handleTrainingClick = () => {
    if (viewLink) {
      window.open(viewLink, "_blank"); // Open the link in a new tab
    } else {
      alert("No training link available for your access level.");
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: `calc(100%)`,
      }}
    >
      <Box>
        <Typography
          variant="heading"
          fontWeight={800}
          fontSize={20}
          margin={3}
          color="black"
        >
          {pageName}
        </Typography>
      </Box>

      <Box
        sx={{
          color: "#0a172b",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box>
          <Button
            type="text"
            onClick={handleTrainingClick}
            icon={<ModelTrainingIcon />}
            style={{border: "1px solid black"}}
          >
            <Typography>Training</Typography>
          </Button>
        </Box>

        <IconButton onClick={handleIconButtonClick}>
          <NotificationsIcon sx={{ color: "#0a172b", fontSize: "30px" }} />
        </IconButton>

        <IconButton
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <AccountCircleRoundedIcon
            sx={{ color: "#0a172b", fontSize: "30px" }}
          />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ width: 250 }}>
            <Typography sx={{ p: 1, fontSize: 14 }}>
              {" "}
              <span style={{ fontWeight: "bold" }}>User Name:</span>{" "}
              {loggedInUser?.username || "--"}{" "}
            </Typography>
            <Typography sx={{ p: 1, fontSize: 14 }}>
              <span style={{ fontWeight: "bold" }}>Designation:</span>{" "}
              {loggedInUser?.designation || "-"}
            </Typography>
          </Box>
        </Popover>

        <Box sx={{ mt: 0.5 }}>
          <img src={Logo} />
        </Box>
      </Box>
    </Box>
  );
};

export default AppBarComponent;
