import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Input, Form, Row, Col, Typography, Select } from "antd";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Usercreationfetch.css";
import { Table, TableBody, TableCell, TableRow, Box } from "@mui/material";
import CommonTable from "../../Common/CommonTable/CommonTable";

const Userfetch = () => {
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null); // State to hold logged-in user details
  const [form] = Form.useForm();

  const tableColumns = [
    { id: 1, label: "Designation", align: "center" },
    { id: 2, label: "Name", align: "center" },
    { id: 3, label: "Username", align: "center" },
    { id: 4, label: "Password", align: "center" },
    { id: 5, label: "Confirm Password", align: "center" },
    { id: 6, label: "Status", align: "center" },
    { id: 7, label: "Created At", align: "center" },
    { id: 8, label: "Created By", align: "center" },
    { id: 9, label: "Modified At", align: "center" },
    { id: 10, label: "Modified By", align: "center" },
    { id: 11, label: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchUsers();
    fetchDesignations();
    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUser(user);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/users`
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/designationsotherthanlevel1`
      );
      setDesignations(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleEditClick = (user) => {
    setEditData(user);
    form.setFieldsValue(user);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditData(null);
  };

  const handleFinish = async (values) => {
    const updatedUserData = {
      ...editData,
      ...values,
      modifiedby: loggedInUser.username, // Track who modified the record
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/users/${editData._id}`,
        updatedUserData
      );
      toast.success("User updated successfully");
      fetchUsers(); // Refresh user data
      handleCancel(); // Close the modal
    } catch (error) {
      console.error(
        "Error updating user:",
        error.response ? error.response.data : error.message
      );

      if (
        error.response &&
        error.response.data.message === "Username must be unique"
      ) {
        toast.error(
          "Username already exists. Please choose a different username."
        );
      } else {
        toast.error("Failed to update user. Please try again later.");
      }
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const updatedUserData = {
        status: !currentStatus,
        modifiedby: loggedInUser.username, // Update modifiedby field with logged-in user's username
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/users/${id}/toggle-status`,
        updatedUserData
      );
      // console.log('Toggle status response:', response.data);

      fetchUsers(); // Assuming fetchUsers() fetches the updated list of users
    } catch (error) {
      console.error(
        "Error toggling status:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="userfetch-total">
      <div id="userfetch-right">
        <div id="userfetch-right-2">
          <div id="userfetch-right-21">
            <label>
              <Link to="/Usercreation">
                <button>Add New</button>
              </Link>
            </label>
          </div>

          <Box
            mt={2}
            sx={{
              width: "93vw",
            }}
          >
            <CommonTable
              height={500}
              columns={tableColumns}
              isWithPagination={false}
            >
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell align="center">{user.designation}</TableCell>
                  <TableCell align="center">{user.name}</TableCell>
                  <TableCell align="center">{user.username}</TableCell>
                  <TableCell align="center">{user.password}</TableCell>
                  <TableCell align="center">{user.confirmpassword}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleToggleStatus(user._id, user.status)}
                      variant="contained"
                      color={user.status ? "primary" : "secondary"}
                    >
                      {user.status ? "Active" : "Inactive"}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{user.createdat}</TableCell>
                  <TableCell align="center">{user.createdby}</TableCell>
                  <TableCell align="center">{user.modifiedat}</TableCell>
                  <TableCell align="center">{user.modifiedby}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleEditClick(user)}
                      variant="outlined"
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </CommonTable>
          </Box>

          <Modal
            title="Edit User"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              initialValues={editData}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Typography.Text strong>Designation</Typography.Text>
                    }
                    name="designation"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Designation",
                      },
                    ]}
                  >
                    <Select placeholder="Select Designation">
                      {designations.map((desig) => (
                        <Select.Option
                          key={desig._id}
                          value={desig.designation}
                        >
                          {desig.designation}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={<Typography.Text strong>Name</Typography.Text>}
                    name="name"
                    rules={[{ required: true, message: "Please enter Name" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<Typography.Text strong>Username</Typography.Text>}
                    name="username"
                    rules={[
                      { required: true, message: "Please enter Username" },
                      {
                        pattern: /^[a-z0-9]+$/,
                        message:
                          "Username should only contain lowercase letters and numbers.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={<Typography.Text strong>Password</Typography.Text>}
                    name="password"
                    rules={[
                      { required: true, message: "Please enter Password" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("username") !== value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Password should not be the same as Username."
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Typography.Text strong>Confirm Password</Typography.Text>
                    }
                    name="confirmpassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your Password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match.")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ textAlign: "right", marginTop: "10px" }}>
                <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#252B3F", color: "#fff" }}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Userfetch;