
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './Dashenergyintencity.css';

const Dashenergyintencity = ({ selectedMonth }) => {
    const [energyIntensityData, setEnergyIntensityData] = useState([]);

    const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_EMS_URL}/api/energyintensity/all`,
            {
              params: {
                month: selectedMonth || new Date().toISOString().slice(0, 7),
              },
            }
          );
          setEnergyIntensityData(response.data);
        } catch (error) {
          console.error("Error Fetching Energy Intensity Data:", error.message);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, [selectedMonth]);


      useEffect(() => {
        if (energyIntensityData && energyIntensityData.date && energyIntensityData.values) {
          const chartContainer = document.getElementById("dashenergyinten-barchart"); // Use document.getElementById
          if (chartContainer) {
            const myChart = echarts.init(chartContainer);
            const option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
              },
            //   legend: {
            //     data: ["Energy Intensity"],
            //   },
              grid: {
                left: "1%",
                right: "1%",
                bottom: "1%",
                containLabel: true,
              },
              xAxis: {
                type: "category",
                data: energyIntensityData.date,
                axisTick: {
                  alignWithLabel: true,
                },
                axisLabel: {
                  interval: 0,
                  rotate: 45, // Rotate labels for better readability
                },
              },
              yAxis: {
                type: "value",
                nameLocation: 'middle',
                axisLabel: {
                  formatter: (value) => value.toFixed(2),
                },
              },
              series: [
                {
                  name: "Energy Intensity",
                  type: "bar",
                  barWidth: "40%",
                  data: energyIntensityData.values,
                  itemStyle: {
                    color: "#7E95DF",
                  },
                },
              ],
            };
    
            myChart.setOption(option);
    
            const resizeChart = () => {
              myChart.resize();
            };
    
            window.addEventListener("resize", resizeChart);
    
            return () => {
              window.removeEventListener("resize", resizeChart);
              myChart.dispose();
            };
          } else {
            console.error("Chart container not found.");
          }
        }
      }, [energyIntensityData]);
    
      const isDataAvailable =
      energyIntensityData &&
      energyIntensityData.date?.length > 0 &&
      energyIntensityData.values?.length > 0;


    return (

        <div id='dashenergyinten-total'>
        <span>Energy Intensity</span>
        {!isDataAvailable ? (
          <p className="no-data" style={{ textAlign: "center" }}>
            No Data Available
          </p>
        ) : (
          <div id="dashenergyinten-barchart" />
        )}
      </div>
    );
};

export default Dashenergyintencity;
