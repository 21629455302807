import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Groundingpointsfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const GroundingpointsFetch = ({ onFormButtonClick }) => {
  const [groundingpoints, setGroundingpoints] = useState([]);
  const [groundingpointseditData, setGroundingpointsEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchGroundingpoints = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints`
      );
      setGroundingpoints(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchGroundingpoints();
  }, []);

  const handleEditClick = (groundingpoint) => {
    setGroundingpointsEditData(groundingpoint);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setGroundingpointsEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setGroundingpointsEditData({ ...groundingpointseditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DD") : null;
      setGroundingpointsEditData({
        ...groundingpointseditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...groundingpointseditData,
        resistance: Number(groundingpointseditData.resistance),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints/${groundingpointseditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Grounding Points updated sucessfully");
      fetchGroundingpoints();
      setGroundingpointsEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="groundingpointsfetch-total">
      <div id="groundingpointsfetch-right">
        <div id="groundingpointsfetch-right-2">
          <div id="groundingpointsfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.GroundingpointHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>

          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Location</th>
                <th>Location Number</th>
                <th>Resistance</th>
                <th>Periodicity of Maintenance</th>
                <th>Previous Maintenance Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groundingpoints.map((gp) => (
                <tr key={gp._id}>
                  <td>{gp.date}</td>
                  <td>{gp.location}</td>
                  <td>{gp.locationNumber}</td>
                  <td>{gp.resistance}</td>
                  <td>{gp.periodicityOfMaintenance}</td>
                  <td>{gp.previousMaintenanceDate}</td>
                  <td>
                    <button onClick={() => handleEditClick(gp)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal
            title="Edit Grounding Point"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" },
            }}
          >
            {groundingpointseditData && (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Date">
                      <Input
                        name="date"
                        value={groundingpointseditData.date}
                        onChange={handleEditChange}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Location">
                      <Input
                        name="location"
                        value={groundingpointseditData.location}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Location Number">
                      <Input
                        name="locationNumber"
                        value={groundingpointseditData.locationNumber}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Resistance">
                      <Input
                        type="number"
                        name="resistance"
                        value={groundingpointseditData.resistance}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* Repeat the same structure for all other fields */}
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Proposed periodicity of maintenance">
                      <Input
                        name="periodicityOfMaintenance"
                        value={groundingpointseditData.periodicityOfMaintenance}
                        onChange={handleEditChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Previous Maintenance Done Date">
                      <DatePicker
                        disabledDate={disableDates}
                        value={
                          groundingpointseditData.previousMaintenanceDate
                            ? dayjs(
                                groundingpointseditData.previousMaintenanceDate
                              )
                            : null
                        }
                        onChange={(date) =>
                          setGroundingpointsEditData({
                            ...groundingpointseditData,
                            previousMaintenanceDate: date,
                          })
                        }
                        format={dateFormat}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default GroundingpointsFetch;
