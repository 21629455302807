
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Newunfilledforms.css';

const Newunfilledforms = ({ setUnfilledFormsCount }) => {
    const [unfilledForms, setUnfilledForms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalUnfilledCount, setTotalUnfilledCount] = useState(0);
    const navigate = useNavigate();

    // Fetch the unfilled forms data
    const fetchUnfilledForms = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/unfilledforms`);
            const { data, Total_unfilled_form_count } = response.data;

            setUnfilledForms(data);
            setTotalUnfilledCount(Total_unfilled_form_count);
            setUnfilledFormsCount(Total_unfilled_form_count); 
            setLoading(false);
        } catch (error) {
            console.error("Error fetching unfilled forms:", error);
            setLoading(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchUnfilledForms();
    }, []);



const handleNavigate = (formName, unfilledDateMonth) => {
  let dateParam = '';
  let monthParam = '';

  // Check if the format is "DD-MM-YYYY" (date) or "MM-YYYY" (month)
  if (/^\d{2}-\d{2}-\d{4}$/.test(unfilledDateMonth)) {
      // It's a date format
      dateParam = unfilledDateMonth;
  } else if (/^\d{2}-\d{4}$/.test(unfilledDateMonth)) {
      // It's a month format
      monthParam = unfilledDateMonth;
  }

  // Navigate to the appropriate route based on the form name and parameters
  switch (formName) {
      case 'ProductionEntry':
          if (dateParam) {
              navigate(`/Forms?section=Productionentry-1&date=${dateParam}`);
          }
          break;
      case 'Transformer':
          if (dateParam) {
              navigate(`/Forms?section=Transformermonitoring-1&date=${dateParam}`);
          }
          break;
      case 'PowerBills':
          if (monthParam) {
              navigate(`/Forms?section=PowerBills-1&month=${monthParam}`);
          }
          break;
      case 'TargetEfficiency':
          if (monthParam) {
              navigate(`/Forms?section=TargetEfficiency-1&month=${monthParam}`);
          }
          break;
      default:
          console.error("Unknown form:", formName);
  }
};


    // Function to generate custom messages based on the form name
    const getFormMessage = (formName, unfilledDateMonth) => {
        switch (formName) {
            case 'ProductionEntry':
                return `No Production entry data available on ${unfilledDateMonth}. Please fill the form.`;
            case 'Transformer':
                return `No Transformer monitoring data available on ${unfilledDateMonth}. Please fill the form.`;
            case 'PowerBills':
                return `No Power Bills data available for the month of ${unfilledDateMonth}. Please fill the form.`;
            case 'TargetEfficiency':
                return `No Target Efficiency data available for the month of ${unfilledDateMonth}. Please fill the form.`;
            default:
                return `No data available for ${formName} on ${unfilledDateMonth}.`;
        }
    };

    return (
        <div>
            <div id='newpending-right-21'>
                <div id='newpending-topone'>
                    <label>
                        <span id='newpending-alert'>Pending Tasks ({totalUnfilledCount})</span>
                    </label>
                    <label>
                        <Link to='/Forms'>
                            <button id='newpending-viewmore'>View More</button>
                        </Link>
                    </label>
                </div>
                    <div id='newpending-right-211'>
                        {unfilledForms.length > 0 ? (
                            unfilledForms.map((form, index) => (
                                <div key={index} className="form-item-details">
                                  <label>
                                  <span>
                                        {getFormMessage(form.form_name, form.unfilled_date_month)}
                                    </span>
                                  </label>
                                    <label>
                                        <button onClick={() => handleNavigate(form.form_name, form.unfilled_date_month)}>
                                                Form
                                     </button>
                                    </label>
                                  
                                </div>
                            ))
                        ) : (
                            <div  tyle={{textAlign:"center"}}>No Pending Forms</div>
                        )}
                    </div>
            </div>
        </div>
    );
};

export default Newunfilledforms;

