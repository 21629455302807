import React, { useState, useEffect } from 'react';
import './Tariff.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, DatePicker } from "antd";
import dayjs from 'dayjs';

const Tariff = () => {
    const [form] = Form.useForm();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [dataId, setDataId] = useState(null);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_EMS_URL}/api/tariff`
            );
            const data = await response.json();
            if (data.length > 0) {
                form.setFieldsValue({
                    ...data[0],
                    date: dayjs(data[0].date) // Ensure date is a dayjs object
                });
                setDataId(data[0]._id); // Assuming the data has an _id field
                setIsSubmitted(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [form]);

    const handleSubmit = async (values) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_EMS_URL}/api/tariff`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ 
                        ...values, 
                        date: values.date.toISOString() // Convert to ISO string
                    }),
                }
            );
            if (response.ok) {
                const result = await response.json();
                setIsSubmitted(true);
                setDataId(result.insertedId); // Assuming the response contains the inserted ID
                toast.success("Tariff added successfully");
            } else {
                toast.error("Error submitting form");
            }
        } catch (error) {
            toast.error("Error submitting form");
        }
    };

    const handleUpdate = async (values) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_EMS_URL}/api/tariff/${dataId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ 
                        ...values, 
                        date: values.date.toISOString() // Convert to ISO string
                    }),
                }
            );
            if (response.ok) {
                setIsEditing(false);
                toast.success("Tariff updated successfully");
            } else {
                const errorText = await response.text();
                console.error("Error response:", errorText);
                toast.error("Error updating form");
            }
        } catch (error) {
            console.error("Error updating form:", error);
            toast.error("Error updating form");
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    return (
        <div id="masterforms-total">
            <div id="masterforms-right">
                <div id="masterforms-right-2">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={isEditing ? handleUpdate : handleSubmit}
                        initialValues={{ date: dayjs() }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h3>{Masterformsheadingsss.TariffHeading}</h3>
                        </div>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Updated Date" name="date">
                                    <DatePicker
                                        disabled={!isEditing}
                                        style={{ width: "100%" }}
                                        format="YYYY-MM-DD"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="General Tariff"
                                    name="General Tariff"
                                    rules={[{ required: true, message: "Please enter General Tariff" }]}
                                >
                                    <Input
                                        disabled={isSubmitted && !isEditing}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Penalty Tariff"
                                    name="Penalty Tariff"
                                    rules={[{ required: true, message: "Please enter Penalty Tariff" }]}
                                >
                                    <Input
                                        disabled={isSubmitted && !isEditing}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Incentives Tariff"
                                    name="Incentives Tariff"
                                    rules={[{ required: true, message: "Please enter Incentives Tariff" }]}
                                >
                                    <Input
                                        disabled={isSubmitted && !isEditing}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {!isSubmitted && (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        backgroundColor: "#252B3F",
                                        borderColor: "#252B3F",
                                        color: "#fff",
                                    }}
                                >
                                    {Masterformsheadingsss.Submitheading}
                                </Button>
                            )}
                            {isSubmitted && !isEditing && (
                                <Button
                                    type="default"
                                    onClick={handleEdit}
                                    style={{
                                        backgroundColor: "#252B3F",
                                        borderColor: "#252B3F",
                                        color: "#fff",
                                    }}
                                >
                                    Edit
                                </Button>
                            )}
                            {isSubmitted && isEditing && (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        backgroundColor: "#252B3F",
                                        borderColor: "#252B3F",
                                        color: "#fff",
                                    }}
                                >
                                    {Masterformsheadingsss.Updateheading}
                                </Button>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Tariff;
