import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Machinefetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const Mastermachinefetch = ({ onFormButtonClick }) => {
    const [masterMachines, setMasterMachines] = useState([]);
    const [masterMachineEditData, setMasterMachineEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-Sectionnames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    const fetchMasterMachines = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/mastermachines`);
            setMasterMachines(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
        fetchMasterMachines();
    }, []);

    const handleEditClick = (masterMachine) => {
        setMasterMachineEditData(masterMachine);
        setIsModalVisible(true);
    };

    
  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setMasterMachineEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setMasterMachineEditData({ ...masterMachineEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setMasterMachineEditData({
        ...masterMachineEditData,
        [field]: formattedDate,
      });
    }
  };


    const handleEditSubmit = async (e) => {
    
        try {

            const dataToSend = {
                ...masterMachineEditData,
                meterslno: Number(masterMachineEditData.meterslno),
        connectedloadinva: Number(masterMachineEditData.connectedloadinva),
        voltage: Number(masterMachineEditData.voltage),
        thresholdload: Number(masterMachineEditData.thresholdload),
        fullloadcurrent: Number(masterMachineEditData.fullloadcurrent),
        idleloadcurrent: Number(masterMachineEditData.idleloadcurrent),
        ratedcurrent: Number(masterMachineEditData.ratedcurrent),
        frequency: Number(masterMachineEditData.frequency),
        outputpower: Number(masterMachineEditData.outputpower),
        powerfactor: Number(masterMachineEditData.powerfactor),
        impedence: Number(masterMachineEditData.impedence),
              
              };

            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/mastermachines/${masterMachineEditData._id}`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success("Master Machine updated successfully");
            fetchMasterMachines();
            setMasterMachineEditData(null);
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='mastermachinefetch-total'>

            <div id='mastermachinefetch-right'>

                <div id='mastermachinefetch-right-2'>
                    <div id='mastermachinefetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.MachineHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter Serial No</th>
                                <th>Phase</th>
                                <th>Connected Load in kVA</th>
                                <th>Voltage</th>
                                <th>Threshold Load</th>
                                <th>Type of Load</th>
                                <th>Full Load Current</th>
                                <th>Idle Load Current</th>
                                <th>Rated Current</th>
                                <th>Frequency</th>
                                <th>Output Power</th>
                                <th>Power Factor</th>
                                <th>Load Rating</th>
                                <th>Impedance</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterMachines.map((masterMachine) => (
                                <tr key={masterMachine._id}>
                                    <td>{masterMachine.date}</td>
                                    <td>{masterMachine.parentname}</td>
                                    <td>{masterMachine.name}</td>
                                    <td>{masterMachine.metermake}</td>
                                    <td>{masterMachine.meterslno}</td>
                                    <td>{masterMachine.phase}</td>
                                    <td>{masterMachine.connectedloadinva}</td>
                                    <td>{masterMachine.voltage}</td>
                                    <td>{masterMachine.thresholdload}</td>
                                    <td>{masterMachine.typeofload}</td>
                                    <td>{masterMachine.fullloadcurrent}</td>
                                    <td>{masterMachine.idleloadcurrent}</td>
                                    <td>{masterMachine.ratedcurrent}</td>
                                    <td>{masterMachine.frequency}</td>
                                    <td>{masterMachine.outputpower}</td>
                                    <td>{masterMachine.powerfactor}</td>
                                    <td>{masterMachine.loadrating}</td>
                                    <td>{masterMachine.impedence}</td>
                                    <td>{masterMachine.proposedperiodicityofmaintenance}</td>
                                    <td>{masterMachine.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(masterMachine)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <Modal
                        title="Edit Machine"
                        visible={isModalVisible}
                        onOk={handleEditSubmit}
                        onCancel={handleCancel}
                        okText="Update"
                        cancelText="Cancel"
                        okButtonProps={{
                            style: { backgroundColor: "#252B3F", color: "#fff" },
                        }}
                    >
                        {masterMachineEditData && (
                            <Form layout="vertical">
                                       <div className="input-row">
                                       <Form.Item label="Date">
                                    <Input
                                        name="date"
                                        value={masterMachineEditData.date}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Parent Name">
                                    <Select
                                        value={masterMachineEditData.parentname}
                                        onChange={(value) =>
                                            setMasterMachineEditData({
                                                ...masterMachineEditData,
                                                parentname: value,
                                            })
                                        }
                                    >
                                        <Select.Option value="">Select Parent Name</Select.Option>
                                        {parentNames.map((name) => (
                                            <Select.Option key={name} value={name}>
                                                {name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                       </div>

                                       <div className="input-row"> 
                                       <Form.Item label="Name">
                                    <Input
                                        name="name"
                                        value={masterMachineEditData.name}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Meter Make">
                                    <Input
                                        name="metermake"
                                        value={masterMachineEditData.metermake}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                       </div>
                                <div className="input-row"> 
                                <Form.Item label="Meter Serial No">
                                    <Input
                                        name="meterslno"
                                        value={masterMachineEditData.meterslno}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Phase">
                                    <Select
                                        value={masterMachineEditData.phase}
                                        onChange={(value) =>
                                            setMasterMachineEditData({
                                                ...masterMachineEditData,
                                                phase: value,
                                            })
                                        }
                                    >
                                        <Select.Option value="">Select Phase</Select.Option>
                                        <Select.Option value="3-Phase">3-Phase</Select.Option>
                                        <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                                        <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                                        <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                                    </Select>
                                </Form.Item>
                                        </div>
                 
                                
                                <div className="input-row"> 
                                <Form.Item label="Connected Load in kVA">
                                    <Input
                                        name="connectedloadinva"
                                        value={masterMachineEditData.connectedloadinva}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Voltage">
                                    <Input
                                        name="voltage"
                                        value={masterMachineEditData.voltage}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                        </div>
                   
                                <div className="input-row"> 
                                <Form.Item label="Threshold Load">
                                    <Input
                                        name="thresholdload"
                                        value={masterMachineEditData.thresholdload}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Type of Load">
                                    <Input
                                        name="typeofload"
                                        value={masterMachineEditData.typeofload}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>  
                                        </div>
                       
                                <div className="input-row"> 
                                <Form.Item label="Full Load Current">
                                    <Input
                                        name="fullloadcurrent"
                                        value={masterMachineEditData.fullloadcurrent}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Idle Load Current">
                                    <Input
                                        name="idleloadcurrent"
                                        value={masterMachineEditData.idleloadcurrent}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item> 
                                        </div>
                     
                                <div className="input-row"> 
                                <Form.Item label="Rated Current">
                                    <Input
                                        name="ratedcurrent"
                                        value={masterMachineEditData.ratedcurrent}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Frequency">
                                    <Input
                                        name="frequency"
                                        value={masterMachineEditData.frequency}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                        </div>
                        
                                <div className="input-row"> 
                                <Form.Item label="Output Power">
                                    <Input
                                        name="outputpower"
                                        value={masterMachineEditData.outputpower}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Power Factor">
                                    <Input
                                        name="powerfactor"
                                        value={masterMachineEditData.powerfactor}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>   
                                        </div>
                      
                                <div className="input-row"> 
                                <Form.Item label="Load Rating">
                                    <Select
                                        value={masterMachineEditData.loadrating}
                                        onChange={(value) =>
                                            setMasterMachineEditData({
                                                ...masterMachineEditData,
                                                loadrating: value,
                                            })
                                        }
                                    >
                                        <Select.Option value="">Select Load Rating</Select.Option>
                                        <Select.Option value="Continious">Continious</Select.Option>
                                        <Select.Option value="Short Time">Short Time</Select.Option>
                                        <Select.Option value="Impulse">Impulse</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Impedance">
                                    <Input
                                        name="impedence"
                                        value={masterMachineEditData.impedence}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                        </div>
                      
                                <div className="input-row"> 
                                <Form.Item label="Proposed Periodicity of Maintenance">
                                    <Input
                                        name="proposedperiodicityofmaintenance"
                                        value={masterMachineEditData.proposedperiodicityofmaintenance}
                                        onChange={handleEditChange}
                                    />
                                </Form.Item>
                                <Form.Item label="Previous Maintenance Done Date">
                                    <DatePicker
                                        disabledDate={(current) =>
                                            current && current.isAfter(dayjs().endOf("day"))
                                        }
                                        value={
                                            masterMachineEditData.previousmaintenancedonedate
                                                ? dayjs(masterMachineEditData.previousmaintenancedonedate)
                                                : null
                                        }
                                        onChange={(date) =>
                                            handleEditChange(date, "previousmaintenancedonedate")
                                        }
                                        format={dateFormat}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item> 
                                        </div>
                        
                            </Form>
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default Mastermachinefetch;
