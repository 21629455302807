import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Generatorsfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const Mastergeneratorfetch = ({ onFormButtonClick }) => {
  const [masterGenerators, setMasterGenerators] = useState([]);
  const [masterGeneratorEditData, setMasterGeneratorEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  const fetchMasterGenerators = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators`
      );
      setMasterGenerators(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchMasterGenerators();
    fetchParentNames();
  }, []);

  // Handle edit click
  const handleEditClick = (section) => {
    setMasterGeneratorEditData(section);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setMasterGeneratorEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setMasterGeneratorEditData({ ...masterGeneratorEditData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setMasterGeneratorEditData({
        ...masterGeneratorEditData,
        [field]: formattedDate,
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
        const dataToSend = {
            ...masterGeneratorEditData,
            generatorpowerinva: Number(masterGeneratorEditData.generatorpowerinva),
            voltagelevel: Number(masterGeneratorEditData.voltagelevel),
            frequency: Number(masterGeneratorEditData.frequency),
            enginepower: Number(masterGeneratorEditData.enginepower),
            fuelconsumptionperhour:Number( masterGeneratorEditData.fuelconsumptionperhour),
            fuelcosumptionper1kwh:Number (masterGeneratorEditData.fuelcosumptionper1kwh),
          
          };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators/${masterGeneratorEditData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Master Generator updated successfully");
      fetchMasterGenerators();
      setMasterGeneratorEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div id="mastergeneratorfetch-total">
      <div id="mastergeneratorfetch-right">
        <div id="mastergeneratorfetch-right-2">
          <div id="mastergeneratorfetch-right-21">
            <label>
              <span> {Masterformsheadingsss.GeneratorHeading}</span>
            </label>
            <label>
              <button onClick={onFormButtonClick}>
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Parent Name</th>
                <th>Name</th>
                <th>Type of Generator</th>
                <th>Meter Make</th>
                <th>Meter Serial No.</th>
                <th>Phase</th>
                <th>Generator Power (kVA)</th>
                <th>Voltage Level</th>
                <th>Frequency</th>
                <th>Engine Power</th>
                <th>Cooling System</th>
                <th>Fuel Consumption per Hour</th>
                <th>Fuel Consumption per 1 kWh</th>
                <th>Start Type</th>
                <th>Proposed Periodicity of Maintenance</th>
                <th>Previous Maintenance Done Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {masterGenerators.map((masterGenerator) => (
                <tr key={masterGenerator._id}>
                  <td>{masterGenerator.date}</td>
                  <td>{masterGenerator.parentname}</td>
                  <td>{masterGenerator.name}</td>
                  <td>{masterGenerator.typeofgenerator}</td>
                  <td>{masterGenerator.metermake}</td>
                  <td>{masterGenerator.meterslno}</td>
                  <td>{masterGenerator.phase}</td>
                  <td>{masterGenerator.generatorpowerinva}</td>
                  <td>{masterGenerator.voltagelevel}</td>
                  <td>{masterGenerator.frequency}</td>
                  <td>{masterGenerator.enginepower}</td>
                  <td>{masterGenerator.coolingsystem}</td>
                  <td>{masterGenerator.fuelconsumptionperhour}</td>
                  <td>{masterGenerator.fuelcosumptionper1kwh}</td>
                  <td>{masterGenerator.starttype}</td>
                  <td>{masterGenerator.proposedperiodicityofmaintenance}</td>
                  <td>{masterGenerator.previousmaintenancedonedate}</td>
                  <td>
                    <button onClick={() => handleEditClick(masterGenerator)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal
            title="Edit Generator"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" }, // Change background color and text color
            }}
          >
            {masterGeneratorEditData && (
              <Form layout="vertical">
                {/* Date and Parent Name */}
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input
                      name="date"
                      value={masterGeneratorEditData.date}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Parent Name">
                    <Select
                      value={masterGeneratorEditData.parentname}
                      onChange={(value) =>
                        setMasterGeneratorEditData({
                          ...masterGeneratorEditData,
                          parentname: value,
                        })
                      }
                    >
                      <Select.Option value="">Select Parent Name</Select.Option>
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* Name, Type of Generator */}
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="name"
                      value={masterGeneratorEditData.name}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Type of Generator">
                    <Select
                      value={masterGeneratorEditData.typeofgenerator}
                      onChange={(value) =>
                        setMasterGeneratorEditData({
                          ...masterGeneratorEditData,
                          typeofgenerator: value,
                        })
                      }
                    >
                      <Select.Option value="">
                        Select type of generator
                      </Select.Option>
                      <Select.Option value="Solar">Solar</Select.Option>
                      <Select.Option value="Diesel Generator (DG)">
                        Diesel Generator (DG)
                      </Select.Option>
                      <Select.Option value="Waste Heat Recovery (WHR)">
                        Waste Heat Recovery (WHR)
                      </Select.Option>
                      <Select.Option value="Wind">Wind</Select.Option>
                      <Select.Option value="Hydro">Hydro</Select.Option>
                      <Select.Option value="Bio energy">
                        Bio energy
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* Meter Make, Meter SL No */}
                <div className="input-row">
                  <Form.Item label="Meter Make">
                    <Input
                      name="metermake"
                      value={masterGeneratorEditData.metermake}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Meter Serial No.">
                    <Input
                      name="meterslno"
                      value={masterGeneratorEditData.meterslno}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Phase, Generator Power */}
                <div className="input-row">
                  <Form.Item label="Phase">
                    <Select
                      value={masterGeneratorEditData.phase}
                      onChange={(value) =>
                        setMasterGeneratorEditData({
                          ...masterGeneratorEditData,
                          phase: value,
                        })
                      }
                    >
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1-Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1-Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1-Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Generator Power (kVA)">
                    <Input
                      type="number"
                      name="generatorpowerinva"
                      value={masterGeneratorEditData.generatorpowerinva}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Voltage Level, Frequency */}
                <div className="input-row">
                  <Form.Item label="Voltage Level">
                    <Input
                      type="number"
                      name="voltagelevel"
                      value={masterGeneratorEditData.voltagelevel}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Frequency">
                    <Input
                      type="number"
                      name="frequency"
                      value={masterGeneratorEditData.frequency}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Engine Power, Cooling System */}
                <div className="input-row">
                  <Form.Item label="Engine Power">
                    <Input
                      type="number"
                      name="enginepower"
                      value={masterGeneratorEditData.enginepower}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Cooling System">
                    <Input
                      name="coolingsystem"
                      value={masterGeneratorEditData.coolingsystem}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Fuel Consumption per Hour, Fuel Consumption per kWh */}
                <div className="input-row">
                  <Form.Item label="Fuel Consumption per Hour">
                    <Input
                      type="number"
                      name="fuelconsumptionperhour"
                      value={masterGeneratorEditData.fuelconsumptionperhour}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Fuel Consumption per 1 kWh">
                    <Input
                      type="number"
                      name="fuelcosumptionper1kwh"
                      value={masterGeneratorEditData.fuelcosumptionper1kwh}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Start Type, Proposed Periodicity of Maintenance */}
                <div className="input-row">
                  <Form.Item label="Start Type">
                    <Input
                      name="starttype"
                      value={masterGeneratorEditData.starttype}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Proposed Periodicity of Maintenance">
                    <Input
                      name="proposedperiodicityofmaintenance"
                      value={
                        masterGeneratorEditData.proposedperiodicityofmaintenance
                      }
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
                {/* Previous Maintenance Done Date */}
                <div className="input-row">
                  <Form.Item label="Previous Maintenance Done Date">
                    <DatePicker
                      disabledDate={disableDates}
                      value={
                        masterGeneratorEditData.previousmaintenancedonedate
                          ? dayjs(
                              masterGeneratorEditData.previousmaintenancedonedate
                            )
                          : null
                      }
                      onChange={(date) =>
                        handleEditChange(date, "previousmaintenancedonedate")
                      }
                      format={dateFormat}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Mastergeneratorfetch;
