import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Sectionsfetch.css";
import { Masterformsheadingsss } from "../../Operator/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table, Modal, Button, Input, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const Sectionsfetch = ({ onFormButtonClick }) => {
  const [sections, setSections] = useState([]);
  const [editData, setEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parentNames, setParentNames] = useState([]);

  const disableDates = (current) => {
    // Disable today and future dates
    return current && current.isAfter(dayjs().endOf("day"));
  };
  
  

  // Fetch sections data
  const fetchSections = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/sections`
      );
      setSections(response.data);
    } catch (error) {
      console.error("Error fetching sections data", error);
    }
  };

  const fetchParentNames = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`
      );
      const data = await response.json();
      setParentNames(data);
    } catch (error) {
      console.error("Error fetching parent names", error);
    }
  };

  useEffect(() => {
    fetchSections();
    fetchParentNames();
  }, []);

  // Handle edit click
  const handleEditClick = (section) => {
    setEditData(section);
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditData(null);
  };

  const handleEditChange = (e, field) => {
    if (e && e.target) {
      // For regular inputs
      const { name, value } = e.target;
      setEditData({ ...editData, [name]: value });
    } else if (field) {
      // For DatePicker
      const formattedDate = e ? e.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
      setEditData({ ...editData, [field]: formattedDate });
    }
  };
  
  
  

  // Handle form submit
  const handleEditSubmit = async () => {
    try {
      const dataToSend = {
        ...editData,
        "Load rating": Number(editData["Load rating"]),
        "Total AMPS": Number(editData["Total AMPS"]),
        Sid: Number(editData.Sid),
        "Low voltage": Number(editData["Low voltage"]),
        "High voltage": Number(editData["High voltage"]),
        "Low frequency": Number(editData["Low frequency"]),
        "High frequency": Number(editData["High frequency"]),
        "High warning current": Number(editData["High warning current"]),
        "High alert current": Number(editData["High alert current"]),
        "High alert PF": Number(editData["High alert PF"]),
        "Low alert PF": Number(editData["Low alert PF"]),
        "Full load current": Number(editData["Full load current"]),
        "Idle Amps": Number(editData["Idle Amps"]),
        idlekva: Number(editData["idlekva"]),
      };

      await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/sections/${editData._id}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Sections updated sucessfully");
      fetchSections();
      setEditData(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating data",
        error.response ? error.response.data : error.message
      );
    }
  };

   // Define columns for Ant Design table
   const columns = [
    {
        title: 'Action',
        key: 'action',
        fixed: 'left',
        width: 100,
        render: (_, record) => <button onClick={() => handleEditClick(record)}>Edit</button>,
    },
    { title: 'Parent Name', dataIndex: 'SELECT', key: 'SELECT' ,ellipsis: false,},
    { title: 'Station Point', dataIndex: 'Station point', key: 'Station point', fixed: 'left', width: 180, ellipsis: false, },
    { title: 'Name', dataIndex: 'NAME', key: 'NAME', ellipsis: false, width: 180 },
    { title: 'Meter Make', dataIndex: 'METER MAKE', key: 'METER MAKE',  ellipsis: false, },
    { title: 'Meter SL No', dataIndex: 'METER SL_NO', key: 'METER SL_NO' , ellipsis: false,},
    { title: 'Phase', dataIndex: 'PHASE', key: 'PHASE',  ellipsis: false, },
    { title: 'Load Rating', dataIndex: 'Load rating', key: 'Load rating',  ellipsis: false, },
    { title: 'Total AMPS', dataIndex: 'Total AMPS', key: 'Total AMPS', ellipsis: false, },
    { title: 'Proposed Maintenance Periodicity', dataIndex: 'PROPOSED PERIODICITY OF MAINTENANCE', key: 'PROPOSED PERIODICITY OF MAINTENANCE' , ellipsis: false,},
    { title: 'Previous Maintenance Date', dataIndex: 'PREVIOUS MAINTENANCE DONE DATE', key: 'PREVIOUS MAINTENANCE DONE DATE', ellipsis: false, },
    { title: 'MAC', dataIndex: 'MAC', key: 'MAC',  ellipsis: false, width: 150},
    { title: 'Sid', dataIndex: 'Sid', key: 'Sid', ellipsis: false, width: 150 },
    { title: 'Division', dataIndex: 'Division', key: 'Division', ellipsis: false, },
    { title: 'Key', dataIndex: 'Key', key: 'Key', ellipsis: false, },
    { title: 'Low Voltage', dataIndex: 'Low voltage', key: 'Low voltage', ellipsis: false, },
    { title: 'High Voltage', dataIndex: 'High voltage', key: 'High voltage', ellipsis: false, },
    { title: 'Low Frequency', dataIndex: 'Low frequency', key: 'Low frequency', ellipsis: false, },
    { title: 'High Frequency', dataIndex: 'High frequency', key: 'High frequency' ,  ellipsis: false,},
    { title: 'High Warning Current', dataIndex: 'High warning current', key: 'High warning current', ellipsis: false, },
    { title: 'High Alert Current', dataIndex: 'High alert current', key: 'High alert current' ,  ellipsis: false,},
    { title: 'High Alert PF', dataIndex: 'High alert PF', key: 'High alert PF' ,  ellipsis: false,},
    { title: 'Low Alert PF', dataIndex: 'Low alert PF', key: 'Low alert PF', ellipsis: false, },
    { title: 'Full Load Current', dataIndex: 'Full load current', key: 'Full load current' ,  ellipsis: false,},
    { title: 'Idle Amps', dataIndex: 'Idle Amps', key: 'Idle Amps' , ellipsis: false,},
    { title: 'Distribution Point', dataIndex: 'distributionpoint', key: 'distributionpoint', ellipsis: false, },
    { title: 'Source', dataIndex: 'source', key: 'source', ellipsis: false, },
    { title: 'Idle kVA', dataIndex: 'idlekva', key: 'idlekva' , ellipsis: false,},
    { title: 'Type of Load', dataIndex: 'TYPE OF LOAD', key: 'TYPE OF LOAD',  ellipsis: false, },
];

  return (
    <div id="sectionsfetch-total">
      <div id="sectionsfetch-right">
        <div id="sectionsfetch-right-2">
          <div id="sectionsfetch-right-21">
            <label>{Masterformsheadingsss.SectionsHeading}</label>
            <label>
              <button onClick={onFormButtonClick}>
                {" "}
                {Masterformsheadingsss.Formheading}
              </button>
            </label>
          </div>
          <div id="sectionsfetch-right-22">
            <Table
              className="sections-table"
              style={{ marginTop: "20px" }}
              dataSource={sections}
              columns={columns}
              rowKey="_id"
              scroll={{ x: "max-content", y: 300 }}
              pagination={false}
              sticky
            />
          </div>

          <Modal
            title="Edit Section"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" }, // Change background color and text color
            }}
          >
            {editData && (
              <Form layout="vertical">
                {/* Date and Parent Name */}
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input name="date" value={editData.date} disabled />
                  </Form.Item>
                  <Form.Item label="Parent Name">
                    <Select
                      value={editData.SELECT}
                      onChange={(value) =>
                        setEditData({ ...editData, SELECT: value })
                      }
                    >
                      <Select.Option value="">Select Parent Name</Select.Option>
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* Name, Meter Make */}
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="NAME"
                      value={editData.NAME}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Meter Make">
                    <Input
                      name="METER MAKE"
                      value={editData["METER MAKE"]}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                </div>

                {/* Meter Sl No, Phase */}
                <div className="input-row">
                  <Form.Item label="Meter SL No">
                    <Input
                      name="METER SL_NO"
                      value={editData["METER SL_NO"]}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Phase">
                    <Select
                      value={editData.PHASE}
                      onChange={(value) =>
                        setEditData({ ...editData, PHASE: value })
                      }
                    >
                      <Select.Option value="3-Phase">3 Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">
                        1 Phase-Vr
                      </Select.Option>
                      <Select.Option value="1-Phase-Vy">
                        1 Phase-Vy
                      </Select.Option>
                      <Select.Option value="1-Phase-Vb">
                        1 Phase-Vb
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* Load Rating, Type of Load */}
                <div className="input-row">
                  <Form.Item label="Load Rating">
                    <Input
                      type="number"
                      name="Load rating"
                      value={editData["Load rating"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Type of Load">
                    <Input
                      name="TYPE OF LOAD"
                      value={editData["TYPE OF LOAD"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Total AMPS, Proposed Periodicity of Maintenance */}
                <div className="input-row">
                  <Form.Item label="Total AMPS">
                    <Input
                      type="number"
                      name="Total AMPS"
                      value={editData["Total AMPS"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Proposed Periodicity of Maintenance">
                    <Input
                      name="PROPOSED PERIODICITY OF MAINTENANCE"
                      value={editData["PROPOSED PERIODICITY OF MAINTENANCE"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Previous Maintenance Done Date, MAC */}
                <div className="input-row">
                  <Form.Item label="Previous Maintenance Done Date">
                    <DatePicker
                     disabledDate={disableDates}
                      name="PREVIOUS MAINTENANCE DONE DATE"
                      value={
                        editData["PREVIOUS MAINTENANCE DONE DATE"]
                          ? dayjs(editData["PREVIOUS MAINTENANCE DONE DATE"])
                          : null
                      }
                      onChange={(date) =>
                        handleEditChange(date, "PREVIOUS MAINTENANCE DONE DATE")
                      }
                      style={{ width: "100%" }}
                      format={dateFormat}
                    />
                  </Form.Item>
                  <Form.Item label="MAC" required>
                    <Input
                      name="MAC"
                      value={editData.MAC}
                      onChange={handleEditChange}
                      required
                    />
                  </Form.Item>
                </div>

                {/* SID, Division */}
                <div className="input-row">
                  <Form.Item label="SID" required>
                    <Input
                      name="Sid"
                      type="number"
                      value={editData.Sid}
                      onChange={handleEditChange}
                      required
                    />
                  </Form.Item>
                  <Form.Item label="Division">
                    <Input
                      name="Division"
                      value={editData.Division}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Station Point, Key */}
                <div className="input-row">
                  <Form.Item label="Station Point" required>
                    <Input
                      name="Station point"
                      value={editData["Station point"]}
                      onChange={handleEditChange}
                      required
                    />
                  </Form.Item>
                  <Form.Item label="Key">
                    <Input
                      name="Key"
                      value={editData.Key}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Low Voltage, High Voltage */}
                <div className="input-row">
                  <Form.Item label="Low Voltage">
                    <Input
                      name="Low voltage"
                      type="number"
                      value={editData["Low voltage"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="High Voltage">
                    <Input
                      name="High voltage"
                      type="number"
                      value={editData["High voltage"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Low Frequency, High Frequency */}
                <div className="input-row">
                  <Form.Item label="Low Frequency">
                    <Input
                      name="Low frequency"
                      type="number"
                      value={editData["Low frequency"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="High Frequency">
                    <Input
                      name="High frequency"
                      type="number"
                      value={editData["High frequency"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* High Warning Current, High Alert Current */}
                <div className="input-row">
                  <Form.Item label="High Warning Current">
                    <Input
                      name="High warning current"
                      type="number"
                      value={editData["High warning current"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="High Alert Current">
                    <Input
                      name="High alert current"
                      type="number"
                      value={editData["High alert current"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* High Alert PF, Low Alert PF */}
                <div className="input-row">
                  <Form.Item label="High Alert PF">
                    <Input
                      name="High alert PF"
                      type="number"
                      value={editData["High alert PF"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Low Alert PF">
                    <Input
                      name="Low alert PF"
                      type="number"
                      value={editData["Low alert PF"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Full Load Current, Idle Amps */}
                <div className="input-row">
                  <Form.Item label="Full Load Current">
                    <Input
                      name="Full load current"
                      type="number"
                      value={editData["Full load current"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                  <Form.Item label="Idle Amps">
                    <Input
                      name="Idle Amps"
                      type="number"
                      value={editData["Idle Amps"]}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>

                {/* Distribution Point, Source */}
                <div className="input-row">
                  <Form.Item label="Distribution Point">
                    <Select
                      value={editData.distributionpoint}
                      onChange={(value) =>
                        setEditData({ ...editData, distributionpoint: value })
                      }
                    >
                      <Select.Option value="">Select Value</Select.Option>
                      <Select.Option value="Feeding Point">
                        Feeding Point
                      </Select.Option>
                      <Select.Option value="Loading Point">
                        Loading Point
                      </Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Source" required>
                    <Select
                      name="source"
                      value={editData.source}
                      onChange={(value) =>
                        setEditData({ ...editData, source: value })
                      }
                      required
                    >
                      <Select.Option value="">Select Value</Select.Option>
                      <Select.Option value="Yes">Yes</Select.Option>
                      <Select.Option value="No">No</Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* Idle kVA */}
                <div className="input-row">
                  <Form.Item label="Idle kVA">
                    <Input
                      name="idlekva"
                      type="number"
                      value={editData.idlekva}
                      onChange={handleEditChange}
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Sectionsfetch;
