import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LVformfetch.css'
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input, Button, Row, Col, Select, Modal } from "antd";
const LVformFetch = ({ onFormButtonClick }) => {
    const [lvforms, setLvforms] = useState([]);
    const [lvformseditData, setLvformsEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);


    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching incomer names', error);
        }
    };

    const fetchlvforms = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/lvforms`);
            setLvforms(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
        fetchlvforms();
    }, []);

 
    const handleEditClick = (hvform) => {
        console.log("Editing data:", hvform); // Log the data being set for editing
        setLvformsEditData(hvform);
        setIsModalVisible(true);
      };
      const handleCancel = () => {
        setIsModalVisible(false);
        setLvformsEditData(null);
      };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setLvformsEditData({ ...lvformseditData, [name]: value });
    };

    const handleEditSubmit = async () => {
     
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/LVforms/${lvformseditData._id}`, lvformseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchlvforms();
            toast.success("Grounding Points updated sucessfully")
            setLvformsEditData(null);
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='lvformsfetch-total'>
            <div id='lvformsfetch-right'>
                <div id='lvformsfetch-right-2' >
                    <div id='lvformsfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.LVHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter S. No.</th>
                                <th>Phase</th>
                                <th>Connection Load (kVA)</th>
                                <th>Threshold load</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lvforms.map((hv) => (
                                <tr key={hv._id}>
                                    <td>{hv.date}</td>
                                    <td>{hv.parentname}</td>
                                    <td>{hv.name}</td>
                                    <td>{hv.metermake}</td>
                                    <td>{hv.meterslno}</td>
                                    <td>{hv.phase}</td>
                                    <td>{hv.connectionloadinva}</td>
                                    <td>{hv.thresholdload}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(hv)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Modal
            title="Edit HV Form"
            visible={isModalVisible}
            onOk={handleEditSubmit}
            onCancel={handleCancel}
            okText="Update"
            cancelText="Cancel"
            okButtonProps={{
              style: { backgroundColor: "#252B3F", color: "#fff" }, // Change background color and text color
            }}
          >
            {lvformseditData && (
              <Form layout="vertical">
                <div className="input-row">
                  <Form.Item label="Date">
                    <Input name="date" value={lvformseditData.date} disabled />
                  </Form.Item>
                  <Form.Item label="Parent Name" name="parentname">
                    <Select
                      placeholder="Select Parent Name"
                      value={lvformseditData.parentname}
                      onChange={(value) =>
                        setLvformsEditData({
                          ...lvformseditData,
                          parentname: value,
                        })
                      }
                    >
                      {parentNames.map((name, index) => (
                        <Select.Option key={index} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Name">
                    <Input
                      name="name"
                      value={lvformseditData.name}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Meter Make">
                    <Input
                      name="metermake"
                      value={lvformseditData.metermake}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Meter S. No.">
                    <Input
                      name="meterslno"
                      value={lvformseditData.meterslno}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Phase" name="phase">
                    <Select
                      placeholder="Select Phase"
                      value={lvformseditData.phase}
                      onChange={(value) =>
                        setLvformsEditData({ ...lvformseditData, phase: value })
                      }
                    >
                      <Select.Option value="">Select Phase</Select.Option>
                      <Select.Option value="3-Phase">3-Phase</Select.Option>
                      <Select.Option value="1-Phase-Vr">1-Phase-Vr</Select.Option>
                      <Select.Option value="1-Phase-Vy">1-Phase-Vy</Select.Option>
                      <Select.Option value="1-Phase-Vb">1-Phase-Vb</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item label="Connection Load (kVA)">
                    <Input
                      type="number"
                      name="connectionloadinva"
                      value={lvformseditData.connectionloadinva}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                  <Form.Item label="Threshold load">
                    <Input
                      type="number"
                      name="thresholdload"
                      value={lvformseditData.thresholdload}
                      onChange={handleEditChange}
                      size="medium"
                    />
                  </Form.Item>
                </div>
              </Form>
            )}
          </Modal>
                </div>
            </div>
        </div>
    );
};

export default LVformFetch;
